import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Company, User, Users } from '@edgeiq/edgeiq-api-js';
// import difference from 'lodash.difference';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { useFetchAccountsList } from '../../../hooks/useFetchAccountsLIst';
import { errorHighlight } from '../../../app/constants';
import AccountCard from '../../../pages/accounts/AccountCard';
import { dispatchError } from '../../../helpers/utils';
import CardsGrid from '../../../components/CardsGrid';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import AccountAutocomplete from '../../AccountAutocomplete';

interface AttachAccountsDrawerProps {
  user: User;
  open: boolean;
  handleCloseDrawer: () => void;
  onAccountsAdded: (accountsIds: string[]) => void;
}

const AttachAccountsDrawer: React.FC<AttachAccountsDrawerProps> = ({
  user,
  open,
  handleCloseDrawer,
  onAccountsAdded,
}) => {
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const userState = useAppSelector((state: RootState) => state.user);

  const [accounts, pagination, fetchAccountsList] =
    useFetchAccountsList(errorDispatcher);
  const totalWithFilter = pagination?.total ?? 0;
  const moreToFetch = accounts.length < totalWithFilter;
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>(
    user.company_ids ?? [],
  );
  const [searchAccount, setSearchAccount] = useState<Company>();
  const [loading, setLoading] = useState(true);

  // // This should only be called once when the logged user enter to the page of a
  // const getUnattachedAccounts = (): void => {
  //   if (userState.user?.company_ids) {
  //     const ids: string[] = [];
  //     if (user.company_ids && user.company_ids.length !== 0) {
  //       // If the user has company_ids with values, then we can attach to them the difference accounts of the logged user.
  //       const diff = difference(userState.user.company_ids, user.company_ids);
  //       ids.push(...diff);
  //     } else {
  //       // If the user doesn't have company_ids with values, then we can only attach to them the accounts of the logged user.
  //       ids.push(...userState.user.company_ids);
  //     }
  //   }
  // };

  const fetchAccounts = (): void => {
    fetchAccountsList({}, false).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAccounts();
  }, [user, userState.user]);

  const checkAccountCallback =
    (accountId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedAccounts([...selectedAccounts, accountId]);
      } else {
        setSelectedAccounts(
          selectedAccounts.filter((item) => item !== accountId),
        );
      }
    };

  const handleActionCallback = (): void => {
    Promise.all(
      selectedAccounts.map(async (accountId) => {
        await Users.addCompanyId(accountId, user._id);
      }),
    )
      .then(() => {
        onAccountsAdded(selectedAccounts);
        dispatch(
          setAlert({
            highlight: 'Manage additional accounts',
            message: 'Additional account added to user.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while attaching new accounts.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectAll = (): void => {
    if (selectedAccounts.length !== accounts.length) {
      setSelectedAccounts(accounts.map((account) => account._id));
    } else {
      setSelectedAccounts([]);
    }
  };

  const handleAccountChange = (account: Company | undefined): void => {
    setSearchAccount(account);
  };

  const handleLoadMore = async (): Promise<void> => {
    return await fetchAccountsList({}, true);
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Attach"
      title="Select Accounts"
      disableAction={selectedAccounts.length === 0}
      actionCallback={handleActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <>
          <div className="my-3 w-100">
            <AccountAutocomplete
              isDisabled={false}
              includeParentId={true}
              selectedAccount={searchAccount?._id}
              placeHolder="Search account"
              onAccountChangeModel={handleAccountChange}
            />
          </div>
          <AttachItemsLayout
            allSelected={selectedAccounts.length === accounts.length}
            itemsSelected={selectedAccounts.length !== 0}
            hasItems={accounts.length !== 0}
            noItemsLabel="No accounts can be attached to the user."
            selectAllCallback={handleSelectAll}
            disableDelay={!moreToFetch}
            showLoadMore={moreToFetch}
            loadMoreHanlder={handleLoadMore}
            grid={
              <>
                {loading ? (
                  <Grid container className="loading-container">
                    <CircularProgress size={75} thickness={5} />
                  </Grid>
                ) : (
                  <CardsGrid
                    oneColumn={true}
                    containerPadding={false}
                    cards={
                      searchAccount
                        ? [searchAccount].map((account) => (
                            <AttachItemCard
                              content={
                                <AccountCard
                                  key={account._id}
                                  company={account}
                                />
                              }
                              checked={selectedAccounts.includes(account._id)}
                              checkboxCallback={checkAccountCallback}
                              id={account._id}
                            />
                          ))
                        : accounts.map((account) => (
                            <AttachItemCard
                              content={
                                <AccountCard
                                  key={account._id}
                                  company={account}
                                />
                              }
                              checked={selectedAccounts.includes(account._id)}
                              checkboxCallback={checkAccountCallback}
                              id={account._id}
                            />
                          ))
                    }
                  />
                )}
              </>
            }
          />
        </>
      }
    />
  );
};

export default AttachAccountsDrawer;
