import React from 'react';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { GATEWAY_TYPE, logsTypesMap } from '../../app/constants';
import useStyles from './styles';

interface DeviceLogConfigProps {
  localLog?: string;
  forwardLog?: string;
  deviceType?: DeviceType;
  onChangeLogConfig: (prop: string, value: string) => void;
}

const DeviceLogConfig: React.FC<DeviceLogConfigProps> = ({
  localLog,
  forwardLog,
  deviceType,
  onChangeLogConfig,
}) => {
  const classes = useStyles();

  const logsTypes = Object.keys(logsTypesMap);

  const handleChangeLog =
    (porp: string) =>
    (event: SelectChangeEvent): void => {
      onChangeLogConfig(porp, event.target.value as string);
    };

  const isDisabled = !deviceType || deviceType.type !== GATEWAY_TYPE;

  return isDisabled ? (
    <Typography
      variant="button"
      component="div"
      className={clsx('mb-1', classes.configTitle)}
    >
      {!deviceType && 'Choose a device profile to configure logging.'}
      {deviceType &&
        `There is no logging configuration for device profile: ${deviceType?.name}.`}
    </Typography>
  ) : (
    <>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-1', classes.configTitle)}
      >
        Select device local logs
      </Typography>
      <FormControl className={clsx('mb-6', classes.input)}>
        <Select
          displayEmpty
          value={localLog ?? 'error'}
          onChange={handleChangeLog('local_level')}
        >
          {logsTypes.map((logType, index) => (
            <MenuItem dense key={index} value={logType}>
              {logsTypesMap[logType]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-1', classes.configTitle)}
      >
        Forward Log Level
      </Typography>
      <FormControl className={clsx('mb-6', classes.input)}>
        <Select
          displayEmpty
          value={forwardLog ?? 'error'}
          onChange={handleChangeLog('forward_level')}
        >
          {logsTypes.map((logType, index) => (
            <MenuItem dense key={index} value={logType}>
              {logsTypesMap[logType]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default DeviceLogConfig;
