import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  Chip,
  Typography,
  Grid,
} from '@mui/material';
import DeviceIcon from '@mui/icons-material/Memory';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Stats } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  errorHighlight,
  heartbeatMetricsLabels,
  metricsColor,
} from '../../app/constants';
import parseFilters from '../../helpers/parseFilters';
import useStyles from './styles';

interface IMetricsData {
  name: string;
  value: number;
}

const HeartbeatChart: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [metricsData, setMetricsData] = useState<IMetricsData[]>([]);

  const handleMetrics = (metrics: Record<string, number>): void => {
    const metricsArray = [];
    for (const name in metrics) {
      metricsArray.push({
        name,
        value: metrics[name],
      });
    }
    setMetricsData(metricsArray);
  };

  useEffect(() => {
    Stats.devicesByHeartbeat(parseFilters({}))
      .then(({ as_expected, inconsistent, not_responsive, never_reported }) => {
        handleMetrics({
          as_expected,
          inconsistent,
          never_reported,
          not_responsive,
        });
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Heartbeat Status
        <Link className={clsx('ml-4', classes.linkButton)} to={'/devices'}>
          <Typography variant="button">View More</Typography>
        </Link>
      </Typography>
      <Grid container flexDirection={'row'}>
        <Grid item sm={6} xs={12}>
          <div
            style={{
              height: 300,
              width: '100%',
            }}
          >
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={metricsData}
                  fill="#8884d8"
                  dataKey="value"
                  innerRadius={45}
                  outerRadius={75}
                >
                  {metricsData.map((_entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={metricsColor[_entry.name].progressMainColor}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="pt-6">
            <List dense disablePadding>
              {metricsData.map((metric, index) => {
                return (
                  <ListItem
                    key={`heartbeat-metric-${index}`}
                    secondaryAction={
                      <Chip
                        label={metric.value}
                        style={{
                          backgroundColor:
                            metricsColor[metric.name].progressMainColor,
                          color: 'white',
                        }}
                      />
                    }
                  >
                    <ListItemIcon>
                      <DeviceIcon />
                    </ListItemIcon>
                    <Typography variant="body1" className="pr-5">
                      {heartbeatMetricsLabels[metric.name]}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeartbeatChart;
