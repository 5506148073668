import React from 'react';
import { Grid } from '@mui/material';
import { Setting, SettingInput } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../components/TextInput';
import { SettingsEditorData } from '../../../models/settings';
import SettingsEditor from '../ConfigurationForm/SettingsEditor';
import useStyles from './styles';

interface SettingsFormProps {
  action: string;
  settings: Setting | SettingInput;
  settingsEditorData: SettingsEditorData;
  onInputChange: (prop: string, value: string) => void;
  onSettingsEditorChange: (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ) => void;
}

const SettingsForm: React.FC<SettingsFormProps> = ({
  action,
  settings,
  settingsEditorData,
  onInputChange,
  onSettingsEditorChange,
}) => {
  const _classes = useStyles();

  const handleInputChange = (prop: string, value: string | number): void => {
    onInputChange(prop, value as string);
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={6}>
        <TextInput
          prop="name"
          label="Name *"
          placeholder=""
          type="text"
          value={settings?.name as string}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          prop="description"
          label="Description"
          placeholder=""
          type="text"
          value={settings?.description as string}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <SettingsEditor
          action={action}
          data={settingsEditorData}
          onInputChange={onSettingsEditorChange}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsForm;
