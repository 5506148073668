import React from 'react';
import { Grid } from '@mui/material';
import { Device } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../components/TextInput';
import RightDrawer from '../../components/RightDrawer/RightDrawer';
interface DeviceLwm2mParametersDrawerProps {
  open: boolean;
  loading: boolean;
  device?: Device;
  handleCloseDrawer: () => void;
  onUpdateData: () => void;
  onInputChange: (prop: string, value: string | number) => void;
}

const DeviceLwm2mParametersDrawer: React.FC<
  DeviceLwm2mParametersDrawerProps
> = ({
  open,
  loading,
  device,
  handleCloseDrawer,
  onUpdateData,
  onInputChange,
}) => {
  return (
    <RightDrawer
      open={open}
      actionLabel="Update"
      title="LWM2M Connection parameters"
      size="750"
      disableAction={false}
      actionLoading={loading}
      actionCallback={onUpdateData}
      onCloseDrawer={handleCloseDrawer}
      content={
        device ? (
          <Grid container>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6} className="mt-6">
                <TextInput
                  label="LwM2M Lifetime (seconds)"
                  value={device.lwm2m_lifetime}
                  type="number"
                  prop="lwm2m_lifetime"
                  onInputChange={onInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                placeholder="Bootstrap Pre-shared Key Identity"
                label="Bootstrap Pre-shared Key Identity"
                prop="lwm2m_bootstrap_psk_identity"
                value={device?.lwm2m_bootstrap_psk_identity}
                onInputChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                placeholder="Bootstrap Pre-shared Key"
                label="Bootstrap Pre-shared Key"
                prop="lwm2m_bootstrap_psk"
                value={device?.lwm2m_bootstrap_psk}
                onInputChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                placeholder="Bootstrap Pre-shared Key Hash (SHA-256)"
                label="Bootstrap Pre-shared Key Hash (SHA-256)"
                prop="lwm2m_bootstrap_psk_hash"
                value={device?.lwm2m_bootstrap_psk_hash}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                label="Server Pre-shared Key Identity"
                placeholder="Server Pre-shared Key Identity"
                prop="lwm2m_server_psk_identity"
                value={device?.lwm2m_server_psk_identity}
                onInputChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                label="Server Pre-shared Key"
                placeholder="Server Pre-shared Key"
                prop="lwm2m_server_psk"
                value={device?.lwm2m_server_psk}
                onInputChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                label="Server Pre-shared Key Hash (SHA-256)"
                placeholder="Server Pre-shared Key Hash (SHA-256)"
                prop="lwm2m_server_psk_hash"
                aria-label="Server Pre-shared Key Hash (SHA-256)"
                value={device?.lwm2m_server_psk_hash}
                disabled={true}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )
      }
    />
  );
};

export default DeviceLwm2mParametersDrawer;
