import { WorkflowAction } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const ActionsTypes = {
  SET_ACTIONS: 'SET_ACTIONS',
  SET_OPTIONS_ACTIONS: 'SET_OPTIONS_ACTIONS',
  SET_SELECTED_ACTIONS: 'SET_SELECTED_ACTIONS',
};

export const setStateActions = (
  workflows: WorkflowAction[],
): ActionsAction => ({
  data: workflows,
  type: ActionsTypes.SET_ACTIONS,
});

export const setSelectedActions = (
  workflows: WorkflowAction[],
): ActionsAction => ({
  data: workflows,
  type: ActionsTypes.SET_SELECTED_ACTIONS,
});

export const setOptionsActions = (
  workflows: WorkflowAction[],
): ActionsAction => ({
  data: workflows,
  type: ActionsTypes.SET_OPTIONS_ACTIONS,
});

export const getActionSelector = createSelector(
  (state: ActionsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.actions.length) {
      return state.action;
    }
    return state.actions.find(
      (integration: WorkflowAction) => integration._id === id,
    );
  },
);

export type ActionsState = {
  action: WorkflowAction | null;
  actions: WorkflowAction[];
  optionsActions: WorkflowAction[];
  selectedActions: WorkflowAction[];
};

// INITIAL STATE
const actionsInitialState: ActionsState = {
  action: null,
  actions: [],
  optionsActions: [],
  selectedActions: [],
};

export type ActionsAction = {
  data: WorkflowAction[] | WorkflowAction;
  type: string;
};

const actionsReducer = (
  state = actionsInitialState,
  action: ActionsAction,
): ActionsState => {
  switch (action.type) {
    case ActionsTypes.SET_ACTIONS:
      return {
        ...state,
        actions: action.data as WorkflowAction[],
      };

    case ActionsTypes.SET_SELECTED_ACTIONS:
      return {
        ...state,
        selectedActions: action.data as WorkflowAction[],
      };

    case ActionsTypes.SET_OPTIONS_ACTIONS:
      return {
        ...state,
        optionsActions: action.data as WorkflowAction[],
      };

    default:
      return state;
  }
};

export default actionsReducer;
