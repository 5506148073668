import React, { Fragment } from 'react';
import { WhereCondition } from '@edgeiq/edgeiq-api-js';
import { Button, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  WHERE_CONDITIONS_OPERATOR_OPTIONS,
  WHERE_CONDITIONS_TYPES_OPTIONS,
} from '../../../../constants/policies';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface WhereConditionSectionProps {
  whereConditions: WhereCondition[] | undefined;
  derivedValueIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
  onAddWhereCondition: (derivedValueIndex: number) => void;
  onRemoveWhereCondition: (
    derivedValueIndex: number,
    whereConditionIndex: number,
  ) => void;
}

const WhereConditionSection: React.FC<WhereConditionSectionProps> = ({
  whereConditions,
  derivedValueIndex,
  onInputChange,
  disabled,
  onAddWhereCondition,
  onRemoveWhereCondition,
}) => {
  const handleAddWhereCondition = () => (): void => {
    onAddWhereCondition(derivedValueIndex);
  };

  const handleClickRemoveWhereCondition =
    (whereConditionIndex: number) => (): void => {
      onRemoveWhereCondition(derivedValueIndex, whereConditionIndex);
    };

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" component="div">
          Where Conditions
        </Typography>
      </Grid>
      {Array.isArray(whereConditions) &&
        whereConditions.map((whereCondition, whereConditionIndex) => (
          <Fragment key={whereConditionIndex}>
            <Grid item xs={6}>
              <SelectInput
                label="Type"
                prop={`${derivedValueIndex}.where_condition.${whereConditionIndex}.type`}
                value={whereCondition.type}
                onSelectChange={onInputChange}
                disabled={disabled}
                options={Object.keys(WHERE_CONDITIONS_TYPES_OPTIONS).map(
                  (key, index) => (
                    <MenuItem className="m-4 p-2" key={index} dense value={key}>
                      {WHERE_CONDITIONS_TYPES_OPTIONS[key]}
                    </MenuItem>
                  ),
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label="Property"
                prop={`${derivedValueIndex}.where_condition.${whereConditionIndex}.property`}
                placeholder=""
                value={whereCondition.property}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={6}>
              <SelectInput
                label="Operator"
                prop={`${derivedValueIndex}.where_condition.${whereConditionIndex}.operator`}
                value={whereCondition.operator}
                onSelectChange={onInputChange}
                disabled={disabled}
                options={Object.keys(WHERE_CONDITIONS_OPERATOR_OPTIONS).map(
                  (key, index) => (
                    <MenuItem className="m-4 p-2" key={index} dense value={key}>
                      {WHERE_CONDITIONS_OPERATOR_OPTIONS[key]}
                    </MenuItem>
                  ),
                )}
              />
            </Grid>

            <Grid item xs={5}>
              <TextInput
                label="Value"
                prop={`${derivedValueIndex}.where_condition.${whereConditionIndex}.value`}
                placeholder=""
                value={whereCondition.value as string}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} className="mt-6">
              <IconButton
                onClick={handleClickRemoveWhereCondition(whereConditionIndex)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Fragment>
        ))}
      <Grid item xs={6} className="d-flex flex-items-center">
        <Button
          variant="outlined"
          size="medium"
          onClick={handleAddWhereCondition()}
          startIcon={<AddIcon />}
          disabled={disabled}
        >
          Add Where Condition
        </Button>
      </Grid>
    </Grid>
  );
};

export default WhereConditionSection;
