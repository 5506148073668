import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { Company, WorkflowAction } from '@edgeiq/edgeiq-api-js';
import { PlaylistAddCheckOutlined as CheckListIcon } from '@mui/icons-material';
import clsx from 'clsx';

import useStyles from './styles';

interface ActionCardProps {
  action: WorkflowAction;
  companies: Company[];
}

const ActionCard: React.FC<ActionCardProps> = ({ action, companies }) => {
  const classes = useStyles();
  const company = companies?.find(
    (userCompany) => userCompany._id === action.company_id,
  );

  return (
    <Box>
      <Tooltip placement="top" title={action.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', classes.title)}
        >
          {action.name}
        </Typography>
      </Tooltip>

      <Tooltip placement="top" title="Type">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <CheckListIcon fontSize="medium" className="mr-2" />
          {action.type}
        </Typography>
      </Tooltip>

      {company && (
        <Tooltip placement="top" title="Account">
          <Typography
            variant="overline"
            component="div"
            className={classes.tag}
          >
            {company.name}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default ActionCard;
