import React from 'react';
import { Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RuleInput } from '@edgeiq/edgeiq-api-js';

import SelectInput from '../../../../components/SelectInput';
import {
  DEVICE_ERROR,
  POLICY_CONDITION_TYPES,
  POLICY_DEVICE_EDGE_ERRORS,
  POLICY_DEVICE_ERRORS_TYPES,
  PROPERTY_EVALUATION,
} from '../../../../constants/policies';
import { checkConditionDisabled } from '../helper';
import PropertyEvaluationSection from './PropertyEvaluationSection';
import PolicyConditionRuleSection from './PolicyConditionRuleSection';

interface PolicyConditionSectionProps {
  newPolicy: RuleInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  disabled?: boolean;
  policyType: string;
  onAddNewValue: (index?: number) => void;
  onRemoveValue: (index: number, conditionIndex?: number) => void;
  onAddNewCondition: () => void;
  onRemoveCondition: (conditionIndex: number) => void;
}

const PolicyConditionSection: React.FC<PolicyConditionSectionProps> = ({
  newPolicy,
  onInputChange,
  disabled,
  policyType,
  onAddNewValue,
  onRemoveValue,
  onAddNewCondition,
  onRemoveCondition,
}) => {
  const edgeOption = (errorType: string): boolean => {
    return (
      newPolicy.cloud_rule && POLICY_DEVICE_EDGE_ERRORS.includes(errorType)
    );
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <SelectInput
          label="Select condition"
          prop="policyType"
          value={policyType}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(POLICY_CONDITION_TYPES).map((key, index) => (
            <MenuItem
              className="m-4 p-2"
              key={index}
              dense
              value={key}
              disabled={checkConditionDisabled(newPolicy.cloud_rule, key)}
            >
              {POLICY_CONDITION_TYPES[key]}
            </MenuItem>
          ))}
        />
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due type compatibility.
        </Typography>
      </Grid>

      {newPolicy.rule_condition.type === DEVICE_ERROR && (
        <Grid item xs={12}>
          <SelectInput
            label="Select Error Type"
            prop="error_type"
            value={newPolicy.rule_condition.error_type}
            onSelectChange={onInputChange}
            disabled={disabled}
            options={Object.keys(POLICY_DEVICE_ERRORS_TYPES).map(
              (key, index) => (
                <MenuItem
                  className="m-4 p-2"
                  dense
                  key={index}
                  value={key}
                  disabled={edgeOption(key)}
                >
                  {POLICY_DEVICE_ERRORS_TYPES[key]}
                  {edgeOption(key) ? ` ('On the Edge' only)` : ''}
                </MenuItem>
              ),
            )}
          />
        </Grid>
      )}

      {policyType === PROPERTY_EVALUATION && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {Array.isArray(newPolicy.rule_condition.rule_conditions) && (
            <PolicyConditionRuleSection
              newPolicy={newPolicy}
              onInputChange={onInputChange}
            />
          )}

          {Array.isArray(newPolicy.rule_condition.rule_conditions) ? (
            newPolicy.rule_condition.rule_conditions.map((item, index) => (
              <Grid item xs={12} key={index}>
                {item && (
                  <PropertyEvaluationSection
                    ruleCondition={item}
                    conditionIndex={index}
                    onInputChange={onInputChange}
                    onAddNewValue={onAddNewValue}
                    onRemoveValue={onRemoveValue}
                    onRemoveCondition={onRemoveCondition}
                  />
                )}
              </Grid>
            ))
          ) : (
            <>
              <Grid item xs={12}>
                <PropertyEvaluationSection
                  ruleCondition={newPolicy.rule_condition}
                  onInputChange={onInputChange}
                  onAddNewValue={onAddNewValue}
                  onRemoveValue={onRemoveValue}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button
              className="w-100"
              variant="outlined"
              size="large"
              onClick={onAddNewCondition}
              startIcon={<AddIcon />}
              disabled={disabled}
            >
              Add Another Condition
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PolicyConditionSection;
