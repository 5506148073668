import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Command,
  DeviceType,
  Setting,
  SettingFilters,
  SettingInput,
  Settings,
} from '@edgeiq/edgeiq-api-js';
import debounce from 'lodash.debounce';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  setNewSettingInput,
  newSettingInitialState,
} from '../../../redux/reducers/settings.reducer';
import {
  errorHighlight,
  SEARCH_LETTERS_QUANTITY,
} from '../../../app/constants';
import SettingsList from '../../../containers/SettingsList';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';

interface ConfigurationSettingsProp {
  deviceTypes: DeviceType[];
  sendCommands: Command[];
}

const ConfigurationSettings: React.FC<ConfigurationSettingsProp> = ({
  deviceTypes,
  sendCommands,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const configuration = useAppSelector(
    (state: RootState) => state.configurations.newConfiguration,
  );
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [settingNameFilter, setSettingNameFilter] = useState('');
  const [settingTypeFilter, setSettingTypeFilter] = useState('created');

  const handleCallbackProp = (value: string): void => {
    setSettingNameFilter(value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleCallbackProp, 1000),
    [],
  );

  const getSettings = (): void => {
    if (configuration && configuration._id) {
      setLoading(true);

      const filters: SettingFilters = {
        configuration_id: {
          operator: 'eq',
          value: configuration._id as string,
        },
      };

      if (settingNameFilter) {
        filters.name = {
          operator: 'like',
          value: settingNameFilter,
        };
      }

      if (settingTypeFilter) {
        filters.is_self_reported = {
          operator: 'eq',
          value: settingTypeFilter === 'reported',
        };
      }

      Settings.list(filters)
        .then((res) => {
          setSettings(res.settings);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!loading) {
      getSettings();
    }
  }, [settingNameFilter, settingTypeFilter]);

  const handleAddSettings = (): void => {
    dispatch(
      setNewSettingInput({
        ...newSettingInitialState,
      } as SettingInput),
    );
    navigate(`/configuration/${configuration?._id}/new-settings`);
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    const realValue = value as string;
    setSearchInputValue(realValue);
    debouncedChangeHandler.cancel();
    if (realValue.length >= SEARCH_LETTERS_QUANTITY || realValue.length === 0) {
      debouncedChangeHandler(realValue);
    }
  };

  const handleClickClear = (): void => {
    setSearchInputValue('');
    handleCallbackProp('');
  };

  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  const onChangeSearchType = (_prop: string, value: string): void => {
    setSettingTypeFilter(value);
  };

  return (
    <Grid container>
      <Grid component={Paper} item xs={12} className="p-4 shadow">
        <Box className="mb-7">
          <Button
            data-cy="tab-page-contained-button"
            variant="contained"
            size="large"
            className="mb-"
            onClick={handleAddSettings}
          >
            Create New Settings
          </Button>
        </Box>
        <Box className="mb-4">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                placeholder="Search setting by name"
                prop="setting-name-filter"
                value={searchInputValue}
                onInputChange={handleInputChange}
                helperText={`Type in at least ${SEARCH_LETTERS_QUANTITY} characters to filter`}
                endAdornment={
                  <InputAdornment data-cy="clear-name-filter" position="end">
                    {searchInputValue && (
                      <IconButton
                        aria-label="clear-name-filter"
                        onClick={handleClickClear}
                        onMouseDown={handleMouseDown}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectInput
                prop="setting-type-filter"
                value={settingTypeFilter}
                onSelectChange={onChangeSearchType}
                fullWidth={false}
                options={[
                  <MenuItem
                    key="created"
                    className="m-4 p-2"
                    dense
                    value="created"
                  >
                    Created
                  </MenuItem>,
                  <MenuItem
                    key="reported"
                    className="m-4 p-2"
                    dense
                    value="reported"
                  >
                    Reported
                  </MenuItem>,
                ]}
              />
            </Grid>
          </Grid>
        </Box>

        <SettingsList
          attachedSettings={false}
          unlinkableSettings={false}
          configuration={configuration}
          settings={settings}
          loading={loading}
          deviceTypes={deviceTypes}
          sendCommands={sendCommands}
          reloadSettings={getSettings}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationSettings;
