import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { Typography } from '@mui/material';
import { Stats } from '@edgeiq/edgeiq-api-js';
import parseFilters from '../../helpers/parseFilters';
import { useAppDispatch } from '../../redux/hooks';
import { errorHighlight } from '../../app/constants';
import { setAlert } from '../../redux/reducers/alert.reducer';
import useStyles from './styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface DeviceTypeChartData {
  name: string;
  count: number;
}
export interface IDeviceTypeValues {
  [key: string]: {
    [key: string]: string | number;
  };
}

const CHART_COLORS = '#40A0DB';

const DeviceByTypeChart: React.FC = () => {
  const dispatch = useAppDispatch();

  const [chartData, setChartData] = useState<DeviceTypeChartData[]>([]);

  const classes = useStyles();

  useEffect(() => {
    Stats.devicesByType(parseFilters({}))
      .then((res) => {
        const labelsDataArr: DeviceTypeChartData[] = [];
        const newChartData: DeviceTypeChartData[] = [];

        Object.keys(res).forEach((key) => {
          const values: IDeviceTypeValues = res[key as never];
          const totalDevices = Object.values(values).reduce((a: number, b) => {
            const count: number = b.count as number;
            const indexOfData = newChartData.findIndex(
              (e) => e.name === b.name,
            );
            if (indexOfData < 0) {
              newChartData.push({
                count,
                name: b.name as string,
              });
            } else {
              newChartData[indexOfData].count += count;
            }
            return a + count;
          }, 0);
          labelsDataArr.push({
            count: totalDevices,
            name: key,
          });
        });
        newChartData.sort((a, b) => {
          return b.count - a.count;
        });
        setChartData(newChartData.slice(0, 2));
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Device by Type
        <Link
          className={clsx('ml-4', classes.linkButton)}
          to={'/device-profiles'}
        >
          <Typography variant="button">View More</Typography>
        </Link>
      </Typography>

      <div
        style={{
          height: 300,
          width: '100%',
        }}
      >
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{
              bottom: 5,
              top: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count">
              {chartData.map((_e, index) => {
                return <Cell key={`cell-${index}`} fill={CHART_COLORS}></Cell>;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DeviceByTypeChart;
