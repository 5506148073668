import React, { useEffect, useState } from 'react';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { DeviceConfig, DeviceType, DeviceConfigs } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  optionsPaginationsFilter,
  errorHighlight,
  GATEWAY_TYPE,
} from '../../app/constants';
import useStyles from './styles';

interface NetworkConfigProps {
  networkConfigId?: string;
  deviceType?: DeviceType;
  onChangeNetworkConfig: (prop: string, integrationId: string) => void;
}

const NetworkConfig: React.FC<NetworkConfigProps> = ({
  networkConfigId,
  deviceType,
  onChangeNetworkConfig,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // TODO: add network configs into redux

  const [networkConfigs, setNetworkConfigs] = useState<DeviceConfig[]>([]);
  const [visibleNetworkConfigs, setVisibleNetworkConfigs] = useState<
    DeviceConfig[]
  >([]);

  useEffect(() => {
    DeviceConfigs.list({}, optionsPaginationsFilter)
      .then((result) => {
        setNetworkConfigs(result.deviceConfigs);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      });
  }, []);

  const handleChangeNetworkConfig = (event: SelectChangeEvent): void => {
    onChangeNetworkConfig('device_config_id', event.target.value as string);
  };

  useEffect(() => {
    if (deviceType) {
      setVisibleNetworkConfigs(
        networkConfigs.filter((e) => {
          return e.device_type_id === deviceType?._id;
        }),
      );
    }
  }, [deviceType]);

  const isDisabled = !deviceType || deviceType.type !== GATEWAY_TYPE;

  return isDisabled ? (
    <Typography
      variant="button"
      component="div"
      className={clsx('mb-1', classes.configTitle)}
    >
      {!deviceType &&
        'Select a device profile to see the available network configs.'}
      {deviceType &&
        `There are no network configs for this device profile: ${deviceType?.name}.`}
    </Typography>
  ) : (
    <>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-1', classes.configTitle)}
      >
        Select a Network Config
      </Typography>
      <FormControl className={clsx('mb-6', classes.input)}>
        <Select
          displayEmpty
          value={networkConfigId ?? ''}
          onChange={handleChangeNetworkConfig}
        >
          <MenuItem dense value="">
            Network Configuration
          </MenuItem>
          {visibleNetworkConfigs.map((config, index) => (
            <MenuItem dense key={index} value={config._id}>
              {config.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default NetworkConfig;
