import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Setting, SettingsApplicationRecord } from '@edgeiq/edgeiq-api-js';
import {
  CompareArrows as CompareIcon,
  PlayArrow as PlayIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import timeHelpers from '../../helpers/timeHelpers';
import { settingApplicationRecordThemeMap } from '../../app/constants';
import ColoredBox from '../../components/ColoredBox';
import useStyles from './styles';

export interface SettingApplicationListCardProps {
  settingApplicationRecord: SettingsApplicationRecord;
  openSettings: (
    settingApplication: SettingsApplicationRecord,
    type: string,
  ) => void;
  reapplySetting: (settingApplication: SettingsApplicationRecord) => void;
  viewApplication: (settingApplication: SettingsApplicationRecord) => void;
  settingsToCompare?: Setting[]; // Can only be 2 settings at a time.
  showCompareButton?: boolean;
  onChoosingSetting?: (setting: Setting) => void;
}

const SettingApplicationListCard: React.FC<SettingApplicationListCardProps> = ({
  settingApplicationRecord,
  openSettings,
  reapplySetting,
  viewApplication,
  settingsToCompare,
  showCompareButton,
  onChoosingSetting,
}) => {
  const classes = useStyles();
  const creationDate = timeHelpers.getDate(settingApplicationRecord.created_at);
  const executionStatuses =
    settingApplicationRecord.command_execution?.execution_statuses;

  const handleOpenSetting = (): void =>
    openSettings(settingApplicationRecord, 'settings');
  const handleViewApplication = (): void =>
    viewApplication(settingApplicationRecord);
  const handleReapply = (): void => reapplySetting(settingApplicationRecord);

  const isItHighlighted = (setting: Setting): boolean => {
    if (settingsToCompare) {
      return !!settingsToCompare.find(
        (s) => s._id === setting._id && s.version === setting.version,
      );
    }
    return false;
  };

  const handleCompareSetting = (setting: Setting) => (): void => {
    if (onChoosingSetting) {
      onChoosingSetting(setting);
    }
  };

  return (
    <Box className={clsx(classes.boxContainer, 'mb-4 position-relative')}>
      <div>
        {executionStatuses && (
          <div className={clsx('px-2 py-1 br-1 mb-2', classes.container)}>
            <Typography
              data-testid="display-status"
              variant="caption"
              className={classes.label}
            >
              {executionStatuses[0].status}
            </Typography>
          </div>
        )}
        <Tooltip placement="top-start" title="Open Setting">
          <Typography
            data-testid="tooltip-setting-drawer"
            data-cy={`open-application-setting-${settingApplicationRecord._id}`}
            variant="button"
            component="div"
            noWrap
            className={clsx('mb-1', classes.title)}
            onClick={handleOpenSetting}
          >
            {settingApplicationRecord.setting.name}
            <Typography
              variant="overline"
              component="span"
              className={clsx(classes.subtitle, 'fw-700 ml-2')}
            >
              {`V${settingApplicationRecord.version}`}
            </Typography>
          </Typography>
        </Tooltip>
      </div>
      <Typography
        variant="overline"
        component="div"
        className={clsx(classes.subtitle, 'mb-2')}
      >
        {creationDate}
      </Typography>
      <div className="mb-2">
        <Tooltip placement="top-start" title="View Application Record">
          <IconButton
            data-cy={`view-application-${settingApplicationRecord._id}`}
            edge="end"
            size="small"
            className="mr-3"
            aria-label="view setting application record"
            onClick={handleViewApplication}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {settingApplicationRecord.type === 'sent' && (
          <Tooltip title="Apply settings" placement="top-end">
            <IconButton
              className="mr-2"
              data-cy={`apply-application-${settingApplicationRecord._id}`}
              size="small"
              aria-label="reapply setting record"
              onClick={handleReapply}
            >
              <PlayIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {showCompareButton && (
          <Tooltip title="Compare Setting" placement="top-end">
            <IconButton
              data-cy={`compare-application-setting-${settingApplicationRecord._id}-${settingApplicationRecord.setting.version}`}
              size="small"
              aria-label="compare-setting"
              disabled={
                settingsToCompare?.length === 2 &&
                !isItHighlighted(settingApplicationRecord.setting)
              }
              onClick={handleCompareSetting(settingApplicationRecord.setting)}
            >
              <CompareIcon
                fontSize="small"
                color={
                  isItHighlighted(settingApplicationRecord.setting)
                    ? 'primary'
                    : 'inherit'
                }
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.statusContainer}>
        <ColoredBox
          type="application_type"
          value={settingApplicationRecord.type}
          colorTheme={
            settingApplicationRecordThemeMap[settingApplicationRecord.type]
          }
        />
      </div>
    </Box>
  );
};

export default SettingApplicationListCard;
