import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { Reports } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import timeHelpers from '../../helpers/timeHelpers';

interface IHeartbeatTableData {
  device_datetime: string;
  device_name: string;
}

const HeartBeatTable: React.FC = () => {
  const [heartbeatTableData, setHeartbeatTableData] = useState<
    IHeartbeatTableData[]
  >([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    Reports.list(
      {
        type: {
          key: 'type',
          operator: 'eq',
          value: 'heartbeat',
        },
      },
      {
        itemsPerPage: 6,
        page: 1,
      },
    )
      .then((res) => {
        const mappedData = res.reports.map((d) => {
          return {
            device_datetime: d.device_datetime,
            device_name: d.device_name,
          };
        });
        setHeartbeatTableData(mappedData);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoToHeartbeats = (): void => {
    navigate(`/messages#heartbeats`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Heartbeats
        <Button
          className="p-0"
          color="primary"
          variant="text"
          type="button"
          onClick={handleGoToHeartbeats}
        >
          <Typography variant="button">View More</Typography>
        </Button>
      </Typography>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Date/Time</TableCell>
                <TableCell align="left">Device</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {heartbeatTableData.map((row, index) => (
                <TableRow
                  key={`${row.device_name}-${index}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    {row.device_datetime && (
                      <>
                        {timeHelpers.getDate(row.device_datetime, false, false)}
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{row.device_name}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default HeartBeatTable;
