import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {
  DeviceTransferRequest,
  DeviceTransferRequests,
  DeviceTypes,
  PaginationFilter,
  Filters,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { RootState } from '../../redux/store';
import { setDeviceTransfers } from '../../redux/reducers/deviceTransfer.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setOptionsDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ActionDialog from '../../components/ActionDialog';
import Header from '../../components/Header';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import { SortingOption } from '../../models/common';
import AcceptDeviceTransferDrawer from '../../containers/RightDrawer/AcceptDeviceTransfer/AcceptDeviceTransferDrawer';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
  TRANFERS_RECEIVED,
  TRANFERS_SENT,
} from '../../app/constants';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import DeviceTranfersType from './DeviceTransferType';
import DeviceTransferFilters from './DeviceTransferFilter';
import DeviceTransferCard from './DeviceTransferCard';
import { DeviceTransferColumns } from './columns';
import useStyles from './styles';
import { GENERIC_SORTING } from '../../constants/sortings';

const DeviceTranfers: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const { userCompanies, user } = useAppSelector(
    (state: RootState) => state.user,
  );
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const { deviceTransfers, deviceTransferType } = useAppSelector(
    (state: RootState) => state.deviceTransfer,
  );

  const [selectedDeviceTransfers, setSelectedDeviceTransfer] = useState<
    string[]
  >([]);
  const [deviceTransfersState, setStateDeviceTransfers] =
    useState<DeviceTransferRequest[]>(deviceTransfers);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.deviceTransfers.sortBy, GENERIC_SORTING),
  );
  const [open, setOpen] = useState(false);

  const [selectedView, setSelectedView] = useState(
    filters.deviceTransfers.view,
  );
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getDeviceTransfer = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    let filtersToApply: { [key: string]: string } =
      filters.deviceTransfers.filters || {};
    const userCompany: string = user?.company_id || '';
    if (TRANFERS_RECEIVED === deviceTransferType) {
      filtersToApply = {
        ...filters.deviceTransfers.filters,
        ['to_company_id']: userCompany,
      };
    } else if (TRANFERS_SENT === deviceTransferType) {
      filtersToApply = {
        ...filters.deviceTransfers.filters,
        ['from_company_id']: userCompany,
      };
    }

    const parseFiltersElements: Filters = parseFilters(filtersToApply ?? {});
    if (
      filters &&
      filters.deviceTransfers &&
      (!filters.deviceTransfers.filters ||
        filters.deviceTransfers.filters.status === '')
    ) {
      parseFiltersElements.filters?.push({
        key: 'status',
        operator: 'ne',
        value: 'completed',
      });
    }

    DeviceTransferRequests.list(parseFiltersElements, pagination)
      .then((result) => {
        setStateDeviceTransfers(result.deviceTransferRequests);
        dispatch(setDeviceTransfers(result.deviceTransferRequests));
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDeviceTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const getTransfersData = (): void => {
    setLoading(true);
    setSelectedDeviceTransfer([]);
    getDeviceTransfer(1);
  };

  useEffect(() => {
    getTransfersData();
  }, [filters.deviceTransfers, deviceTransferType]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getDeviceTransfer(page + 1, true);
  };

  const checkProfileCallback =
    (transferId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedDeviceTransfer([...selectedDeviceTransfers, transferId]);
      } else {
        setSelectedDeviceTransfer(
          selectedDeviceTransfers.filter((item) => item !== transferId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'deviceTransfers'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'deviceTransfers'));
    setSelectedView(view);
  };

  const handleSelectAll = (): void => {
    if (selectedDeviceTransfers.length !== deviceTransfersState.length) {
      setSelectedDeviceTransfer(
        deviceTransfersState.map((transfer) => transfer._id),
      );
    } else {
      setSelectedDeviceTransfer([]);
    }
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);

    const deleteApis = selectedDeviceTransfers.map((e) => {
      return DeviceTransferRequests.delete(e);
    });

    Promise.all(deleteApis)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedDeviceTransfers.length,
              'Device Transfer Request',
              'Device Transfer Requests',
            ),
            type: 'success',
          }),
        );
        setStateDeviceTransfers(
          deviceTransfersState.filter(
            (transfer) => !selectedDeviceTransfers.includes(transfer._id),
          ),
        );
        setSelectedDeviceTransfer([]);
        setTotal(total - selectedDeviceTransfers.length);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const selectedDeviceTransfer = deviceTransfersState.filter((transfer) =>
    selectedDeviceTransfers.includes(transfer._id),
  );

  const handleOpenTransfers = (): void => {
    setOpen(true);
  };

  const handleCloseTransfers = (update = false): void => {
    setOpen(false);
    if (update) {
      getTransfersData();
    }
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Device Transfer"
        nextTitleContent={<DeviceTranfersType />}
      />
      <DeviceTransferFilters
        total={deviceTransfersState.length}
        accountPlaceHolder={
          deviceTransferType === TRANFERS_RECEIVED
            ? 'From account'
            : 'To account'
        }
      />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={GENERIC_SORTING}
        selectedLabel="transfer"
        viewsOptions={genericViewOptions}
        itemsSelected={selectedDeviceTransfers.length !== 0}
        itemsSelectedCount={selectedDeviceTransfers.length}
        allSelected={
          !!deviceTransfersState.length &&
          selectedDeviceTransfers.length === deviceTransfersState.length
        }
        selectedActions={
          <Button
            className="ml-4"
            variant="outlined"
            size="medium"
            data-cy="issue-bulk-transfer-button"
            startIcon={<DownloadForOfflineIcon />}
            onClick={handleOpenTransfers}
          >
            Issue bulk transfer
          </Button>
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />

      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              containerPadding={true}
              cards={deviceTransfersState.map((transfer) => (
                <Card
                  id={transfer._id}
                  content={
                    <DeviceTransferCard
                      deviceTransfer={transfer}
                      deviceTypes={deviceTypes}
                      userCompanies={userCompanies}
                    />
                  }
                  checked={selectedDeviceTransfers.includes(transfer._id)}
                  checkboxCallback={checkProfileCallback}
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={DeviceTransferColumns(userCompanies, deviceTypes)}
              rows={deviceTransfersState}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={
                selectedDeviceTransfers.length === deviceTransfersState.length
              }
              loading={loading}
              selectedItemsIds={selectedDeviceTransfers}
              xPadding={false}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkProfileCallback}
            />
          )}

          {deviceTransfersState.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedDeviceTransfers.length === 1 ? 'transfer' : 'transfers'
            }:`}</span>
            <ul>
              {deviceTransfersState
                .filter((transfer) =>
                  selectedDeviceTransfers.includes(transfer._id),
                )
                .map((transfer) => (
                  <li key={transfer._id}>{transfer._id}</li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
      <AcceptDeviceTransferDrawer
        open={open}
        deviceTransfers={selectedDeviceTransfer}
        deviceTypes={deviceTypes}
        selectedDeviceTransferIds={selectedDeviceTransfers}
        userCompanies={userCompanies}
        handleCloseDrawer={handleCloseTransfers}
      />
    </Grid>
  );
};

export default DeviceTranfers;
