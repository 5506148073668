import React from 'react';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import clsx from 'clsx';

import AccountAutocomplete from '../AccountAutocomplete';
import useStyles from './styles';

interface PublishProps {
  label: string;
  enableSubmit: boolean;
  submitting: boolean;
  companyId?: string;
  isAccountDisabled?: boolean;
  hideAccountSelect?: boolean;
  onChangeAccount?: (companyId: string) => void;
  onSubmit: () => void;
}

const Publish: React.FC<PublishProps> = ({
  label,
  enableSubmit,
  submitting,
  companyId,
  isAccountDisabled,
  onChangeAccount,
  onSubmit,
  hideAccountSelect = false,
}) => {
  const classes = useStyles();

  const handleAccountChange = (id: string): void => {
    if (onChangeAccount) {
      onChangeAccount(id);
    }
  };

  return (
    <Paper className={clsx('shadow p-8', classes.container)}>
      <Typography variant="h5" className="mb-2">
        Publish
      </Typography>
      {!hideAccountSelect && (
        <>
          <Typography
            variant="overline"
            component="div"
            className={clsx('mb-6', classes.label)}
          >
            Select the account the {label} will belong to
          </Typography>

          <AccountAutocomplete
            isDisabled={isAccountDisabled}
            selectedAccount={companyId}
            onAccountChange={handleAccountChange}
          />
        </>
      )}
      <Button
        data-cy="button-publish"
        className={clsx('mt-6', classes.button)}
        color="primary"
        size="large"
        variant="contained"
        type="button"
        disabled={!enableSubmit || submitting}
        onClick={onSubmit}
      >
        {!submitting ? (
          <Typography variant="button">Create</Typography>
        ) : (
          <CircularProgress size={25} />
        )}
      </Button>
    </Paper>
  );
};

export default Publish;
