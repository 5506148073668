import React, { useState } from 'react';
import { Box, Grid, Button, IconButton, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { NetworkConnection } from '@edgeiq/edgeiq-api-js';

import {
  connectionsLabel,
  defaultConnectionType,
} from '../../../app/constants';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import SelectInput from '../../../components/SelectInput';
import TextInput from '../../../components/TextInput';

type indexes = 'type' | 'name';

interface ConnectionsDrawerProps {
  open: boolean;
  connections: NetworkConnection[];
  handleCloseDrawer: () => void;
  onConnectionsAdd: (connections: NetworkConnection[]) => void;
}

const ConnectionsDrawer: React.FC<ConnectionsDrawerProps> = ({
  open,
  connections,
  handleCloseDrawer,
  onConnectionsAdd,
}) => {
  const originalDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.deviceType,
  );
  const [connectionsList, setConnectionsList] = useState<NetworkConnection[]>(
    cloneDeep(connections),
  );

  const handleAddConnectionsCallback = (): void => {
    onConnectionsAdd(connectionsList);
  };

  const addNewConnection = (): void => {
    setConnectionsList([
      ...connectionsList,
      {
        name: '',
        type: defaultConnectionType,
      },
    ]);
  };

  const handleCloseClick = (): void => {
    setConnectionsList([...connections]);
    handleCloseDrawer();
  };

  const handleRemove = (index: number): void => {
    const auxArray = [...connectionsList];
    auxArray.splice(index, 1);
    setConnectionsList(auxArray);
  };

  const handleDynamicChange =
    (prop: indexes, index: number) =>
    (_field: string, value: string | number): void => {
      const auxArray = [...connectionsList];
      auxArray[index][prop] = value as string;
      setConnectionsList(auxArray);
    };

  return (
    <RightDrawer
      open={open}
      actionLabel="Update Connections"
      title="Update Connections"
      disableAction={
        isEqual(
          originalDeviceType?.capabilities.network_connections,
          connections,
        ) &&
        connectionsList.length < 1 &&
        connections.length < 1
      }
      actionCallback={handleAddConnectionsCallback}
      onCloseDrawer={handleCloseClick}
      content={
        <Box>
          {connectionsList.map((item, index) => (
            <Grid
              key={index}
              container
              direction="row"
              spacing={2}
              className="mt-2"
              alignItems="center"
            >
              <Grid item xs={5}>
                <SelectInput
                  label="Connection Type"
                  prop="type"
                  value={item.type}
                  onSelectChange={handleDynamicChange('type', index)}
                  options={Object.keys(connectionsLabel).map((key) => (
                    <MenuItem key={key} dense value={key}>
                      {connectionsLabel[key]}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  placeholder="Name"
                  label="Connection Name"
                  type="text"
                  prop="name"
                  required={true}
                  value={item.name}
                  onInputChange={handleDynamicChange('name', index)}
                />
              </Grid>
              <Grid item xs={2} className="mt-6">
                <IconButton onClick={(): void => handleRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            size="medium"
            className="mt-4"
            onClick={addNewConnection}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>
        </Box>
      }
    />
  );
};

export default ConnectionsDrawer;
