import React from 'react';
import { Grid } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../../components/TextInput';

interface TopicTemplateSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const TopicTemplateSection: React.FC<TopicTemplateSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
}) => {
  return (
    <Grid item xs={12} className="mt-2">
      <TextInput
        label="Topic Template"
        placeholder=""
        prop={`${actionIndex}.topic`}
        value={action.topic}
        onInputChange={onInputChange}
        disabled={disabled}
      />
    </Grid>
  );
};

export default TopicTemplateSection;
