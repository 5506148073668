import React, { useState } from 'react';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Authentication } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import TypographyWithCopy from '../../../components/TypographyWithCopy';

interface ResetApiTokenDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
}

const ResetApiTokenDrawer: React.FC<ResetApiTokenDrawerProps> = ({
  open,
  handleCloseDrawer,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const [newToken, setNewToken] = useState('');

  const handleChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    setValues({ ...values, [prop]: value });
  };

  const handleClickShowPassword = (): void => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleActionCallback = (): void => {
    setLoading(true);
    Authentication.tokenReset(values.email, values.password)
      .then((result) => {
        dispatch(
          setAlert({
            highlight: 'API token reset successfully',
            type: 'success',
          }),
        );
        if (result.api_token) {
          setNewToken(result.api_token);
        }
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  const closeDrawer = (): void => {
    setValues({
      email: '',
      password: '',
      showPassword: false,
    });
    setNewToken('');
    handleCloseDrawer();
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Reset"
      cancelLabel={newToken ? 'Close' : 'Cancel'}
      title={
        newToken
          ? 'Your new API token'
          : 'Please enter your username/email and password in order to reset your API token.'
      }
      disableAction={!values.email || !values.password}
      actionLoading={loading}
      actionCallback={handleActionCallback}
      onCloseDrawer={closeDrawer}
      content={
        !newToken ? (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mt-6">
              <TextInput
                type="text"
                label="Email"
                prop="email"
                required={true}
                value={values.email}
                onInputChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-6">
              <TextInput
                type={values.showPassword ? 'text' : 'password'}
                label="Password"
                prop="password"
                required={true}
                value={values.password}
                onInputChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      style={{
                        marginRight: 0,
                      }}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        ) : (
          <TypographyWithCopy
            dataCy="copy-token-button"
            component="span"
            typographyVariant="button"
            text={newToken}
          />
        )
      }
    />
  );
};

export default ResetApiTokenDrawer;
