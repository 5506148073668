import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Command, Commands, Devices, DeviceTypes } from '@edgeiq/edgeiq-api-js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  setOriginalSelectedCommands,
  setSelectedCommands,
} from '../../../redux/reducers/commands.reducer';
import { errorHighlight } from '../../../app/constants';
import { ContentPageTabProps } from '../../../models/common';
import TabsPage from '../../../components/TabsPage';
import { IssueCommandsDrawer } from '../../../containers/RightDrawer';
import ExecuteCommandDrawer from '../../../containers/RightDrawer/ExecuteCommand/ExecuteCommandDrawer';
import ActionDialog from '../../../components/ActionDialog';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import useStyles from '../styles';

const DeviceCommands: React.FC<ContentPageTabProps> = ({ goToItem }) => {
  const { id } = useParams<string>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedCommands = useAppSelector(
    (state: RootState) => state.commands.selectedCommands,
  );
  const originalCommands = useAppSelector(
    (state: RootState) => state.commands.originalSelectedCommands,
  );
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [loading, setLoading] = useState(false);
  const [manageCommandsDrawer, setManageCommandsDrawer] = useState(false);
  const [commandToExecute, setCommandToExecute] = useState<Command>();
  const [executeCommandDrawer, setExecuteCommandDrawer] = useState(false);
  const [executeCommandDialog, setExecuteCommandDialog] = useState(false);
  const [deviceTypeCommands, setDeviceTypeCommands] = useState<Command[]>([]);

  const columns: GridColDef[] = [
    {
      field: '_id',
      flex: 0.7,
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <TypographyWithCopy
          dataCy={`device-command-${params.row._id}`}
          text={params.row._id}
          typographyVariant="button"
        />
      ),
    },
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
          onClick={goToItem('command', params.row._id)}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'sender_type',
      flex: 0.3,
      headerName: 'Sender Type',
    },
    {
      field: 'is_from_device_type',
      flex: 0.3,
      headerName: 'Attach via Device Profile',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.is_from_device_type ? 'True' : 'False'}</span>
      ),
    },
    {
      field: 'options',
      headerName: 'Execute',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <IconButton
          aria-label="execute-command"
          onClick={handleOpenExecuteCommandDrawer(params.row)}
          size="small"
        >
          <PlayArrowIcon />
        </IconButton>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);
      const deviceCommands: Command[] = [];
      Devices.getCommands(editableDevice._id)
        .then((resDeviceCommands) => {
          deviceCommands.push(...resDeviceCommands);
          return DeviceTypes.getCommands(editableDevice.device_type_id);
        })
        .then((resDeviceTypeCommands) => {
          const adaptedDeviceTypeCommands = resDeviceTypeCommands.map(
            (deviceTypeCommand) => {
              return { ...deviceTypeCommand, is_from_device_type: true };
            },
          );
          setDeviceTypeCommands(adaptedDeviceTypeCommands);
          const resultArray = [...adaptedDeviceTypeCommands];

          const filteredList = deviceCommands
            .filter((dc) => !resultArray.find((dtc) => dtc._id === dc._id))
            .map((e) => ({ ...e, is_from_device_type: false }));
          resultArray.push(...filteredList);
          dispatch(setSelectedCommands(resultArray));
          dispatch(setOriginalSelectedCommands(resultArray));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  const handleOpenManageCommandsDrawer = (): void => {
    setManageCommandsDrawer(true);
  };

  const handleCloseManageCommandsDrawer = (): void => {
    setManageCommandsDrawer(false);
  };

  const handleOpenExecuteCommandDrawer = (command: Command) => (): void => {
    setCommandToExecute(command);

    if (command.sender_type === 'gcp_pubsub_sender') {
      return setExecuteCommandDrawer(true);
    }

    setExecuteCommandDialog(true);
  };

  const handleCloseExecuteCommandDrawer = (): void => {
    setExecuteCommandDrawer(false);
  };

  const handleCloseExecuteCommandDialog = (): void => {
    setExecuteCommandDialog(false);
  };

  const handleManageCommands = (newCommands: Command[]): void => {
    dispatch(setSelectedCommands(newCommands));
    handleCloseManageCommandsDrawer();
    setLoading(true);

    const attachCommands = newCommands.filter((newCommand) =>
      originalCommands.every(
        (originalCommand) => newCommand._id !== originalCommand._id,
      ),
    );
    const detachCommands = originalCommands.filter((originalCommand) =>
      newCommands.every((newCommand) => originalCommand._id !== newCommand._id),
    );

    //TODO: Check what its happening to bulkAttachRules
    //TODO: Check bulkDetachRules avaiability
    Promise.all([
      Promise.all(
        attachCommands.map(async (attachCommand) => {
          await Commands.assignToDevice(attachCommand._id, id as string);
        }),
      ),
      Promise.all(
        detachCommands.map(async (detachCommand) => {
          await Commands.removeFromDevice(detachCommand._id, id as string);
        }),
      ),
    ])
      .then(() => {
        newCommands = newCommands.map((command) => {
          const found = deviceTypeCommands.find(
            (dtc) => dtc._id === command._id,
          );
          return {
            ...command,
            is_from_device_type: !!found,
          };
        });
        dispatch(setOriginalSelectedCommands(newCommands));
        dispatch(setSelectedCommands(newCommands));
        dispatch(
          setAlert({
            highlight: 'Commands updated',
            message: 'Device commands successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExecuteCommand = (): void => {
    setExecuteCommandDrawer(false);
    setLoading(true);

    if (commandToExecute && editableDevice) {
      Commands.executeOnDevice(
        commandToExecute._id,
        editableDevice._id,
        commandToExecute.options as {
          [key: string]: string | number;
        },
      )
        .then((commandExecution) => {
          dispatch(
            setAlert({
              highlight: `Command Execution ID: ${commandExecution._id}`,
              link: `command/${commandToExecute._id}`,
              linkText: commandToExecute.name,
              message: 'To see the command execution status go to <link>',
              type: 'success',
            }),
          );
          setCommandToExecute(undefined);
          handleCloseExecuteCommandDialog();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOptionChange = (prop: string, value: string | number): void => {
    if (commandToExecute) {
      const auxOptionsObj = { ...commandToExecute.options };
      auxOptionsObj[prop] = value;
      setCommandToExecute({ ...commandToExecute, options: auxOptionsObj });
    }
  };

  const handleCreateCommand = (): void => {
    goToItem('command')();
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedCommands}
        addButtonIcon={false}
        addButtonLabel="Manage Commands"
        createButtonLabel="Create Command"
        onCreateClick={handleCreateCommand}
        onAddClick={handleOpenManageCommandsDrawer}
        tableTitle="Commands added"
        loading={loading}
      />

      <IssueCommandsDrawer
        open={manageCommandsDrawer}
        handleCloseDrawer={handleCloseManageCommandsDrawer}
        onChoosingCommands={handleManageCommands}
      />

      <ExecuteCommandDrawer
        open={executeCommandDrawer}
        command={commandToExecute}
        handleCloseDrawer={handleCloseExecuteCommandDrawer}
        onExecuteCommand={handleExecuteCommand}
        onOptionChange={handleOptionChange}
      />

      <ActionDialog
        open={executeCommandDialog}
        loading={loading}
        onDeleteCallback={handleExecuteCommand}
        onCloseCallback={handleCloseExecuteCommandDialog}
        content={`You are about to execute the command: ${commandToExecute?.name}`}
        actionButtonLabel="Execute"
      />
    </Box>
  );
};

export default DeviceCommands;
