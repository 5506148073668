import { useState } from 'react';

export const usePageSetter = (
  initialTotalPages = 0,
  initialPage = 0,
): {
  total: number;
  page: number;
  setTotalAndPage: (total: number, addPage: boolean) => void;
  setTotal: (total: number) => void;
} => {
  const [total, setTotal] = useState(initialTotalPages);
  const [page, setPage] = useState(initialPage);

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    setPage((prev) => (addPage ? prev + 1 : 1));
  };

  return {
    page,
    setTotal,
    setTotalAndPage,
    total,
  };
};
