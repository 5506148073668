import { Translator, TranslatorInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';
import {
  EMPTY_COMMAND_TRANSLATOR,
  EMPTY_TRANSLATOR,
} from '../../constants/translators';

export const getTranslatorSelector = createSelector(
  (state: TranslatorsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.translators.length) {
      return state.translator;
    }
    return state.translators.find(
      (translator: Translator) => translator._id === id,
    );
  },
);

export const TranslatorsTypes = {
  CLEAN_NEW_COMMAND_TRANSLATOR: 'CLEAN_NEW_COMMAND_TRANSLATOR',
  CLEAN_NEW_TRANSLATOR: 'CLEAN_NEW_TRANSLATOR',
  SET_ACTUAL_TRANSLATOR: 'SET_ACTUAL_TRANSLATOR',
  SET_NEW_TRANSLATOR: 'SET_NEW_TRANSLATOR',
  SET_OPTIONS_TRANSLATORS: 'SET_OPTIONS_TRANSLATORS',
  SET_SELECTED_TRANSLATORS: 'SET_SELECTED_TRANSLATORS',
  SET_TRANSLATORS: 'SET_TRANSLATORS',
};

export const setTranslatorsOptions = (
  translators: Translator[],
): TranslatorsAction => ({
  data: translators,
  type: TranslatorsTypes.SET_OPTIONS_TRANSLATORS,
});

export const setStateTranslators = (
  translators: Translator[],
): TranslatorsAction => ({
  data: translators,
  type: TranslatorsTypes.SET_TRANSLATORS,
});

export const setActualTranslator = (
  translator: Translator,
): TranslatorsAction => ({
  data: translator,
  type: TranslatorsTypes.SET_ACTUAL_TRANSLATOR,
});

export const setSelectedTranslators = (
  translators: Translator[],
): TranslatorsAction => ({
  data: translators,
  type: TranslatorsTypes.SET_SELECTED_TRANSLATORS,
});

export const setNewTranslator = (
  translator: TranslatorInput,
): TranslatorsAction => ({
  data: translator,
  type: TranslatorsTypes.SET_NEW_TRANSLATOR,
});

export const cleanNewTranslator = (): TranslatorsAction => ({
  data: EMPTY_TRANSLATOR as TranslatorInput,
  type: TranslatorsTypes.CLEAN_NEW_TRANSLATOR,
});

export const cleanNewCommandTranslator = (): TranslatorsAction => ({
  data: EMPTY_COMMAND_TRANSLATOR as TranslatorInput,
  type: TranslatorsTypes.CLEAN_NEW_COMMAND_TRANSLATOR,
});

export type TranslatorsState = {
  translators: Translator[];
  optionsTranslators: Translator[];
  translator: Translator | null;
  selectedTranslators: Translator[];
  newTranslator: TranslatorInput | null;
};

// INITIAL STATE
const translatorsInitialState: TranslatorsState = {
  newTranslator: EMPTY_TRANSLATOR,
  optionsTranslators: [],
  selectedTranslators: [],
  translator: null,
  translators: [],
};

export type TranslatorsAction = {
  data: Translator[] | Translator | TranslatorInput;
  type: string;
};

const translatorsReducer = (
  state = translatorsInitialState,
  action: TranslatorsAction,
): TranslatorsState => {
  switch (action.type) {
    case TranslatorsTypes.SET_TRANSLATORS:
      return {
        ...state,
        translators: action.data as Translator[],
      };

    case TranslatorsTypes.SET_OPTIONS_TRANSLATORS:
      return {
        ...state,
        optionsTranslators: action.data as Translator[],
      };

    case TranslatorsTypes.SET_ACTUAL_TRANSLATOR:
      return {
        ...state,
        newTranslator: action.data as Translator,
        translator: action.data as Translator,
      };

    case TranslatorsTypes.SET_SELECTED_TRANSLATORS:
      return {
        ...state,
        selectedTranslators: action.data as Translator[],
      };

    case TranslatorsTypes.SET_NEW_TRANSLATOR:
      return {
        ...state,
        newTranslator: action.data as TranslatorInput,
      };

    case TranslatorsTypes.CLEAN_NEW_TRANSLATOR:
      return {
        ...state,
        newTranslator: action.data as TranslatorInput,
      };

    case TranslatorsTypes.CLEAN_NEW_COMMAND_TRANSLATOR:
      return {
        ...state,
        newTranslator: action.data as TranslatorInput,
      };

    default:
      return state;
  }
};

export default translatorsReducer;
