import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { User, UserType } from '@edgeiq/edgeiq-api-js';

import { stringAvatar } from '../../../../../helpers/avatar';

interface AccountUsersProps {
  setUserDeleteSelected: (user: User) => void;
  users: User[];
  userTypes: UserType[];
}

const AccountUsersList: React.FC<AccountUsersProps> = ({
  setUserDeleteSelected,
  users,
  userTypes,
}) => {
  const getUserType = (userTypeId: string): UserType | undefined => {
    return userTypes.find((userType) => userType._id === userTypeId);
  };

  const deleteUser = (user: User) => (): void => {
    setUserDeleteSelected(user);
  };

  return (
    <List
      className="scrollbar"
      data-cy="account-users-container"
      sx={{
        bgcolor: 'background.paper',
        maxHeight: 360,
        overflow: 'auto',
        width: '100%',
      }}
    >
      {users.map((user: User, index: number) => {
        return (
          <Box key={user._id}>
            <ListItem
              data-cy="account-user-container"
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  aria-label="link off"
                  edge="end"
                  data-cy="account-user-unlink-button"
                  onClick={deleteUser(user)}
                >
                  <LinkOffIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                {user.logo_url ? (
                  <Avatar
                    alt={`${user.first_name} ${user.last_name}`}
                    src={user.logo_url}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(`${user.first_name} ${user.last_name}`)}
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                data-cy="account-user-name"
                primary={`${user.first_name} ${user.last_name}`}
                secondary={
                  <>
                    <Typography
                      component="span"
                      color="text.primary"
                      sx={{ display: 'inline' }}
                      variant="body2"
                    >
                      {getUserType(user.user_type_id)?.name}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {index !== users.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </Box>
        );
      })}
    </List>
  );
};

export default AccountUsersList;
