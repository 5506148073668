import { Translator } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../../components/SharedTable';
import { translatorTypesMap } from '../../../app/constants';

export const TranslatorsColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const translator = item as Translator;
      return translator.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    link: (item: TableItemType): string => {
      const translator = item as Translator;
      return `/data-management/translator/${translator._id}`;
    },
    type: 'link',
  },
  {
    cellValue: (item: TableItemType): string => {
      const translator = item as Translator;
      return translatorTypesMap[translator.type];
    },
    id: 'type',
    label: 'Type',
    type: 'text',
  },
];
