import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';
import PasswordInput from '../../../components/PasswordInput';

interface OrbcommIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  hideEmptySensitiveFields?: boolean;
}

const OrbcommIntegrationSection: React.FC<OrbcommIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
  hideEmptySensitiveFields,
}) => {
  return (
    <>
      {(!hideEmptySensitiveFields || newIntegration.orbcomm_username) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="Orbcomm Username"
            prop="orbcomm_username"
            value={newIntegration.orbcomm_username}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
      {(!hideEmptySensitiveFields || newIntegration.orbcomm_password) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="Orbcomm Password"
            prop="orbcomm_password"
            value={newIntegration.orbcomm_password}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Orbcomm Solution Provider Id"
          prop="orbcomm_solution_provider_id"
          value={newIntegration.orbcomm_solution_provider_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Orbcomm Gateway Account Id"
          prop="orbcomm_gateway_account_id"
          value={newIntegration.orbcomm_gateway_account_id}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default OrbcommIntegrationSection;
