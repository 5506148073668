import React from 'react';
import PasswordInput from '../../components/PasswordInput';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { useAppDispatch } from '../../redux/hooks';

interface PasswordInputWithAlertProps {
  label?: string;
  value?: string;
  prop: string;
  placeholder?: string;
  required?: boolean;
  onInputChange?: (prop: string, value: string | number) => void;
  disabled?: boolean;
  hasCopyButton?: boolean;
  hasPasswordStrengthBar?: boolean;
}

const PasswordInputWithAlert: React.FC<PasswordInputWithAlertProps> = ({
  label,
  value,
  prop,
  required,
  disabled,
  placeholder,
  onInputChange,
  hasCopyButton,
  hasPasswordStrengthBar = true,
}) => {
  const dispatch = useAppDispatch();

  const onCopy = (): void => {
    dispatch(
      setAlert({
        highlight: 'Password copied to clipboard',
        type: 'success',
      }),
    );
  };
  return (
    <PasswordInput
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      prop={prop}
      value={value}
      onInputChange={onInputChange}
      hasCopyButton={hasCopyButton}
      hasPasswordStrengthBar={hasPasswordStrengthBar}
      onCopy={onCopy}
    />
  );
};

export default PasswordInputWithAlert;
