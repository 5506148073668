import React, { Dispatch } from 'react';
import { Typography } from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import DeviceHeartbeat from '../../components/DeviceHeartbeat';
import { GATEWAY_TYPE } from '../../app/constants';
import useStyles from './styles';

interface HeartbeatConfigProps {
  deviceType?: DeviceType;
  heartbeatPeriod?: number;
  heartbeatValues?: string[];
  onChangePeriod: (prop: string, period: number | string[]) => void;
  selectedHeartbeatUnit: string;
  setSelectedHearbeatUnit: Dispatch<React.SetStateAction<string>>;
}

const HeartbeatConfig: React.FC<HeartbeatConfigProps> = ({
  deviceType,
  heartbeatPeriod,
  heartbeatValues,
  onChangePeriod,
  selectedHeartbeatUnit,
  setSelectedHearbeatUnit,
}) => {
  const classes = useStyles();

  const handleChange = (prop: string, value: string | number): void => {
    switch (prop) {
      case 'heartbeatUnit':
        return setSelectedHearbeatUnit(value as string);
      case 'heartbeat_period':
        return onChangePeriod('heartbeat_period', value as number);
      case 'heartbeat_values':
        if (heartbeatValues) {
          const index = heartbeatValues.findIndex(
            (e) => e === (value as string),
          );
          if (index > -1) {
            heartbeatValues.splice(index, 1);
          } else {
            heartbeatValues.push(value as string);
          }
        }
        return onChangePeriod(
          'heartbeat_values',
          heartbeatValues || [value as string],
        );
    }
  };

  const isGateway = deviceType && deviceType.type === GATEWAY_TYPE;

  return (
    <>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-1', classes.configTitle)}
      >
        Select frecuency
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-4', classes.configSubtitle)}
      >
        {isGateway
          ? 'Generate a Heartbeat Report with the fields below at least every:'
          : 'Expect a report at least every:'}
      </Typography>
      <DeviceHeartbeat
        deviceType={deviceType}
        heartbeatUnit={selectedHeartbeatUnit}
        heartbeat_period={heartbeatPeriod}
        heartbeat_values={heartbeatValues}
        onInputChange={handleChange}
      />
    </>
  );
};

export default HeartbeatConfig;
