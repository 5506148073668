import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, Chip } from '@mui/material';
import clsx from 'clsx';

import CircularProgressWithLabel from '../CircularProgressWithLabel';
import useStyles from './styles';

export interface MetricsCardProps {
  title: string; // Main text of the card in the left part
  value: number; // Main value of the card in the left part
  percentage: number; // Percentage of value x total devices
  colorPallete: {
    // Colors that will be used to customize the card
    [key: string]: string;
  };
  extrainfo?: string; // Info inside the chip component in the left part
}

const MetricsCard: React.FC<MetricsCardProps> = ({
  title,
  value,
  percentage,
  colorPallete,
  extrainfo,
}) => {
  const [progress, setProgress] = useState(0);
  const [mainValue, setMainValue] = useState(0);
  const classes = useStyles({ backgroundColor: colorPallete.backgroundColor });

  useEffect(() => {
    if (value > 0 || percentage > 0) {
      setTimeout(() => {
        setMainValue(value);
        setProgress(percentage);
      }, 200);
    } else {
      setMainValue(0);
      setProgress(0);
    }
  }, [percentage, value]);

  return (
    <Paper elevation={0} className={clsx('p-6 br-4', classes.card)}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="caption" data-testid="title">
            {title}
          </Typography>
          <Typography variant="h4" data-testid="mainValue">
            {mainValue}
          </Typography>
          {extrainfo && (
            <Chip
              label={
                <Typography variant="caption" data-testid="extraInfo">
                  {extrainfo}
                </Typography>
              }
              className={clsx('py-1 px-2 br-1', classes.chip)}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.lastChild}>
          <CircularProgressWithLabel
            data-testid="progress"
            value={progress}
            progressMainColor={colorPallete.progressMainColor}
            progressSecondaryColor={colorPallete.progressSecondaryColor}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MetricsCard;
