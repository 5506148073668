import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import {
  Command,
  CommandExecution,
  Device,
  Devices,
} from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { useAppDispatch } from '../../../redux/hooks';

interface CommandExecutionInformationDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  selectedExecution: CommandExecution | undefined;
  commands: Command[];
}

const CommandExecutionInformationDrawer: React.FC<
  CommandExecutionInformationDrawerProps
> = ({ open, handleCloseDrawer, selectedExecution, commands }) => {
  const dispatch = useAppDispatch();
  const [device, setDevice] = useState<undefined | Device>();

  const getCommandName = (commandId: string): string => {
    const foundCommand = commands.find((command) => command._id === commandId);
    if (foundCommand) {
      return foundCommand.name;
    }
    return '--';
  };

  useEffect(() => {
    getDevice();
  }, [selectedExecution]);

  const getDevice = (): void => {
    if (selectedExecution) {
      Devices.getOneById(selectedExecution.device_id)
        .then((res) => {
          if (res) {
            setDevice(res);
          }
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        });
    }
  };

  if (!selectedExecution) {
    return <></>;
  }
  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      title="Command Execution Information"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Box>
          <div>
            <Typography variant="h5" className="custom-label">
              Command name
            </Typography>
            <Typography variant="subtitle2" className="custom-label">
              {getCommandName(selectedExecution.command_id)}
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h5" className="custom-label">
              Executed at
            </Typography>
            <Typography variant="subtitle2" className="custom-label">
              {`${DateTime.fromISO(selectedExecution.created_at).toFormat(
                'TT',
              )} ${DateTime.fromISO(
                selectedExecution.created_at,
              ).toLocaleString(DateTime.DATE_SHORT)}`}
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h5" className="custom-label">
              Status
            </Typography>
            <Typography variant="subtitle2" className="custom-label title-case">
              {selectedExecution.execution_statuses?.[0]?.status || '-'}
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h5" className="custom-label">
              Device
            </Typography>
            <Typography variant="subtitle2" className="custom-label title-case">
              {device?.name || '-'}
            </Typography>
          </div>
        </Box>
      }
    />
  );
};

export default CommandExecutionInformationDrawer;
