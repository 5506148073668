import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '400px',
    },
    publishContainer: {
      height: '100%',
    },
    title: {
      fontWeight: 700,
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
