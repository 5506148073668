import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceByTypeLegendContainer: {
      margin: 'auto',
    },
    linkButton: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    linkColor: {
      color: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
