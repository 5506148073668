import React from 'react';
import { Grid, Typography, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { GATEWAY_TYPE, hearbeatValuesLabel } from '../../app/constants';
import useStyles from './styles';
import TextInput from '../TextInput';
import SelectInput from '../SelectInput';
import CheckboxInput from '../CheckboxInput';

export interface DeviceHeartbeatProps {
  heartbeat_period?: number;
  heartbeat_values?: string[];
  heartbeatUnit: string;
  deviceType?: DeviceType | null;
  showLabel?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
}

const DeviceHeartbeat: React.FC<DeviceHeartbeatProps> = ({
  heartbeat_period,
  heartbeat_values,
  heartbeatUnit,
  deviceType,
  showLabel,
  onInputChange,
}) => {
  const classes = useStyles();
  const isGateway = deviceType && deviceType.type === GATEWAY_TYPE;
  return (
    <Grid container direction="row" spacing={0}>
      <Grid
        item
        xs={6}
        className={clsx({
          ['mt-4']: showLabel,
        })}
      >
        {showLabel && (
          <>
            <Typography className="mt-6 mb-3" variant="h5">
              Heartbeat
            </Typography>
            <Typography variant="subtitle2" className="custom-label">
              Select reporting frequency
            </Typography>
          </>
        )}
        <Box className={clsx(classes.rowBox)}>
          <TextInput
            data-testid="heartbeat-interval"
            placeholder="Heartbeat interval"
            value={heartbeat_period ?? '0'}
            prop="heartbeat_period"
            onInputChange={onInputChange}
            type="number"
          />

          <SelectInput
            data-testid="heartbeatUnit"
            value={heartbeatUnit}
            prop="heartbeatUnit"
            onSelectChange={onInputChange}
            classes={clsx('ml-2', classes.unitInput)}
            options={[
              <MenuItem key="seconds" className="m-4 p-2" dense value="seconds">
                Seconds
              </MenuItem>,
              <MenuItem key="minutes" className="m-4 p-2" dense value="minutes">
                Minutes
              </MenuItem>,
              <MenuItem key="hours" className="m-4 p-2" dense value="hours">
                Hours
              </MenuItem>,
              <MenuItem key="days" className="m-4 p-2" dense value="days">
                Days
              </MenuItem>,
            ]}
          />
        </Box>
      </Grid>
      {/* NOTE: In case we want to do 3 inputs instead of 2 */}
      {/* <Grid container>
        <Grid item xs={2}>
          <Typography variant="subtitle2" className="custom-label">
            Days
          </Typography>
          <FormControl variant="outlined" className={classes.heartbeatInput}>
            <OutlinedInput
              name="days"
              placeholder="Days"
              type="number"
              value={period.hours}
              onChange={handleChange('days')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" className="custom-label">
            Hours
          </Typography>
          <FormControl
            variant="outlined"
            className={clsx('ml-1', classes.heartbeatInput)}
          >
            <OutlinedInput
              name="hours"
              placeholder="hours"
              type="number"
              value={period.minutes}
              onChange={handleChange('hours')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" className="custom-label">
            Minutes
          </Typography>
          <FormControl
            variant="outlined"
            className={clsx('ml-1', classes.heartbeatInput)}
          >
            <OutlinedInput
              name="minutes"
              placeholder="minutes"
              type="number"
              value={period.minutes}
              onChange={handleChange('minutes')}
            />
          </FormControl>
        </Grid>
      </Grid> */}
      {isGateway && (
        <Grid item xs={12} className="mt-2">
          <Grid container spacing={0}>
            {hearbeatValuesLabel.map((item, key) => (
              <Grid
                key={`${item.value}-${key}`}
                item
                xs={12}
                sm={6}
                lg={6}
                xl={4}
              >
                <CheckboxInput
                  label={item.label}
                  prop="heartbeat_values"
                  value={item.value}
                  checked={
                    heartbeat_values &&
                    heartbeat_values.indexOf(item.value) > -1
                  }
                  onCheckboxClick={onInputChange}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {/* In case we want to show the values as a select */}
      {/* <Grid item xs={6} className="mt-4">
        <FormControl variant="outlined" fullWidth>
          <Typography variant="subtitle2" className="custom-label">
            Optional Data
          </Typography>
          <Select
            multiple
            value={heartbeat_values ?? []}
            onChange={onInputChange('heartbeat_values')}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected): string[] | ReactElement => {
              if (selected?.length === 0) {
                return <span>Optional data</span>;
              }

              if (selected?.length > 0) {
                return <span>{`Values selected (${selected?.length})`}</span>;
              }

              return selected;
            }}
          >
            {hearbeatValuesLabel.map((item, key) => (
              <MenuItem key={key} value={item.value}>
                <Checkbox
                  checked={
                    heartbeat_values
                      ? heartbeat_values.indexOf(item.value) > -1
                      : false
                  }
                />
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
    </Grid>
  );
};

export default DeviceHeartbeat;
