import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  Chip,
  Typography,
  Button,
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { GatewayCommands } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import timeHelpers from '../../helpers/timeHelpers';

interface IMetricsData {
  name: string;
  value: number;
}

interface IGatewayCommadChartData {
  type: string;
  datetime: string;
  devices: string;
  status: string;
  _id: string;
}

export const CHART_COLORS = ['#40A0DB', '#609884', '#EDB31E', '#DE4F48'];

const GatewayCommandsChart: React.FC = () => {
  const [commandChartData, setCommandChartData] = useState<
    IGatewayCommadChartData[]
  >([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pieChartData, setPieChartData] = useState<IMetricsData[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    GatewayCommands.list({}, { itemsPerPage: 6, page: 1 })
      .then((res) => {
        const pieChartDataMapping: IMetricsData[] = [];
        const mappedData = res.gatewayCommands.map((d) => {
          const data: IGatewayCommadChartData = {
            _id: d._id,
            datetime: d.created_at,
            devices: d.device_unique_id ?? '-',
            status: '-',
            type: d.command_type,
          };

          if (d.device_unique_id) {
            data.status = d.statuses[d.device_unique_id].status;
          }

          const index = pieChartDataMapping.findIndex(
            (p) => p.name === d.command_type,
          );
          if (index < 0) {
            pieChartDataMapping.push({
              name: d.command_type,
              value: 1,
            });
          } else {
            pieChartDataMapping[index].value += 1;
          }

          return data;
        });
        setPieChartData(pieChartDataMapping);
        setCommandChartData(mappedData);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoToHeartbeats = (): void => {
    navigate(`/messages#gateway-commands`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column overflow-hidden">
      <Grid container flexDirection={'row'}>
        <div
          style={{
            height: 80,
            width: 80,
          }}
        >
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieChartData}
                innerRadius={18}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {pieChartData.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={CHART_COLORS[index % CHART_COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="d-flex flex-justify-between flex-grow-1 flex-items-center">
          <div>
            <div>
              <div>
                <Typography
                  variant="h5"
                  className={clsx('d-flex flex-justify-between flex-grow-1')}
                >
                  Gateway Commands
                </Typography>
              </div>
              <div>
                {pieChartData.map((metric, index) => {
                  return (
                    <div
                      key={`${metric.name}_${index}`}
                      className={clsx('d-flex flex-items-center')}
                    >
                      <Typography variant="subtitle1">{metric.name}</Typography>
                      <Chip
                        className="ml-4"
                        size="small"
                        label={metric.value}
                        style={{
                          backgroundColor:
                            CHART_COLORS[index % CHART_COLORS.length],
                          color: 'white',
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <Button
              className="p-0"
              color="primary"
              variant="text"
              type="button"
              onClick={handleGoToHeartbeats}
            >
              <Typography variant="button">View More</Typography>
            </Button>
          </div>
        </div>
      </Grid>

      <Grid
        container
        spacing={3}
        justifyContent={isMobile ? 'center' : 'space-around'}
      >
        <Grid item sm={12} style={{ overflowX: 'auto' }}>
          <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date/Time</TableCell>
                  <TableCell align="center">Device</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {commandChartData.map((row, index) => (
                  <TableRow
                    key={`${row._id}_${index}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      {row.datetime && (
                        <>{timeHelpers.getDate(row.datetime, false, false)}</>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.devices}</TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default GatewayCommandsChart;
