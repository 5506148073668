import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Command,
  Configuration,
  Configurations,
  DeviceType,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setActualConfiguration,
  setNewConfiguration,
  getConfigurationSelector,
} from '../../redux/reducers/configuration.reducer';
import {
  configurationTabsLabel,
  DETAILS_DEFAULT_TAB,
  errorHighlight,
} from '../../app/constants';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { LocationState } from '../../models/common';
import {
  dispatchError,
  getAttachError,
  getFetchError,
  getPageHash,
} from '../../helpers/utils';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import Header from '../../containers/HeaderWithActionButton';
import ConfigurationCommands from '../../containers/Forms/ConfigurationForm/ConfigurationCommands';
import EntitiesSection from '../../containers/EntitiesSection';
import ConfigurationSettings from '../configurationContent/configurationSettings';
import ConfigurationDetails from './configurationDetails';

const ConfigurationContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const location = useLocation();
  const errorDispatcher = dispatchError(dispatch);
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const stateConfiguration = useAppSelector((state: RootState) =>
    getConfigurationSelector(state.configurations, id),
  );
  const newConfiguration = useAppSelector(
    (state: RootState) => state.configurations.newConfiguration,
  );
  const [configurationData, setConfigurationData] = useState<
    Configuration | undefined | null
  >(stateConfiguration);
  const [sendCommands, setSendCommands] = useState<Command[]>([]);
  const [fetchCommand, setFetchCommand] = useState<Command>();
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [configurationCompany] = useFetchCompany(
    configurationData?.company_id,
    errorDispatcher,
  );
  const [activeTab, setActiveTab] = useState(DETAILS_DEFAULT_TAB);

  const getConfigCommands = (): void => {
    if (id) {
      Configurations.getSendCommands(id)
        .then((response) => {
          setSendCommands(response);
          return Configurations.getFetchCommand(id);
        })
        .then((response) => {
          setFetchCommand(response);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('commands.'),
          );
        });
    }
  };

  const getConfigDeviceTypes = (): void => {
    if (id) {
      Configurations.getDeviceTypes(id)
        .then((response) => {
          setSelectedDeviceTypes(response);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('device and/or device profiles.'),
          );
        });
    }
  };

  // If the user is coming from the list page then `stateConfiguration` will have a value and its value will be set right away to
  // `configurationData` so there is no need to get the config details
  // If the user is reloading the page or going to `/configuration/<id>` then `stateConfiguration` will be null and `configurationData` then.
  // In both cases we need to dispatch `setActualConfiguration` as it sets the data to `actualConfiguration` and `actualConfiguration`
  // also we need to get the configuraton related commands, devices, and device types.
  useEffect(() => {
    if (configurationData) {
      dispatch(setActualConfiguration(configurationData));
      getConfigCommands();
      getConfigDeviceTypes();
    } else if (id) {
      setLoading(true);
      Configurations.getOneById(id)
        .then((response) => {
          dispatch(setActualConfiguration(response));
          setConfigurationData(response);
          getConfigCommands();
          getConfigDeviceTypes();
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('configuration data'),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    setActiveTab(
      getPageHash(location.hash, DETAILS_DEFAULT_TAB, configurationTabsLabel),
    );
  }, [location.hash]);

  const handleDeleteConfiguration = (): void => {
    if (!configurationData) {
      return;
    }
    setLoading(true);

    Configurations.delete(configurationData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete configuration',
            message: 'Configuration successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/configurations');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newConfiguration !== configurationData) {
      setLoading(true);
      Configurations.update(newConfiguration as Configuration)
        .then((response) => {
          dispatch(setActualConfiguration(response));
          dispatch(
            setAlert({
              highlight: 'Update configuration',
              message: 'Configuration successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDynamicChange = (prop: string, value: string | number): void => {
    dispatch(
      setNewConfiguration({
        ...newConfiguration,
        [prop]: value,
      } as Configuration),
    );
  };

  const commandSuccess = (highlight: string): void => {
    dispatch(
      setAlert({
        highlight,
        type: 'success',
      }),
    );
  };

  const commandFail = (message: string, model: string): void => {
    errorDispatcher(message, getAttachError(model, 'configuration'));
  };

  const handleChangeSendCommands = (commands: Command[]): void => {
    if (fetchCommand && commands.map((c) => c._id).includes(fetchCommand._id)) {
      commandFail(
        `Command: "${fetchCommand.name}" is already chosen to fetch settings.`,
        'send commands',
      );
    } else {
      const commandsToAttach = commands.filter(
        (c) => !sendCommands.find((sd) => c._id === sd._id),
      );
      const commandsToDetach = sendCommands.filter(
        (sd) => !commands.find((c) => c._id === sd._id),
      );
      if (id) {
        Promise.all([
          Promise.all(
            commandsToDetach.map(async (command) => {
              await Configurations.removeCommand(id, command._id);
            }),
          ),
          Promise.all(
            commandsToAttach.map(async (command) => {
              await Configurations.addCommand(id, command._id, 'send');
            }),
          ),
        ])
          .then(() => {
            commandSuccess('Updated send commands');
            setSendCommands(commands);
          })
          .catch((error) => {
            commandFail(error.messages || error.message, 'send commands');
          });
      }
    }
  };

  const handleChangeFetchCommand = (command: Command | undefined): void => {
    if (id && command) {
      if (sendCommands.map((c) => c._id).includes(command._id)) {
        commandFail(
          `Command: "${command.name}" is already chosen to send settings.`,
          'fetch command',
        );
      } else {
        Configurations.addCommand(id, command._id, 'fetch')
          .then(() => {
            commandSuccess('Updated fetch command');
            setFetchCommand(command);
          })
          .catch((error) => {
            commandFail(error.messages || error.message, 'fetch command');
          });
      }
    } else if (id && !command && fetchCommand) {
      Configurations.removeCommand(id, fetchCommand._id)
        .then(() => {
          commandSuccess('Updated fetch command');
          setFetchCommand(command);
        })
        .catch((error) => {
          commandFail(error.messages || error.message, 'fetch command');
        });
    }
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    const deviceTypesToDetach = selectedDeviceTypes.filter(
      (dt) => !deviceTypes.find((d) => d._id === dt._id),
    );
    const deviceTypesToAttach = deviceTypes.filter(
      (dt) => !selectedDeviceTypes.find((d) => d._id === dt._id),
    );
    if (id) {
      Promise.all([
        Promise.all(
          deviceTypesToAttach.map(async (deviceType) => {
            await DeviceTypes.attachConfiguration(deviceType._id, id);
          }),
        ),
        Promise.all(
          deviceTypesToDetach.map(async (deviceType) => {
            await DeviceTypes.detachConfiguration(deviceType._id, id);
          }),
        ),
      ])
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Updated Attached Device Profiles',
              type: 'success',
            }),
          );
          setSelectedDeviceTypes(deviceTypes);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('device profiles', 'configuration'),
          );
        });
    }
  };

  /**
   * PLEASE KEEP THE ORDER THE WAY IT IS AND IGNORE THE LINTING ORDER
   */
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details:
        configurationData && newConfiguration ? (
          <ConfigurationDetails
            onInputChange={handleDynamicChange}
            configuration={
              configurationData._id !== newConfiguration._id
                ? configurationData
                : newConfiguration
            }
          />
        ) : (
          <></>
        ),
      settings: (
        <ConfigurationSettings
          sendCommands={sendCommands}
          deviceTypes={selectedDeviceTypes}
        />
      ),
      commands: (
        <ConfigurationCommands
          addPaper={true}
          sendCommands={sendCommands}
          fetchCommand={fetchCommand}
          onChangeFetchCommand={handleChangeFetchCommand}
          onChangeSendCommands={handleChangeSendCommands}
        />
      ),
      device_profiles:
        configurationData && newConfiguration ? (
          <Paper className="shadow p-8">
            <EntitiesSection
              title="Select Device Profiles"
              subTitle="You can choose Device Profiles to attach to the configuration"
              newInput={
                configurationData._id !== newConfiguration._id
                  ? configurationData
                  : newConfiguration
              }
              selectedDeviceTypes={selectedDeviceTypes}
              onChangeDeviceTypes={handleChangeDeviceTypes}
            />
          </Paper>
        ) : (
          <></>
        ),
    };
    return tabs;
  };

  const isAbleToBeSaved = (): boolean => {
    return (
      newConfiguration?.name !== '' &&
      newConfiguration?.unique_identifier !== '' &&
      newConfiguration?.company_id !== ''
    );
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        actionLabel="Create New Setting"
        goBack={goBackUrl ? goBackUrl : 'configurations'}
        goBackLabel={goBackLabel || `Configurations`}
        title="Configuration content"
        model="configuration"
        link="new-setting"
      />
      {configurationData && (
        <Grid item xs={12} className="content-page-container">
          <ContentHeader
            contentType="configuration"
            title={configurationData.name}
            subtitle={configurationData._id}
            copySubtitleToClipboard
            extraImage={configurationCompany?.branding?.logo_url}
            extraTitle={configurationCompany?.name}
            extraSubtitle={configurationCompany?._id}
          />
          <VerticalTabs
            tabs={getTabs()}
            defaultTab={activeTab}
            tabsLabel={configurationTabsLabel}
            changeUrl={true}
          />
        </Grid>
      )}
      <FooterBar
        deleteModalContent="You are about to delete the configuration"
        loading={loading}
        disableSaveButton={!isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteConfiguration}
      />
    </Grid>
  );
};

export default ConfigurationContent;
