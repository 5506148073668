import { Filters, Filter } from '@edgeiq/edgeiq-api-js';

const likeKeys = ['name', 'description', 'unique_id', 'device_name', 'email'];
const specialKeys = ['transfer_status', 'activation_status'];
const arrayKeys = ['tags'];

const parseFilters = (
  filters: { [key: string]: string },
  caseSensitive = false,
): Filters => {
  if (Object.keys(filters).length === 0) {
    return {};
  }

  const result: Filter[] = [];
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const value = filters[key];
      if (value !== '') {
        if (specialKeys.includes(key)) {
          // special case for not yet activated of activation status
          if (key === specialKeys[1] && value === 'activated_at') {
            const filter: Filter = {
              key: 'activated_at',
              operator: 'eq',
              value: 'null',
            };
            result.push(filter);
            const secondFilter: Filter = {
              key: 'deactivated_at',
              operator: 'eq',
              value: 'null',
            };
            result.push(secondFilter);
          } else {
            const filter: Filter = {
              key:
                value.indexOf('_ne') !== -1
                  ? value.substring(0, value.indexOf('_ne'))
                  : value,
              operator: value.indexOf('_ne') !== -1 ? 'ne' : 'eq',
              value: 'null',
            };
            result.push(filter);
          }
        } else {
          const isMulti = value.indexOf('|') !== -1;
          const filterValue = isMulti ? value.split('|') : value;
          const filter: Filter = {
            key,
            operator: arrayKeys.includes(key)
              ? 'incany'
              : isMulti
              ? 'in'
              : likeKeys.includes(key)
              ? `${caseSensitive ? '' : 'i'}like`
              : 'eq',
            value: filterValue,
          };
          result.push(filter);
        }
      }
    }
  }

  return {
    filters: result,
  };
};

export default parseFilters;
