import { MouseEvent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Command } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';
import { commandsSenderTypes } from '../../app/constants';
import { ContentCopy } from '@mui/icons-material';

export const CommandsColumns = (
  handleCopyCommand: (
    command: Command,
  ) => (event: MouseEvent<HTMLButtonElement>) => void,
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const command = item as Command;
        return command.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const command = item as Command;
        return `/command/${command._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const command = item as Command;
        return commandsSenderTypes[command.sender_type];
      },
      id: 'sender_type',
      label: 'Sender Type',
      type: 'text',
    },
    {
      id: 'actions',
      label: 'Actions',
      parser: (item: TableItemType): React.ReactElement => {
        const command = item as Command;
        return (
          <Tooltip placement="top" title="Copy Command">
            <IconButton
              size="small"
              data-y={`command-${command._id}-copy-button`}
              onClick={handleCopyCommand(command)}
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
      type: 'custom',
    },
  ];
};
