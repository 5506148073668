import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    actionContainer: {
      textAlign: 'end',
    },
    backButton: {
      color: theme.palette.grey[100],
    },
    container: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerPadding: {
      [theme.breakpoints.down('md')]: {
        paddingTop: '70px',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: '48px',
      },
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    titlesWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export default useStyles;
