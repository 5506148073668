export const pollableTypes = [
  {
    key: 'snmp_mib',
    label: 'SNMP MIB',
  },
];

export const pollableDataTypes = [
  {
    key: 'snmp_mib_integer',
    label: 'SNMP MIB Integer',
  },
  {
    key: 'snmp_mib_octet_string',
    label: 'SNMP MIB Octet String',
  },
];
