import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: '0',
      width: '100%',
    },
    deviceName: {
      padding: '0 !important',
    },
    executionInfo: {
      color: theme.palette.common.white,
      fontFamily: 'Inter, arial, sans-serif',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: 700,
    },
  }),
);

export default useStyles;
