import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import {
  GatewayCommand,
  GatewayCommands,
  PaginationFilter,
  GatewayCommandsFilters,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight, SENSOR_TYPE } from '../../app/constants';
import CommandExecutionRecord from '../CommandExecutionRecord';
import CommandExecutionDrawer from '../CommandExecutionDrawer';
import {
  getChildExecutions,
  getCommandExecutionStatus,
} from '../../helpers/executionStatuses';

interface RecentSoftwareUpdates {
  deviceType?: DeviceType | null;
}

const RecentSoftwareUpdates: React.FC<RecentSoftwareUpdates> = ({
  deviceType,
}) => {
  const dispatch = useAppDispatch();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [updates, setUpdates] = useState<GatewayCommand[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedGatewayCommand, setSelectedGatewayCommand] =
    useState<GatewayCommand>();
  const [infoDrawer, setInfoDrawer] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getReports = (pageNumber: number, addPage: boolean): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 10,
      order_by: '-created_at',
      page: pageNumber,
    };

    let filters: GatewayCommandsFilters = {
      command_type: { operator: 'eq', value: 'software_update' },
      device_id: {
        operator: 'eq',
        value: editableDevice?._id as string,
      },
    };

    if (deviceType?.type === SENSOR_TYPE) {
      filters = {
        attached_device_unique_ids: {
          operator: 'eq',
          value: editableDevice?.unique_id as string,
        },
      } as GatewayCommandsFilters;
    }

    GatewayCommands.list(filters, pagination)
      .then((res) => {
        const newReports = addPage
          ? [...updates, ...res.gatewayCommands]
          : res.gatewayCommands;

        setUpdates(newReports);
        setTotalAndPage(res.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    setLoading(true);
    getReports(1, false);
  }, []);

  const handleOpenDrawer = (
    r: GatewayCommand | undefined = undefined,
  ): void => {
    setInfoDrawer(!infoDrawer);
    if (r) {
      setSelectedGatewayCommand(r);
    }
  };

  const handleExecutionRecordCallback = (command: GatewayCommand): void => {
    setSelectedGatewayCommand(command);
    handleOpenDrawer();
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getReports(page + 1, true);
  };

  return (
    <>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <Grid container direction="column">
          {updates.map((command, i) => (
            <CommandExecutionRecord
              key={`${command._id}_${i}`}
              command={command}
              isLast={i === updates.length - 1}
              showSoftware={true}
              onCommandClick={handleExecutionRecordCallback}
            />
          ))}
          {updates.length !== total && (
            <Grid item xs={12} className={clsx('my-6 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {selectedGatewayCommand && (
        <CommandExecutionDrawer
          open={infoDrawer}
          handleCloseDrawer={handleOpenDrawer}
          childExecutions={getChildExecutions(selectedGatewayCommand)}
          executionStatus={getCommandExecutionStatus(selectedGatewayCommand)}
          executedBy={selectedGatewayCommand.user_id}
          executionDate={selectedGatewayCommand.created_at || ''}
          title={'Software Update'}
          subtitle1={`Package: ${selectedGatewayCommand.software_update_id}`}
        />
      )}
    </>
  );
};

export default RecentSoftwareUpdates;
