import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
export interface ActionsButtonProps {
  actionLabel?: string;
  link?: string;
  actionIcon?: ReactElement;
  margin?: boolean;
  actionCallback?: () => void;
  hasPermission: boolean;
}

const ActionButton: React.FC<ActionsButtonProps> = ({
  actionLabel,
  link,
  actionIcon,
  margin = true,
  actionCallback,
  hasPermission,
}) => {
  if (!hasPermission) {
    return null;
  }

  const navigate = useNavigate();

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    if (link) {
      navigate(`/${link}`);
    }

    if (actionCallback) {
      actionCallback();
    }
  };

  return (
    <Button
      data-cy="action-button"
      variant="contained"
      size="large"
      startIcon={actionIcon ?? <AddIcon />}
      onClick={handleActionClick}
      className={clsx({
        ['ml-4']: margin,
      })}
    >
      {actionLabel}
    </Button>
  );
};

export default ActionButton;
