import {
  IssueSoftwareUpdateInput,
  SoftwareUpdate,
  SoftwareUpdateInput,
} from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export type IssueSoftwareUpdateCommandType = 'software_update';

export interface CommandSchedule {
  end_datetime: string;
  retry_interval?: string;
  start_datetime: string;
}

const emptySoftwareUpdateInput: SoftwareUpdateInput & {
  company_id?: string;
  url?: string;
  type?: string;
} = {
  company_id: '',
  device_type_id: '',
  files: [],
  name: '',
  reboot: false,
  script: '',
  type: 'bash_script',
};

export const getSoftwareUpdatesSelector = createSelector(
  (state: SoftwareUpdatesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.softwareUpdates.length) {
      return state.softwareUpdate;
    }
    return state.softwareUpdates.find(
      (softwareUpdate: SoftwareUpdate) => softwareUpdate._id === id,
    );
  },
);

export const SoftwareUpdates = {
  CLEAN_NEW_SOFTWARE_UPDATE_INPUT: 'CLEAN_NEW_SOFTWARE_UPDATE_INPUT',
  SET_ACTUAL_SOFTWARE_UPDATE: 'SET_ACTUAL_SOFTWARE_UPDATE',
  SET_ISSUE_SOFTWARE_UPDATE_INPUT: 'ISSUE_SOFTWARE_UPDATE_INPUT',
  SET_NEW_SOFTWARE_UPDATE: 'SET_NEW_SOFTWARE_UPDATE',
  SET_NEW_SOFTWARE_UPDATE_INPUT: 'SET_NEW_SOFTWARE_UPDATE_INPUT',
  SET_ORIGINAL_SOFTWARE_UPDATE: 'SET_ORIGINAL_SOFTWARE_UPDATE',
  SET_SELECTED_SOFTWARE_UPDATE: 'SET_SELECTED_SOFTWARE_UPDATE',
  SET_SOFTWARE_UPDATES: 'SET_SOFTWARE_UPDATES',
};

export const setStateSoftwareUpdate = (
  softwareUpdates: SoftwareUpdate[],
): SoftwareUpdatesAction => ({
  data: softwareUpdates,
  type: SoftwareUpdates.SET_SOFTWARE_UPDATES,
});

export const setActualSoftwareUpdate = (
  softwareUpdate: SoftwareUpdate,
): SoftwareUpdatesAction => ({
  data: softwareUpdate,
  type: SoftwareUpdates.SET_ACTUAL_SOFTWARE_UPDATE,
});

export const setNewSoftwareUpdate = (
  softwareUpdate: SoftwareUpdate,
): SoftwareUpdatesAction => ({
  data: softwareUpdate,
  type: SoftwareUpdates.SET_NEW_SOFTWARE_UPDATE,
});

export const cleanNewSoftwareUpdateInput = (): SoftwareUpdatesAction => ({
  data: emptySoftwareUpdateInput,
  type: SoftwareUpdates.CLEAN_NEW_SOFTWARE_UPDATE_INPUT,
});

export const setNewSoftwareUpdateInput = (
  deviceProfile: SoftwareUpdateInput,
): SoftwareUpdatesAction => ({
  data: deviceProfile,
  type: SoftwareUpdates.SET_NEW_SOFTWARE_UPDATE_INPUT,
});

export const setNewIssueSoftwareUpdateInput = (
  deviceProfile: IssueSoftwareUpdateInput,
): SoftwareUpdatesAction => ({
  data: deviceProfile,
  type: SoftwareUpdates.SET_ISSUE_SOFTWARE_UPDATE_INPUT,
});

export const setSelectedSoftwareUpdate = (
  softwareUpdate: SoftwareUpdate[],
): SoftwareUpdatesAction => ({
  data: softwareUpdate,
  type: SoftwareUpdates.SET_SELECTED_SOFTWARE_UPDATE,
});

export const setOriginalSelectedSoftwareUpdate = (
  softwareUpdate: SoftwareUpdate[],
): SoftwareUpdatesAction => ({
  data: softwareUpdate,
  type: SoftwareUpdates.SET_ORIGINAL_SOFTWARE_UPDATE,
});

export type SoftwareUpdatesState = {
  softwareUpdates: SoftwareUpdate[];
  softwareUpdate: SoftwareUpdate | null;
  newSoftwareUpdate: SoftwareUpdate | null;
  newSoftwareUpdateInput: SoftwareUpdateInput & {
    type?: string;
    url?: string;
    company_id?: string;
  };
  newIssueSoftwareUpdateInput: IssueSoftwareUpdateInput | null;
  originalSelectedSoftwareUpdate: SoftwareUpdate[];
  selectedSoftwareUpdate: SoftwareUpdate[];
};

const softwareUpdatesInitialState: SoftwareUpdatesState = {
  newIssueSoftwareUpdateInput: {
    command_type: 'software_update',
    ids: [],
    software_update_id: '',
  },
  newSoftwareUpdate: null,
  newSoftwareUpdateInput: emptySoftwareUpdateInput,
  originalSelectedSoftwareUpdate: [],
  selectedSoftwareUpdate: [],
  softwareUpdate: null,
  softwareUpdates: [],
};

export type SoftwareUpdatesAction = {
  type: string;
  data:
    | string
    | SoftwareUpdate[]
    | SoftwareUpdate
    | SoftwareUpdateInput
    | null
    | IssueSoftwareUpdateInput;
};

const softwareUpdatesReducer = (
  state = softwareUpdatesInitialState,
  action: SoftwareUpdatesAction,
): SoftwareUpdatesState => {
  switch (action.type) {
    case SoftwareUpdates.SET_SOFTWARE_UPDATES:
      return {
        ...state,
        softwareUpdates: action.data as SoftwareUpdate[],
      };
    case SoftwareUpdates.SET_NEW_SOFTWARE_UPDATE:
      return {
        ...state,
        newSoftwareUpdate: action.data as SoftwareUpdate,
      };
    case SoftwareUpdates.SET_ACTUAL_SOFTWARE_UPDATE:
      return {
        ...state,
        newSoftwareUpdate: action.data as SoftwareUpdate,
        softwareUpdate: action.data as SoftwareUpdate,
      };
    case SoftwareUpdates.CLEAN_NEW_SOFTWARE_UPDATE_INPUT:
      return {
        ...state,
        newSoftwareUpdateInput: action.data as SoftwareUpdateInput,
      };
    case SoftwareUpdates.SET_NEW_SOFTWARE_UPDATE_INPUT:
      return {
        ...state,
        newSoftwareUpdateInput: action.data as SoftwareUpdateInput,
      };
    case SoftwareUpdates.SET_ISSUE_SOFTWARE_UPDATE_INPUT:
      return {
        ...state,
        newIssueSoftwareUpdateInput: action.data as IssueSoftwareUpdateInput,
      };

    case SoftwareUpdates.SET_SELECTED_SOFTWARE_UPDATE:
      return {
        ...state,
        selectedSoftwareUpdate: action.data as SoftwareUpdate[],
      };

    case SoftwareUpdates.SET_ORIGINAL_SOFTWARE_UPDATE:
      return {
        ...state,
        originalSelectedSoftwareUpdate: action.data as SoftwareUpdate[],
      };
    default:
      return state;
  }
};

export default softwareUpdatesReducer;
