import { ScheduledJob } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../components/SharedTable';
import { ClassNameMap, Typography } from '@mui/material';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import timeHelpers from '../../helpers/timeHelpers';

export const ScheduledJobColumns = (classes: ClassNameMap): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const scheduledJob = item as ScheduledJob;
        return scheduledJob.job_name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const command = item as ScheduledJob;
        return `/scheduled-job/${command._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const scheduledJob = item as ScheduledJob;
        return scheduledJob.job_type;
      },
      id: 'job_type',
      label: 'Job Type',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const scheduledJob = item as ScheduledJob;
        return scheduledJob.schedule;
      },
      id: 'schedule',
      label: 'Schedule',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const scheduledJob = item as ScheduledJob;
        return `${timeHelpers.getDate(scheduledJob.last_run_time)}`;
      },
      id: 'last_run_time',
      label: 'Last Run Time',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const scheduledJob = item as ScheduledJob;
        return `${timeHelpers.getDate(scheduledJob.next_run_time)}`;
      },
      id: 'next_run_time',
      label: 'Next Run Time',
      type: 'text',
    },
    {
      id: 'active',
      label: 'Active',
      parser: (item): React.ReactElement => {
        const device = item as ScheduledJob;
        return (
          <Typography variant="button" component="div" className={classes.tag}>
            <FiberSmartRecordIcon fontSize="small" className="mr-2" />
            {device.enabled ? 'Active' : 'Inactive'}
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};
