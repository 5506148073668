import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import {
  PaginationFilter,
  BulkResponse,
  BulkResponses,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setSorting } from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { defaultItemsPerPage, errorHighlight } from '../../../app/constants';
import { BulkJob, SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import ListSelection from '../../../components/ListSelection';
import SharedTable from '../../../components/SharedTable';
import useStyles from '../styles';
import BulkJobsFilters from './BulkJobsFilters';
import { BulkJobsColumns } from './columns';
import BulkJobInformationDrawer from '../../../containers/RightDrawer/BulkJobInformationDrawer/BulkJobInformationDrawer';
import { OLDEST_NEWEST_SORTING } from '../../../constants/sortings';

export const mapBulkResponseToBulkJobs = (
  bulkResponses: BulkResponse[],
): BulkJob[] => {
  return bulkResponses.map((response) => {
    const data: BulkJob = {
      _id: response._id,
      datetime: response.created_at,
      failures: '-',
      response,
      successes: '-',
      summary: <AutorenewIcon color="primary" />,
    };
    if (
      response.bulk_response_statuses &&
      response.bulk_response_statuses.length
    ) {
      const totalJobs = response.bulk_response_statuses.length;
      data.failures = response.bulk_response_statuses.filter(
        (e) => e?.status === 'fail',
      ).length;
      data.successes = totalJobs - (data.failures as number);

      if (data.failures === totalJobs) {
        data.summary = <ErrorIcon color="error" />;
      } else if (data.successes === totalJobs) {
        data.summary = <CheckCircleIcon color="success" />;
      } else {
        data.summary = <WarningIcon color="warning" />;
      }
    }

    return data;
  });
};

const BulkJobs: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.bulkJobs.sortBy, OLDEST_NEWEST_SORTING),
  );
  const [bulkJobs, setBulkJobs] = useState<BulkJob[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [bulkjobInformationDrawer, setBulkjobInformationDrawer] =
    useState(false);
  const [selectedBulkJob, setSelectedBulkJob] = useState<undefined | BulkJob>();

  const handleCloseJobInformationDrawer = (): void => {
    setBulkjobInformationDrawer(false);
  };

  const handleOpenExecutionInformationDrawer = (item: BulkJob): void => {
    setSelectedBulkJob(item);
    setBulkjobInformationDrawer(true);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getBulkResponses = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    BulkResponses.list(
      parseFilters(filters.bulkJobs.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const jobs = mapBulkResponseToBulkJobs(result.bulkResponses);
        const results: BulkJob[] = addPage ? [...bulkJobs, ...jobs] : jobs;

        setBulkJobs(results);
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getBulkResponses(1);
  }, [filters.commandsExecutions]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getBulkResponses(page + 1, true);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'commandsExecutions'));
    setSelectedSorting(option);
  };

  const handleActionClick = (item: BulkJob, action: 'details'): void => {
    switch (action) {
      case 'details':
        handleOpenExecutionInformationDrawer(item);
        break;
      default:
        break;
    }
  };

  return (
    <Grid container direction="column" spacing={0}>
      <BulkJobsFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={'list'}
        sortingOptions={OLDEST_NEWEST_SORTING}
        viewsOptions={[]}
        itemsSelected={false}
        allSelected={false}
        deleteAction={true}
        xPadding={false}
        sortingCallback={handleSorting}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <SharedTable
            columns={BulkJobsColumns(bulkJobs, handleActionClick)}
            rows={bulkJobs}
            sortBy={selectedSorting.value}
            sortDirection={
              selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
            }
            allSelected={false}
            loading={loading}
            selectedItemsIds={[]}
            xPadding={false}
            onRequestSort={handleTableSorting}
            viewOnly={true}
          />

          {bulkJobs.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <BulkJobInformationDrawer
        open={bulkjobInformationDrawer}
        handleCloseDrawer={handleCloseJobInformationDrawer}
        selectedBulkJob={selectedBulkJob}
      />
    </Grid>
  );
};

export default BulkJobs;
