import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  OutlinedInput,
} from '@mui/material';

import { SortingOption } from '../../models/common';

export interface AdvancedSelectProps {
  prop: string;
  options: SortingOption[];
  value?: string[];
  searchPlaceholder?: string;
  onOptionsChange: (prop: string, value: string) => void;
}

const AdvancedSelect: React.FC<AdvancedSelectProps> = ({
  prop,
  options,
  value = [],
  searchPlaceholder,
  onOptionsChange,
}) => {
  const [shownOptions, setShownOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');

  const allSelected = value.length === options.length;
  const indeterminate = value.length !== 0;

  useEffect(() => {
    if (inputValue) {
      setShownOptions(
        options.filter(
          (option) =>
            option.label
              .toLowerCase()
              .indexOf(inputValue.toLocaleLowerCase()) !== -1,
        ),
      );
    } else {
      setShownOptions(options);
    }
  }, [inputValue, options]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleOptionClick =
    (optionValue: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      let newValue: string[] = [];
      if (event.target.checked) {
        newValue = [...value, optionValue];
      } else {
        newValue = value.filter((option) => optionValue !== option);
      }
      onOptionsChange(prop, newValue.join('|'));
    };

  const selectAllCallback = (): void => {
    if (allSelected) {
      onOptionsChange(prop, '');
    } else {
      const newValue = options.map((option) => option.value);
      onOptionsChange(prop, newValue.join('|'));
    }
  };

  return (
    <>
      {options.length > 5 && (
        <div className="mb-2">
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              data-testid="advanced-select-search-text-input"
              name="inputValue"
              placeholder={searchPlaceholder || ''}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
            />
          </FormControl>
        </div>
      )}
      {options.length > 1 && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="advanced-select-checkbox-allselect"
                checked={allSelected}
                indeterminate={!allSelected && indeterminate}
                onClick={selectAllCallback}
              />
            }
            label={allSelected ? 'Deselect All' : 'Select All'}
          />
        </div>
      )}
      <Grid container>
        {shownOptions.map((option, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="advanced-select-bulk-checkbox"
                  checked={value.includes(option.value)}
                  onChange={handleOptionClick(option.value)}
                />
              }
              label={option.label}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AdvancedSelect;
