import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { UserType } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';
import useStyles from './styles';

interface UsersFiltersProps {
  total: number;
  userTypes: UserType[];
}

const UsersFilters: React.FC<UsersFiltersProps> = ({ userTypes, total }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.users.filters;

  const [rolesSelected, setTypesSelected] = useState<string[]>([]);

  const allRolesSelected = rolesSelected.length === userTypes.length;

  useEffect(() => {
    setTypesSelected(
      filters?.user_type_id && filters.user_type_id !== ''
        ? filters.user_type_id.split('|')
        : [],
    );
  }, [filters]);

  const changeFilters = (prop: string, value: string): void => {
    // TODO: check if the values are the same before dispatching
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'users',
      ),
    );
  };

  const handleRoleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event;

    if (value.includes('all')) {
      return;
    }
    changeFilters(
      'user_type_id',
      typeof value === 'string'
        ? value.replaceAll(',', '|')
        : value.length > 0
        ? value.join('|')
        : '',
    );
  };

  const selectAllTypes = (): void => {
    if (!allRolesSelected) {
      changeFilters(
        'user_type_id',
        userTypes.map((userType) => userType._id).join('|'),
      );
    } else {
      changeFilters('user_type_id', '');
    }
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search users by Email"
      inputKey="email"
      filterModel="users"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          multiple
          displayEmpty
          data-cy="role-filter"
          className={classes.input}
          value={rolesSelected}
          onChange={handleRoleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | React.ReactElement => {
            if (selected.length === 0) {
              return <Typography variant="button">Role</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Roles selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox
              checked={allRolesSelected}
              indeterminate={rolesSelected.length !== 0 && !allRolesSelected}
              onClick={selectAllTypes}
            />
            <ListItemText primary="All" />
          </MenuItem>
          {userTypes.map((userType) => (
            <MenuItem dense key={userType._id} value={userType._id}>
              <Checkbox checked={rolesSelected.indexOf(userType._id) > -1} />
              <ListItemText primary={userType.description} />
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default UsersFilters;
