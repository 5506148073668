import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { Ingestor, IngestorInput, Translator } from '@edgeiq/edgeiq-api-js';
import { checkListenerTypeDisabled, checkHandlerTypeDisabled } from './helper';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import ListenerTypeDynamicSection from './ListenerTypeDynamicSection/ListenerTypeDynamicSection';
import HandlerTypeDynamicSections from './HandlerTypeDynamicSections/HandlerTypeDynamicSections';
import {
  INGESTOR_HANDLER_TYPES_MAP,
  INGESTOR_LISTENER_TYPES_MAP,
  INGESTOR_TYPES_MAP,
} from '../../../constants/ingestors';

interface IngestorFormProps {
  newIngestor: IngestorInput | Ingestor;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  disabled?: boolean;
  addNewFilters: () => void;
  removeFilter: (index: number) => void;
  addDeviceTypeMapping: () => void;
  removeDeviceTypeMapping: (index: number) => void;
  addRoute: () => void;
  removeRoute: (index: number) => void;
  translatorOptions: Translator[];
}

const IngestorForm: React.FC<IngestorFormProps> = ({
  newIngestor,
  onInputChange,
  disabled,
  addNewFilters,
  removeFilter,
  addDeviceTypeMapping,
  removeDeviceTypeMapping,
  addRoute,
  removeRoute,
  translatorOptions,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Ingestor Name"
          prop="name"
          value={newIngestor.name}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} className="mt-2">
        <SelectInput
          label="Ingestor Type"
          prop="type"
          value={newIngestor.type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(INGESTOR_TYPES_MAP).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {INGESTOR_TYPES_MAP[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={12} className="mt-6">
        <Typography variant="h5">Listener Type</Typography>
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due the ingestor type compatibility.
        </Typography>
        <SelectInput
          prop="listener_type"
          value={newIngestor.listener_type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(INGESTOR_LISTENER_TYPES_MAP).map(
            (key, index) => (
              <MenuItem
                disabled={checkListenerTypeDisabled(newIngestor.type, key)}
                className="m-4 p-2"
                key={index}
                dense
                value={key}
              >
                {INGESTOR_LISTENER_TYPES_MAP[key]}
              </MenuItem>
            ),
          )}
        />
      </Grid>

      <ListenerTypeDynamicSection
        newIngestor={newIngestor}
        onInputChange={onInputChange}
        disabled={disabled}
        addNewFilters={addNewFilters}
        removeFilter={removeFilter}
        addDeviceTypeMapping={addDeviceTypeMapping}
        removeDeviceTypeMapping={removeDeviceTypeMapping}
      />

      <Grid item xs={12} className="mt-6">
        <Typography variant="h5">Handler Type</Typography>
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due the ingestor type and listener type
          compatibility.
        </Typography>
        <SelectInput
          prop="handler_type"
          value={newIngestor.handler_type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(INGESTOR_HANDLER_TYPES_MAP).map((key, index) => (
            <MenuItem
              disabled={checkHandlerTypeDisabled(
                newIngestor.type,
                newIngestor.listener_type,
                key,
              )}
              className="m-4 p-2"
              key={index}
              dense
              value={key}
            >
              {INGESTOR_HANDLER_TYPES_MAP[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <HandlerTypeDynamicSections
        newIngestor={newIngestor}
        onInputChange={onInputChange}
        disabled={disabled}
        addRoute={addRoute}
        removeRoute={removeRoute}
      />

      <Grid item xs={12} className="mt-6">
        <Typography variant="h5">Translator</Typography>
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due the ingestor type compatibility.
        </Typography>
        <SelectInput
          prop="translator_id"
          value={newIngestor.translator_id}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={translatorOptions.map((translator, index) => (
            <MenuItem
              className="m-4 p-2"
              key={index}
              dense
              value={translator._id}
            >
              {translator.name}
            </MenuItem>
          ))}
        />
      </Grid>
    </Grid>
  );
};

export default IngestorForm;
