import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Grid, Paper, FormControl, Typography } from '@mui/material';
import { Devices, DeviceType, EscrowDevices } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useStyles from './styles';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  cleanNewDeviceInput,
  cleanNewEscrowDeviceInput,
  setActualDevice,
  setDeviceInput,
  setEscrowDeviceInput,
} from '../../redux/reducers/devices.reducer';
import {
  errorHighlight,
  GATEWAY_TYPE,
  GENRE_ACTIVE,
  LWM2M_TYPE,
} from '../../app/constants';
import { getHeartbeatSeconds } from '../../helpers/heartbeat';
import SideTabs from '../../components/SideTabs';
import TextInput from '../../components/TextInput';
import Tags from '../../containers/Tags';
import Header from '../../containers/HeaderWithActionButton';
import DeviceMqttPassword from '../../containers/DeviceMqttPassword';
import MqttPasswordConfirmationDialog from '../../containers/MqttPasswordConfirmationDialog';
import IntegrationConfig from '../../containers/IntegrationConfig';
import PasswordInputWithAlert from '../../containers/PasswordInputWithAlert/PasswordInputWithAlert';
import DevicesGenre from '../devices/DevicesGenre';
import Publish from '../../containers/Publish';
import ProfileConfig from './NewDeviceProfileConfig';
import DeviceLogConfig from './NewDeviceLogConfig';
import HeartbeatConfig from './NewDeviceHeartbeatConfig';
import NewDevicePolicies from './NewDevicePolicies';
import NetworkConfig from './NewDeviceNetConfig';
import NewDeviceRelations from './NewDeviceRelations';

const CreateDevicePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [createdDeviceId, setCreatedDeviceId] = useState<string>('');

  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const devicesState = useAppSelector((state: RootState) => state.devices);
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;
  const { selectedPolicies } = useAppSelector(
    (state: RootState) => state.policies,
  );
  const { deviceInput, escrowDeviceInput } = devicesState;

  const [chosenDeviceType, setChosenDeviceType] = useState<
    DeviceType | undefined
  >(
    deviceTypesState.deviceTypes.find(
      (deviceType) => deviceType._id === deviceInput.device_type_id,
    ),
  );
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedHeartbeatUnit, setSelectedHearbeatUnit] =
    useState<string>('hours');

  const checkSubmitEnable = (): void => {
    if (isActive) {
      setEnableSubmit(
        deviceInput.name !== '' &&
          deviceInput.unique_id !== '' &&
          deviceInput.device_type_id !== '' &&
          deviceInput.company_id !== '' &&
          (chosenDeviceType?.type === GATEWAY_TYPE
            ? deviceInput.enforce_secure_mqtt === true
              ? deviceInput.mqtt_password !== ''
              : true
            : true),
      );
    } else {
      setEnableSubmit(
        escrowDeviceInput.unique_id !== '' &&
          escrowDeviceInput.token !== '' &&
          escrowDeviceInput.company_id !== '',
      );
    }
  };

  useEffect(() => {
    checkSubmitEnable();
    if (isActive) {
      if (
        !chosenDeviceType ||
        chosenDeviceType._id !== deviceInput.device_type_id
      ) {
        setChosenDeviceType(
          deviceTypesState.optionsDeviceTypes.find(
            (deviceType) => deviceType._id === deviceInput.device_type_id,
          ),
        );
      }
    }
  }, [escrowDeviceInput, devicesState.devicesGenre, deviceInput]);

  useEffect(() => {
    checkSubmitEnable();
  }, [chosenDeviceType]);

  const handleActiveDeviceChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    dispatch(setDeviceInput({ ...deviceInput, [prop]: value }));
  };

  const handleEscrowDeviceChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    dispatch(
      setEscrowDeviceInput({
        ...escrowDeviceInput,
        [prop]: value,
      }),
    );
  };

  const handleLwM2MCheckboxChange = (
    evt: ChangeEvent<HTMLInputElement>,
  ): void => {
    dispatch(
      setEscrowDeviceInput({
        ...escrowDeviceInput,
        ['lwm2m']: evt.target.checked,
      }),
    );
  };

  const handleDeviceChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (isActive) {
      dispatch(setDeviceInput({ ...deviceInput, [prop]: value }));
    } else {
      dispatch(setEscrowDeviceInput({ ...escrowDeviceInput, [prop]: value }));
    }
  };

  const handleLogConfigChange = (prop: string, value: string): void => {
    dispatch(
      setDeviceInput({
        ...deviceInput,
        log_config: {
          ...deviceInput.log_config,
          [prop]: value,
        },
      }),
    );
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleDeviceChange('company_id', companyId);
  };

  const handleShowActionDialog = (): void => {
    if (deviceInput.mqtt_password !== '') {
      setActionDialogOpen(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = (): void => {
    setActionDialogOpen(false);
    // was part of handlePublishSubmit before
    dispatch(cleanNewDeviceInput());
    if (createdDeviceId) {
      navigate(`/device/${createdDeviceId}`);
    } else {
      navigate('/devices');
    }
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    if (isActive) {
      if (deviceInput.heartbeat_period) {
        const seconds = getHeartbeatSeconds(
          selectedHeartbeatUnit,
          deviceInput.heartbeat_period,
        );
        deviceInput.heartbeat_period = seconds;
      }
      Devices.create(deviceInput)
        .then((deviceCreated) => {
          dispatch(setActualDevice(deviceCreated));
          setCreatedDeviceId(deviceCreated._id);
          if (selectedPolicies.length !== 0) {
            return Devices.bulkAttachRules(
              deviceCreated._id,
              selectedPolicies.map((policy) => policy._id),
            );
          }
        })
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: 'Device created successfully',
              type: 'success',
            }),
          );
          handleShowActionDialog();
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      EscrowDevices.create(escrowDeviceInput)
        .then((_deviceCreated) => {
          dispatch(
            setAlert({
              highlight: 'Escrow device created successfully',
              type: 'success',
            }),
          );
          dispatch(cleanNewEscrowDeviceInput());
          navigate('/devices');
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const inputChangeHandler = isActive
    ? handleActiveDeviceChange
    : handleEscrowDeviceChange;

  const renderPSKDisclaimer = (): JSX.Element => (
    <Grid item xs={12}>
      <Typography variant="subtitle1" className="custom-label">
        PSK fields are optional. Leave blank to have them automatically
        generated.
      </Typography>
    </Grid>
  );

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="devices"
          goBackLabel="Devices"
          isCreatePage={true}
          model="device"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-device-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new device
                </Typography>
                <DevicesGenre checkCreation={true} />
              </div>
              <Grid container direction="row" spacing={3}>
                {isActive && (
                  <Grid item xs={12}>
                    <TextInput
                      placeholder="Device name *"
                      prop="name"
                      required={true}
                      value={deviceInput.name}
                      onInputChange={inputChangeHandler}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextInput
                    label="Unique ID *"
                    placeholder="Unique ID *"
                    prop="unique_id"
                    required={true}
                    value={
                      isActive
                        ? deviceInput.unique_id
                        : escrowDeviceInput.unique_id
                    }
                    onInputChange={inputChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    label={isActive ? 'Serial number' : 'Token *'}
                    placeholder={isActive ? 'Serial number' : 'Token *'}
                    prop={isActive ? 'serial' : 'token'}
                    required={false}
                    value={
                      isActive ? deviceInput.serial : escrowDeviceInput.token
                    }
                    onInputChange={inputChangeHandler}
                  />
                </Grid>
                {isActive && (
                  <Grid item xs={12}>
                    <ProfileConfig
                      device={deviceInput}
                      onChangeDeviceType={handleDeviceChange}
                    />
                  </Grid>
                )}
                {isActive && chosenDeviceType?.type === LWM2M_TYPE && (
                  <>
                    {renderPSKDisclaimer()}
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Bootstrap PSK Identity"
                        placeholder="Bootstrap PSK Identity"
                        prop="lwm2m_bootstrap_psk_identity"
                        required={false}
                        value={deviceInput.lwm2m_bootstrap_psk_identity}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PasswordInputWithAlert
                        label="Bootstrap PSK"
                        placeholder="Bootstrap PSK"
                        prop="lwm2m_bootstrap_psk"
                        required={false}
                        value={deviceInput.lwm2m_bootstrap_psk}
                        onInputChange={inputChangeHandler}
                        hasCopyButton={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Server PSK Identity"
                        placeholder="Server PSK Identity"
                        prop="lwm2m_server_psk_identity"
                        required={false}
                        value={deviceInput.lwm2m_server_psk_identity}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PasswordInputWithAlert
                        label="Server PSK"
                        placeholder="Server PSK"
                        prop="lwm2m_server_psk"
                        required={false}
                        value={deviceInput.lwm2m_server_psk}
                        onInputChange={inputChangeHandler}
                        hasCopyButton={true}
                      />
                    </Grid>
                  </>
                )}

                {!isActive && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Serial number"
                        placeholder="Serial number"
                        prop="serial"
                        required={true}
                        value={escrowDeviceInput.serial}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Part number"
                        placeholder="Part number"
                        prop="part_number"
                        required={true}
                        value={escrowDeviceInput.part_number}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="subtitle2"
                        className={clsx('custom-label', classes.titleContainer)}
                      >
                        <FormControl variant="outlined">
                          <Checkbox
                            checked={escrowDeviceInput.lwm2m}
                            onChange={handleLwM2MCheckboxChange}
                          />
                        </FormControl>
                        Device will be managed via LwM2M?
                      </Typography>
                    </Grid>
                  </>
                )}
                {!isActive && escrowDeviceInput?.lwm2m && (
                  <>
                    {renderPSKDisclaimer()}
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Bootstrap PSK Identity"
                        placeholder="Bootstrap PSK Identity"
                        prop="lwm2m_bootstrap_psk_identity"
                        required={false}
                        value={escrowDeviceInput.lwm2m_bootstrap_psk_identity}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PasswordInputWithAlert
                        label="Bootstrap PSK"
                        placeholder="Bootstrap PSK"
                        prop="lwm2m_bootstrap_psk"
                        required={false}
                        value={escrowDeviceInput.lwm2m_bootstrap_psk}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        label="Server PSK Identity"
                        placeholder="Server PSK Identity"
                        prop="lwm2m_server_psk_identity"
                        required={false}
                        value={escrowDeviceInput.lwm2m_server_psk_identity}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PasswordInputWithAlert
                        label="Server PSK"
                        placeholder="Server PSK"
                        prop="lwm2m_server_psk"
                        required={false}
                        value={escrowDeviceInput.lwm2m_server_psk}
                        onInputChange={inputChangeHandler}
                      />
                    </Grid>
                  </>
                )}
                {isActive && chosenDeviceType?.type === GATEWAY_TYPE && (
                  <Grid item xs={12}>
                    <DeviceMqttPassword
                      mqttPassword={deviceInput.mqtt_password}
                      enforceSecureMqtt={deviceInput.enforce_secure_mqtt}
                      onInputChange={inputChangeHandler}
                      hasPasswordDisclaimer={true}
                      authMethod={deviceInput.auth_method}
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          {isActive && (
            <Grid item xs={12}>
              <Paper className="shadow p-8">
                <Typography
                  variant="h5"
                  className={clsx('mb-6', classes.title)}
                >
                  Configuration
                </Typography>
                <SideTabs
                  tabs={{
                    config: (
                      <NetworkConfig
                        deviceType={chosenDeviceType}
                        networkConfigId={deviceInput.device_config_id}
                        onChangeNetworkConfig={handleDeviceChange}
                      />
                    ),
                    heartbeat: (
                      <HeartbeatConfig
                        deviceType={chosenDeviceType}
                        heartbeatPeriod={deviceInput.heartbeat_period}
                        heartbeatValues={deviceInput.heartbeat_values}
                        onChangePeriod={handleDeviceChange}
                        selectedHeartbeatUnit={selectedHeartbeatUnit}
                        setSelectedHearbeatUnit={setSelectedHearbeatUnit}
                      />
                    ),
                    integrations: (
                      <IntegrationConfig
                        deviceType={chosenDeviceType}
                        device={deviceInput}
                        onIntegrationChange={handleDeviceChange}
                        isCreateDialog={true}
                      />
                    ),
                    logging: (
                      <DeviceLogConfig
                        deviceType={chosenDeviceType}
                        localLog={deviceInput.log_config?.local_level}
                        forwardLog={deviceInput.log_config?.forward_level}
                        onChangeLogConfig={handleLogConfigChange}
                      />
                    ),
                    policies: <NewDevicePolicies />,
                    relations: (
                      <NewDeviceRelations
                        deviceType={chosenDeviceType}
                        onChangeRelations={handleDeviceChange}
                      />
                    ),
                    tags: (
                      <Tags
                        key="device-tags"
                        originalTags={deviceInput.tags ?? []}
                        onChangeTags={handleDeviceChange}
                        label="Tags"
                      />
                    ),
                  }}
                  labelMap={{
                    relations:
                      chosenDeviceType?.role === 'endpoint'
                        ? 'Choose parent gateway'
                        : 'Relations',
                  }}
                  defaultTab="config"
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="device"
          submitting={submitting}
          companyId={
            isActive ? deviceInput.company_id : escrowDeviceInput.company_id
          }
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
      <MqttPasswordConfirmationDialog
        mqtt_password={deviceInput.mqtt_password ?? ''}
        onConfirm={handleConfirm}
        open={ActionDialogOpen}
        changed={false}
      />
    </Grid>
  );
};

export default CreateDevicePage;
