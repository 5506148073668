import { DeviceType, DeviceTypeInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';

const emptyDeviceTypeInput: DeviceTypeInput = {
  capabilities: {
    firmware: {
      backup: false,
      upgrade: false,
    },
    iptables: false,
    network_connections: [],
    peripherals: [],
  },
  company_id: '',
  manufacturer: '',
  model: '',
  name: '',
  role: 'gateway',
  type: 'gateway',
};

export const getDeviceTypeSelector = createSelector(
  (state: DeviceTypesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.deviceTypes.length) {
      return state.deviceType;
    }
    return state.deviceTypes.find(
      (deviceType: DeviceType) => deviceType._id === id,
    );
  },
);

export const DeviceTypesTypes = {
  CLEAN_NEW_DEVICE_TYPE_INPUT: 'CLEAN_NEW_DEVICE_TYPE_INPUT',
  SET_ACTUAL_DEVICE_TYPE: 'SET_ACTUAL_DEVICE_TYPE',
  SET_DEVICE_TYPES: 'SET_DEVICE_TYPES',
  SET_NEW_DEVICE_TYPE: 'SET_NEW_DEVICE_TYPE',
  SET_NEW_DEVICE_TYPE_INPUT: 'SET_NEW_DEVICE_TYPE_INPUT',
  SET_OPTIONS_DEVICE_TYPES: 'SET_OPTIONS_DEVICE_TYPES',
};

export const setStateDeviceTypes = (
  devices: DeviceType[],
): DeviceTypesAction => ({
  data: devices,
  type: DeviceTypesTypes.SET_DEVICE_TYPES,
});

export const setOptionsDeviceTypes = (
  devices: DeviceType[],
): DeviceTypesAction => ({
  data: devices,
  type: DeviceTypesTypes.SET_OPTIONS_DEVICE_TYPES,
});

export const setActualDeviceType = (device: DeviceType): DeviceTypesAction => ({
  data: device,
  type: DeviceTypesTypes.SET_ACTUAL_DEVICE_TYPE,
});

export const setNewDeviceType = (
  deviceType: DeviceType,
): DeviceTypesAction => ({
  data: deviceType,
  type: DeviceTypesTypes.SET_NEW_DEVICE_TYPE,
});

export const setNewDeviceTypeInput = (
  deviceProfile: DeviceTypeInput,
): DeviceTypesAction => ({
  data: deviceProfile,
  type: DeviceTypesTypes.SET_NEW_DEVICE_TYPE_INPUT,
});

export const cleanNewDeviceTypeInput = (): DeviceTypesAction => ({
  data: emptyDeviceTypeInput,
  type: DeviceTypesTypes.CLEAN_NEW_DEVICE_TYPE_INPUT,
});

export type DeviceTypesState = {
  deviceType: DeviceType | undefined;
  deviceTypes: DeviceType[];
  newDeviceType: DeviceType | undefined;
  newDeviceTypeInput: DeviceTypeInput;
  optionsDeviceTypes: DeviceType[];
};

// INITIAL STATE
const deviceTypesInitialState: DeviceTypesState = {
  deviceType: undefined,
  deviceTypes: [],
  newDeviceType: undefined,
  newDeviceTypeInput: emptyDeviceTypeInput,
  optionsDeviceTypes: [],
};

export type DeviceTypesAction = {
  data: DeviceType[] | DeviceType | DeviceTypeInput;
  type: string;
};

const devicesTypesReducer = (
  state = deviceTypesInitialState,
  action: DeviceTypesAction,
): DeviceTypesState => {
  switch (action.type) {
    case DeviceTypesTypes.SET_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: action.data as DeviceType[],
      };

    case DeviceTypesTypes.SET_OPTIONS_DEVICE_TYPES:
      return {
        ...state,
        optionsDeviceTypes: action.data as DeviceType[],
      };

    case DeviceTypesTypes.SET_ACTUAL_DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.data as DeviceType,
        newDeviceType: cloneDeep(action.data) as DeviceType,
      };
    case DeviceTypesTypes.SET_NEW_DEVICE_TYPE:
      return {
        ...state,
        newDeviceType: action.data as DeviceType,
      };
    case DeviceTypesTypes.SET_NEW_DEVICE_TYPE_INPUT:
      return {
        ...state,
        newDeviceTypeInput: action.data as DeviceTypeInput,
      };
    case DeviceTypesTypes.CLEAN_NEW_DEVICE_TYPE_INPUT:
      return {
        ...state,
        newDeviceTypeInput: action.data as DeviceTypeInput,
      };
    default:
      return state;
  }
};

export default devicesTypesReducer;
