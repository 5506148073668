import React from 'react';
import { DiscoveredDevice } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper } from '@mui/material';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setNewDiscoveredDevice } from '../../redux/reducers/devices.reducer';
import TextInput from '../../components/TextInput';
import Tags from '../../containers/Tags';

const DeviceDetails: React.FC<Record<string, unknown>> = () => {
  const dispatch = useAppDispatch();

  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDiscoveredDevice,
  );

  const dispatchChange = (
    prop: string,
    value: string | string[] | number,
  ): void => {
    dispatch(
      setNewDiscoveredDevice({
        ...editableDevice,
        [prop]: value,
      } as DiscoveredDevice),
    );
  };

  const handleChange = (prop: string, value: string | number): void => {
    dispatchChange(prop, value);
  };

  const handleTags = (prop: string, value: string[]): void => {
    dispatchChange(prop, value);
  };

  return (
    <Grid container>
      {editableDevice && (
        <>
          <Grid
            component={Paper}
            item
            xs={12}
            md={8}
            className={clsx('p-7 shadow')}
          >
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6} className="mt-6">
                <TextInput
                  label="Name"
                  value={editableDevice.name}
                  prop="name"
                  onInputChange={handleChange}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mt-6">
                <TextInput
                  label="Serial Number"
                  value={editableDevice.serial}
                  prop="serial"
                  onInputChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mt-6">
                <TextInput
                  label="Manufacturer"
                  value={editableDevice.manufacturer}
                  prop="manufacturer"
                  onInputChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mt-6">
                <TextInput
                  label="Model"
                  value={editableDevice.model}
                  prop="model"
                  onInputChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} className="mt-6">
                <TextInput
                  label="Location"
                  value={editableDevice.location}
                  prop="location"
                  onInputChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mt-6">
                <Tags
                  key="discovered-device-tags"
                  originalTags={editableDevice.tags ?? []}
                  onChangeTags={handleTags}
                  label="Tags"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeviceDetails;
