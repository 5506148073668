import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chosenTypeButton: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.primary.main,
    },
    input: {
      height: '44px',
    },
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    tag: {
      alignItems: 'center',
      color: theme.palette.grey[100],
      display: 'flex',
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: 700,
      width: '170px',
    },
    typeButton: {
      color: theme.palette.grey[200],
    },
  }),
);

export default useStyles;
