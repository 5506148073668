import * as React from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';

import { WizardStep } from '../../models/common';

interface WizardStepperProps {
  steps: WizardStep[];
}

const WizardStepper: React.FC<WizardStepperProps> = ({ steps }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepSkipped = (step: number): boolean => {
    return skipped.has(step);
  };

  const handleNext = (): void => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {
            optional: (
              <Typography variant="caption">
                {step.optional ? 'Optional' : 'Required'}
              </Typography>
            ),
          };
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel data-cy={`wizard-step-${index + 1}`} {...labelProps}>
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>
        <div>{steps[activeStep].content}</div>
        <Box
          className="mt-6"
          sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
        >
          {activeStep !== 0 && (
            <Button
              data-cy="wizard-back-button"
              variant="contained"
              size="medium"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          )}
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep !== steps.length - 1 && (
            <Button
              data-cy="wizard-next-button"
              variant="contained"
              size="medium"
              color="primary"
              disabled={activeStep === steps.length - 1}
              onClick={handleNext}
              sx={{ mr: 1 }}
            >
              Next
            </Button>
          )}
        </Box>
      </>
    </Box>
  );
};

export default WizardStepper;
