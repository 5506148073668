import React, { useState, useEffect } from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid, Typography, Button, MenuItem } from '@mui/material';
import SwitchButton from '../../../components/SwitchButton';
import IntegrationInfoDrawer from '../../RightDrawer/IntegrationInformation/AwsInformationDrawer';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';

import { greengrassVersion } from '../../../app/constants';
import PasswordInput from '../../../components/PasswordInput';

interface AwsIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
  hideEmptySensitiveFields?: boolean;
}

const AwsIntegrationSection: React.FC<AwsIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
  disabled,
  hideEmptySensitiveFields,
}) => {
  const [greengrassSwitch, setGreengrassSwitch] = useState<boolean>(false);
  const [greengrassDrawer, setGreengrassDrawer] = useState<boolean>(false);

  useEffect(() => {
    //NOTE: Properly updates greenglass switch state when navigating between readonly AWS Integrations form views.
    if (!disabled) {
      return;
    }
    if (
      newIntegration.greengrass_version &&
      newIntegration.greengrass_core_install_url
    ) {
      setGreengrassSwitch(true);
      return;
    }
    setGreengrassSwitch(false);
  }, [newIntegration]);

  const handleOpenDrawer = (): void => {
    setGreengrassDrawer(!greengrassDrawer);
  };

  const handleGreengrassSwitch = (_prop: string, checked: boolean): void => {
    setGreengrassSwitch(checked);
  };

  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Region"
          prop="region"
          value={newIntegration.region}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      {(!hideEmptySensitiveFields || newIntegration.external_id) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="External Id"
            prop="external_id"
            value={newIntegration.external_id}
            onInputChange={onInputChange}
            disabled={disabled}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}

      <Grid item xs={12} className="mt-6">
        <TextInput
          label="Role Arn"
          prop="role_arn"
          value={newIntegration.role_arn}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} className="mt-6">
        <SwitchButton
          label="Include AWS Greengrass"
          prop="greenGrassSwitch"
          value={greengrassSwitch}
          onSwitchChange={handleGreengrassSwitch}
          disabled={disabled}
        />
      </Grid>

      {greengrassSwitch && (
        <Grid item xs={12} className="mt-4">
          <Grid item xs={12} className="mt-5">
            <SelectInput
              label="Greengrass Version"
              prop="greengrass_version"
              value={newIntegration.greengrass_version ?? ''}
              onSelectChange={onInputChange}
              options={[
                <MenuItem dense value={''} key="no-value-greengrass">
                  Select greengrass version
                </MenuItem>,
                ...Object.keys(greengrassVersion).map((key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {greengrassVersion[key]}
                  </MenuItem>
                )),
              ]}
              disabled={disabled}
            />
          </Grid>
          <Typography variant="subtitle1" className="mt-5">
            In order to setup the Greengrass integration we need a URL to the
            installation file.
          </Typography>
          <Button className="p-0 mt-2" onClick={handleOpenDrawer}>
            These instructions will help you locate your version's URL.
          </Button>

          <Grid item xs={12} className="mt-6">
            <TextInput
              placeholder="AWS IoT Greengrass Core Install URL"
              prop="greengrass_core_install_url"
              value={newIntegration.greengrass_core_install_url}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </Grid>
          <IntegrationInfoDrawer
            open={greengrassDrawer}
            handleCloseDrawer={handleOpenDrawer}
          />
        </Grid>
      )}
    </>
  );
};

export default AwsIntegrationSection;
