import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IntegrationInput, Integrations } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import Header from '../../containers/HeaderWithActionButton';
import Publish from '../../containers/Publish';
import IntegrationForm from '../../containers/Forms/IntegrationForm/IntegrationForm';
import useStyles from './styles';
import { setOptionsIntegrations } from '../../redux/reducers/integrations.reducer';

const EmptyIntegration: IntegrationInput = {
  archetype: '',
  company_id: '',
  long_description: '',
  name: '',
  type: 'aws_device_integrations',
};

const CreateIntegrationPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [newIntegration, setNewIntegration] =
    useState<IntegrationInput>(EmptyIntegration);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [dynamicSubmit, setDynamicSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      newIntegration.name !== '' && newIntegration.company_id !== '',
    );
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [newIntegration]);

  const handleValueChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    setNewIntegration({ ...newIntegration, [prop]: value });
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleValueChange('company_id', companyId);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);

    Integrations.create(newIntegration)
      .then((_response) => {
        dispatch(
          setAlert({
            highlight: 'Integration created successfully',
            type: 'success',
          }),
        );
        dispatch(setOptionsIntegrations([]));
        navigate('/integrations');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleEnableSubmit = (args: boolean): void => {
    setDynamicSubmit(args);
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="integrations"
          goBackLabel="Integrations"
          isCreatePage={true}
          model="integration"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8">
              <Box className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-integration-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new integration
                </Typography>
              </Box>

              <IntegrationForm
                newIntegration={newIntegration}
                onInputChange={handleValueChange}
                setEnableSubmit={handleEnableSubmit}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="integration"
          submitting={submitting}
          companyId={newIntegration.company_id}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit && dynamicSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateIntegrationPage;
