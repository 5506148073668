import { WorkflowAction } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';

export const ActionColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const action = item as WorkflowAction;
      return action.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const action = item as WorkflowAction;
      return action.type;
    },
    id: 'type',
    label: 'Type',
    type: 'text',
  },
];
