import React from 'react';
import { Grid, Paper } from '@mui/material';
import { Device, Integration } from '@edgeiq/edgeiq-api-js';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNewPolicy } from '../../../redux/reducers/policies.reducer';
import {
  addNewThenAction,
  changeThenActionsValues,
  removeThenAction,
} from '../../../containers/Forms/PolicyForm/helper';
import PolicyActionsSection from '../../../containers/Forms/PolicyForm/PolicyActionsSection/PolicyActionsSection';

type Props = {
  integrations: Integration[];
  selectedDevices: Device[];
  mixedTypes: boolean;
};
const PolicyActionsSectionWrapper: React.FC<Props> = ({
  integrations,
  selectedDevices,
  mixedTypes,
}) => {
  const dispatch = useAppDispatch();
  const newPolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );

  // START OF THEN ACTIONS MODULE
  const handleThenActionValueChange = (
    prop: string,
    value: string | number | string[] | boolean | { [key: string]: string },
  ): void => {
    dispatch(
      setNewPolicy(changeThenActionsValues(newPolicy, prop, value as string)),
    );
  };

  const handleAddNewAction = (): void => {
    return dispatch(setNewPolicy(addNewThenAction(newPolicy)));
  };

  const handleRemoveAction = (actionIndex: number): void => {
    return dispatch(setNewPolicy(removeThenAction(newPolicy, actionIndex)));
  };

  // END OF THEN ACTIONS MODULE

  return (
    <Grid container>
      {newPolicy && (
        <Grid component={Paper} item xs={12} className={'p-7 shadow'}>
          <PolicyActionsSection
            newPolicy={newPolicy}
            integrations={integrations}
            selectedDevices={selectedDevices}
            mixedTypes={mixedTypes}
            onInputChange={handleThenActionValueChange}
            onAddNewAction={handleAddNewAction}
            onRemoveAction={handleRemoveAction}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PolicyActionsSectionWrapper;
