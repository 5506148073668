import React, { ReactElement } from 'react';
import { TextField, Stack, TextFieldProps } from '@mui/material';
import {
  MobileDateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import AdapterLuxon from '@mui/lab/AdapterLuxon';

export interface DatePickerProps {
  value: string;
  prop: string;
  disabled?: boolean;
  onInputChange: (prop: string, value: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onInputChange,
  value,
  prop,
  disabled,
}) => {
  const handleDateChange = (newValue: string | null): void => {
    onInputChange(prop, newValue as string);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Stack spacing={3} data-testid="datePicker">
        <MobileDateTimePicker
          disabled={disabled}
          value={value}
          onChange={handleDateChange}
          renderInput={(params: TextFieldProps): ReactElement => (
            <TextField {...params} />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePicker;
