import { CommandExecution } from '@edgeiq/edgeiq-api-js';

export const CommandsTypes = {
  SET_COMMANDS_EXECUTIONS: 'SET_COMMANDS_EXECUTIONS',
};

export const setStateCommandsExecutions = (
  commands: CommandExecution[],
): CommandsExecutionsAction => ({
  data: commands,
  type: CommandsTypes.SET_COMMANDS_EXECUTIONS,
});

export type CommandsExecutionsState = {
  commandsExecutions: CommandExecution[];
};

// INITIAL STATE
const commandsInitialState: CommandsExecutionsState = {
  commandsExecutions: [],
};

export type CommandsExecutionsAction = {
  data: CommandExecution[];
  type: string;
};

const commandsExecutionsReducer = (
  state = commandsInitialState,
  action: CommandsExecutionsAction,
): CommandsExecutionsState => {
  switch (action.type) {
    case CommandsTypes.SET_COMMANDS_EXECUTIONS:
      return {
        ...state,
        commandsExecutions: action.data as CommandExecution[],
      };

    default:
      return state;
  }
};

export default commandsExecutionsReducer;
