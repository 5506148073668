import { EIQFile } from '@edgeiq/edgeiq-api-js';
import { IconButton, Tooltip } from '@mui/material';
import { ReactElement } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { TableColumn, TableItemType } from '../../components/SharedTable';
import { getFileSizeText } from '../../helpers/mimetype';

export const ConfigDeviceCols = (
  handleDownloadFile: (file: EIQFile) => () => void,
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const file = item as EIQFile;
        return file.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const file = item as EIQFile;
        return file.company_id ?? '';
      },
      id: 'device_id',
      label: 'AccountId',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const file = item as EIQFile;
        return file.mime_type;
      },
      id: 'mime_type',
      label: 'Mime Type',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const file = item as EIQFile;
        if (file.size) {
          return getFileSizeText(file.size);
        }
        return '--';
      },
      id: 'size',
      label: 'Size',
      type: 'text',
    },
    {
      id: 'options',
      label: 'Download file',
      parser: (item: TableItemType): ReactElement => {
        const file = item as EIQFile;
        if (!file.size) {
          return <></>;
        }
        return (
          <Tooltip placement="top" title="Download file">
            <IconButton
              aria-label="download-file"
              onClick={handleDownloadFile(file as EIQFile)}
              size="small"
            >
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        );
      },
      type: 'custom',
    },
  ];
};
