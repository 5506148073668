import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import {
  Commands,
  CommandExecution,
  CommandExecutions,
  PaginationFilter,
  PaginatedCommandExecutions,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setStateCommands } from '../../../redux/reducers/commands.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import CommandExecutionInfoDrawer from './CommandExecutionInfoDrawer';
import CommandExecutionRecord from '../../../containers/CommandExecutionRecord';

interface CommandExecutionListProps {
  commandId?: string;
  deviceId?: string;
  ref?: React.ForwardedRef<unknown>;
}

const CommandExecutionList: React.FC<CommandExecutionListProps> = forwardRef(
  (props, ref) => {
    const dispatch = useAppDispatch();
    const { commandId, deviceId } = props;
    const { commands } = useAppSelector((state: RootState) => state.commands);
    const [commandExecutions, setCommandExecutions] = useState<
      CommandExecution[]
    >([]);
    const [selectedCommandExecution, setSelectedCommandExecution] =
      useState<CommandExecution>();
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [openInfoDrawer, setOpenInfoDrawer] = useState(false);

    const setTotalAndPage = (newTotal: number, addPage = false): void => {
      setTotal(newTotal);
      if (addPage) {
        setPage(page + 1);
      }
    };

    const dispatchError = (errorMessage: string): void => {
      dispatch(
        setAlert({
          highlight: errorHighlight,
          message: errorMessage,
          type: 'error',
        }),
      );
    };

    const noLoading = (): void => {
      setLoading(false);
    };

    const processResults = (
      result: PaginatedCommandExecutions,
      addPage: boolean,
    ): void => {
      const newCommandExecutions = addPage
        ? [...commandExecutions, ...result.commandExecutions]
        : result.commandExecutions;
      setCommandExecutions(newCommandExecutions);
      setTotalAndPage(result.pagination.total, addPage);
    };

    const getCommandExecutions = (
      pageNumber: number,
      addPage = false,
    ): void => {
      const pagination: PaginationFilter = {
        itemsPerPage: 10,
        page: pageNumber,
      };
      if (commandId) {
        Commands.getCommandExecutions(commandId as string, {}, pagination)
          .then((result) => {
            processResults(result, addPage);
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => noLoading());
      } else if (deviceId) {
        CommandExecutions.list(
          {
            device_id: { operator: 'eq', value: deviceId },
          },
          pagination,
        )
          .then((result) => {
            processResults(result, addPage);
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => noLoading());
      }
    };

    const getCommands = (): void => {
      Commands.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setStateCommands(result.commands));
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => noLoading());
    };

    useEffect(() => {
      if (commandId || deviceId) {
        setLoading(true);
        getCommandExecutions(1);
      }

      // In the device details tab we want show this list with the command name for each execution record
      // In the command content page we want to show this list with the device unirque id for each execution record.
      // If the device ID is provided then we are in the device page, then we get all the commands available, because unlike devices
      // it's not common to have hundreds of commands.
      // in CommandExecutionRecord component either we just get the command or we fetch the device depending on the page.
      if (deviceId && commands.length === 0) {
        getCommands();
      }
    }, [commandId, deviceId]);

    // This hook function is called when the ref has a value, in Command Content page the list is the ref passed forward,
    // if the user applies the command the execution list is refreshed.
    useImperativeHandle(ref, () => ({
      refreshExecutionList(): void {
        setCommandExecutions([]);
        getCommandExecutions(1);
      },
    }));

    const handleCommandExecutionCardCallback = (
      commandExecution?: CommandExecution,
    ): void => {
      if (commandExecution) {
        setSelectedCommandExecution(commandExecution);
        handleOpenCommandExecutionDrawer();
      }
    };

    const handleOpenCommandExecutionDrawer = (): void => {
      setOpenInfoDrawer(true);
    };

    const handleCloseCommandExecutionDrawer = (): void => {
      setOpenInfoDrawer(false);
    };

    const handleLoadMore = (
      event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
      event.preventDefault();
      setLoadingMore(true);
      getCommandExecutions(page + 1, true);
    };

    return (
      <>
        {commandId && (
          <Typography className={clsx('mt-8 mb-4')} variant="h5">
            Execution Records
          </Typography>
        )}
        {loading ? (
          <Grid container className="loading-container">
            <CircularProgress size={75} thickness={5} />
          </Grid>
        ) : (
          <>
            <Grid container direction="column">
              {commandExecutions.map((commandExecution, i) => {
                return (
                  <CommandExecutionRecord
                    key={commandExecution._id}
                    showDevice={!deviceId}
                    showCommand={!!deviceId}
                    commandExecution={commandExecution}
                    isLast={i === commandExecutions.length - 1}
                    onExecutionClick={handleCommandExecutionCardCallback}
                  />
                );
              })}
            </Grid>
            {commandExecutions.length !== total && (
              <Grid item xs={12} className={clsx('my-6 loading-container')}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleLoadMore}
                >
                  {!loadingMore ? (
                    <Typography variant="button">Load more</Typography>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Button>
              </Grid>
            )}
          </>
        )}

        {selectedCommandExecution && (
          <CommandExecutionInfoDrawer
            open={openInfoDrawer}
            handleCloseDrawer={handleCloseCommandExecutionDrawer}
            commandExecution={selectedCommandExecution}
          />
        )}
      </>
    );
  },
);

export default CommandExecutionList;
