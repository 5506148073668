import ActionButton from '../../components/ActionButton';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { ActionsButtonProps } from '../../components/ActionButton/ActionButton';

export interface ActionButtonContainerProps
  extends Omit<ActionsButtonProps, 'hasPermission'> {
  model: string;
}

const ActionButtonContainer: React.FC<ActionButtonContainerProps> = (props) => {
  const { permissions } = useAppSelector((state: RootState) => state.user);

  return <ActionButton hasPermission={!!permissions[props.model]} {...props} />;
};

export default ActionButtonContainer;
