import React from 'react';
import PolicyDerivedValuesSection from '../../../containers/Forms/PolicyForm/PolicyDerivedValuesSection/PolicyDerivedValuesSection';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNewPolicy } from '../../../redux/reducers/policies.reducer';
import { RootState } from '../../../redux/store';

import {
  addNewDerivedValue,
  addNewProperty,
  addNewWhereCondition,
  changeDerivedValues,
  removeDerivedValue,
  removeProperty,
  removeWhereCondition,
} from '../../../containers/Forms/PolicyForm/helper';
import { Grid, Paper } from '@mui/material';

const PolicyDerivedValuesSectionWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const newPolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );

  // START OF THE DERIVED VALUES MODULE
  const handleDerivedValuesChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    dispatch(setNewPolicy(changeDerivedValues(newPolicy, prop, value)));
  };

  const handleAddDerivedValue = (): void => {
    if (!newPolicy.rule_condition?.derived_values) {
      newPolicy.rule_condition = {
        ...newPolicy.rule_condition,
        derived_values: [],
      };
    }
    return dispatch(setNewPolicy(addNewDerivedValue(newPolicy)));
  };

  const handleRemoveDerivedValue = (derivedValueIndex: number): void => {
    return dispatch(
      setNewPolicy(removeDerivedValue(newPolicy, derivedValueIndex)),
    );
  };

  const handleAddNewProperty = (derivedValueIndex: number): void => {
    return dispatch(setNewPolicy(addNewProperty(newPolicy, derivedValueIndex)));
  };

  const handleRemoveProperty = (
    derivedValueIndex: number,
    propertyIndex: number,
  ): void => {
    return dispatch(
      setNewPolicy(removeProperty(newPolicy, derivedValueIndex, propertyIndex)),
    );
  };

  const handleAddWhereCondition = (derivedValueIndex: number): void => {
    return dispatch(
      setNewPolicy(addNewWhereCondition(newPolicy, derivedValueIndex)),
    );
  };

  const handleRemoveWhereCondition = (
    derivedValueIndex: number,
    whereConditionIndex: number,
  ): void => {
    return dispatch(
      setNewPolicy(
        removeWhereCondition(newPolicy, derivedValueIndex, whereConditionIndex),
      ),
    );
  };
  // END OF THE DERIVED VALUES MODULE

  return (
    <Grid container>
      {newPolicy && (
        <>
          <Grid component={Paper} item xs={12} className={'p-7 shadow'}>
            {/* Details Form */}
            {/* <Typography variant="h5" className='mb-4'>Derived Values</Typography> */}
            <PolicyDerivedValuesSection
              newPolicy={newPolicy}
              onInputChange={handleDerivedValuesChange}
              onAddNewProperty={handleAddNewProperty}
              onRemoveProperty={handleRemoveProperty}
              onAddNewDerivedValue={handleAddDerivedValue}
              onRemoveDerivedValue={handleRemoveDerivedValue}
              onAddWhereCondition={handleAddWhereCondition}
              onRemoveWhereCondition={handleRemoveWhereCondition}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PolicyDerivedValuesSectionWrapper;
