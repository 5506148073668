import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    executionInfo: {
      color: theme.palette.common.white,
      fontFamily: 'Inter, arial, sans-serif',
    },
    executionInfoContainer: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: '4px',
      marginBottom: '10px',
      marginTop: '10px',
      overflow: 'auto',
      padding: '10px',
    },
  }),
);

export default useStyles;
