import React from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

interface CardsGridProps {
  cards: JSX.Element[];
  oneColumn?: boolean;
  twoColumns?: boolean;
  containerPadding?: boolean;
  xsSize?: number;
}

const CardsGrid: React.FC<CardsGridProps> = ({
  cards,
  oneColumn = false,
  twoColumns = false,
  containerPadding = true,
  xsSize,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      className={clsx({
        ['mb-9 px-8']: containerPadding,
      })}
    >
      <Grid container data-cy="cards-grid-container" spacing={2}>
        {cards.map((card, key) => (
          <Grid
            item
            data-cy="card-container"
            key={key}
            xs={xsSize ? xsSize : 12}
            sm={oneColumn ? 12 : 6}
            md={oneColumn ? 12 : 6}
            lg={oneColumn ? 12 : twoColumns ? 6 : 3}
            className={clsx({
              [classes.cardGrid]: !oneColumn && !twoColumns,
            })}
          >
            {card}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CardsGrid;
