import React from 'react';
import { Grid, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../../components/TextInput';

interface DbusSignalSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
  addNewFilters: () => void;
  removeFilter: (index: number) => void;
}

const DbusSignalSection: React.FC<DbusSignalSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
  addNewFilters,
  removeFilter,
}) => {
  return (
    <>
      {Array.isArray(newIngestor.listener.filters) &&
        newIngestor.listener.filters.map((item, index) => (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className="mt-2 ml-0"
            alignItems="center"
          >
            <Grid item xs={3}>
              <TextInput
                label="Path"
                placeholder=""
                prop={`filters.path.${index}`}
                value={item.path}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label="Interface"
                placeholder=""
                prop={`filters.interface.${index}`}
                value={item.interface}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label="Member"
                placeholder=""
                prop={`filters.member.${index}`}
                value={item.member}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className="mt-6">
              <IconButton onClick={(): void => removeFilter(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4 ml-4"
        onClick={addNewFilters}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </>
  );
};

export default DbusSignalSection;
