import React from 'react';
import { Grid } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../components/TextInput';

interface SingleAddressSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
}

const SingleAddressSection: React.FC<SingleAddressSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Address"
          placeholder=""
          prop="listener.address"
          value={newIngestor.listener.address}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default SingleAddressSection;
