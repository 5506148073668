import { Device } from '@edgeiq/edgeiq-api-js';

export function getHeartbeatSeconds(
  heartbeat_time: string,
  heartbeat_period_changed: number,
): number {
  let seconds = heartbeat_period_changed;
  switch (heartbeat_time) {
    case 'seconds':
      seconds = heartbeat_period_changed;
      break;
    case 'minutes':
      seconds = heartbeat_period_changed * 60;
      break;
    case 'hours':
      seconds = heartbeat_period_changed * 3600;
      break;
    case 'days':
      seconds = heartbeat_period_changed * 86400;
      break;
  }
  return seconds;
}

export function formatHeartbeat(device: Device): {
  heartbeat_period_changed: number;
  heartbeat_time: string;
} {
  let heartbeat_period_changed = device.heartbeat_period ?? 0;
  let heartbeat_time = 'seconds';

  if (device.heartbeat_period) {
    if (device.heartbeat_period % 86400 === 0) {
      heartbeat_time = 'days';
      heartbeat_period_changed = device.heartbeat_period / 86400;
    } else if (device.heartbeat_period % 3600 === 0) {
      heartbeat_time = 'hours';
      heartbeat_period_changed = device.heartbeat_period / 3600;
    } else if (device.heartbeat_period % 60 === 0) {
      heartbeat_time = 'minutes';
      heartbeat_period_changed = device.heartbeat_period / 60;
    } else {
      heartbeat_time = 'seconds';
      heartbeat_period_changed = device.heartbeat_period;
    }
  }

  return { heartbeat_period_changed, heartbeat_time };
}
