const copyTextToClipboard =
  (text: string, onCopy?: () => void) =>
  (event: React.MouseEvent<HTMLElement>): void => {
    navigator.clipboard.writeText(text);
    event.stopPropagation();
    if (onCopy) {
      onCopy();
    }
  };

export default copyTextToClipboard;
