import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setFilters } from '../../../redux/reducers/filters.reducer';
import Filters from '../../../containers/Filters';
import AccountFilter from '../../../containers/AccountSelect/AccountFilter';

interface DeviceErrorsMessagesFiltersProps {
  total: number;
}

const DeviceErrorsMessagesFilters: React.FC<
  DeviceErrorsMessagesFiltersProps
> = ({ total }) => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.deviceErrors.filters;

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'deviceErrors',
      ),
    );
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search by device"
      inputKey="device"
      filterModel="deviceErrors"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
    />
  );
};

export default DeviceErrorsMessagesFilters;
