import { Ingestor } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import {
  INGESTOR_HANDLER_TYPES_MAP,
  INGESTOR_LISTENER_TYPES_MAP,
} from '../../../constants/ingestors';

export const IngestorsColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const ingestor = item as Ingestor;
      return ingestor.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    link: (item: TableItemType): string => {
      const ingestor = item as Ingestor;
      return `ingestor/${ingestor._id}`;
    },
    type: 'link',
  },
  {
    cellValue: (item: TableItemType): string => {
      const ingestor = item as Ingestor;
      return INGESTOR_LISTENER_TYPES_MAP[ingestor.listener_type];
    },
    id: 'listener_type',
    label: 'Listener Type',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const ingestor = item as Ingestor;
      return INGESTOR_HANDLER_TYPES_MAP[ingestor.handler_type];
    },
    id: 'handler_type',
    label: 'Handler Type',
    type: 'text',
  },
];
