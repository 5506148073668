import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import {
  Command,
  CommandExecution,
  GatewayCommand,
} from '@edgeiq/edgeiq-api-js';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { useUserWithType } from '../../hooks/useUserWithType';
import {
  getCommandExecutionStatus,
  getExecutionStatusObject,
  sortExecutionStatuses,
} from '../../helpers/executionStatuses';
import { commandExecutionColorThemeMap } from '../../app/constants';
import { convertCommandStatus } from '../../helpers/utils';
import RecordExecutionCard from '../../components/RecordExecutionCard';
import TypographyWithCopy from '../../components/TypographyWithCopy';
import { getGatewayCommandName } from './helper';

interface CommandExecutionRecordProps {
  command?: GatewayCommand;
  commandExecution?: CommandExecution;
  isLast: boolean;
  showCommand?: boolean;
  showDevice?: boolean;
  showSoftware?: boolean;
  onCommandClick?: (command: GatewayCommand) => void;
  onExecutionClick?: (command: CommandExecution) => void;
}

// This component is used for all the execution records, no matter where they appear, of:
// gateway commands - commands - software updates
const CommandExecutionRecord: React.FC<CommandExecutionRecordProps> = ({
  command,
  commandExecution,
  isLast,
  showCommand = false,
  showDevice = false,
  showSoftware = false,
  onCommandClick,
  onExecutionClick,
}) => {
  const { user } = useUserWithType(
    command
      ? command.user_id
      : commandExecution
      ? commandExecution.user_id
      : '',
    false,
  );
  const commandsState = useAppSelector((state: RootState) => state.commands);
  const [executionStatus, setExecutionStatus] = useState('');
  const [executionMessage, setExecutionMessage] = useState('');
  const [userDefinedCommand, setUserDefinedCommand] = useState<Command>();

  useEffect(() => {
    if (command) {
      setExecutionStatus(getCommandExecutionStatus(command));
    }
    if (commandExecution) {
      const statuses = sortExecutionStatuses(commandExecution);
      const status = getExecutionStatusObject(statuses);
      setExecutionStatus(status?.status ?? '');
      setExecutionMessage(status?.message ?? '');
      setUserDefinedCommand(
        commandsState.commands.find(
          (c) => c._id === commandExecution.command_id,
        ),
      );
    }
  }, []);

  const handleClick = (): void => {
    if (command && onCommandClick) {
      onCommandClick(command);
    }
    if (commandExecution && onExecutionClick) {
      onExecutionClick(commandExecution);
    }
  };

  return (
    <RecordExecutionCard
      actionBtnText="View details"
      isLast={isLast}
      badgeColor={
        executionStatus
          ? commandExecutionColorThemeMap[convertCommandStatus(executionStatus)]
          : ''
      }
      badgeText={executionStatus}
      createdAt={command?.created_at ?? commandExecution?.created_at}
      executedBy={user ? `${user?.first_name} ${user?.last_name}` : ''}
      executionMessage={executionMessage}
      handleActionBtnClick={handleClick}
    >
      {command && showCommand && (
        <Tooltip title="Gateway Command Type" placement="top">
          <Typography variant="button">
            {getGatewayCommandName(command)}
          </Typography>
        </Tooltip>
      )}

      {command?.software_update_id && showSoftware && (
        <TypographyWithCopy
          dataCy={`software-update-${command.software_update_id}`}
          component="p"
          typographyVariant="button"
          textMaxWidth={200}
          containerClassName="mb-2"
          text={`Package: ${
            (command.payload?.name as string) || command.software_update_id
          }`}
          tooltipText={
            (command.payload?.name as string) || command.software_update_id
          }
          textToCopy={command.software_update_id}
          copyTooltipText="Click to copy Software Package ID"
        />
      )}

      {command?.device_id && showDevice && (
        <TypographyWithCopy
          dataCy={`device-${command.device_unique_id}`}
          component="p"
          typographyVariant="button"
          containerClassName="mb-2"
          text={`Device: ${command.device_unique_id}`}
          textToCopy={command.device_unique_id}
        />
      )}

      {userDefinedCommand && showCommand && (
        <TypographyWithCopy
          dataCy={`command-${userDefinedCommand._id}`}
          component="p"
          typographyVariant="button"
          textMaxWidth={200}
          containerClassName="mb-2"
          text={`Command: ${userDefinedCommand.name}`}
          tooltipText={userDefinedCommand.name}
          textToCopy={userDefinedCommand._id}
          copyTooltipText="Click to copy Command ID"
        />
      )}
    </RecordExecutionCard>
  );
};

export default CommandExecutionRecord;
