import React, { useEffect, useState } from 'react';
import { WorkflowExecution } from '@edgeiq/edgeiq-api-js';

import { useUserWithType } from '../../hooks/useUserWithType';
import { workflowExecutionColorThemeMap } from '../../app/constants';
import {
  getExecutionStatus,
  getExecutionStatusObject,
  sortExecutionStatuses,
} from '../../helpers/executionStatuses';
import RecordExecutionCard from '../../components/RecordExecutionCard';

interface WorkflowExecutionCardProps {
  isLast: boolean;
  workflowExecution: WorkflowExecution;
  actionCallback?: (action: WorkflowExecution | undefined) => void;
}

const WorkflowExecutionCard: React.FC<WorkflowExecutionCardProps> = ({
  isLast,
  workflowExecution,
  actionCallback,
}) => {
  const { user } = useUserWithType(workflowExecution.user_id);
  const [executionStatus, setExecutionStatus] = useState('');
  const [executionMessage, setExecutionMessage] = useState('');

  useEffect(() => {
    const statuses = sortExecutionStatuses(workflowExecution);
    const status = getExecutionStatusObject(statuses);
    setExecutionStatus(getExecutionStatus(statuses));
    setExecutionMessage(status?.message ?? '');
  }, []);

  const handleClick = (): void => {
    if (actionCallback) {
      actionCallback(workflowExecution);
    }
  };

  return (
    <RecordExecutionCard
      actionBtnText="View details"
      isLast={isLast}
      badgeColor={
        executionStatus ? workflowExecutionColorThemeMap[executionStatus] : ''
      }
      badgeText={executionStatus}
      createdAt={workflowExecution.created_at ?? workflowExecution.created_at}
      executedBy={user ? `${user?.first_name} ${user?.last_name}` : ''}
      executionMessage={executionMessage}
      handleActionBtnClick={handleClick}
    />
  );
};

export default WorkflowExecutionCard;
