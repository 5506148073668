import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import ObjectExplorer from './ObjectExplorer';
import ObjectData from './ObjectData';

export interface NestedObjectItem {
  collapsed: boolean;
  data: { [key: string]: unknown };
  items: NestedObjectItem[];
  index: string;
}

export interface NestedObjectInput {
  columns: 3 | 4 | 6 | 12; // How many columns in the UI the input will have
  defaultValue?: string | boolean | number; // The default value to use for added items.
  inputKey: string; // The key string of the input in the data object
  isEnableNestedItems?: boolean; // If this is true a specific Checkbox will appear that allows enabling all the nested items inside the item in question. Only if it has nested items
  isNameInput?: boolean; // If the input will be used as the name in the explorer tree
  label: string; // The label to show in the input
  required: boolean;
  type: 'string' | 'number' | 'boolean';
}

interface NestedObjectProps {
  explorerNameKey: string;
  items: NestedObjectItem[]; // The real items that can have the same type as an array of children (example: 'objects' in MIB form)
  inputs: NestedObjectInput[]; // The inputs or data strcutre of each object, NestedObjectItem should always have 'data' object with its data mapped with these inputs
  hasEnabledItems?: boolean; // If the nested items can be enabled or not, it should be with an enabled key in the data object of the items. Use it to show some UI indicator.
  onUpdatingNestedItems: (items: NestedObjectItem[]) => void;
}

const NestedObject: React.FC<NestedObjectProps> = ({
  explorerNameKey,
  hasEnabledItems,
  inputs,
  items,
  onUpdatingNestedItems,
}) => {
  const [nestedItems, setNestedItems] = useState<NestedObjectItem[]>([]);
  const [actualItem, setActualItem] = useState<NestedObjectItem>();

  useEffect(() => {
    if (nestedItems.length === 0 && items.length !== 0) {
      setNestedItems(items);
      // Setting the first item of the array to show, this is to avoid a UI weird behaviour.
      // When the user hovers over the the name of an item in the explorer tree for the first time a weird behaviour happens if no item is selected.
      // So it can be avoided by having the first item selected by default.
      setActualItem(items[0]);
    }
  }, [items]);

  useEffect(() => {
    if (nestedItems.length !== 0) {
      onUpdatingNestedItems(nestedItems);
    }
  }, [nestedItems]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <ObjectExplorer
          chosenItem={actualItem}
          explorerNameKey={explorerNameKey}
          hasEnabledItems={hasEnabledItems}
          inputs={inputs}
          nestedItems={nestedItems}
          updateItems={setNestedItems}
          setActualItem={setActualItem}
        />
      </Grid>
      <Grid item xs={8}>
        <ObjectData
          chosenItem={actualItem}
          explorerNameKey={explorerNameKey}
          inputs={inputs}
          nestedItems={nestedItems}
          updateItems={setNestedItems}
          setActualItem={setActualItem}
        />
      </Grid>
    </Grid>
  );
};

export default NestedObject;
