import { Configuration, DeviceType } from '@edgeiq/edgeiq-api-js';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import TypographyWithCopy from '../../components/TypographyWithCopy';

import useStyles from './styles';

interface ConfigurationCardProps {
  configuration: Configuration;
  deviceTypes?: DeviceType[];
}

const ConfigurationCard: React.FC<ConfigurationCardProps> = ({
  configuration,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.cardContainer)}>
      <Typography
        variant="h6"
        component="div"
        noWrap
        className={clsx('mb-4', classes.name)}
      >
        {configuration.name}
      </Typography>
      <TypographyWithCopy
        dataCy={`copy-configuration-id-${configuration._id}`}
        containerClassName="mb-4"
        textClassName={classes.subtitle}
        component="div"
        text={`Id: ${configuration._id}`}
        textToCopy={configuration._id}
        typographyVariant="button"
      />
      <TypographyWithCopy
        dataCy={`copy-configuration-unique-id-${configuration.unique_identifier}`}
        containerClassName="mb-4"
        textClassName={classes.subtitle}
        component="div"
        text={`Unique Id: ${configuration.unique_identifier}`}
        textToCopy={configuration.unique_identifier}
        typographyVariant="button"
      />
    </Box>
  );
};

export default ConfigurationCard;
