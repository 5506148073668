type StringAvatarColor = {
  bgcolor: string;
};

type StringAvatar = {
  sx: StringAvatarColor;
  children: string;
};

const stringToColor = (item: string): string => {
  let hash = 0;
  let i;

  for (i = 0; i < item.length; i += 1) {
    hash = item.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

const stringAvatar = (name: string): StringAvatar => {
  const avatarSecondParam = name.split(' ');
  const avatarInitials = avatarSecondParam[1]
    ? `${avatarSecondParam[0]?.[0] || ''}${avatarSecondParam[1]?.[0] || ''}`
    : `${avatarSecondParam[0]?.[0] || ''}${avatarSecondParam[0]?.[1] || ''}`;
  return {
    children: (avatarInitials || '').toUpperCase(),
    sx: {
      bgcolor: stringToColor(name),
    },
  };
};

export { stringToColor, stringAvatar };
