import React from 'react';
import { Grid } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../../components/TextInput';

interface SendSmsSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const SendSmsSection: React.FC<SendSmsSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
}) => {
  return (
    <Grid item xs={12} className="mt-2">
      <TextInput
        label="Receipient phone number, format: '12223334444'"
        placeholder=""
        prop={`${actionIndex}.send_to`}
        value={action.send_to}
        onInputChange={onInputChange}
        disabled={disabled}
      />
    </Grid>
  );
};

export default SendSmsSection;
