import { SettingsApplicationRecord } from '@edgeiq/edgeiq-api-js';

export const SettingsApplicationRecordTypes = {
  SET_SELECTED_SETTINGS_APPLICATION_RECORD:
    'SET_SELECTED_SETTINGS_APPLICATION_RECORD',
  SET_SETTINGS_APPLICATION_RECORD: 'SET_SETTINGS_APPLICATION_RECORD',
};

export const setSettingsApplicationRecord = (
  settingApplication: SettingsApplicationRecord[],
): SettingsApplicationRecordAction => ({
  data: settingApplication,
  type: SettingsApplicationRecordTypes.SET_SETTINGS_APPLICATION_RECORD,
});

export const setSelectedSettingsApplicationRecord = (
  settingApplication: SettingsApplicationRecord[],
): SettingsApplicationRecordAction => ({
  data: settingApplication,
  type: SettingsApplicationRecordTypes.SET_SELECTED_SETTINGS_APPLICATION_RECORD,
});

export type SettingsApplicationRecordState = {
  settingsApplicationRecord: SettingsApplicationRecord[];
  selectedSettingsApplicationRecord: SettingsApplicationRecord[];
};

// INITIAL STATE
const settingsApplicationRecordState: SettingsApplicationRecordState = {
  selectedSettingsApplicationRecord: [],
  settingsApplicationRecord: [],
};

export type SettingsApplicationRecordAction = {
  data: SettingsApplicationRecord[] | SettingsApplicationRecord;
  type: string;
};

const settingsApplicationRecordReducer = (
  state = settingsApplicationRecordState,
  action: SettingsApplicationRecordAction,
): SettingsApplicationRecordState => {
  switch (action.type) {
    case SettingsApplicationRecordTypes.SET_SETTINGS_APPLICATION_RECORD:
      return {
        ...state,
        settingsApplicationRecord: action.data as SettingsApplicationRecord[],
      };
    case SettingsApplicationRecordTypes.SET_SELECTED_SETTINGS_APPLICATION_RECORD:
      return {
        ...state,
        selectedSettingsApplicationRecord:
          action.data as SettingsApplicationRecord[],
      };
    default:
      return state;
  }
};

export default settingsApplicationRecordReducer;
