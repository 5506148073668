import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { checkListenerTypeDisabled } from '../../../containers/Forms/IngestorForm/helper';
import SelectInput from '../../../components/SelectInput';
import ListenerTypeDynamicSection from '../../../containers/Forms/IngestorForm/ListenerTypeDynamicSection/ListenerTypeDynamicSection';
import { objectKeys } from '../../../helpers/utils';
import { INGESTOR_LISTENER_TYPES_MAP } from '../../../constants/ingestors';

interface NewIngestorListenerConfigProps {
  newIngestor: IngestorInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  setEnableSubmit?: (args: boolean) => void;
  addNewFilters: () => void;
  removeFilter: (index: number) => void;
  addDeviceTypeMapping: () => void;
  removeDeviceTypeMapping: (index: number) => void;
}

const NewIngestorListenerConfig: React.FC<NewIngestorListenerConfigProps> = ({
  newIngestor,
  onInputChange,
  addNewFilters,
  removeFilter,
  addDeviceTypeMapping,
  removeDeviceTypeMapping,
}) => {
  const compatibleIngestorListeners = objectKeys<Record<string, string>>(
    INGESTOR_LISTENER_TYPES_MAP,
  ).filter((key) => !checkListenerTypeDisabled(newIngestor.type, key));

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="caption" className="pb-4">
          Only compatible options with the ingestor type are shown.
        </Typography>
        <SelectInput
          prop="listener_type"
          value={newIngestor.listener_type}
          onSelectChange={onInputChange}
          options={compatibleIngestorListeners.map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {INGESTOR_LISTENER_TYPES_MAP[key]}
            </MenuItem>
          ))}
        />
      </Grid>
      <ListenerTypeDynamicSection
        newIngestor={newIngestor}
        onInputChange={onInputChange}
        addNewFilters={addNewFilters}
        removeFilter={removeFilter}
        addDeviceTypeMapping={addDeviceTypeMapping}
        removeDeviceTypeMapping={removeDeviceTypeMapping}
      />
    </Grid>
  );
};

export default NewIngestorListenerConfig;
