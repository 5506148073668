import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
} from '@mui/material';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DeviceErrors } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { errorHighlight } from '../../app/constants';
import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import timeHelpers from '../../helpers/timeHelpers';

interface IMetricsData {
  datetime: string;
  count: number;
}

interface IDeviceErrorsChartData {
  datetime: string;
  type: string;
  level: number;
  error: string;
  device: string;
}

const DeviceErrorsChart: React.FC = () => {
  const [deviceErrorsData, setDeviceErrorsData] = useState<
    IDeviceErrorsChartData[]
  >([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lineChartData, setLineChartData] = useState<IMetricsData[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    DeviceErrors.list({}, { itemsPerPage: 10, page: 1 })
      .then((res) => {
        const lineChartDataMapping: IMetricsData[] = [];
        const mappedData = res.resources.map((d) => {
          const data: IDeviceErrorsChartData = {
            datetime: d.created_at,
            device: d.device_id,
            error: d.error,
            level: d.level,
            type: d.type,
          };

          const date = timeHelpers.getPlainDate(d.created_at);
          const index = lineChartDataMapping.findIndex(
            (p) => p.datetime === date,
          );
          if (index < 0) {
            lineChartDataMapping.push({
              count: 1,
              datetime: date,
            });
          } else {
            lineChartDataMapping[index].count += 1;
          }

          return data;
        });
        setLineChartData(lineChartDataMapping);
        setDeviceErrorsData(mappedData);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoDeviceErrors = (): void => {
    navigate(`/messages#device-errors`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column overflow-hidden">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Device Errors
        <Button
          className="p-0"
          color="primary"
          variant="text"
          type="button"
          onClick={handleGoDeviceErrors}
        >
          <Typography variant="button">View More</Typography>
        </Button>
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent={isMobile ? 'center' : 'space-around'}
      >
        <Grid item sm={12} justifyContent={'center'}>
          <div
            style={{
              height: 300,
              width: '100%',
            }}
          >
            <ResponsiveContainer>
              <LineChart data={lineChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="datetime" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Line type="monotone" dataKey="count" stroke="#40A0DB" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item sm={12} style={{ overflowX: 'auto' }}>
          <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date/Time</TableCell>
                  <TableCell align="center">Device</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Level</TableCell>
                  <TableCell align="left">Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceErrorsData.map((row) => (
                  <TableRow
                    key={row.type}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align="left">
                      {row.datetime && (
                        <>{timeHelpers.getDate(row.datetime, false, false)}</>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.device}</TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {row.type}
                    </TableCell>
                    <TableCell align="center">{row.level}</TableCell>
                    <TableCell align="left">{row.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeviceErrorsChart;
