import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  PollableAttribute,
  PollableAttributeInput,
} from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';

import { pollableTypes } from './constants';

interface PollableFormProps {
  labelRequiredField?: boolean;
  pollableAttribute?: PollableAttribute;
  pollableAttributeInput?: PollableAttributeInput;
  onInputChange: (prop: string, value: string | number) => void;
}

const PollableForm: React.FC<PollableFormProps> = ({
  labelRequiredField,
  pollableAttribute,
  pollableAttributeInput,
  onInputChange,
}) => {
  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6} className="mt-6 ">
          <TextInput
            prop="name"
            label="Name"
            placeholder="Name"
            type="text"
            required={true}
            value={
              pollableAttribute?.name || pollableAttributeInput?.name || ''
            }
            onInputChange={onInputChange}
          />
        </Grid>
        {labelRequiredField && (
          <Grid item xs={6} className="mt-6 ">
            <SelectInput
              label="Type"
              prop="sender_type"
              value={
                pollableAttribute?.type ||
                pollableAttributeInput?.type ||
                'snmp_mib'
              }
              onSelectChange={onInputChange}
              options={pollableTypes.map((type) => (
                <MenuItem dense key={type.key} value={type.key}>
                  {type.label}
                </MenuItem>
              ))}
            />
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6} className="mt-6">
          <TextInput
            prop="interval"
            label="Interval"
            placeholder="Interval"
            type="text"
            value={
              pollableAttribute?.interval ||
              pollableAttributeInput?.interval ||
              ''
            }
            onInputChange={onInputChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PollableForm;
