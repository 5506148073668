import React from 'react';
import { Grid } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../components/TextInput';

interface CloudPollingSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
}

const CloudPollingSection: React.FC<CloudPollingSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Access Id"
          placeholder=""
          prop="listener.access_id"
          required={true}
          value={newIngestor.listener.access_id}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Password"
          placeholder=""
          prop="listener.password"
          required={true}
          value={newIngestor.listener.password}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Minimum Timeout (seconds)"
          prop="listener.min_timeout_seconds"
          placeholder=""
          type="number"
          required={true}
          value={newIngestor.listener.min_timeout_seconds}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default CloudPollingSection;
