import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
