import { Company, Workflow } from '@edgeiq/edgeiq-api-js';
import {
  workflowStatusLabelMap,
  workflowStatusThemeMap,
} from '../../app/constants';
import ColoredBox from '../../components/ColoredBox';
import { TableColumn, TableItemType } from '../../components/SharedTable';

export const WorkflowColumns = (userCompanies?: Company[]): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const workflow = item as Workflow;
        return workflow.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const workflow = item as Workflow;
        return `/workflow/${workflow._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const workflow = item as Workflow;
        const company = userCompanies?.find(
          (userCompany) => userCompany._id === workflow.company_id,
        );
        return company?.name ?? workflow.company_id;
      },
      id: 'company',
      label: 'Account',
      type: 'text',
    },
    {
      id: 'current_status',
      label: 'Status',
      parser: (item): React.ReactElement => {
        const workflow = item as Workflow;
        return (
          <div>
            <ColoredBox
              type="current_status"
              value={
                workflowStatusLabelMap[workflow.current_status] ?? 'Active'
              }
              colorTheme={
                workflowStatusThemeMap[workflow.current_status] ?? 'info'
              }
            />
          </div>
        );
      },
      type: 'custom',
    },
  ];
};
