import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setFilters } from '../../../redux/reducers/filters.reducer';
import Filters from '../../../containers/Filters';
import AccountFilter from '../../../containers/AccountSelect/AccountFilter';

interface CommandsMessagesFiltersProps {
  total: number;
}

const CommandsMessagesFilters: React.FC<CommandsMessagesFiltersProps> = ({
  total,
}) => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.commandsExecutions.filters;

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'commandsExecutions',
      ),
    );
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search commands by name"
      inputKey="name"
      filterModel="commandsExecutions"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
    />
  );
};

export default CommandsMessagesFilters;
