import React, { ChangeEvent } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

export interface SwitchButtonProps {
  label: string;
  value?: boolean;
  prop: string;
  disabled?: boolean;
  addMargin?: boolean;
  onSwitchChange: (prop: string, value: boolean) => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({
  label,
  value,
  prop,
  disabled,
  addMargin = true,
  onSwitchChange,
}) => {
  const classes = useStyles();

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onSwitchChange(prop, event.target.checked);
  };

  return (
    <FormControlLabel
      disableTypography
      data-cy={prop}
      className={clsx(classes.label, {
        ['mt-3']: addMargin,
      })}
      label={label}
      control={
        <Switch
          data-testid="switch"
          className="mr-3"
          checked={value}
          onChange={handleSwitchChange}
          disabled={disabled}
        />
      }
    />
  );
};

export default SwitchButton;
