import { Company, CompanyInput, User } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getCompaniesSelector = createSelector(
  (state: CompaniesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.companies.length) {
      return state.company;
    }
    return state.companies.find((company: Company) => company._id === id);
  },
);

export const Companies = {
  SET_ACTUAL_COMPANY: 'SET_ACTUAL_COMPANY',
  SET_COMPANIES: 'SET_COMPANIES',
  SET_COMPANY_INPUT: 'SET_COMPANY_INPUT',
  SET_COMPANY_USERS: 'SET_COMPANY_USERS',
  SET_NEW_COMPANY: 'SET_NEW_COMPANY',
};

export const setStateCompanies = (companies: Company[]): CompaniesAction => ({
  data: companies,
  type: Companies.SET_COMPANIES,
});

export const setActualCompany = (company: Company): CompaniesAction => ({
  data: company,
  type: Companies.SET_ACTUAL_COMPANY,
});

export const setNewCompany = (company: Company): CompaniesAction => ({
  data: company,
  type: Companies.SET_NEW_COMPANY,
});

export const setCompanyInput = (company: CompanyInput): CompaniesAction => ({
  data: company,
  type: Companies.SET_COMPANY_INPUT,
});

export const setCompanyUsers = (users: User[]): CompaniesAction => ({
  data: users,
  type: Companies.SET_COMPANY_USERS,
});

export type CompaniesState = {
  companies: Company[];
  company: Company | null;
  companyInput: CompanyInput | null;
  newCompany: Company | null;
  users: User[];
};

const companiesInitialState: CompaniesState = {
  companies: [],
  company: null,
  companyInput: null,
  newCompany: null,
  users: [],
};

export type CompaniesAction = {
  data: string | Company[] | Company | CompanyInput | User[] | null;
  type: string;
};

const companiesReducer = (
  state = companiesInitialState,
  action: CompaniesAction,
): CompaniesState => {
  switch (action.type) {
    case Companies.SET_COMPANIES:
      return {
        ...state,
        companies: action.data as Company[],
      };
    case Companies.SET_NEW_COMPANY:
      return {
        ...state,
        newCompany: action.data as Company,
      };
    case Companies.SET_ACTUAL_COMPANY:
      return {
        ...state,
        company: action.data as Company,
        newCompany: action.data as Company,
      };
    case Companies.SET_COMPANY_USERS:
      return {
        ...state,
        users: action.data as User[],
      };
    case Companies.SET_COMPANY_INPUT:
      return {
        ...state,
        companyInput: action.data as CompanyInput,
      };
    default:
      return state;
  }
};

export default companiesReducer;
