import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, IconButton, Tooltip } from '@mui/material';
import {
  ContentCopy as CopyIcon,
  CompareArrows as CompareIcon,
  Delete as DeleteIcon,
  LinkOff as LinkOffIcon,
  PlayArrow as PlayIcon,
} from '@mui/icons-material';
import {
  Command,
  Configuration,
  Device,
  Devices,
  DeviceType,
  Setting,
  SettingInput,
  Settings,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../redux/hooks';
import { setNewSettingInput } from '../../redux/reducers/settings.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import timeHelpers from '../../helpers/timeHelpers';
import { dispatchError, getFetchError } from '../../helpers/utils';
import SharedTable, {
  TableColumn,
  TableItemType,
  TableSubItemsType,
} from '../../components/SharedTable';
import TypographyWithCopy from '../../components/TypographyWithCopy';
import ActionDialog from '../../components/ActionDialog';
import { ApplySettingsDrawer } from '../RightDrawer';

interface SettingsListProps {
  attachedSettings: boolean;
  configuration?: Configuration | null;
  device?: Device | null;
  loading: boolean;
  settings: Setting[];
  unlinkableSettings: boolean;
  deviceTypes?: DeviceType[];
  sendCommands?: Command[];
  settingsToCompare?: Setting[]; // Can only be 2 settings at a time.
  showCompareButton?: boolean;
  onChoosingSetting?: (setting: Setting) => void;
  reloadSettings: () => void;
}

const SettingsList: React.FC<SettingsListProps> = ({
  attachedSettings,
  configuration,
  device,
  loading,
  settings,
  unlinkableSettings,
  deviceTypes,
  sendCommands,
  settingsToCompare,
  showCompareButton,
  onChoosingSetting,
  reloadSettings,
}) => {
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const navigate = useNavigate();
  const [deleteSettingDialog, setDeleteSettingDialog] = useState(false);
  const [settingToDelete, setSettingToDelete] = useState<Setting>();
  const [openApplySettingDrawer, setOpenApplySettingDrawer] = useState(false);
  const [settingToApply, setSettingToApply] = useState<Setting>();
  const [latestSettings, setLatestSettings] = useState<Setting[]>([]);
  const [subSettingsRows, setSettingsRows] = useState<TableSubItemsType>({});

  useEffect(() => {
    const idsVersionsMap: { [key: string]: number } = {};
    for (let i = 0; i < settings.length; i++) {
      if (
        !idsVersionsMap[settings[i]._id] ||
        (idsVersionsMap[settings[i]._id] &&
          idsVersionsMap[settings[i]._id] < settings[i].version)
      ) {
        idsVersionsMap[settings[i]._id] = settings[i].version;
      }
    }
    const primarySettings: Setting[] = [];
    const subSettings: TableSubItemsType = {};
    for (let i = 0; i < settings.length; i++) {
      const item = settings[i];
      if (
        idsVersionsMap[item._id] &&
        idsVersionsMap[item._id] === item.version
      ) {
        primarySettings.push(item);
      } else if (!subSettings[item._id]) {
        subSettings[item._id] = [item];
      } else {
        subSettings[item._id].push(item);
      }
    }
    setLatestSettings(primarySettings);
    setSettingsRows(subSettings);
  }, [settings]);

  const handleGoToSettings =
    (
      configId: string,
      setting?: Setting,
      copyDevices?: Device[],
      copyDeviceTypes?: DeviceType[],
    ) =>
    (): void => {
      navigate(
        `/configuration/${configId}/${
          setting
            ? `settings/${setting._id}#${setting.version}`
            : 'new-settings'
        }`,
        {
          state: {
            copyDevices,
            copyDeviceTypes,
            goBackLabel: device
              ? `Device: ${device?.name}`
              : `Configuration: ${configuration?.name}`,
            goBackUrl: device
              ? `device/${device?._id}#settings`
              : `configuration/${configuration?._id}#settings`,
          },
        },
      );
    };

  const handleCopySetting = (setting: Setting) => (): void => {
    dispatch(
      setNewSettingInput({
        company_id: setting.company_id,
        configuration_id: setting.configuration_id,
        description: setting.description,
        name: `${setting.name} - copy`,
        values: setting.values,
      } as SettingInput),
    );
    let devices: Device[] = [];
    Settings.getDevices(setting._id)
      .then((response) => {
        devices = response;
        return Settings.getDeviceTypes(setting._id);
      })
      .then((response) => {
        handleGoToSettings(
          setting.configuration_id,
          undefined,
          devices,
          response,
        )();
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          getFetchError('settings data to copy it'),
        );
      });
  };

  const handleApplySetting = (setting: Setting) => (): void => {
    setSettingToApply(setting);
  };

  const handleCloseApplySettings = (): void => {
    setSettingToApply(undefined);
  };

  useEffect(() => {
    setOpenApplySettingDrawer(typeof settingToApply !== 'undefined');
  }, [settingToApply]);

  const deleteSettings = (): void => {
    if (settingToDelete) {
      Settings.delete(settingToDelete._id)
        .then((_res) => {
          dispatch(
            setAlert({
              message: `Setting successfully deleted.`,
              type: 'success',
            }),
          );
          reloadSettings();
          handleCloseDeleteDialog();
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('settings.'),
          );
        });
    }
  };

  const handleCloseDeleteDialog = (): void => {
    setSettingToDelete(undefined);
    setDeleteSettingDialog(false);
  };

  const handleDeleteSetting = (setting: Setting) => (): void => {
    setDeleteSettingDialog(true);
    setSettingToDelete(setting);
  };

  const handleUnlinkSetting = (setting: Setting) => (): void => {
    if (device) {
      Devices.detachSettings(device?._id, setting._id)
        .then((_res) => {
          dispatch(
            setAlert({
              message: `Setting detached from device.`,
              type: 'success',
            }),
          );
          reloadSettings();
          handleCloseDeleteDialog();
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('settings.'),
          );
        });
    }
  };

  const handleCompareSetting = (setting: Setting) => (): void => {
    if (onChoosingSetting) {
      onChoosingSetting(setting);
    }
  };

  const isItHighlighted = (setting: Setting): boolean => {
    if (settingsToCompare) {
      return !!settingsToCompare.find(
        (s) => s._id === setting._id && s.version === setting.version,
      );
    }
    return false;
  };

  const columns: TableColumn[] = [
    {
      id: 'name',
      label: 'Name',
      parser: (item: TableItemType): string | React.ReactElement => {
        const setting = item as Setting;
        return (
          <Link
            underline="none"
            style={{ cursor: 'pointer' }}
            onClick={handleGoToSettings(setting.configuration_id, setting)}
          >
            <strong>{`${setting.name} - v${setting.version}`}</strong>
          </Link>
        );
      },
      type: 'custom',
    },
    {
      id: '_id',
      label: 'ID',
      parser: (item): string | React.ReactElement => {
        const setting = item as Setting;
        return (
          <TypographyWithCopy
            dataCy={`copy-setting-id-${setting._id}`}
            text={setting._id}
            textToCopy={setting._id}
            tooltipText={setting._id}
            typographyVariant="button"
            textMaxWidth={100}
          />
        );
      },
      type: 'custom',
    },
    {
      cellValue: (item: TableItemType): string => {
        const setting = item as Setting;
        return `${timeHelpers.getDate(setting.updated_at)}`;
      },
      id: 'updated_at',
      label: 'Last Updated',
      type: 'text',
    },
    {
      id: 'actions',
      label: 'Actions',
      parser: (item): string | React.ReactElement => {
        const setting = item as Setting;
        return (
          <div className="d-flex">
            <Tooltip title="Copy settings" placement="top-end">
              <IconButton
                className="mr-2"
                data-cy={`copy-settings-${setting._id}-${setting.version}`}
                size="small"
                aria-label="copy-setting"
                onClick={handleCopySetting(setting)}
              >
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Apply settings" placement="top-end">
              <IconButton
                className="mr-2"
                data-cy={`apply-settings-${setting._id}-${setting.version}`}
                size="small"
                aria-label="apply-setting"
                onClick={handleApplySetting(setting)}
              >
                <PlayIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!attachedSettings && (
              <Tooltip title="Delete settings" placement="top-end">
                <IconButton
                  data-cy={`delete-settings-${setting._id}-${setting.version}`}
                  size="small"
                  aria-label="delete-setting"
                  onClick={handleDeleteSetting(setting)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {attachedSettings && unlinkableSettings && (
              <Tooltip title="Unlink settings" placement="top-end">
                <IconButton
                  data-cy={`unlink-settings-${setting._id}-${setting.version}`}
                  size="small"
                  aria-label="unlink-setting"
                  onClick={handleUnlinkSetting(setting)}
                >
                  <LinkOffIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {device && showCompareButton && (
              <Tooltip title="Compare Setting" placement="top-end">
                <IconButton
                  data-cy={`compare-settings-${setting._id}-${setting.version}`}
                  size="small"
                  aria-label="compare-setting"
                  disabled={
                    settingsToCompare?.length === 2 && !isItHighlighted(setting)
                  }
                  onClick={handleCompareSetting(setting)}
                >
                  <CompareIcon
                    fontSize="small"
                    color={isItHighlighted(setting) ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
      type: 'custom',
    },
  ];

  const hasOtherVersions = (item: TableItemType): boolean => {
    const setting = item as Setting;
    const idsWithVersions = Object.keys(subSettingsRows);
    return idsWithVersions.includes(setting._id);
  };

  return (
    <>
      <SharedTable
        columns={columns}
        rows={latestSettings}
        sortBy="updated-at"
        sortDirection="desc"
        allSelected={false}
        loading={loading}
        selectedItemsIds={[]}
        viewOnly={true}
        xPadding={false}
        shadow={false}
        hasActionColumn={true}
        addSubrowsCheckbox={false}
        tablePadding={false}
        subRows={subSettingsRows}
        hasNestedTable={hasOtherVersions}
        subKeyToAdd="version"
      />

      <ActionDialog
        open={deleteSettingDialog}
        loading={loading}
        onDeleteCallback={deleteSettings}
        onCloseCallback={handleCloseDeleteDialog}
        content={`You are about to delete the setting: ${settingToDelete?.name}`}
        actionButtonLabel="Delete"
      />

      {settingToApply && configuration && (
        <ApplySettingsDrawer
          open={openApplySettingDrawer}
          isBulk={false}
          configuration={configuration}
          commands={sendCommands ?? []}
          devices={device ? [device] : []}
          deviceTypes={deviceTypes}
          setting={settingToApply}
          handleCloseDrawer={handleCloseApplySettings}
        />
      )}
    </>
  );
};

export default SettingsList;
