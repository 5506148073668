import React, { useState, ReactElement, useEffect } from 'react';
import { Select, MenuItem, SelectChangeEvent, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setFilters } from '../../../redux/reducers/filters.reducer';
import { transferStatus, transferStatusLabel } from '../../../app/constants';
import Filters from '../../../containers/Filters';
import AccountFilter from '../../../containers/AccountSelect/AccountFilter';
import useStyles from '../styles';

interface EscrowDevicesFiltersProps {
  total: number;
}

const EscrowDevicesFilters: React.FC<EscrowDevicesFiltersProps> = ({
  total,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const escrowFilters = stateFilters.escrowDevices.filters;

  const [transferStatusSelected, setTransferStatusSelected] =
    useState<string>('');

  useEffect(() => {
    if (escrowFilters) {
      setTransferStatusSelected(escrowFilters.transfer_status);
    }
  }, [escrowFilters]);

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...escrowFilters,
          [prop]: value,
        },
        'escrowDevices',
      ),
    );
  };

  const handleTransferStatusChange = (
    event: SelectChangeEvent<typeof transferStatusSelected>,
  ): void => {
    changeFilters('transfer_status', event.target.value);
  };

  return (
    <Filters
      filterModel="escrowDevices"
      total={total}
      hasSearchInput={true}
      inputPlaceholder="Search escrow devices by Unique ID"
      inputKey="unique_id"
      firstFilter={
        <AccountFilter
          accountsSelected={escrowFilters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          className={classes.filterInput}
          value={transferStatusSelected}
          onChange={handleTransferStatusChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string | ReactElement => {
            if (selected?.length === 0) {
              return <Typography variant="button">Transfer Status</Typography>;
            }

            return (
              <Typography variant="button">
                {transferStatusLabel[selected]}
              </Typography>
            );
          }}
        >
          {transferStatus.map((item, key) => (
            <MenuItem dense key={key} value={item}>
              {transferStatusLabel[item]}
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default EscrowDevicesFilters;
