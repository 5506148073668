// Library documentation https://reactjsexample.com/react-editable-json-tree/
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  JsonTree,
  // ADD_DELTA_TYPE,
  // REMOVE_DELTA_TYPE,
  // UPDATE_DELTA_TYPE,
  // DATA_TYPES,
  // INPUT_USAGE_TYPES,
} from 'react-editable-json-tree';

interface JsonTreeViewProps {
  data: Record<string, unknown>;
  onUpdateData: (data: Record<string, unknown>) => void;
  disabled?: boolean;
}

const JsonTreeView: React.FC<JsonTreeViewProps> = ({
  data,
  onUpdateData,
  disabled = false,
}) => {
  const renderIconButton = (title: string, icon: JSX.Element): JSX.Element => {
    if (disabled) {
      return <></>;
    }
    return (
      <Tooltip title={title} placement="top-end">
        <IconButton size="small">{icon}</IconButton>
      </Tooltip>
    );
  };

  return (
    <JsonTree
      rootName=""
      data={data}
      onFullyUpdate={onUpdateData}
      addButtonElement={renderIconButton('Save changes', <CheckIcon />)}
      cancelButtonElement={renderIconButton('Cancel', <CloseIcon />)}
      editButtonElement={renderIconButton(
        'Click here or click Enter to save changes',
        <CheckIcon />,
      )}
      minusMenuElement={renderIconButton(
        'Remove Key/Value pair',
        <RemoveIcon />,
      )}
      plusMenuElement={renderIconButton('Add Key/Value pair', <AddIcon />)}
      inputElement={(
        _usage: string,
        _keyPath: unknown,
        _deep: number,
        _keyName: string,
        _value: unknown,
        _dataType: string,
      ): JSX.Element => {
        // console.info('USAGE: ', usage);
        // console.info('KEY PATH: ', keyPath);
        // console.info('DEEP: ', deep);
        // console.info('KEY NAME: ', keyName);
        // console.info('VALUE: ', value);
        // console.info('DATA TYPE: ', dataType);
        return <input />;
      }}
    />
  );
};

export default JsonTreeView;
