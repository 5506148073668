import {
  Device,
  EscrowDevice,
  DiscoveredDevice,
  DeviceInput,
  EscrowDeviceInput,
} from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';
import { GENRE_ACTIVE } from '../../app/constants';
import { LwM2MSecretData } from '../../models/lwm2m_secret_data';

const emptyDevice: DeviceInput = {
  company_id: '',
  device_type_id: '',
  // change this to change the default later on
  enforce_secure_mqtt: false,
  mqtt_password: '',
  name: '',
  serial: '',
  unique_id: '',
};

const emptyEscrowDevice: EscrowDeviceInput = {
  company_id: '',
  lwm2m: false,
  token: '',
  unique_id: '',
};

export const getDeviceSelector = createSelector(
  (state: DevicesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.devices.length) {
      return state.device;
    }
    return state.devices.find((device: Device) => device._id === id);
  },
);

export const getDiscoveredDeviceSelector = createSelector(
  (state: DevicesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.discoveredDevices) {
      return state.discoveredDevice;
    }
    return state.discoveredDevices.find(
      (device: DiscoveredDevice) => device._id === id,
    );
  },
);

export const getEscrowDeviceSelector = createSelector(
  (state: DevicesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.escrowDevices) {
      return state.escrowDevice;
    }
    return state.escrowDevices.find(
      (device: EscrowDevice) => device._id === id,
    );
  },
);

export const DevicesTypes = {
  CLEAN_NEW_DEVICE_INPUT: 'CLEAN_NEW_DEVICE_INPUT',
  CLEAN_NEW_ESCROW_DEVICE_INPUT: 'CLEAN_NEW_ESCROW_DEVICE_INPUT',
  SET_ACTUAL_DEVICE: 'SET_ACTUAL_DEVICE',
  SET_ACTUAL_DISCOVERED_DEVICE: 'SET_ACTUAL_DISCOVERED_DEVICE',
  SET_DEVICE_INPUT: 'SET_DEVICE_INPUT',
  SET_DEVICES: 'SET_DEVICES',
  SET_DEVICES_GENRE: 'SET_DEVICES_GENRE',
  SET_DEVICES_OPTIONS: 'SET_DEVICES_OPTIONS',
  SET_DISCOVERED_DEVICES: 'SET_DISCOVERED_DEVICES',
  SET_ESCROW_DEVICE_INPUT: 'SET_ESCROW_DEVICE_INPUT',
  SET_ESCROW_DEVICES: 'SET_ESCROW_DEVICES',
  SET_NEW_DEVICE: 'SET_NEW_DEVICE',
  SET_NEW_DISCOVERED_DEVICE: 'SET_NEW_DISCOVERED_DEVICE',
  SET_SELECTED_DEVICE_SECRET_DATA: 'SET_SELECTED_DEVICE_SECRET_DATA',
  SET_SELECTED_ESCROW_DEVICE: 'SET_SELECTED_ESCROW_DEVICE',
  SET_SELECTED_ESCROW_DEVICE_SECRET_DATA:
    'SET_SELECTED_ESCROW_DEVICE_SECRET_DATA',
};

export const setDevicesGenre = (genre: string): DevicesAction => ({
  data: genre,
  type: DevicesTypes.SET_DEVICES_GENRE,
});

export const setStateDevices = (devices: Device[]): DevicesAction => ({
  data: devices,
  type: DevicesTypes.SET_DEVICES,
});

export const setStateDevicesOptions = (devices: Device[]): DevicesAction => ({
  data: devices,
  type: DevicesTypes.SET_DEVICES_OPTIONS,
});

export const setActualDevice = (device: Device): DevicesAction => ({
  data: device,
  type: DevicesTypes.SET_ACTUAL_DEVICE,
});

export const setSelectedDeviceSecretData = (
  secretData: LwM2MSecretData,
): DevicesAction => ({
  data: secretData,
  type: DevicesTypes.SET_SELECTED_DEVICE_SECRET_DATA,
});

export const setStateEscrowDevices = (
  escrowDevices: EscrowDevice[],
): DevicesAction => ({
  data: escrowDevices,
  type: DevicesTypes.SET_ESCROW_DEVICES,
});

export const setSelectedEscrowDevice = (
  escrowDevice: EscrowDevice,
): DevicesAction => ({
  data: escrowDevice,
  type: DevicesTypes.SET_SELECTED_ESCROW_DEVICE,
});

export const setSelectedEscrowDeviceSecretData = (
  secretData: LwM2MSecretData,
): DevicesAction => ({
  data: secretData,
  type: DevicesTypes.SET_SELECTED_ESCROW_DEVICE_SECRET_DATA,
});

export const setStateDiscoveredDevices = (
  discoveredDevices: DiscoveredDevice[],
): DevicesAction => ({
  data: discoveredDevices,
  type: DevicesTypes.SET_DISCOVERED_DEVICES,
});

export const setNewDevice = (device: Device): DevicesAction => ({
  data: device,
  type: DevicesTypes.SET_NEW_DEVICE,
});

export const setDeviceInput = (device: DeviceInput): DevicesAction => ({
  data: device,
  type: DevicesTypes.SET_DEVICE_INPUT,
});

export const setEscrowDeviceInput = (
  device: EscrowDeviceInput,
): DevicesAction => ({
  data: device,
  type: DevicesTypes.SET_ESCROW_DEVICE_INPUT,
});

export const setActualDiscoveredDevice = (
  device: DiscoveredDevice,
): DevicesAction => ({
  data: device,
  type: DevicesTypes.SET_ACTUAL_DISCOVERED_DEVICE,
});

export const setNewDiscoveredDevice = (
  device: DiscoveredDevice,
): DevicesAction => ({
  data: device,
  type: DevicesTypes.SET_NEW_DISCOVERED_DEVICE,
});

export const cleanNewDeviceInput = (): DevicesAction => ({
  data: emptyDevice,
  type: DevicesTypes.CLEAN_NEW_DEVICE_INPUT,
});

export const cleanNewEscrowDeviceInput = (): DevicesAction => ({
  data: emptyDevice,
  type: DevicesTypes.CLEAN_NEW_ESCROW_DEVICE_INPUT,
});

export type DevicesState = {
  device: Device | null;
  devices: Device[];
  deviceInput: DeviceInput;
  devicesGenre: string;
  devicesOptions: Device[];
  discoveredDevice: DiscoveredDevice | null;
  discoveredDevices: DiscoveredDevice[];
  escrowDevice: EscrowDevice | null;
  escrowDeviceInput: EscrowDeviceInput;
  escrowDevices: EscrowDevice[];
  newDevice: Device | null;
  newDiscoveredDevice: DiscoveredDevice | null;
  selectedDeviceSecretData: LwM2MSecretData | null;
  selectedEscrowDeviceSecretData: LwM2MSecretData | null;
};

// INITIAL STATE
const devicesInitialState: DevicesState = {
  device: null,
  deviceInput: emptyDevice,
  devices: [],
  devicesGenre: GENRE_ACTIVE,
  devicesOptions: [],
  discoveredDevice: null,
  discoveredDevices: [],
  escrowDevice: null,
  escrowDeviceInput: emptyEscrowDevice,
  escrowDevices: [],
  newDevice: null,
  newDiscoveredDevice: null,
  selectedDeviceSecretData: null,
  selectedEscrowDeviceSecretData: null,
};

export type DevicesAction = {
  type: string;
  data:
    | string
    | Device
    | DeviceInput
    | Device[]
    | DiscoveredDevice
    | DiscoveredDevice[]
    | EscrowDevice
    | EscrowDeviceInput
    | EscrowDevice[]
    | LwM2MSecretData;
};

const devicesReducer = (
  state = devicesInitialState,
  action: DevicesAction,
): DevicesState => {
  switch (action.type) {
    case DevicesTypes.SET_DEVICES:
      return {
        ...state,
        devices: action.data as Device[],
      };
    case DevicesTypes.SET_DEVICES_OPTIONS:
      return {
        ...state,
        devicesOptions: action.data as Device[],
      };
    case DevicesTypes.SET_NEW_DEVICE:
      return {
        ...state,
        newDevice: action.data as Device,
      };
    case DevicesTypes.SET_DEVICES_GENRE:
      return {
        ...state,
        devicesGenre: action.data as string,
      };

    case DevicesTypes.SET_DEVICES:
      return {
        ...state,
        devices: action.data as Device[],
      };

    case DevicesTypes.SET_ACTUAL_DEVICE:
      return {
        ...state,
        device: action.data as Device,
        newDevice: action.data as Device,
      };

    case DevicesTypes.SET_SELECTED_DEVICE_SECRET_DATA:
      return {
        ...state,
        selectedDeviceSecretData: action.data as LwM2MSecretData,
      };

    case DevicesTypes.SET_ESCROW_DEVICES:
      return {
        ...state,
        escrowDevices: action.data as EscrowDevice[],
      };

    case DevicesTypes.SET_SELECTED_ESCROW_DEVICE:
      return {
        ...state,
        escrowDevice: action.data as EscrowDevice,
      };

    case DevicesTypes.SET_SELECTED_ESCROW_DEVICE_SECRET_DATA:
      return {
        ...state,
        selectedEscrowDeviceSecretData: action.data as LwM2MSecretData,
      };

    case DevicesTypes.SET_NEW_DEVICE:
      return {
        ...state,
        newDevice: action.data as Device,
      };
    case DevicesTypes.CLEAN_NEW_DEVICE_INPUT:
      return {
        ...state,
        deviceInput: action.data as Device,
      };
    case DevicesTypes.CLEAN_NEW_ESCROW_DEVICE_INPUT:
      return {
        ...state,
        escrowDeviceInput: action.data as EscrowDevice,
      };
    case DevicesTypes.SET_DISCOVERED_DEVICES:
      return {
        ...state,
        discoveredDevices: action.data as DiscoveredDevice[],
      };

    case DevicesTypes.SET_DEVICE_INPUT:
      return {
        ...state,
        deviceInput: action.data as DeviceInput,
      };

    case DevicesTypes.SET_ESCROW_DEVICE_INPUT:
      return {
        ...state,
        escrowDeviceInput: action.data as EscrowDeviceInput,
      };

    case DevicesTypes.SET_ACTUAL_DISCOVERED_DEVICE:
      return {
        ...state,
        discoveredDevice: action.data as DiscoveredDevice,
        newDiscoveredDevice: action.data as DiscoveredDevice,
      };

    case DevicesTypes.SET_NEW_DISCOVERED_DEVICE:
      return {
        ...state,
        newDiscoveredDevice: action.data as DiscoveredDevice,
      };

    default:
      return state;
  }
};

export default devicesReducer;
