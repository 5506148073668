import {
  CommandType,
  EIQFile,
  EIQFiles,
  Lwm2mRequest,
} from '@edgeiq/edgeiq-api-js';
import { MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  errorHighlight,
  LWM2M_REQUEST,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setOptionsFiles } from '../../../redux/reducers/files.reducer';
import { RootState } from '../../../redux/store';
import SelectInput from '../../../components/SelectInput';
import TextInput from '../../../components/TextInput';
import {
  lwm2mFileActionTypes,
  lwm2mRequestActionTypes,
  valueRequiredActions,
  valueShownActions,
} from './constants';

interface Lwm2mGatewayCommandsProps {
  chosenGatewayCommand: string;
  gatewayCommand?: Lwm2mRequest;
  onChangeCommandData: (prop: string, value: string) => void;
}

const Lwm2mGatewayCommands: React.FC<Lwm2mGatewayCommandsProps> = ({
  chosenGatewayCommand,
  gatewayCommand,
  onChangeCommandData,
}) => {
  const dispatch = useAppDispatch();
  const filesState = useAppSelector((state: RootState) => state.files);
  const [chosenType, setChosenType] = useState<keyof CommandType | ''>('');
  const [filesOptions, setFilesOptions] = useState<EIQFile[]>(
    filesState.optionsFiles,
  );

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    if (filesOptions.length === 0) {
      EIQFiles.list({}, optionsPaginationsFilter)
        .then((result) => {
          setFilesOptions(result.files);
          dispatch(setOptionsFiles(result.files));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const handleInputChange = (prop: string, value: string | number): void => {
    if (prop === 'type') {
      setChosenType(value as keyof CommandType);
    }
    onChangeCommandData(prop, value as string);
  };

  // const handleResourcePath = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const value = event.target.value;
  //   handleInputChange('endpoint', value);
  // };

  const isRequestCommand = chosenGatewayCommand === LWM2M_REQUEST;
  const isValueRequired = valueRequiredActions.includes(chosenType as string);
  const isValueShown = valueShownActions.includes(chosenType as string);

  const renderTypesOptions = (): JSX.Element[] => {
    let actualActions = [];
    if (isRequestCommand) {
      actualActions = lwm2mRequestActionTypes;
    } else {
      actualActions = lwm2mFileActionTypes;
    }
    return actualActions.map((action) => (
      <MenuItem
        className="m-4 p-2"
        dense
        key={action.action}
        value={action.action}
      >
        {action.label}
      </MenuItem>
    ));
  };

  const renderFilesOptions = (): JSX.Element[] =>
    filesOptions.map((file) => (
      <MenuItem className="m-4 p-2" dense key={file.id} value={file.id}>
        {file.name}
      </MenuItem>
    ));

  return !chosenGatewayCommand ? (
    <></>
  ) : (
    <>
      <div className="mb-6 mt-6">
        <SelectInput
          prop="type"
          classes="mb-6"
          label="Type"
          value={chosenType as string}
          onSelectChange={handleInputChange}
          options={[
            <MenuItem dense value="" key="no-value">
              Select a type
            </MenuItem>,
            ...renderTypesOptions(),
          ]}
        />
        {chosenType && (
          <>
            {isRequestCommand ? (
              <>
                <TextInput
                  label="Resource Path"
                  prop="endpoint"
                  classes="mb-6"
                  value={gatewayCommand?.endpoint ?? ''}
                  required={true}
                  onInputChange={handleInputChange}
                />
                {isValueShown && (
                  <TextInput
                    label={`Parameters${isValueRequired ? '' : ' (optional)'}`}
                    prop="data"
                    classes="mb-6"
                    value={gatewayCommand?.data ?? ''}
                    onInputChange={handleInputChange}
                  />
                )}
                <TextInput
                  label="Timeout (seconds)"
                  prop="timeout"
                  classes="mb-6"
                  value={gatewayCommand?.timeout ?? ''}
                  type="number"
                  required={true}
                  onInputChange={handleInputChange}
                />
              </>
            ) : (
              <>
                <TextInput
                  label="File Path"
                  prop="filepath"
                  classes="mb-6"
                  value={gatewayCommand?.filepath ?? ''}
                  onInputChange={handleInputChange}
                />
                {isValueShown && (
                  <SelectInput
                    label="File"
                    prop="file_id"
                    value={gatewayCommand?.file_id ?? ''}
                    onSelectChange={handleInputChange}
                    options={[
                      <MenuItem dense value="" key="no-value">
                        Select a file
                      </MenuItem>,
                      ...renderFilesOptions(),
                    ]}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Lwm2mGatewayCommands;
