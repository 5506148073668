import React from 'react';
import { Grid } from '@mui/material';
import ActionDialog from '../../components/ActionDialog';
import PasswordInputWithAlert from '../PasswordInputWithAlert/PasswordInputWithAlert';

interface MqttPasswordConfirmationDialogProps {
  mqtt_password: string;
  onConfirm: () => void;
  open: boolean;
  // set this to true if this is after an "update" action, false otherwise
  changed?: boolean;
}

const MqttPasswordConfirmationDialog: React.FC<
  MqttPasswordConfirmationDialogProps
> = ({ mqtt_password, onConfirm, open, changed = false }) => {
  return (
    <>
      <ActionDialog
        open={open}
        title={changed ? 'MQTT Password Changed' : 'MQTT Password Set'}
        loading={false}
        content={
          <>
            <p>
              You have {changed ? 'changed' : 'set'} the MQTT password, please
              make sure to copy this value and {changed ? 'change' : 'set'} it
              on the edge device as well.
            </p>
            <p>
              You will not be able to retrieve the plaintext password at a later
              stage.
            </p>
            <Grid>
              <PasswordInputWithAlert
                label={'MQTT Password'}
                placeholder={'MQTT Password'}
                disabled={true}
                required={false}
                prop={'mqtt_password'}
                value={mqtt_password}
                onInputChange={undefined}
                hasCopyButton={true}
                hasPasswordStrengthBar={false}
              />
            </Grid>
          </>
        }
        actionButtonLabel="Understood"
        actionButtonColor="error"
        actionButtonVariant="contained"
        onCloseCallback={onConfirm}
        onDeleteCallback={onConfirm}
        hideCancelButton={true}
      />
    </>
  );
};

export default MqttPasswordConfirmationDialog;
