import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { User, Users, UserType, UserTypes } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import { setAlert } from '../../../../redux/reducers/alert.reducer';
import { setStateUserTypes } from '../../../../redux/reducers/userTypes.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../../app/constants';
import ActionDialog from '../../../../components/ActionDialog';
import AccountUsersList from './accountUsersList';
import useStyles from './styles';

const AccountUsers: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userTypesState = useAppSelector((state: RootState) => state.userTypes);
  const [userTypes, setUserTypes] = useState<UserType[]>(
    userTypesState.userTypes,
  );
  const companyState = useAppSelector(
    (state: RootState) => state.companies.company,
  );
  const [userList, setUsersList] = useState<User[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userDeleteSelected, setUserDeleteSelected] = useState<User>();
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    if (userTypes.length === 0) {
      UserTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          const { userTypes: resultUserTypes } = result;
          setUserTypes(resultUserTypes);
          dispatch(setStateUserTypes(resultUserTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const getUsers = (): void => {
    Users.list(
      { company_id: { operator: 'eq', value: companyState?._id || '' } },
      optionsPaginationsFilter,
    )
      .then((result) => {
        setUsersList(result.users);
        setTotal(result.pagination.total);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    setLoading(true);
    setUsersList([]);
    getUsers();
  }, [companyState]);

  const noLoading = (): void => {
    setLoading(false);
  };

  const handlerDeleteUserEvent = (user: User): void => {
    setUserDeleteSelected(user);
    setDeleteUserModalOpen(true);
  };

  const handleDeleteUser = (): void => {
    if (!userDeleteSelected) {
      return;
    }
    setLoading(true);
    Users.removeCompanyId(companyState?._id || '', userDeleteSelected._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'User Detached',
            message: 'User successfully detached from the account.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeDeleteModal = (): void => {
    setDeleteUserModalOpen(false);
  };

  const handleNewClick = (): void => {
    navigate(`/new-user`);
  };

  return (
    <Grid item xs={12} md={4}>
      <Paper className={clsx(classes.container, 'shadow p-7')}>
        <Typography
          className="mt-10 pl-4"
          variant="h5"
          data-cy="account-users-total"
        >
          Users {`(${total})`}
          <Button
            color="primary"
            type="button"
            variant="text"
            data-cy="create-new-user-link"
            onClick={handleNewClick}
          >
            <Typography variant="button">Create new</Typography>
          </Button>
        </Typography>

        {loading ? (
          <Grid container className="loading-container">
            <CircularProgress size={75} thickness={5} />
          </Grid>
        ) : (
          <AccountUsersList
            setUserDeleteSelected={handlerDeleteUserEvent}
            users={userList}
            userTypes={userTypes}
          />
        )}
      </Paper>

      <ActionDialog
        content="You are about to detach this user"
        open={deleteUserModalOpen}
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleDeleteUser}
        loading={loading}
      />
    </Grid>
  );
};

export default AccountUsers;
