import React, { ReactElement, useRef, useState, ReactNode } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import MapIcon from '@mui/icons-material/Map';
import ReorderIcon from '@mui/icons-material/Reorder';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';

import { SortingOption } from '../../models/common';
import useStyles from './styles';

export interface ListSelectionProps {
  sortingOptions: SortingOption[];
  viewsOptions: string[]; // Available options: 'list', 'grid', 'map'
  selectedSorting: SortingOption;
  selectedView: string;
  itemsSelected: boolean;
  allSelected: boolean;
  showSelectAll?: boolean;
  itemsSelectedCount?: number;
  selectedLabel?: string;
  selectedPluralLabel?: string;
  loadingAllItems?: boolean;
  selectedActions?: ReactElement;
  customAction?: boolean;
  customActionButtonLabel?: string;
  customActionButtonIcon?: ReactNode;
  deleteAction?: boolean;
  xPadding?: boolean;
  topPadding?: boolean;
  sortingCallback: (option: SortingOption) => void;
  selectAllCallback?: () => void;
  selectViewCallback?: (view: string) => void;
  customActionCallback?: () => void;
  deleteCallback?: () => void;
}

const ListSelection: React.FC<ListSelectionProps> = ({
  sortingOptions,
  viewsOptions,
  selectedSorting,
  selectedView,
  showSelectAll = true,
  itemsSelected,
  allSelected,
  itemsSelectedCount,
  selectedLabel,
  selectedPluralLabel,
  loadingAllItems = false,
  selectedActions,
  deleteAction = true,
  xPadding = true,
  topPadding = true,
  sortingCallback,
  selectAllCallback,
  selectViewCallback,
  deleteCallback,
}) => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openSortingMenu, setOpenSortingMenu] = useState(false);

  const handleOpenSortingMenu = (): void => {
    setOpenSortingMenu(true);
  };

  const handleCloseSortingMenu = (): void => {
    setOpenSortingMenu(false);
  };

  const handleMenuItem = (sortingOption: SortingOption) => (): void => {
    handleCloseSortingMenu();
    sortingCallback(sortingOption);
  };

  const handleViewSelected = (view: string) => (): void => {
    if (selectViewCallback) {
      selectViewCallback(view);
    }
  };

  const handleDeleteAction = (): void => {
    if (deleteCallback && itemsSelected) {
      deleteCallback();
    }
  };

  const renderSelectAllCheckbox = (): JSX.Element => (
    <div
      className={clsx({
        ['mr-6']: loadingAllItems,
      })}
    >
      <FormControlLabel
        data-cy="select-all-checkbox"
        control={
          <Checkbox
            checked={allSelected}
            indeterminate={itemsSelected && !allSelected}
            onClick={selectAllCallback}
          />
        }
        label={allSelected ? 'Deselect All' : 'Select All'}
      />
      {loadingAllItems && <CircularProgress size={15} />}
    </div>
  );

  const itemsSelectedLabel = selectedPluralLabel
    ? selectedPluralLabel
    : `${selectedLabel}s`;

  const setViewOptionColor = (
    viewOption: 'map' | 'grid' | 'list',
  ): 'primary' | 'inherit' =>
    selectedView === viewOption ? 'primary' : 'inherit';

  return (
    <Grid
      item
      xs={12}
      className={clsx('py-6', classes.container, {
        ['px-8']: xPadding,
        ['topPadding']: topPadding,
      })}
    >
      {!itemsSelected ? (
        <>
          <div className={classes.sortingContainer}>
            {selectedView === 'grid' &&
              showSelectAll &&
              renderSelectAllCheckbox()}
            <Typography variant="overline" className={classes.sortLabel}>
              Sort by:
            </Typography>
            <Button
              ref={anchorRef}
              color="inherit"
              variant="text"
              type="button"
              data-cy="sort-by-button"
              endIcon={
                !openSortingMenu ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )
              }
              onClick={handleOpenSortingMenu}
            >
              <Typography
                variant="overline"
                className={clsx('ml-2', classes.selectedSorting)}
              >
                {selectedSorting.label}
              </Typography>
            </Button>
            <Menu
              data-cy="sort-by-menu"
              anchorEl={anchorRef.current}
              open={openSortingMenu}
              onClose={handleCloseSortingMenu}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
              className={classes.sortingMenu}
            >
              {sortingOptions.map((sortingOption, index) => (
                <MenuItem
                  dense
                  data-cy={`sort-by-${sortingOption.value}`}
                  key={index}
                  selected={sortingOption.value === selectedSorting.value}
                  onClick={handleMenuItem(sortingOption)}
                >
                  <Typography variant="button">
                    {sortingOption.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div>
            {viewsOptions.map((viewOption, index) => (
              <IconButton onClick={handleViewSelected(viewOption)} key={index}>
                {viewOption === 'map' && (
                  <MapIcon
                    data-cy="map-selection"
                    color={setViewOptionColor(viewOption)}
                  />
                )}
                {viewOption === 'list' && (
                  <ReorderIcon
                    data-cy="list-selection"
                    color={setViewOptionColor(viewOption)}
                  />
                )}
                {viewOption === 'grid' && (
                  <ViewModuleIcon
                    data-cy="grid-selection"
                    color={setViewOptionColor(viewOption)}
                  />
                )}
              </IconButton>
            ))}
          </div>
        </>
      ) : (
        <div className={classes.sortingContainer}>
          {selectedView === 'grid' &&
            showSelectAll &&
            renderSelectAllCheckbox()}
          <Typography
            data-cy="selected-items-count"
            variant="overline"
            className={clsx('mr-6', classes.sortLabel)}
          >
            {allSelected
              ? `All ${itemsSelectedLabel} (${itemsSelectedCount}) selected`
              : `${itemsSelectedCount} ${
                  itemsSelectedCount === 1 ? selectedLabel : itemsSelectedLabel
                } selected`}
          </Typography>
          {selectedActions}
          {deleteAction && (
            <Button
              data-cy="delete-button-list"
              className="ml-4"
              variant="outlined"
              size="medium"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteAction}
            >
              Delete
            </Button>
          )}
        </div>
      )}
    </Grid>
  );
};

export default ListSelection;
