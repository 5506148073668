import { GatewayCommand } from '@edgeiq/edgeiq-api-js';

export const GatewayTypes = {
  SET_GATEWAY_COMMAND: 'SET_GATEWAY_COMMAND',
  SET_GATEWAY_SELECTED_COMMANDS: 'SET_GATEWAY_SELECTED_COMMANDS',
};

export const setGatewayCommands = (
  hearbeats: GatewayCommand[],
): GatewayCommandsAction => ({
  data: hearbeats,
  type: GatewayTypes.SET_GATEWAY_COMMAND,
});

export const setSelectedGatewayCommands = (
  hearbeats: GatewayCommand[],
): GatewayCommandsAction => ({
  data: hearbeats,
  type: GatewayTypes.SET_GATEWAY_SELECTED_COMMANDS,
});

export type GatewayCommandState = {
  gatewayCommands: GatewayCommand[];
  selectedHearbeats: GatewayCommand[];
};

// INITIAL STATE
const gatewayCommandsInitialState: GatewayCommandState = {
  gatewayCommands: [],
  selectedHearbeats: [],
};

export type GatewayCommandsAction = {
  data: GatewayCommand[] | GatewayCommand;
  type: string;
};

const gatewayCommandsReducer = (
  state = gatewayCommandsInitialState,
  action: GatewayCommandsAction,
): GatewayCommandState => {
  switch (action.type) {
    case GatewayTypes.SET_GATEWAY_COMMAND:
      return {
        ...state,
        gatewayCommands: action.data as GatewayCommand[],
      };
    case GatewayTypes.SET_GATEWAY_SELECTED_COMMANDS:
      return {
        ...state,
        selectedHearbeats: action.data as GatewayCommand[],
      };
    default:
      return state;
  }
};

export default gatewayCommandsReducer;
