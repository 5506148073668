import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { Command, Device, DeviceType, Devices } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { optionsPaginationsFilter } from '../../../app/constants';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import CodeEditor from '../../../components/CodeEditor';
import useStyles from '../../../components/RightDrawer/styles';
import { SettingsEditorData } from '../../../models/settings';
import DeviceTypeIconName from '../../../components/DeviceTypeIconName';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { dispatchError } from '../../../helpers/utils';

interface DryRunCommandProps {
  open: boolean;
  deviceProfile?: DeviceType;
  devices?: Device[];
  settings?: SettingsEditorData;
  command: Command;
  handleCloseDrawer: () => void;
}

const DryRunCommand: React.FC<DryRunCommandProps> = ({
  open,
  handleCloseDrawer,
  settings,
  devices,
  deviceProfile,
  command,
}) => {
  const classes = useStyles({});
  const anchorRef = useRef<HTMLDivElement>(null);
  const deviceTypes = useAppSelector(
    (state: RootState) => state.deviceTypes.optionsDeviceTypes,
  );
  const [chosenSiblingDevice, setChosenSiblingDevice] = useState<string>('');
  const [deviceOptionsList, setDeviceOptionsList] = useState<Device[]>(
    devices || [],
  );
  const [results, setResults] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const getDeviceType = (id: string): DeviceType | undefined =>
    deviceTypes.find((deviceType) => deviceType._id === id);

  const checkSiblingDevice = (deviceId: string) => (): void => {
    setChosenSiblingDevice(deviceId);
  };

  const renderDevices = (list: Device[], preIndex?: string): JSX.Element => (
    <div className={clsx('scrollbar mb-4', classes.drawerSelectedDevices)}>
      {list.map((device) => (
        <div
          key={`${preIndex ? `${preIndex}-` : ''}${device._id}`}
          data-cy="device-container"
          className={clsx('mb-2 br-1 p-2', classes.drawerDeviceContainer)}
        >
          <Typography variant="subtitle2" component="div" data-cy="device-name">
            <Checkbox
              className="p-0 mr-2"
              checked={chosenSiblingDevice === device._id}
              onChange={checkSiblingDevice(device._id)}
            />

            {device.name}
          </Typography>
          <div className="d-flex flex-items-center">
            <DeviceTypeIconName
              labelVariant="subtitle2"
              deviceType={getDeviceType(device.device_type_id)}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const fetchDeviceForDeviceProfile = async (): Promise<void> => {
    if (!devices?.length && deviceProfile) {
      const deviceList = await Devices.list(
        {
          device_type_id: {
            operator: 'in',
            value: deviceTypes.map((dt) => dt._id),
          },
        },
        optionsPaginationsFilter,
      );
      setDeviceOptionsList(deviceList.devices);
    }
  };

  useEffect(() => {
    fetchDeviceForDeviceProfile();
  }, [devices]);

  const handleApply = (): void => {
    setResults('');
    setLoading(true);
    if (settings) {
      Devices.dryRunCommand(chosenSiblingDevice, command._id, {
        context: {
          settings: [{ values: settings.jsonTree }],
        },
      })
        .then((res) => {
          setResults(JSON.stringify(res, null, 2));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          dispatchError(error.message);
        });
    }
  };

  const openCommandData = (): void => {
    let url = `${window.location.origin}`;
    const commandId = command._id;
    url = `${url}/command/${commandId}`;

    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Run"
      title="Dry Run Command"
      disableAction={!chosenSiblingDevice || loading}
      actionCallback={handleApply}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid
          container
          direction="column"
          spacing={2}
          className="position-relative"
        >
          {command && (
            <Grid item xs={12}>
              <Typography variant="h6" className="mb-2">
                Command:
              </Typography>
              <Tooltip placement="top-start" title="Open Configuration">
                <Typography
                  data-testid="tooltip-setting-drawer"
                  data-cy={`open-application-command-${command._id}`}
                  variant="button"
                  component="div"
                  noWrap
                  className={clsx('mb-1', classes.link)}
                  onClick={openCommandData}
                >
                  {command.name}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12} className="my-4">
            <Typography variant="h6" className="mb-2">
              Settings Values:
            </Typography>
            <Box ref={anchorRef}>
              {settings?.jsonContent !== '' ? (
                <CodeEditor
                  label=""
                  prop="json_schema"
                  value={settings?.jsonContent}
                  width={anchorRef.current?.clientWidth}
                  height={200}
                  disabled={true}
                  mode="json"
                />
              ) : (
                <Typography variant="button">
                  The settings selected have no values
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} className="my-4">
            <Typography variant="h6" className="mb-2">
              Select Device:
            </Typography>
            <Box>{devices && renderDevices(deviceOptionsList, undefined)}</Box>
            {!devices?.length && !deviceProfile && (
              <Typography variant="subtitle2" className="mb-3">
                In order to dry run the command at least 1 device profile must
                be attached to the configuration.
              </Typography>
            )}
          </Grid>

          {results && (
            <Grid item xs={12} className="my-4">
              <Typography variant="h6" className="mb-2">
                Results:
              </Typography>
              <Box>
                {results !== '' ? (
                  <CodeEditor
                    label=""
                    prop="json_schema"
                    value={results}
                    width={anchorRef.current?.clientWidth}
                    height={200}
                    disabled={true}
                    mode="json"
                  />
                ) : (
                  <Typography variant="button">
                    The settings selected have no values
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default DryRunCommand;
