import { DiscoveredDevice } from '@edgeiq/edgeiq-api-js';
import { heartbeatColorThemeMap } from '../../../app/constants';
import ColoredBox from '../../../components/ColoredBox';

import { TableColumn } from '../../../components/SharedTable';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import timeHelpers from '../../../helpers/timeHelpers';

export const DiscoveredColumns: TableColumn[] = [
  {
    cellValue: (item): string => {
      const device = item as DiscoveredDevice;
      return device.name;
    },
    id: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    id: 'unique_id',
    label: 'Unique ID',
    parser: (item): string | React.ReactElement => {
      const device = item as DiscoveredDevice;
      if (!device.unique_id) {
        return '--';
      }
      return (
        <TypographyWithCopy
          component="div"
          dataCy={`copy-device-id-${device.unique_id}-table`}
          text={device.unique_id}
          textToCopy={device.unique_id}
          tooltipText={device.unique_id}
          typographyVariant="button"
          textMaxWidth={300}
        />
      );
    },
    type: 'custom',
  },
  {
    cellValue: (item): string => {
      const device = item as DiscoveredDevice;
      return device.manufacturer ?? '';
    },
    id: 'manufacturer',
    label: 'Manufacturer',
    type: 'text',
  },
  {
    cellValue: (item): string => {
      const device = item as DiscoveredDevice;
      return device.model ?? '';
    },
    id: 'model',
    label: 'Model',
    type: 'text',
  },
  {
    id: 'heartbeat_status',
    label: 'Heartbeat Status',
    parser: (item): React.ReactElement => {
      const device = item as DiscoveredDevice;
      return (
        <div>
          <ColoredBox
            type="heartbeat_status"
            value={device.heartbeat_status ?? ''}
            colorTheme={
              heartbeatColorThemeMap[
                device.heartbeat_status ?? 'never_reported'
              ]
            }
          />
        </div>
      );
    },
    type: 'custom',
  },
  {
    cellValue: (item): string => {
      const device = item as DiscoveredDevice;
      const date = device.created_at
        ? timeHelpers.getDate(device.created_at)
        : '';
      return date;
    },
    id: 'discovered-date',
    label: 'Discovered Date',
    type: 'text',
  },
  {
    cellValue: (item): string => {
      const device = item as DiscoveredDevice;
      const date = device.heartbeat_at
        ? timeHelpers.getDate(device.heartbeat_at)
        : '';
      return device.heartbeat_status === 'never_reported'
        ? 'Never reported'
        : date;
    },
    id: 'last-heartbeat-date',
    label: 'Last Heartbeat',
    type: 'text',
  },
];
