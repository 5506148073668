import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionContainer: {
      borderRadius: '5px',
      marginBottom: '24px !important',
      padding: '8px 32px',
      width: '100%',
    },
    header: {
      color: theme.palette.grey[200],
    },
    instancesContainer: {
      border: `1px solid ${theme.palette.grey.A200}`,
      borderRadius: '4px',
      padding: '24px',
    },
    operationButton: {
      backgroundColor: theme.palette.grey[600],
      borderRadius: '4px',
      marginRight: '8px',
      minWidth: '1px',
      padding: '6px 12px',
    },
    resourceContainer: {
      borderTop: `1px solid ${theme.palette.grey.A200}`,
    },
  }),
);

export default useStyles;
