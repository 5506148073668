import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { closeAlert } from '../../redux/reducers/alert.reducer';
import { DEFAULT_TOASTER_TIMER } from '../../app/constants';
import ToastAlert from '../../components/ToastAlert';

const ToastAlertContainer: React.FC<Record<string, unknown>> = () => {
  const dispatch = useAppDispatch();
  const alert = useAppSelector((state: RootState) => state.alert);

  const [open, setOpen] = React.useState(false);

  const onClose = (): void => {
    setOpen(false);
    dispatch(closeAlert(false));
  };

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  useEffect(() => {
    if (!alert.show) {
      return;
    }
    setOpen(alert.show);
    if (alert.type === 'success') {
      const onCloseTimeout = setTimeout(() => {
        onClose();
      }, DEFAULT_TOASTER_TIMER);

      return () => clearTimeout(onCloseTimeout);
    }
  }, [alert]);

  return <ToastAlert handleClose={handleClose} open={open} {...alert} />;
};
export default ToastAlertContainer;
