import { GatewayCommand } from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import { Typography } from '@mui/material';

export const HeartBeatColumns = (
  handleActionClick: (item: GatewayCommand) => void,
): TableColumn[] => [
  {
    id: 'created_at',
    label: 'Date/Time',
    parser: (item: TableItemType): string | React.ReactElement => {
      const report = item as GatewayCommand;
      return (
        <>
          {DateTime.fromISO(report.created_at).toFormat('TT')}
          <br />
          {DateTime.fromISO(report.created_at).toLocaleString(
            DateTime.DATE_SHORT,
          )}
        </>
      );
    },
    type: 'custom',
  },
  {
    cellValue: (item: TableItemType): string => {
      const gatwayCommand = item as GatewayCommand;
      return gatwayCommand.device_unique_id ?? '--';
    },
    id: 'device_name',
    label: 'Device',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const gatwayCommand = item as GatewayCommand;
      return gatwayCommand.command_type ?? '--';
    },
    id: 'command_type',
    label: 'Data',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const gatwayCommand = item as GatewayCommand;
      if (!gatwayCommand.device_unique_id) {
        return '-';
      }
      return (
        gatwayCommand.statuses[gatwayCommand.device_unique_id].status ?? '-'
      );
    },
    id: 'status',
    label: 'Status',
    type: 'text',
  },
  {
    id: 'details',
    label: 'Details',
    parser: (item: TableItemType): React.ReactElement => {
      const command = item as GatewayCommand;
      return (
        <Typography
          variant="button"
          style={{ cursor: 'pointer' }}
          onClick={(): void => handleActionClick(command)}
        >
          View Details
        </Typography>
      );
    },
    type: 'custom',
  },
];
