import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { DeviceType, SoftwareUpdate } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setNewSoftwareUpdateInput } from '../../redux/reducers/softwareUpdates.reducer';
import useCardStyles from '../../components/Card/styles';

interface SoftwarePackageCardProps {
  softwareUpdate: SoftwareUpdate;
  deviceType?: DeviceType;
}
type SoftwareUpdateWithType = SoftwareUpdate & { type: string };

const SoftwarePackageCard: React.FC<SoftwarePackageCardProps> = ({
  softwareUpdate,
  deviceType,
}) => {
  const cardClasses = useCardStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCopySoftware = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    dispatch(
      setNewSoftwareUpdateInput({
        ...softwareUpdate,
        name: `${softwareUpdate.name} - copy`,
      }),
    );
    navigate('/new-software-update');
  };

  return (
    <Box>
      <Tooltip placement="top" title={softwareUpdate.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-1', cardClasses.title)}
        >
          {softwareUpdate.name}
        </Typography>
      </Tooltip>

      <Typography
        variant="overline"
        className={clsx('mb-2 truncate', cardClasses.highlight)}
      >
        {(softwareUpdate as SoftwareUpdateWithType).type}
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', cardClasses.tag)}
      >
        Reboot after installation:{' '}
        <Typography
          variant="overline"
          component="div"
          className={clsx(cardClasses.subtitle)}
        >
          {softwareUpdate.reboot ? 'YES' : 'NO'}
        </Typography>
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-2', cardClasses.tag)}
      >
        Files Attached:{' '}
        <Typography
          variant="overline"
          component="div"
          className={clsx(cardClasses.subtitle)}
        >
          {softwareUpdate.files?.length ?? 0}
        </Typography>
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', cardClasses.tag)}
      >
        <DevicesIcon fontSize="small" className="mr-2" />
        {deviceType?.name}
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', cardClasses.tag)}
      >
        <FiberSmartRecordIcon fontSize="small" className="mr-2" />
        <span className="truncate">{softwareUpdate.company_id}</span>
      </Typography>

      <div className={cardClasses.statusContainer}>
        <Tooltip placement="top" title="Copy Command">
          <IconButton
            size="small"
            data-y={`software-update-${softwareUpdate._id}-copy-button`}
            onClick={handleCopySoftware}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>

      {softwareUpdate.script && (
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1 br-1 p-2', cardClasses.scriptContainer)}
          height={40}
          noWrap
        >
          {`${softwareUpdate.script}`}
        </Typography>
      )}
    </Box>
  );
};

export default SoftwarePackageCard;
