import { EIQFile } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getFilesSelector = createSelector(
  (state: FilesState) => state,
  (_: null, id?: string): string | undefined => id,
  (state, id) => {
    if (!state.files.length) {
      return state.files;
    }
    return state.files.find((file: EIQFile) => file._id === id);
  },
);

export const Files = {
  SET_ACTUAL_FILE: 'SET_ACTUAL_FILE',
  SET_FILES: 'SET_FILES',
  SET_NEW_FILE: 'SET_NEW_FILE',
  SET_OPTIONS_FILES: 'SET_OPTIONS_FILES',
};

export const setOptionsFiles = (files: EIQFile[]): FilesAction => ({
  data: files,
  type: Files.SET_OPTIONS_FILES,
});

export const setStateFiles = (files: EIQFile[]): FilesAction => ({
  data: files,
  type: Files.SET_FILES,
});

export const setActualFile = (file: EIQFile): FilesAction => ({
  data: file,
  type: Files.SET_ACTUAL_FILE,
});

export const setNewFile = (file: EIQFile): FilesAction => ({
  data: file,
  type: Files.SET_NEW_FILE,
});

export type FilesState = {
  file: EIQFile | null;
  files: EIQFile[];
  optionsFiles: EIQFile[];
};

const initFiles: FilesState = {
  file: null,
  files: [],
  optionsFiles: [],
};

export type FilesAction = {
  data: EIQFile[] | EIQFile | null;
  type: string;
};

const filesReducer = (state = initFiles, action: FilesAction): FilesState => {
  switch (action.type) {
    case Files.SET_FILES:
      return {
        ...state,
        files: action.data as EIQFile[],
      };
    case Files.SET_NEW_FILE:
      return {
        ...state,
        files: [...state.files, action.data as EIQFile],
      };
    case Files.SET_ACTUAL_FILE:
      return {
        ...state,
        file: action.data as EIQFile,
      };
    case Files.SET_OPTIONS_FILES:
      return {
        ...state,
        optionsFiles: action.data as EIQFile[],
      };
    default:
      return state;
  }
};

export default filesReducer;
