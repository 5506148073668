import {
  Company,
  Companies,
  CompaniesFilters,
  Pagination,
} from '@edgeiq/edgeiq-api-js';
import { useState } from 'react';
import { defaultPaginationFilter } from '../app/constants';

export const useFetchAccountsList = (
  cb: (errorMessage: string) => void,
): [
  Company[],
  Pagination | undefined,
  (filters: CompaniesFilters, nextPage?: boolean) => Promise<void>,
] => {
  const [accounts, setAccounts] = useState<Company[]>([]);
  const [pagination, setPagination] = useState<Pagination>();

  const fetchAccountsList = async (
    filters: CompaniesFilters,
    nextPage?: boolean,
  ): Promise<void> => {
    const paginationFilter =
      nextPage && pagination
        ? { ...pagination, page: ++pagination.page }
        : defaultPaginationFilter;
    try {
      const companiesList = await Companies.list(filters, paginationFilter);
      setAccounts((prev) =>
        nextPage
          ? [...prev, ...companiesList.companies]
          : companiesList.companies,
      );
      setPagination(companiesList.pagination);
    } catch (error) {
      const { message: errorMessage } = error as { message: string };
      cb(errorMessage);
    }
  };

  return [accounts, pagination, fetchAccountsList] as [
    Company[],
    Pagination | undefined,
    (filters: CompaniesFilters) => Promise<void>,
  ];
};
