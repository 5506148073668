import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  PollableAttributeNotification,
  PollableAttribute,
  PollableAttributeInput,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  setNewPollableAttribute,
  setNewPollableAttributesInput,
} from '../../../redux/reducers/pollableAttributes.reducer';
import NestedObject from '../../../components/NestedObject';
import {
  NestedObjectInput,
  NestedObjectItem,
} from '../../../components/NestedObject/NestedObject';

interface PollableAttributeNotificationsFormProps {
  isNew?: boolean;
}

const notificationInputs: NestedObjectInput[] = [
  {
    columns: 4,
    inputKey: 'name',
    isNameInput: true,
    label: 'Name',
    required: true,
    type: 'string',
  },
  {
    columns: 4,
    inputKey: 'status',
    label: 'Status',
    required: true,
    type: 'string',
  },
  {
    columns: 4,
    inputKey: 'oid',
    label: 'OID',
    required: true,
    type: 'string',
  },
  {
    columns: 12,
    inputKey: 'description',
    label: 'Description',
    required: false,
    type: 'string',
  },
  {
    columns: 12,
    defaultValue: true,
    inputKey: 'enabled',
    label: 'Enabled',
    required: true,
    type: 'boolean',
  },
  {
    columns: 12,
    inputKey: 'enabled_children',
    isEnableNestedItems: true,
    label: 'Enable children',
    required: false,
    type: 'boolean',
  },
];

const notificationObjectsToNestedObject = (
  objects?: PollableAttributeNotification[],
  index?: string,
): NestedObjectItem[] => {
  if (!objects || objects?.length === 0) {
    return [];
  }
  const items: NestedObjectItem[] = objects.map(
    (notificationObject, subIndex) => {
      const indexToUse = index ? `${index}.${subIndex}` : `${subIndex}`;
      return {
        collapsed: false,
        data: {
          enabled: notificationObject.enabled,
          name: notificationObject.name,
          oid: notificationObject.oid,
          status: notificationObject.status,
        },
        index: indexToUse,
        items: notificationObjectsToNestedObject(
          notificationObject.objects,
          indexToUse,
        ),
      };
    },
  );

  return items;
};

const nestedObjectsToNotificationObjects = (
  items?: NestedObjectItem[],
): PollableAttributeNotification[] => {
  if (!items || items.length === 0) {
    return [];
  }

  const objects: PollableAttributeNotification[] = items.map(
    (nestedObjectItem) => {
      return {
        description: nestedObjectItem.data.description as string,
        enabled: nestedObjectItem.data.enabled as boolean,
        name: nestedObjectItem.data.name as string,
        objects: nestedObjectsToNotificationObjects(nestedObjectItem.items),
        oid: nestedObjectItem.data.oid as string,
        status: nestedObjectItem.data.status as string,
      };
    },
  );

  return objects;
};

const PollableAttributeNotificationsForm: React.FC<
  PollableAttributeNotificationsFormProps
> = ({ isNew = false }) => {
  const dispatch = useAppDispatch();
  const pollableState = useAppSelector(
    (state: RootState) => state.pollableAttributes,
  );
  const { newPollableAttribute, newPollableAttributeInput } = pollableState;

  const handleNotificationsChange = (items: NestedObjectItem[]): void => {
    let newPollable = isNew ? newPollableAttributeInput : newPollableAttribute;
    if (newPollable) {
      newPollable = {
        ...newPollable,
        notifications: nestedObjectsToNotificationObjects(items),
      };
    }
    dispatch(
      isNew
        ? setNewPollableAttributesInput(newPollable as PollableAttributeInput)
        : setNewPollableAttribute(newPollable as PollableAttribute),
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} className={clsx('mb-6')}>
        <Typography variant="h5" className="fw-700">
          Notifications
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NestedObject
          explorerNameKey="name"
          hasEnabledItems={true}
          items={notificationObjectsToNestedObject(
            isNew
              ? newPollableAttributeInput?.notifications
              : newPollableAttribute?.notifications,
          )}
          inputs={notificationInputs}
          onUpdatingNestedItems={handleNotificationsChange}
        />
      </Grid>
    </Grid>
  );
};

export default PollableAttributeNotificationsForm;
