import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    label: {
      fontSize: '14px',
      marginLeft: 0,
    },
  }),
);

export default useStyles;
