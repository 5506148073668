import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

interface StyleProps {
  sticky: boolean;
  height: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    container: (props) => ({
      backgroundColor: '#fff',
      bottom: 0,
      display: 'flex',
      height: props.height,
      justifyContent: 'space-between',
      position: props.sticky ? 'sticky' : 'relative',
      width: '100%',
      zIndex: 100,
    }),
    errorButton: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
  }),
);

export default useStyles;
