import { Rule } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';
import { ClassNameMap, Typography } from '@mui/material';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';

export const PoliciesColumns = (classes: ClassNameMap): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const policy = item as Rule;
        return policy.description;
      },
      id: 'description',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const policy = item as Rule;
        return `/policy/${policy._id}`;
      },
      type: 'link',
    },
    {
      id: 'active',
      label: 'Active',
      parser: (item): React.ReactElement => {
        const policy = item as Rule;
        return (
          <Typography
            variant="button"
            component="div"
            className={classes.centerItem}
          >
            <FiberSmartRecordIcon fontSize="small" className="mr-2" />
            {policy.active ? 'Active' : 'Inactive'}
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};
