import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { Notifications } from '@edgeiq/edgeiq-api-js';
// import { DateTime } from 'luxon';
import clsx from 'clsx';

import { setAlert } from '../../redux/reducers/alert.reducer';
import { useAppDispatch } from '../../redux/hooks';
import { errorHighlight } from '../../app/constants';
import timeHelpers from '../../helpers/timeHelpers';

interface IAlertTableData {
  created_at: string;
  message: string;
}

const AlertsTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [alertTableData, setAlertTableData] = useState<IAlertTableData[]>([]);

  useEffect(() => {
    Notifications.list({}, { itemsPerPage: 6, page: 1 })
      .then((res) => {
        const mappedData = res.resources.map((d) => {
          return {
            created_at: d.created_at,
            message: d.message,
          };
        });
        setAlertTableData(mappedData);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoAlerts = (): void => {
    navigate(`/messages#alerts`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Alerts
        <Button
          className="p-0"
          color="primary"
          variant="text"
          type="button"
          onClick={handleGoAlerts}
        >
          <Typography variant="button">View More</Typography>
        </Button>
      </Typography>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Date/Time</TableCell>
                <TableCell align="left">Device</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alertTableData.map((row) => (
                <TableRow
                  key={row.message}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    {row.created_at && (
                      <>{timeHelpers.getDate(row.created_at, false, false)}</>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{row.message}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AlertsTable;
