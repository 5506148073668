import { Box, CircularProgress } from '@mui/material';

const RefreshLoader: React.FC = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={75} thickness={5} />
    </Box>
  );
};
export default RefreshLoader;
