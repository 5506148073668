import React, { Dispatch, useState } from 'react';
import { Button, IconButton, Link, Typography } from '@mui/material';
import {
  ControlPoint as AddIcon,
  RemoveCircleOutline as MinusIcon,
} from '@mui/icons-material';
import { get, set } from 'lodash';
import clsx from 'clsx';

import { NestedObjectInput, NestedObjectItem } from './NestedObject';
import { getNestedItemKeyPath } from './helpers';
import useStyles from './styles';

interface ObjectExplorerProps {
  chosenItem?: NestedObjectItem;
  explorerNameKey: string;
  hasEnabledItems?: boolean;
  inputs: NestedObjectInput[];
  nestedItems: NestedObjectItem[];
  updateItems: Dispatch<React.SetStateAction<NestedObjectItem[]>>;
  setActualItem: Dispatch<React.SetStateAction<NestedObjectItem | undefined>>;
}

const ObjectExplorer: React.FC<ObjectExplorerProps> = ({
  chosenItem,
  explorerNameKey,
  hasEnabledItems,
  inputs,
  nestedItems,
  updateItems,
  setActualItem,
}) => {
  const classes = useStyles();
  const [collapseAll, setCollapseAll] = useState(false);

  const getNestedItemName = (item: NestedObjectItem): string => {
    if (item.data && item.data[explorerNameKey]) {
      return item.data[explorerNameKey] as string;
    }
    return `Object name ${item.index}`;
  };

  const newNestedItem = (index: string, _length: number): NestedObjectItem => {
    const data: { [key: string]: unknown } = {};
    inputs.forEach((input) => {
      if (input.type === 'string') {
        data[input.inputKey] = input.defaultValue
          ? input.defaultValue
          : input.inputKey === explorerNameKey
          ? `Object name ${index}`
          : '';
      }
      if (input.type === 'number') {
        data[input.inputKey] = input.defaultValue ?? null;
      }
      if (input.type === 'boolean') {
        data[input.inputKey] = input.defaultValue ?? true;
      }
    });
    return {
      collapsed: false,
      data,
      index,
      items: [],
    };
  };

  const collapseNestedItem = (index?: string) => (): void => {
    const itemsObject = {
      items: [...nestedItems],
    };
    if (!index) {
      setCollapseAll(!collapseAll);
    } else {
      const itemKeyPath = getNestedItemKeyPath(index);
      const priorState = get(itemsObject, `items.${itemKeyPath}.collapsed`);
      set(itemsObject, `items.${itemKeyPath}.collapsed`, !priorState);
      updateItems(itemsObject.items);
    }
  };

  const addNestedItem = (index?: string) => (): void => {
    const itemsObject = {
      rootItems: [...nestedItems],
    };
    let newItem: NestedObjectItem;
    if (!index) {
      newItem = newNestedItem(`${nestedItems.length}`, nestedItems.length);
      itemsObject.rootItems.push(newItem);
    } else {
      const itemKeyPath = getNestedItemKeyPath(index);
      const itemArray = get(itemsObject, `rootItems.${itemKeyPath}.items`);
      newItem = newNestedItem(`${index}.${itemArray.length}`, itemArray.length);
      set(itemsObject, `rootItems.${itemKeyPath}.items`, [
        ...itemArray,
        newItem,
      ]);
    }
    updateItems(itemsObject.rootItems);
    handleChooseItem(newItem)();
  };

  const handleChooseItem = (item: NestedObjectItem) => (): void => {
    if (item.index !== chosenItem?.index) {
      setActualItem(item);
    }
  };

  const renderTreeItem = (
    item: NestedObjectItem,
    index: string,
    isLast: boolean,
  ): JSX.Element => {
    const subItemItems = item.items as NestedObjectItem[];
    return (
      <div
        key={`tree-object-item-${index}`}
        className={clsx('ml-6', classes.hierarchyContainer, {
          ['mb-4']: !isLast,
        })}
      >
        <div className={classes.nestedItemLine}></div>
        <div className={classes.nestedItem}>
          <IconButton size="small" onClick={collapseNestedItem(index)}>
            {item.collapsed && (
              <AddIcon className={classes.icon} fontSize="small" />
            )}
            {!item.collapsed && (
              <MinusIcon className={classes.icon} fontSize="small" />
            )}
          </IconButton>
          <Link onClick={handleChooseItem(item)} underline="none">
            <Typography
              variant="button"
              className={clsx(classes.hierarchyTitle, 'hierarchyTitle', {
                ['fw-600']: chosenItem?.index === item.index,
                [classes.hierarchyTitleDisabled]:
                  hasEnabledItems &&
                  !item.data.enabled &&
                  chosenItem?.index !== item.index,
              })}
            >
              {getNestedItemName(item)}
            </Typography>
          </Link>
          <Button
            color="primary"
            variant="text"
            type="button"
            onClick={addNestedItem(index)}
            className={clsx(
              classes.nestedItemAddButton,
              'nestedItemAddButton ml-3',
            )}
          >
            <Typography variant="button">Add</Typography>
          </Button>
        </div>
        {subItemItems?.length === 0 && (
          <Typography
            variant="subtitle2"
            component="span"
            className={classes.hierarchySubtitle}
          >
            0 nested
          </Typography>
        )}
        {item.collapsed && subItemItems?.length !== 0 && (
          <Typography
            variant="subtitle2"
            component="span"
            className={classes.hierarchySubtitle}
          >
            {subItemItems?.length} nested
          </Typography>
        )}
        {!item.collapsed &&
          subItemItems?.map((treeObjectItem, subIndex) =>
            renderTreeItem(
              treeObjectItem,
              `${index}.${subIndex}`,
              subIndex === subItemItems.length - 1,
            ),
          )}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        'scrollbar overflow-x-auto w-100 pb-2',
        classes.hierarchyContainer,
      )}
    >
      <div className={classes.nestedItemLine}></div>
      <div className={classes.nestedItem}>
        <IconButton size="small" onClick={collapseNestedItem()}>
          {collapseAll && <AddIcon className={classes.icon} fontSize="small" />}
          {!collapseAll && (
            <MinusIcon className={classes.icon} fontSize="small" />
          )}
        </IconButton>
        <Typography variant="button" className="fw-600">
          All
        </Typography>
        <Button
          color="primary"
          variant="text"
          type="button"
          onClick={addNestedItem()}
          className={clsx(
            classes.nestedItemAddButton,
            'nestedItemAddButton ml-3',
          )}
        >
          <Typography variant="button">Add</Typography>
        </Button>
      </div>
      {nestedItems.length === 0 && (
        <Typography variant="subtitle2" className={classes.hierarchySubtitle}>
          0 nested
        </Typography>
      )}
      {collapseAll && nestedItems.length !== 0 && (
        <Typography variant="subtitle2" className={classes.hierarchySubtitle}>
          {nestedItems.length} nested
        </Typography>
      )}
      {!collapseAll &&
        nestedItems.map((treeObjectItem, index) =>
          renderTreeItem(
            treeObjectItem,
            index.toString(),
            index === nestedItems.length - 1,
          ),
        )}
    </div>
  );
};

export default ObjectExplorer;
