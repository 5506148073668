import { IntegrationEvent } from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';
import { TableColumn, TableItemType } from '../../../components/SharedTable';

export const IntegrationEventsColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const integrationEvent = item as IntegrationEvent;
      return integrationEvent.integration_id ?? '--';
    },
    id: 'integration_id',
    label: 'integration_id',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const integrationEvent = item as IntegrationEvent;
      return integrationEvent.type ?? '--';
    },
    id: 'type',
    label: 'Type',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const integrationEvent = item as IntegrationEvent;
      return integrationEvent.message ?? '--';
    },
    id: 'message',
    label: 'Message',
    type: 'text',
  },
  {
    id: 'created_at',
    label: 'Date/Time',
    parser: (item: TableItemType): string | React.ReactElement => {
      const report = item as IntegrationEvent;
      return (
        <>
          {DateTime.fromISO(report.created_at).toFormat('TT')}
          <br />
          {DateTime.fromISO(report.created_at).toLocaleString(
            DateTime.DATE_SHORT,
          )}
        </>
      );
    },
    type: 'custom',
  },
];
