import { DeviceError } from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';
import { TableColumn, TableItemType } from '../../../components/SharedTable';

export const DeviceErrorColumns: TableColumn[] = [
  {
    id: 'created_at',
    label: 'Date/Time',
    parser: (item: TableItemType): string | React.ReactElement => {
      const report = item as DeviceError;
      return (
        <>
          {DateTime.fromISO(report.created_at).toFormat('TT')}
          <br />
          {DateTime.fromISO(report.created_at).toLocaleString(
            DateTime.DATE_SHORT,
          )}
        </>
      );
    },
    type: 'custom',
  },
  {
    cellValue: (item: TableItemType): string => {
      const error = item as DeviceError;
      return error.device_id ?? '--';
    },
    id: 'device',
    label: 'Device ID',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const error = item as DeviceError;
      return error.error ?? '--';
    },
    id: 'error',
    label: 'Error',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const error = item as DeviceError;
      return error.level.toString() ?? '--';
    },
    id: 'level',
    label: 'Level',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const error = item as DeviceError;
      return error.type ?? '--';
    },
    id: 'type',
    label: 'Type',
    type: 'text',
  },
];
