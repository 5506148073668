import React, { ChangeEvent, ReactElement } from 'react';
import {
  Typography,
  FormControl,
  OutlinedInput,
  TextareaAutosize,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

export interface TextInputProps {
  autocomplete?: string;
  autosize?: boolean;
  label?: string;
  value?: string | number;
  prop: string;
  placeholder?: string;
  required?: boolean;
  onInputChange?: (prop: string, value: string | number) => void;
  multiline?: boolean;
  rows?: number;
  classes?: string;
  disabled?: boolean;
  type?: string;
  minValue?: number;
  maxValue?: number;
  minRows?: number;
  endAdornment?: ReactElement;
  dataCy?: string;
  handleClick?: () => void;
  error?: boolean;
  helperText?: string;
  labelInfoTooltip?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  autocomplete,
  autosize,
  label,
  value,
  prop,
  required,
  disabled,
  placeholder,
  onInputChange,
  multiline,
  rows,
  classes,
  type,
  minValue,
  maxValue,
  minRows,
  endAdornment,
  dataCy,
  handleClick,
  error,
  helperText,
  labelInfoTooltip,
}) => {
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    if (onInputChange) {
      if (type === 'number' && event.target.value) {
        onInputChange(prop, parseInt(event.target.value));
        return;
      }
      onInputChange(prop, event.target.value);
    }
  };

  const handleOnclick = (): void => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <>
      {label && (
        <Typography variant="subtitle2" className="custom-label">
          {label} {required ? '*' : ''}
          {labelInfoTooltip && (
            <Tooltip placement="top" title={labelInfoTooltip}>
              <InfoIcon
                className="ml-1"
                style={{
                  fontSize: '18px',
                  verticalAlign: 'middle',
                }}
              />
            </Tooltip>
          )}
        </Typography>
      )}
      <FormControl variant="outlined" fullWidth>
        {autosize ? (
          <TextareaAutosize
            data-testid="textarea"
            autoComplete={autocomplete ?? 'off'}
            onClick={handleOnclick}
            className={classes}
            data-cy={dataCy ?? prop}
            name={prop}
            placeholder={placeholder ?? label}
            required={required}
            disabled={disabled}
            value={value}
            minRows={minRows}
            onChange={handleInputChange}
            style={{ overflow: 'auto' }}
          />
        ) : (
          <OutlinedInput
            autoComplete={
              type === 'password' ? 'new-password' : autocomplete ?? 'off'
            }
            onClick={handleOnclick}
            data-testid="input"
            data-cy={dataCy ?? prop}
            className={classes}
            name={prop}
            placeholder={placeholder ?? label}
            type={type || 'text'}
            required={required}
            disabled={disabled}
            value={value}
            onChange={handleInputChange}
            multiline={multiline}
            rows={rows}
            inputProps={
              type === 'number' ? { max: maxValue ?? null, min: minValue } : {}
            }
            endAdornment={endAdornment}
            error={error}
          />
        )}
        {helperText && (
          <FormHelperText
            id={`${prop}-helper-text`}
            data-cy={`${dataCy}-helper-text`}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default TextInput;
