import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import Filters from '../../containers/Filters';
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { scheduledJobTypes } from '../../app/constants';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';
import SelectInput from '../../components/SelectInput';
import useStyles from './styles';
interface ScheduledJobsFiltersProps {
  total: number;
  handleJobStatus: (isEnabled?: boolean) => void;
}

const JOB_STATUS = ['Disabled jobs', 'Enabled jobs', 'All jobs'];

const ScheduledJobsFilters: React.FC<ScheduledJobsFiltersProps> = ({
  total,
  handleJobStatus,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.scheduledJobs.filters;
  const [jobTypeSelected, setJobTypeSelected] = useState<string>('');

  useEffect(() => {
    if (filters) {
      setJobTypeSelected(filters.job_type);
    }
  }, [filters]);

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'scheduledJobs',
      ),
    );
  };

  const handleSelect = (event: SelectChangeEvent<number>): void => {
    const selected = event.target.value as number;
    if (selected > 1) {
      handleJobStatus();
      return;
    }
    handleJobStatus(!!selected);
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search scheduled jobs by name"
      inputKey="name"
      filterModel="scheduledJobs"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <SelectInput
          prop="job_type"
          value={jobTypeSelected}
          placeholder="Job Type"
          options={Object.keys(scheduledJobTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {scheduledJobTypes[key]}
            </MenuItem>
          ))}
          onSelectChange={changeFilters}
        />
      }
      thirdFilter={
        <Select
          displayEmpty
          className={classes.input}
          onChange={handleSelect}
          defaultValue={2}
        >
          {JOB_STATUS.map((status, index) => {
            return (
              <MenuItem dense key={`status-${index}`} value={index}>
                <ListItemText primary={status} />
              </MenuItem>
            );
          })}
        </Select>
      }
    />
  );
};

export default ScheduledJobsFilters;
