import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import {
  CompanyInput,
  Companies,
  CompaniesFilters,
  UserType,
  UserTypes,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setCompanyInput } from '../../redux/reducers/companies.reducer';
import { EMPTY_COMPANY } from '../../constants/companies';
import {
  setUserCompanies,
  setUserCompany,
  setUserType,
} from '../../redux/reducers/user.reducer';
import { errorHighlight } from '../../app/constants';
import Publish from '../../containers/Publish';
import Header from '../../containers/HeaderWithActionButton';
import AccountForm from '../accountContent/accountDetails/accountForm';
import useStyles from './styles';

const CreateDevicePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, userCompanies } = useAppSelector(
    (state: RootState) => state.user,
  );
  const dispatch = useAppDispatch();
  const companyInput = useAppSelector(
    (state: RootState) => state.companies.companyInput,
  );
  const [newCompany, setNewCompany] = useState<CompanyInput>(
    companyInput ?? EMPTY_COMPANY,
  );
  const [submitting, setSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [logo, setLogo] = useState<File>();

  const checkSubmitEnable = (): void => {
    setEnableSubmit(newCompany.name !== '' && newCompany.company_id !== '');
  };

  useEffect(() => {
    setNewCompany(companyInput ?? EMPTY_COMPANY);
  }, [companyInput]);

  useEffect(() => {
    checkSubmitEnable();
  }, [newCompany]);

  const dispatchChange = (
    prop: string,
    value: string | boolean | number,
  ): void => {
    // Check if the prop has a '.' and have one case for direct props and another for subprops (mainly for branding object here)
    const mainProp = prop.split('.');
    switch (mainProp[0]) {
      case 'branding':
        return dispatch(
          setCompanyInput({
            ...newCompany,
            branding: {
              ...newCompany?.branding,
              [mainProp[1]]: value,
            },
          } as CompanyInput),
        );
      default:
        dispatch(
          setCompanyInput({
            ...newCompany,
            [prop]: value,
          } as CompanyInput),
        );
    }
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    dispatchChange(prop, value);
  };

  const handleCheckboxChange = (prop: string, value: boolean): void => {
    dispatchChange(prop, value);
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleInputChange('company_id', companyId);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);

    Companies.create({ ...newCompany, _id: newCompany._id.trim() })
      .then((result) => {
        dispatch(
          setAlert({
            highlight: 'Account created successfully',
            type: 'success',
          }),
        );
        uploadCompanyLogo(result._id);
        updateAccountList(result._id);
        dispatch(setCompanyInput(EMPTY_COMPANY));
        navigate('/accounts');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const updateAccountList = (newCompanyId: string): void => {
    if (user) {
      const userCompanyIds = userCompanies.map((company) => company._id);
      const filters: CompaniesFilters = {
        _id: {
          operator: 'in',
          value: [...userCompanyIds, newCompanyId],
        },
      };

      Companies.list(filters, { itemsPerPage: 1000, page: 1 })
        .then((result) => {
          dispatch(
            setUserCompany(
              result.companies.filter(
                (company) => user.company_id === company._id,
              )[0],
            ),
          );
          dispatch(setUserCompanies(result.companies));
          return UserTypes.getOneById(user.user_type_id);
        })
        .then((userType: UserType) => {
          dispatch(setUserType(userType));
        });
    }
  };

  const uploadCompanyLogo = (newCompanyId: string): void => {
    if (logo) {
      Companies.uploadCompanyLogo(newCompanyId, logo)
        .then((result) => {
          console.info(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="accounts"
          goBackLabel="Accounts"
          isCreatePage={true}
          model="company"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-account-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new Account
                </Typography>
              </div>

              <AccountForm
                companyInput={companyInput || undefined}
                uploadedLogo={logo}
                onInputChange={handleInputChange}
                onCheckboxChange={handleCheckboxChange}
                setUploadedLogo={setLogo}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="account"
          submitting={submitting}
          companyId={newCompany.company_id}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateDevicePage;
