import {
  Device,
  Devices,
  DevicesFilters,
  Pagination,
} from '@edgeiq/edgeiq-api-js';
import { useState } from 'react';
import { defaultPaginationFilter } from '../app/constants';

export const useFetchDevicesList = (
  cb: (errorMessage: string) => void,
): [
  Device[],
  Pagination | undefined,
  (filters: DevicesFilters, nextPage?: boolean) => Promise<void>,
] => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [pagination, setPagination] = useState<Pagination>();

  const fetchDeviceList = async (
    filters: DevicesFilters,
    nextPage?: boolean,
  ): Promise<void> => {
    const paginationFilter =
      nextPage && pagination
        ? { ...pagination, page: ++pagination.page }
        : defaultPaginationFilter;
    try {
      const deviceList = await Devices.list(filters, paginationFilter);
      setDevices((prev) =>
        nextPage ? [...prev, ...deviceList.devices] : deviceList.devices,
      );
      setPagination(deviceList.pagination);
    } catch (error) {
      const { message: errorMessage } = error as { message: string };
      cb(errorMessage);
    }
  };

  return [devices, pagination, fetchDeviceList] as [
    Device[],
    Pagination | undefined,
    (filters: DevicesFilters) => Promise<void>,
  ];
};
