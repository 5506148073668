import React, { ReactElement, useEffect, useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setFilters } from '../../../redux/reducers/filters.reducer';
import { heartbeatStatus, heartBeatStatusLabel } from '../../../app/constants';
import Filters from '../../../containers/Filters';
import AccountFilter from '../../../containers/AccountSelect/AccountFilter';
import useStyles from '../styles';

interface DiscoveredDevicesFiltersProps {
  total: number;
}

const DiscoveredDevicesFilters: React.FC<DiscoveredDevicesFiltersProps> = ({
  total,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const discoveredFilters = stateFilters.discoveredDevices.filters;

  const [heartbeatSelected, setHeartbeatSelected] = useState<string[]>([]);

  const allHeartbeatSelected =
    heartbeatStatus.length > 0 &&
    heartbeatSelected.length === heartbeatStatus.length;

  useEffect(() => {
    if (discoveredFilters) {
      setHeartbeatSelected(
        discoveredFilters.heartbeat_status &&
          discoveredFilters.heartbeat_status !== ''
          ? discoveredFilters.heartbeat_status.split('|')
          : [],
      );
    }
  }, [discoveredFilters]);

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...discoveredFilters,
          [prop]: value,
        },
        'discoveredDevices',
      ),
    );
  };

  const handleMultiSelect =
    (prop: string) =>
    (event: SelectChangeEvent<string[]>): void => {
      const {
        target: { value },
      } = event;

      if (value.includes('all')) {
        changeFilters(
          prop,
          heartbeatStatus.reduce((preVal, current, i): string => {
            return i === 0 ? current : `${preVal}|${current}`;
          }, ''),
        );
        return;
      }

      changeFilters(
        prop,
        typeof value === 'string'
          ? value.replaceAll(',', '|')
          : value.length > 0
          ? value.join('|')
          : '',
      );
    };

  return (
    <Filters
      filterModel="discoveredDevices"
      total={total}
      hasSearchInput={true}
      inputPlaceholder="Search discovered devices by Unique ID"
      inputKey="unique_id"
      firstFilter={
        <AccountFilter
          accountsSelected={discoveredFilters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          className={classes.filterInput}
          multiple
          value={heartbeatSelected}
          onChange={handleMultiSelect('heartbeat_status')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | ReactElement => {
            if (selected.length === 0) {
              return <Typography variant="button">Heartbeat</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Heartbeat selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox checked={allHeartbeatSelected} />
            <ListItemText primary="All" />
          </MenuItem>
          {heartbeatStatus.map((item, key) => (
            <MenuItem dense key={key} value={item}>
              <Checkbox checked={heartbeatSelected.indexOf(item) > -1} />
              <ListItemText primary={heartBeatStatusLabel[item]} />
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default DiscoveredDevicesFilters;
