import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { IngestorInput, Translator } from '@edgeiq/edgeiq-api-js';
import SelectInput from '../../../components/SelectInput';

interface NewIngestorTranslatorConfigProps {
  newIngestor: IngestorInput;
  translatorOptions: Translator[];
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  setEnableSubmit?: (args: boolean) => void;
}

const NewIngestorTranslatorConfig: React.FC<
  NewIngestorTranslatorConfigProps
> = ({ newIngestor, onInputChange, translatorOptions }) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} className="mt-6">
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due the ingestor type compatibility.
        </Typography>
        <SelectInput
          prop="translator_id"
          value={newIngestor.translator_id}
          onSelectChange={onInputChange}
          options={translatorOptions.map((translator, index) => (
            <MenuItem
              className="m-4 p-2"
              key={index}
              dense
              value={translator._id}
            >
              {translator.name}
            </MenuItem>
          ))}
        />
      </Grid>
    </Grid>
  );
};

export default NewIngestorTranslatorConfig;
