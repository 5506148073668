import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

interface StyleProps {
  hideSelectAll: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    card: {
      '&:hover': {
        '& .checkboxContainer': {
          display: 'block',
        },
      },
      backgroundColor: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey.A200}`,
      display: 'flex',
      position: 'relative',
    },
    checkboxContainer: {
      '&.checked': {
        display: 'block',
      },
      display: 'none',
    },
    checkedCard: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    content: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
      maxHeight: (props: StyleProps): string =>
        `calc(100vh - ${props.hideSelectAll ? '230' : '290'}px)`,
      minHeight: '200px',
      overflow: 'auto',
    },
    searchInput: {
      paddingRight: '10px !important',
    },
  }),
);

export default useStyles;
