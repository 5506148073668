import React from 'react';
import { ClassNameMap, Typography } from '@mui/material';

import { useFetchCompany } from '../../hooks/useFetchCompany';

interface DeviceCardProps {
  account_id: string;
  classes?: ClassNameMap;
}

const AccountCell: React.FC<DeviceCardProps> = ({ account_id, classes }) => {
  const [deviceCompany] = useFetchCompany(account_id, (message: string) =>
    console.info(message),
  );

  return (
    <Typography
      noWrap
      variant="overline"
      component="div"
      className={classes?.tag}
    >
      {deviceCompany?.name ?? account_id}
    </Typography>
  );
};

export default AccountCell;
