import React, { useEffect, useState } from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import {
  Command,
  Commands,
  DeviceType,
  DeviceTypes,
  SoftwareUpdate,
  SoftwareUpdateInput,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setOptionsDeviceTypes } from '../../../redux/reducers/deviceTypes.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import TextInput from '../../../components/TextInput';
import SwitchButton from '../../../components/SwitchButton';
import SelectInput from '../../../components/SelectInput';
import DropZone from '../../../components/DropZone';
import {
  errorHighlight,
  optionsPaginationsFilter,
  softwareUpdateType,
} from '../../../app/constants';

interface SoftwarePackageFormProps {
  softwareUpdate:
    | (SoftwareUpdate & { type?: string; url?: string })
    | (SoftwareUpdateInput & { type?: string; url?: string });
  onInputChange: (prop: string, value: string | number | boolean) => void;
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const SoftwarePackageForm: React.FC<SoftwarePackageFormProps> = ({
  onInputChange,
  softwareUpdate,
  setUploadedFiles,
  uploadedFiles,
}) => {
  const dispatch = useAppDispatch();
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );
  const [commandList, setCommandList] = useState<Command[]>([]);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    if (devicesTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
    getCommands();
  }, []);

  const getCommands = (): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 1000,
      order_by: 'name',
      page: 1,
    };

    Commands.list({}, pagination)
      .then((result) => {
        setCommandList(result.commands);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const onAddFile = (file: File): void => {
    setUploadedFiles((prev) => [...prev, file]);
  };

  const onRemoveFile = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const targetFileName = e.currentTarget.id;
    setUploadedFiles((prev) =>
      prev.filter((file) => file.name !== targetFileName),
    );
  };

  return (
    <Box>
      {/* Row 1 */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6} className="mt-6">
          <TextInput
            label="Package Name"
            value={softwareUpdate.name}
            prop="name"
            onInputChange={onInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-6">
          <SelectInput
            label="Device profile"
            defaultValue=""
            value={
              devicesTypes.length === 0
                ? ''
                : softwareUpdate?.device_type_id ?? ''
            }
            onSelectChange={onInputChange}
            prop="device_type_id"
            options={[
              <MenuItem dense value="" key="">
                Select a device profile
              </MenuItem>,
              ...devicesTypes.map((deviceType, index) => (
                <MenuItem dense key={index} value={deviceType._id}>
                  {deviceType.name}
                </MenuItem>
              )),
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="mt-2">
          <SelectInput
            label="Software update type"
            value={softwareUpdate?.type}
            options={Object.keys(softwareUpdateType).map((type) => (
              <MenuItem dense key={type} value={type}>
                {softwareUpdateType[type]}
              </MenuItem>
            ))}
            prop="type"
            onSelectChange={onInputChange}
          />
        </Grid>

        {softwareUpdate?.type !== 'bash_script' && (
          <Grid item xs={6} className="mt-2">
            <TextInput
              label="Package URL"
              value={softwareUpdate.url}
              prop="url"
              onInputChange={onInputChange}
            />
          </Grid>
        )}

        {softwareUpdate?.type === 'bash_script' && (
          <>
            <Grid item xs={12} className="mt-2">
              <SwitchButton
                label="Reboot after installation"
                value={softwareUpdate.reboot}
                prop="reboot"
                onSwitchChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-2">
              <SwitchButton
                label="Enforce secure file download during software update (requires edge >= v3.1.1)"
                value={softwareUpdate.enforce_secure_download}
                prop="enforce_secure_download"
                onSwitchChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} className="mt-2">
              <Typography variant="h5" className="mb-4">
                Files
              </Typography>
              <DropZone
                title="Upload file"
                stateFiles={uploadedFiles}
                onFileUpload={onAddFile}
                onRemoveFile={onRemoveFile}
              />
            </Grid>

            <Grid item xs={12} className="mt-2">
              <TextInput
                label="Script"
                value={softwareUpdate.script}
                prop="script"
                onInputChange={onInputChange}
              />
            </Grid>
          </>
        )}
        {softwareUpdate?.type === 'command_delivered' && (
          <Grid item xs={12} className="mt-2">
            <SelectInput
              label="User-Defined Command"
              value={softwareUpdate?.command_id ?? ''}
              prop="command_id"
              onSelectChange={onInputChange}
              options={[
                <MenuItem dense value="">
                  Select a command
                </MenuItem>,
                ...commandList.map((command, index) => (
                  <MenuItem
                    key={index}
                    className="m-4 p-2"
                    dense
                    value={command._id}
                  >
                    {command.name}
                  </MenuItem>
                )),
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SoftwarePackageForm;
