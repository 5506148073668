import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import {
  Configurations,
  ConfigurationInput,
  Command,
  Device,
  DeviceType,
  Devices,
  DeviceTypes,
  Settings,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  newConfiguration,
  setNewConfigurationExtra,
  setNewConfigurationInput,
} from '../../redux/reducers/configuration.reducer';
import {
  SettingsEditorData,
  initialSettingsEditorData,
} from '../../models/settings';
import { LocationState } from '../../models/common';
import {
  dispatchError,
  getAttachError,
  getCreatingError,
  getCreatingSuccess,
} from '../../helpers/utils';
import WizardStepper from '../../components/WizardStepper';
import Publish from '../../containers/Publish';
import ConfigurationForm from '../../containers/Forms/ConfigurationForm';
import Header from '../../containers/HeaderWithActionButton';
import ConfigurationCommands from '../../containers/Forms/ConfigurationForm/ConfigurationCommands';
import EntitiesSection from '../../containers/EntitiesSection';

const CreateConfiguration: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorDispatcher = dispatchError(dispatch);
  const { state: locationState } = useLocation();
  const goBackLabel = (locationState as LocationState)?.goBackLabel;
  const goBackUrl = (locationState as LocationState)?.goBackUrl;
  const configurationInput = useAppSelector(
    (state: RootState) => state.configurations.newConfigurationInput,
  );
  const settingsEditorData = useAppSelector(
    (state: RootState) => state.configurations.newConfigurationExtra,
  );
  const [sendCommands, setSendCommands] = useState<Command[]>([]);
  const [fetchCommand, setFetchCommand] = useState<Command>();
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [submitting, setSubmitting] = useState(false);
  const [attachingCommands, setAttachingCommands] = useState(false);
  const [attachingDevices, setAttachingDevices] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      configurationInput?.name !== '' &&
        configurationInput?.unique_identifier !== '' &&
        configurationInput?.company_id !== '',
    );
  };

  useEffect(() => {
    checkSubmitEnable();
    if (
      configurationInput?.name !== '' &&
      configurationInput?.unique_identifier !== ''
    ) {
      setCompletedSteps([true, completedSteps[1], completedSteps[1]]);
    } else {
      setCompletedSteps([false, completedSteps[1], completedSteps[1]]);
    }
  }, [configurationInput]);

  useEffect(() => {
    if (sendCommands.length !== 0 && fetchCommand) {
      setCompletedSteps([completedSteps[0], true, completedSteps[1]]);
    }
  }, [sendCommands, fetchCommand]);

  const saveCommands = (configId: string): void => {
    const commandsData: { id: string; usage: string }[] = [];
    if (sendCommands.length !== 0) {
      sendCommands.forEach((command) => {
        commandsData.push({ id: command._id, usage: 'send' });
      });
    }
    if (fetchCommand) {
      commandsData.push({ id: fetchCommand._id, usage: 'fetch' });
    }
    if (commandsData.length !== 0) {
      setAttachingCommands(true);
      Promise.all(
        commandsData.map(async (command) => {
          await Configurations.addCommand(configId, command.id, command.usage);
        }),
      )
        .then(() => {
          console.info('Commands attached');
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('commands', 'configuration'),
          );
        })
        .finally(() => {
          setAttachingCommands(false);
        });
    }
  };

  const resetValues = (): void => {
    dispatch(
      setNewConfigurationInput({
        ...newConfiguration,
      }),
    );
    dispatch(
      setNewConfigurationExtra({
        ...initialSettingsEditorData,
      }),
    );
  };

  const saveDevicesAndDeviceTypes = (id: string, isConfig = true): void => {
    if (selectedDevices.length !== 0 || selectedDeviceTypes.length !== 0) {
      setAttachingDevices(true);
      Promise.all([
        Promise.all(
          selectedDevices.map(async (device) => {
            if (!isConfig) {
              await Devices.attachSettings(device._id, id);
            }
          }),
        ),
        Promise.all(
          selectedDeviceTypes.map(async (deviceType) => {
            if (isConfig) {
              await DeviceTypes.attachConfiguration(deviceType._id, id);
            } else {
              await DeviceTypes.attachSettings(deviceType._id, id);
            }
          }),
        ),
      ])
        .then(() => {
          console.info('Devices and Device profiles attached');
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('devices and device profiles', 'configuration'),
          );
        })
        .finally(() => {
          setAttachingDevices(false);
        });
    }
  };

  const createFirstSettings = (configId: string): void => {
    Settings.create({
      company_id: configurationInput?.company_id ?? '',
      configuration_id: configId,
      name: `${configurationInput?.name} - settings`,
      values: settingsEditorData.jsonTree,
    })
      .then((result) => {
        saveDevicesAndDeviceTypes(result._id, false);
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          getCreatingError('default settings'),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    Configurations.create(configurationInput as ConfigurationInput)
      .then((result) => {
        dispatch(
          setAlert({
            highlight: getCreatingSuccess('Configuration'),
            type: 'success',
          }),
        );
        saveCommands(result._id);
        saveDevicesAndDeviceTypes(result._id);
        createFirstSettings(result._id);
        resetValues();
        navigate(`/configuration/${result._id}`);
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          getCreatingError('Configuration'),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleChange = (prop: string, value: string | number): void => {
    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        [prop]: value,
      } as ConfigurationInput),
    );
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
  };

  const handleChangeSendCommands = (commands: Command[]): void => {
    setSendCommands(commands);
  };

  const handleChangeFetchCommand = (command: Command | undefined): void => {
    setFetchCommand(command);
  };

  const handleChangeDevices = (devices: Device[]): void => {
    setSelectedDevices(devices);
  };

  const handleChangeDeviceTypes = (newDeviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes(newDeviceTypes);
  };

  const handleExtraDataChange = (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ): void => {
    dispatch(
      setNewConfigurationExtra({
        ...settingsEditorData,
        [prop]: value,
      } as SettingsEditorData),
    );
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9 pt-6">
      <Grid item xs={12}>
        <Header
          model="configuration"
          isCreatePage={true}
          goBack={goBackUrl ? goBackUrl : 'configurations'}
          goBackLabel={goBackLabel || `Configurations`}
        />
      </Grid>
      <Grid item xs={8}>
        <Paper className="shadow p-8">
          <WizardStepper
            steps={[
              {
                completed: completedSteps[0],
                content: (
                  <ConfigurationForm
                    action="create"
                    addPaper={false}
                    configuration={
                      (configurationInput as ConfigurationInput) || undefined
                    }
                    settingsEditorData={settingsEditorData}
                    onInputChange={handleChange}
                    onExtraConfigDataChange={handleExtraDataChange}
                  />
                ),
                label: 'Create Configuration',
                optional: false,
              },
              {
                completed: completedSteps[1],
                content: (
                  <Grid container className="mt-6">
                    <Grid item xs={12}>
                      <Typography
                        className="mb-6"
                        data-cy="assign-devices-title"
                        variant="h6"
                      >
                        Devices and Device Profiles
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <EntitiesSection
                        newInput={configurationInput}
                        selectedDevices={selectedDevices}
                        selectedDeviceTypes={selectedDeviceTypes}
                        onChangeDevices={handleChangeDevices}
                        onChangeDeviceTypes={handleChangeDeviceTypes}
                      />
                    </Grid>
                  </Grid>
                ),
                label: 'Assign Devices & Device Profiles',
                optional: true,
              },
              {
                completed: completedSteps[2],
                content: (
                  <ConfigurationCommands
                    addPaper={false}
                    sendCommands={sendCommands}
                    fetchCommand={fetchCommand}
                    onChangeFetchCommand={handleChangeFetchCommand}
                    onChangeSendCommands={handleChangeSendCommands}
                    selectedDevices={selectedDevices}
                    selectedDeviceTypes={selectedDeviceTypes}
                    settingsEditorData={settingsEditorData}
                  />
                ),
                label: 'Assign User Defined Commands',
                optional: true,
              },
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="account"
          submitting={submitting || attachingCommands || attachingDevices}
          companyId={configurationInput?.company_id as string}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateConfiguration;
