import { DeviceConfig, DeviceConfigInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getDevieConfigConnectionSelector = createSelector(
  (state: ConfigsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.configs.length) {
      return state.actualDeviceConfigConnection;
    }
    return state.configs.find((config: DeviceConfig) => config._id === id);
  },
);

const newDeviceConfigConnection = {
  connections: [],
  device_type_id: '',
  name: '',
};

export const ConfigsTypes = {
  CLEAN_NEW_DEVICE_CONFIG_CONNECTION_INPUT:
    'CLEAN_NEW_DEVICE_CONFIG_CONNECTION_INPUT',
  SET_ACTUAL_DEVICE_CONFIG_CONNECTION: 'SET_ACTUAL_DEVICE_CONFIG_CONNECTION',
  SET_CONFIGS: 'SET_CONFIGS',
  SET_NEW_DEVICE_CONFIG_CONNECTION: 'SET_NEW_DEVICE_CONFIG_CONNECTION',
  SET_NEW_DEVICE_CONFIG_CONNECTION_INPUT:
    'SET_NEW_DEVICE_CONFIG_CONNECTION_INPUT',
};

export const cleanNewDeviceConfig = (): ConfigsAction => ({
  data: newDeviceConfigConnection as DeviceConfigInput,
  type: ConfigsTypes.CLEAN_NEW_DEVICE_CONFIG_CONNECTION_INPUT,
});

export const setStateConfigs = (configs: DeviceConfig[]): ConfigsAction => ({
  data: configs,
  type: ConfigsTypes.SET_CONFIGS,
});

export const setActualDevieConfigConnection = (
  config: DeviceConfig,
): ConfigsAction => ({
  data: config,
  type: ConfigsTypes.SET_ACTUAL_DEVICE_CONFIG_CONNECTION,
});

export const setNewDeviceConfigConnection = (
  config: DeviceConfig,
): ConfigsAction => ({
  data: config,
  type: ConfigsTypes.SET_NEW_DEVICE_CONFIG_CONNECTION,
});

export const setNewDeviceConfigConnectionInput = (
  config: DeviceConfigInput,
): ConfigsAction => ({
  data: config,
  type: ConfigsTypes.SET_NEW_DEVICE_CONFIG_CONNECTION_INPUT,
});

export type ConfigsState = {
  actualDeviceConfigConnection: DeviceConfig | null;
  configs: DeviceConfig[];
  newDeviceConfigConnection: DeviceConfig | null;
  newDeviceConfigConnectionInput: DeviceConfigInput | null;
};

// INITIAL STATE
const configsInitialState: ConfigsState = {
  actualDeviceConfigConnection: null,
  configs: [],
  newDeviceConfigConnection: null,
  newDeviceConfigConnectionInput:
    newDeviceConfigConnection as DeviceConfigInput,
};

export type ConfigsAction = {
  data: DeviceConfig[] | DeviceConfig | DeviceConfigInput | string;
  type: string;
};

const configsReducer = (
  state = configsInitialState,
  action: ConfigsAction,
): ConfigsState => {
  switch (action.type) {
    case ConfigsTypes.SET_CONFIGS:
      return {
        ...state,
        configs: action.data as DeviceConfig[],
      };

    case ConfigsTypes.SET_ACTUAL_DEVICE_CONFIG_CONNECTION:
      return {
        ...state,
        actualDeviceConfigConnection: action.data as DeviceConfig,
        newDeviceConfigConnection: action.data as DeviceConfig,
      };

    case ConfigsTypes.SET_NEW_DEVICE_CONFIG_CONNECTION:
      return {
        ...state,
        newDeviceConfigConnection: action.data as DeviceConfig,
      };

    case ConfigsTypes.SET_NEW_DEVICE_CONFIG_CONNECTION_INPUT:
      return {
        ...state,
        newDeviceConfigConnectionInput: action.data as DeviceConfigInput,
      };

    case ConfigsTypes.CLEAN_NEW_DEVICE_CONFIG_CONNECTION_INPUT:
      return {
        ...state,
        newDeviceConfigConnectionInput: action.data as DeviceConfigInput,
      };

    default:
      return state;
  }
};

export default configsReducer;
