import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    configSubtitle: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
    },
    configTitle: {
      color: theme.palette.grey[100],
    },
    title: {
      fontWeight: 700,
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
