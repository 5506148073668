import React, { useEffect, useState } from 'react';
import { Device, DeviceType, Devices } from '@edgeiq/edgeiq-api-js';
import {
  Button,
  CircularProgress,
  // CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { allowActions } from '../../../helpers/permissions';
import { getToken } from '../../../helpers/storage';
import { errorHighlight } from '../../../app/constants';
import useStyles from '../styles';
import './terminal_styles.css';

const MIN_VERSION = 3;

interface DeviceRemoteTerminalProps {
  deviceType: DeviceType;
}

const DeviceRemoteTerminal: React.FC<DeviceRemoteTerminalProps> = ({
  deviceType,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [starting, setStarting] = useState(true);
  const [stopping, setStopping] = useState(true);

  const stateDevice = useAppSelector(
    (state: RootState) => state.devices.device,
  );
  const userType = useAppSelector((state: RootState) => state.user.userType);
  const userCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const [device, setDevice] = useState<Device>(stateDevice as Device);
  const [hasPermission, setHasPermission] = useState(false);
  const [versionEligible, setVersionEligible] = useState(false);
  const [deviceTypeAbility, setDeviceTypeAbility] = useState(false);

  const checkDeviceVersion = (): void => {
    if (!device.edge_version) {
      setVersionEligible(false);
      return;
    }
    // version examples: "3.0.0", "3.1.23_abcdef", "5.4.32", "2.9.30","2.9.30_edb3ad"
    const version = device.edge_version.split('.');
    // in go, a versions usually have a trailing 'v', i.e. "v3.0.0" or "v2.9.30" (see golang.org/x/mod/semver)
    // remove trailing "v" if present
    version[0] = version[0].replace(/^(v)/, '');
    const mainVersion = parseInt(version[0]);
    if (!mainVersion) {
      setVersionEligible(false);
    }
    if (mainVersion >= MIN_VERSION) {
      setVersionEligible(true);
      return;
    }
    setVersionEligible(false);
  };

  const stopLoading = (): void => {
    setStarting(false);
    setStopping(false);
  };

  useEffect(() => {
    setDevice(stateDevice as Device);
    if (userType && userCompany && device) {
      setHasPermission(
        allowActions(
          userType.abilities,
          ['remote_terminal'],
          'device',
          userCompany?._id,
          device._id,
        ),
      );
      if (deviceType) {
        setDeviceTypeAbility(
          deviceType.capabilities?.actions?.remote_terminal || false,
        );
      }
      checkDeviceVersion();
      stopLoading();
    }
  }, [stateDevice]);

  const startRemoteTerminal = (): void => {
    setStarting(true);
    Devices.startRemoteTerminal(device._id)
      .then()
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        stopLoading();
      });
  };

  const stopRemoteTerminal = (): void => {
    setStopping(true);
    Devices.stopRemoteTerminal(device._id)
      .then()
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <Grid container>
      {hasPermission && versionEligible && deviceTypeAbility && (
        <Grid component={Paper} item xs={12} className={clsx('p-7 shadow')}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                data-cy="start-remote-terminal-button"
                color="primary"
                variant="contained"
                size="large"
                type="button"
                className={clsx('mb-4 mr-4', classes.terminalButton)}
                disabled={starting || stopping}
                startIcon={<PlayArrowIcon fontSize="small" />}
                onClick={startRemoteTerminal}
              >
                {!starting ? (
                  <Typography variant="button">
                    Start Remote Terminal
                  </Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
              <Button
                data-cy="stop-remote-terminal-button"
                color="primary"
                variant="contained"
                type="button"
                size="large"
                className={clsx('mb-4', classes.terminalButton)}
                disabled={starting || stopping}
                startIcon={<StopIcon fontSize="small" />}
                onClick={stopRemoteTerminal}
              >
                {!stopping ? (
                  <Typography variant="button">Stop Remote Terminal</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <iframe
                className="w-100 scrollbar"
                src={`${process.env.REACT_APP_API_URL}/devices/${
                  device._id
                }/terminalwindow?Authorization=${getToken()}`}
                style={{
                  border: 'none',
                  height: `calc(100vh - 400px)`,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {(!hasPermission || !versionEligible || !deviceTypeAbility) && (
        <Grid component={Paper} item xs={12} className={clsx('p-7 shadow')}>
          {!hasPermission && (
            <Typography variant="h6" className="mb-4">
              You don't have permissions to connect via remote terminal to this
              device.
            </Typography>
          )}
          {!versionEligible && (
            <Typography variant="h6" className="mb-4">
              Remote Terminal is only available for the edge client version
              3.0.0 and above, and this device's version is{' '}
              {device.edge_version ? device.edge_version : 'unknown'}.
            </Typography>
          )}
          {!deviceTypeAbility && (
            <Typography variant="h6">
              The device profile does not have the ability 'Remote Terminal'
              enabled.
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceRemoteTerminal;
