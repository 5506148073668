import { TRANFERS_RECEIVED } from '../../app/constants';
import { DeviceTransferRequest } from '@edgeiq/edgeiq-api-js';

import { createSelector } from 'reselect';

export const getDeviceTransferSelector = createSelector(
  (state: DeviceTransferState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.deviceTransfers.length) {
      return state.deviceTransfer;
    }
    return state.deviceTransfers.find(
      (deviceTransfer: DeviceTransferRequest) => deviceTransfer._id === id,
    );
  },
);

export const DeviceTransfer = {
  SET_DEVICE_TRANSFERS: 'SET_DEVICE_TRANSFERS',
  SET_DEVICE_TRANSFERS_TYPE: 'SET_DEVICE_TRANSFERS_TYPE',
  SET_SELECTED_DEVICE_TRANSFER: 'SET_SELECTED_DEVICE_TRANSFER',
};

export type DeviceTransferState = {
  deviceTransfer: DeviceTransferRequest | null;
  deviceTransfers: DeviceTransferRequest[];
  deviceTransferType: string;
};

export const setDeviceTransferType = (
  deviceType: string,
): DeviceTransferRequestAction => ({
  data: deviceType,
  type: DeviceTransfer.SET_DEVICE_TRANSFERS_TYPE,
});

export const setDeviceTransfers = (
  deviceTransfers: DeviceTransferRequest[],
): DeviceTransferRequestAction => ({
  data: deviceTransfers,
  type: DeviceTransfer.SET_DEVICE_TRANSFERS,
});

export const setSelectedDeviceTransfer = (
  deviceTransfer: DeviceTransferRequest,
): DeviceTransferRequestAction => ({
  data: deviceTransfer,
  type: DeviceTransfer.SET_SELECTED_DEVICE_TRANSFER,
});

// INITIAL STATE
const deviceTransferInitialState: DeviceTransferState = {
  deviceTransfer: null,
  deviceTransfers: [],
  deviceTransferType: TRANFERS_RECEIVED,
};

export type DeviceTransferRequestAction = {
  data: DeviceTransferRequest[] | DeviceTransferRequest | string;
  type: string;
};

const deviceTransferReducer = (
  state = deviceTransferInitialState,
  action: DeviceTransferRequestAction,
): DeviceTransferState => {
  switch (action.type) {
    case DeviceTransfer.SET_DEVICE_TRANSFERS:
      return {
        ...state,
        deviceTransfers: action.data as DeviceTransferRequest[],
      };

    case DeviceTransfer.SET_SELECTED_DEVICE_TRANSFER:
      return {
        ...state,
        deviceTransfer: action.data as DeviceTransferRequest,
      };

    case DeviceTransfer.SET_SELECTED_DEVICE_TRANSFER:
      return {
        ...state,
        deviceTransfer: action.data as DeviceTransferRequest,
      };

    case DeviceTransfer.SET_DEVICE_TRANSFERS_TYPE:
      return {
        ...state,
        deviceTransferType: action.data as string,
      };
    default:
      return state;
  }
};

export default deviceTransferReducer;
