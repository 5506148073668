import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import PasswordInput from '../../../components/PasswordInput';

interface PostmarkIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  hideEmptySensitiveFields?: boolean;
}

const PostmarkIntegrationSection: React.FC<PostmarkIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
  hideEmptySensitiveFields,
}) => {
  return (
    <>
      {(!hideEmptySensitiveFields || newIntegration.token) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="Token"
            prop="token"
            value={newIntegration.token}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
    </>
  );
};

export default PostmarkIntegrationSection;
