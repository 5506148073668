import {
  PollableAttribute,
  PollableAttributeInput,
} from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getPollableSelector = createSelector(
  (state: PollableAttributesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.pollableAttributes.length) {
      return state.pollableAttribute;
    }
    return state.pollableAttributes.find(
      (pollable: PollableAttribute) => pollable._id === id,
    );
  },
);

export const PollableAttributesTypes = {
  CLEAR_NEW_POLLABLE_ATTRIBUTE: 'CLEAR_NEW_POLLABLE_ATTRIBUTE',
  SET_ACTUAL_POLLABLE_ATTRIBUTE: 'SET_ACTUAL_POLLABLE_ATTRIBUTE',
  SET_NEW_POLLABLE_ATTRIBUTE: 'SET_NEW_POLLABLE_ATTRIBUTE',
  SET_NEW_POLLABLE_ATTRIBUTES_INPUT: 'SET_NEW_POLLABLE_ATTRIBUTES_INPUT',
  SET_ORIGINAL_POLLABLE_ATTRIBUTES: 'SET_ORIGINAL_POLLABLE_ATTRIBUTES',
  SET_POLLABLE_ATTRIBUTES: 'SET_POLLABLE_ATTRIBUTES',
  SET_SELECTED_POLLABLE_ATTRIBUTES: 'SET_SELECTED_POLLABLE_ATTRIBUTES',
};

const newPollableAttributeInput = {
  attributes: [],
  company_id: '',
  defaults: {},
  interval: 16,
  mib: {
    identity: '',
  },
  name: '',
  type: 'snmp_mib',
};

export const setStatePollableAttributes = (
  pollableAttributes: PollableAttribute[],
): PollableAttributesAction => ({
  data: pollableAttributes,
  type: PollableAttributesTypes.SET_POLLABLE_ATTRIBUTES,
});

export const setActualPollableAttribute = (
  pollableAttribute: PollableAttribute,
): PollableAttributesAction => ({
  data: pollableAttribute,
  type: PollableAttributesTypes.SET_ACTUAL_POLLABLE_ATTRIBUTE,
});

export const setSelectedPollableAttributes = (
  pollableAttributes: PollableAttribute[],
): PollableAttributesAction => ({
  data: pollableAttributes,
  type: PollableAttributesTypes.SET_SELECTED_POLLABLE_ATTRIBUTES,
});

export const setOriginalPollableAttributes = (
  pollableAttributes: PollableAttribute[],
): PollableAttributesAction => ({
  data: pollableAttributes,
  type: PollableAttributesTypes.SET_ORIGINAL_POLLABLE_ATTRIBUTES,
});

export const setNewPollableAttributesInput = (
  pollableAttributesInput: PollableAttributeInput,
): PollableAttributesAction => ({
  data: pollableAttributesInput,
  type: PollableAttributesTypes.SET_NEW_POLLABLE_ATTRIBUTES_INPUT,
});

export const setNewPollableAttribute = (
  pollableAttribute: PollableAttribute,
): PollableAttributesAction => ({
  data: pollableAttribute,
  type: PollableAttributesTypes.SET_NEW_POLLABLE_ATTRIBUTE,
});

export const cleanNewPollableAttributes = (): PollableAttributesAction => ({
  data: newPollableAttributeInput as PollableAttributeInput,
  type: PollableAttributesTypes.CLEAR_NEW_POLLABLE_ATTRIBUTE,
});

export type PollableAttributesState = {
  newPollableAttribute: PollableAttribute | null;
  newPollableAttributeInput: PollableAttributeInput | null;
  originalPollableAttributes: PollableAttribute[];
  pollableAttribute: PollableAttribute | null;
  pollableAttributes: PollableAttribute[];
  selectedPollableAttributes: PollableAttribute[];
};

// INITIAL STATE
const pollableAttributesInitialState: PollableAttributesState = {
  newPollableAttribute: null,
  newPollableAttributeInput:
    newPollableAttributeInput as PollableAttributeInput,
  originalPollableAttributes: [],
  pollableAttribute: null,
  pollableAttributes: [],
  selectedPollableAttributes: [],
};

export type PollableAttributesAction = {
  data: PollableAttribute[] | PollableAttribute | PollableAttributeInput;
  type: string;
};

const pollableAttributesReducer = (
  state = pollableAttributesInitialState,
  action: PollableAttributesAction,
): PollableAttributesState => {
  switch (action.type) {
    case PollableAttributesTypes.SET_POLLABLE_ATTRIBUTES:
      return {
        ...state,
        pollableAttributes: action.data as PollableAttribute[],
      };

    case PollableAttributesTypes.SET_ACTUAL_POLLABLE_ATTRIBUTE:
      return {
        ...state,
        newPollableAttribute: action.data as PollableAttribute,
        pollableAttribute: action.data as PollableAttribute,
      };

    case PollableAttributesTypes.SET_SELECTED_POLLABLE_ATTRIBUTES:
      return {
        ...state,
        selectedPollableAttributes: action.data as PollableAttribute[],
      };
    case PollableAttributesTypes.SET_NEW_POLLABLE_ATTRIBUTE:
      return {
        ...state,
        newPollableAttribute: action.data as PollableAttribute,
      };
    case PollableAttributesTypes.SET_NEW_POLLABLE_ATTRIBUTES_INPUT:
      return {
        ...state,
        newPollableAttributeInput: action.data as PollableAttributeInput,
      };

    case PollableAttributesTypes.CLEAR_NEW_POLLABLE_ATTRIBUTE:
      return {
        ...state,
        newPollableAttributeInput: action.data as PollableAttributeInput,
      };
    case PollableAttributesTypes.SET_ORIGINAL_POLLABLE_ATTRIBUTES:
      return {
        ...state,
        originalPollableAttributes: action.data as PollableAttribute[],
        selectedPollableAttributes: action.data as PollableAttribute[],
      };

    default:
      return state;
  }
};

export default pollableAttributesReducer;
