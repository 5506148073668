import React, { useState } from 'react';
import { Typography, Button, Tooltip, IconButton } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Device, DeviceInput, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { GATEWAY_ROLE } from '../../app/constants';
import { AttachEndpointsDrawer } from '../../containers/RightDrawer';
import useStyles from './styles';

interface DeviceRelationsProps {
  deviceType?: DeviceType;
  onChangeRelations: (prop: string, ids: string[] | string) => void;
}

const NewDeviceRelations: React.FC<DeviceRelationsProps> = ({
  deviceType,
  onChangeRelations,
}) => {
  const classes = useStyles();
  const device = useAppSelector(
    (state: RootState) => state.devices.deviceInput,
  );

  const [attachedDevices, setAttachedDevices] = useState<Device[]>([]);
  const [open, setOpen] = useState(false);

  const handleDevicesCallback = (devices: Device[]): void => {
    if (deviceType) {
      setAttachedDevices(devices);
      onChangeRelations(
        deviceType.role === GATEWAY_ROLE
          ? 'attached_device_ids'
          : 'parent_device_id',
        deviceType.role === GATEWAY_ROLE
          ? devices.map((item) => item._id)
          : devices[0]._id,
      );
      handleCloseDrawer();
    }
  };

  const handleOpenDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseDrawer = (): void => {
    setOpen(false);
  };

  const handleDetachDevice = (deviceId: string) => (): void => {
    const newAttachedDevices = device?.attached_device_ids?.filter(
      (id) => id !== deviceId,
    );
    setAttachedDevices(
      attachedDevices.filter(
        (attachedDevice) => attachedDevice._id !== deviceId,
      ),
    );
    if (deviceType) {
      onChangeRelations(
        deviceType.role === GATEWAY_ROLE
          ? 'attached_device_ids'
          : 'parent_device_id',
        deviceType.role === GATEWAY_ROLE
          ? newAttachedDevices ?? []
          : newAttachedDevices?.[0] || '',
      );
    }
  };

  const deviceLabel = deviceType?.role === GATEWAY_ROLE ? 'devices' : 'device';

  return !deviceType?.type ? (
    <Typography
      variant="button"
      component="div"
      className={clsx('mb-1', classes.configTitle)}
    >
      Select a device profile to see the available configs.
    </Typography>
  ) : (
    <>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-1', classes.configTitle)}
      >
        {deviceType.role === GATEWAY_ROLE
          ? 'Select devices'
          : 'Select Parent Device'}
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-4', classes.configSubtitle)}
      >
        Select gateway {deviceLabel} to attach to the device.
      </Typography>
      {attachedDevices.length === 0 ? (
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-4', classes.configSubtitle)}
        >
          No {deviceLabel} selected.
        </Typography>
      ) : (
        <div className={clsx('scrollbar', classes.policiesContainer)}>
          {attachedDevices.map((attachedDevice) => (
            <Typography
              noWrap
              key={attachedDevice._id}
              variant="button"
              component="div"
              className={clsx('py-2', classes.title, classes.policy)}
            >
              {`${attachedDevice.name}. ID: ${attachedDevice.unique_id}`}
              <Tooltip placement="top" title="Detach device">
                <IconButton
                  aria-label="detach-device"
                  onClick={handleDetachDevice(attachedDevice._id)}
                >
                  <LinkOffIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          ))}
        </div>
      )}
      <Button
        color="primary"
        size="large"
        variant="contained"
        type="button"
        onClick={handleOpenDrawer}
      >
        <Typography variant="button">Attach {deviceLabel}</Typography>
      </Button>
      <AttachEndpointsDrawer
        open={open}
        device={device as DeviceInput}
        handleCloseDrawer={handleCloseDrawer}
        onChoosingEndpoints={handleDevicesCallback}
        title={
          deviceType.role === GATEWAY_ROLE
            ? 'Select Endpoints'
            : 'Select Parent Device'
        }
        deviceType={deviceType}
      />
    </>
  );
};

export default NewDeviceRelations;
