import React, { useRef, useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';

import { StatusTheme } from '../../models/common';
import timeHelpers from '../../helpers/timeHelpers';
import ColoredBox from '../ColoredBox';
import UserAvatar from '../UserAvatar';
import useStyles from './styles';

interface RecordExecutionCardProps {
  actionBtnText: string;
  badgeColor?: string;
  badgeText: string;
  createdAt?: string;
  executedBy?: string;
  executionMessage?: string;
  isLast: boolean;
  handleActionBtnClick: () => void;
}

const RecordExecutionCard: React.FC<RecordExecutionCardProps> = ({
  actionBtnText,
  badgeColor = 'info',
  badgeText,
  createdAt,
  children,
  executedBy,
  executionMessage,
  isLast,
  handleActionBtnClick,
}) => {
  const classes = useStyles();
  const messageRef = useRef<HTMLDivElement>(null);
  // const [isLongMessage, setIsLongMessage] = useState(false);
  const [truncated, setTruncated] = useState(true);

  const handleClick = (): void => {
    handleActionBtnClick();
  };

  // TO DO: manage the truncated text better
  // useEffect(() => {
  //   if (messageRef.current?.offsetHeight && messageRef.current.scrollHeight) {
  //     setIsLongMessage(true);
  //   }
  // }, [messageRef]);

  // const isEllipsisActive = (): boolean => {
  //   if (messageRef.current?.offsetHeight && messageRef.current.scrollHeight) {
  //     return (messageRef.current.offsetHeight < messageRef.current.scrollHeight);
  //   }
  //   return false
  // }

  const _handleMessageOverflow = (): void => {
    setTruncated(!truncated);
  };

  return (
    <Box className={classes.container}>
      {badgeText && (
        <ColoredBox
          colorTheme={badgeColor as StatusTheme}
          type="command_execution_state"
          value={badgeText}
          className={classes.status}
        />
      )}
      {children}
      {createdAt && (
        <Typography variant="overline" component="div" className="my-2">
          {timeHelpers.getDate(createdAt, false, true)}
        </Typography>
      )}
      {executedBy && (
        <UserAvatar
          preAvatarText="Executed by:"
          preAvatarTextVariant="button"
          name={executedBy}
        />
      )}
      {executionMessage && (
        <div>
          <Typography
            variant="overline"
            component="div"
            fontFamily="monospace"
            ref={messageRef}
            className={clsx('my-2', classes.message)}
            // style={{
            //   WebkitLineClamp: truncated ? 1 : 5,
            //   // '-webkit-line-clamp': '1',
            // }}
          >
            {executionMessage}
          </Typography>
          {/* {isLongMessage && (
            <Typography
              component="div"
              variant="overline"
              fontFamily="monospace"
              onClick={handleMessageOverflow}
            >
              read {truncated ? 'more': 'less'}
            </Typography>
          )} */}
        </div>
      )}
      <Button
        color="primary"
        variant="text"
        type="button"
        onClick={handleClick}
        className="p-0"
      >
        <Typography variant="overline">{actionBtnText}</Typography>
      </Button>
      {!isLast && <Divider className="my-3" />}
    </Box>
  );
};

export default RecordExecutionCard;
