import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Device,
  Devices,
  DevicesFilters,
  DeviceInput,
  DeviceType,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  ENDPOINT_ROLE,
  errorHighlight,
  GATEWAY_ROLE,
} from '../../../app/constants';
import { usePageSetter } from '../../../hooks/usePageSetter';
import DeviceCard from '../../../pages/devices/activeDevices/ActiveDeviceCard';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';

interface AttachEndpointsDrawerProps {
  device: Device | DeviceInput;
  open: boolean;
  handleCloseDrawer: () => void;
  onChoosingEndpoints: (endpoints: Device[]) => void;
  title?: string;
  deviceType: DeviceType;
}

const AttachEndpointsDrawer: React.FC<AttachEndpointsDrawerProps> = ({
  device,
  open,
  handleCloseDrawer,
  onChoosingEndpoints,
  title,
  deviceType,
}) => {
  const dispatch = useAppDispatch();

  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [deviceTypesToFilterBy, setDeviceTypesToFilterBy] = useState<
    DeviceType[]
  >([]);
  const [devicesList, setDevicesList] = useState<Device[]>([]);
  const [deviceTypeRoleFilter, setDeviceTypeRoleFilter] = useState(
    deviceType.role === ENDPOINT_ROLE ? GATEWAY_ROLE : ENDPOINT_ROLE,
  );
  const [selectedDevices, setSelectedDevices] = useState<string[]>(
    deviceType.role === GATEWAY_ROLE
      ? device.attached_device_ids ?? []
      : device.parent_device_id
      ? [device.parent_device_id]
      : [],
  );
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { setTotalAndPage, total, page } = usePageSetter(0, 1);
  const [multiple, setMultiple] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  // This is just to use total for somrthing and avoid compiling error
  const [_totalDevice, _setTotalDevices] = useState(total);
  const [searchValue, setSearchValue] = useState('');
  const preEndpointAttachList = useRef<string>('');

  useEffect(() => {
    if (deviceType.role === GATEWAY_ROLE) {
      setIsDisabled(isEqual(device.attached_device_ids, selectedDevices));
    } else {
      setIsDisabled(device.parent_device_id === selectedDevices[0]);
    }
  }, [selectedDevices, device]);

  useEffect(() => {
    if (deviceType) {
      setMultiple(deviceType.role === GATEWAY_ROLE);
      setDeviceTypeRoleFilter(
        deviceType.role === ENDPOINT_ROLE ? GATEWAY_ROLE : ENDPOINT_ROLE,
      );
      setSelectedDevices(
        deviceType.role === GATEWAY_ROLE
          ? device.attached_device_ids ?? []
          : device.parent_device_id
          ? [device.parent_device_id]
          : [],
      );
    }
  }, [deviceType]);

  useEffect(() => {
    if (deviceTypesState.optionsDeviceTypes.length !== 0) {
      setDeviceTypesToFilterBy(
        deviceTypesState.optionsDeviceTypes.filter(
          (dt) => dt.role === deviceTypeRoleFilter,
        ),
      );
    }
  }, [deviceTypesState, deviceTypeRoleFilter]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getDevices(page + 1, true);
  };

  const getDevices = (
    pageNumber: number,
    addPage = false,
    searchName?: string,
  ): void => {
    if (!device) {
      return;
    }

    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      page: pageNumber,
    };

    if (!addPage) {
      setLoading(true);
    }

    const filters: DevicesFilters = {
      company_id: { operator: 'eq', value: device.company_id || '' },
    };

    if (deviceTypesToFilterBy.length !== 0) {
      filters.device_type_id = {
        operator: 'in',
        value: deviceTypesToFilterBy.map((dt) => dt._id),
      };
    }

    if (searchName) {
      setSearchValue(searchName);
      filters.name = {
        operator: 'like',
        value: searchName,
      };
    }

    Devices.list(filters, pagination)
      .then((result) => {
        // When we want to select endpoints, we have to avoid those that have other parent device
        // For new devices, we only want those who have no parent
        // For device content we want those with no parent and the those whose parent is the same gateway device
        // If we are filtering for Gateway devices then just show the result returned.
        const filtered =
          deviceTypeRoleFilter === ENDPOINT_ROLE
            ? !(device as Device)._id
              ? result.devices.filter((d) => !d.parent_device_id)
              : result.devices.filter(
                  (d) =>
                    !d.parent_device_id ||
                    d.parent_device_id === (device as Device)._id,
                )
            : result.devices;
        const devicesListArr = addPage
          ? [...devicesList, ...filtered]
          : filtered;
        setDevicesList(devicesListArr);
        // The load more allow to get more devices to filter, optimally this filter should be donde in the API but it doesn't support "or"
        // So after each request we check if there is still pages to get
        setShowLoadMore(result.pagination.page !== page);
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    if (deviceTypesToFilterBy.length !== 0) {
      getDevices(1);
    }
  }, [deviceTypesToFilterBy, device]);

  const handleOnChangeCallback = (value: string): void => {
    if (deviceTypesToFilterBy.length !== 0) {
      getDevices(1, false, value);
    }
  };

  const checkEndpointCallback =
    (endpointId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedDevices([...selectedDevices, endpointId]);
      } else {
        setSelectedDevices(
          selectedDevices.filter((item) => item !== endpointId),
        );
      }
    };

  const handleSelectActionCallback = (): void => {
    onChoosingEndpoints(
      devicesList.filter((d) => selectedDevices.includes(d._id)),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedDevices.length !== devicesList.length) {
      setSelectedDevices(devicesList.map((d) => d._id));
    } else {
      setSelectedDevices([]);
    }
  };

  useEffect(() => {
    preEndpointAttachList.current =
      selectedDevices.length > 0 ? String(selectedDevices.length) : '';
  }, [selectedDevices]);

  return (
    <RightDrawer
      open={open}
      actionLabel={
        !!preEndpointAttachList && selectedDevices.length === 0
          ? 'Dettach All'
          : 'Attach'
      }
      title={title || 'Select Endpoints'}
      size="800"
      disableAction={isDisabled}
      actionCallback={handleSelectActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          hideSelectAll={!multiple}
          allSelected={selectedDevices.length === devicesList.length}
          itemsSelected={selectedDevices.length !== 0}
          hasItems={devicesList.length !== 0}
          searchPlaceholder="Search devices"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <>
                <CardsGrid
                  twoColumns={true}
                  containerPadding={false}
                  cards={devicesList.map((optionDevice) => (
                    <AttachItemCard
                      hideCheckBox={
                        !multiple &&
                        selectedDevices.length >= 1 &&
                        !selectedDevices.includes(optionDevice._id)
                      }
                      content={
                        <DeviceCard
                          key={optionDevice._id}
                          device={optionDevice}
                          deviceType={deviceTypesToFilterBy.find(
                            (dt) => dt._id === optionDevice.device_type_id,
                          )}
                          searchText={searchValue}
                        />
                      }
                      checked={selectedDevices.includes(optionDevice._id)}
                      checkboxCallback={checkEndpointCallback}
                      id={optionDevice._id}
                    />
                  ))}
                />

                {showLoadMore && (
                  <Grid item xs={12} className="mt-6 loading-container">
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleLoadMore}
                    >
                      {!loadingMore ? (
                        <Typography variant="button">Load more</Typography>
                      ) : (
                        <CircularProgress size={25} />
                      )}
                    </Button>
                  </Grid>
                )}
              </>
            )
          }
        />
      }
    />
  );
};

export default AttachEndpointsDrawer;
