import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey.A100,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export default useStyles;
