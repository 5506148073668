import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipContainer: {
      backgroundColor: theme.palette.info.light,
    },
    chipIcon: {
      color: `${theme.palette.grey[100]} !important`,
    },
    chipsContainer: {
      display: 'flex',
      maxWidth: '70%',
      overflow: 'auto',
    },
    chipText: {
      color: theme.palette.grey[100],
    },
    container: {
      backgroundColor: theme.palette.common.white,
      maxWidth: '100% !important',
      position: 'sticky',
      top: 0,
      zIndex: 100,
    },
    content: {
      borderTop: `1px solid ${theme.palette.grey.A200}`,
    },
    matchCaseIcon: {
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
    resultsAndChips: {
      alignItems: 'center',
      display: 'flex',
      height: '60px',
      width: '100%',
    },
    searchInput: {
      padding: '10px !important',
    },
    searchInputContainer: {
      width: '100%',
    },
  }),
);

export default useStyles;
