import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { PaginationFilter, Report, Reports } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setStateHeartbeats } from '../../../redux/reducers/heartbeats.reducer';
import { setSorting } from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { defaultItemsPerPage, errorHighlight } from '../../../app/constants';
import { SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import ListSelection from '../../../components/ListSelection';
import SharedTable from '../../../components/SharedTable';
import useStyles from '../styles';
import { HeartBeatColumns } from './columns';
import HeartbeatFilters from './HeartbeatsMessagesFilters';
import ReportInformationDrawer from '../../../containers/RightDrawer/ReportInformationDrawer/ReportInformationDrawer';
import { OLDEST_NEWEST_SORTING } from '../../../constants/sortings';

const HeartbeatsMessages: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const heartbeatsState = useAppSelector(
    (state: RootState) => state.heartbeats,
  );

  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.heartbeats.sortBy, OLDEST_NEWEST_SORTING),
  );

  const [heartbeats, setHeartbeats] = useState<Report[]>(
    heartbeatsState.heartbeats,
  );

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [reportDrawer, setReportDrawer] = useState(false);
  const [selectedReport, setSelectedReport] = useState<undefined | Report>();

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getHeartbeats = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Reports.list(
      {
        type: {
          key: 'type',
          operator: 'eq',
          value: 'heartbeat',
        },
        ...parseFilters(
          filters.heartbeats.filters ?? {},
          filters.case_sensitive,
        ),
      },
      pagination,
    )
      .then((result) => {
        const reports: Report[] = addPage
          ? [...heartbeats, ...result.reports]
          : result.reports;

        setHeartbeats(reports);
        dispatch(setStateHeartbeats(reports));
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getHeartbeats(1);
  }, [filters.heartbeats]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getHeartbeats(page + 1, true);
  };
  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'heartbeats'));
    setSelectedSorting(option);
  };

  const handleCloseReportDrawer = (): void => {
    setReportDrawer(false);
  };

  const handleOpenExecutionInformationDrawer = (item: Report): void => {
    setSelectedReport(item);
    setReportDrawer(true);
  };

  const handleActionClick = (item: Report): void => {
    handleOpenExecutionInformationDrawer(item);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <HeartbeatFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={'list'}
        sortingOptions={OLDEST_NEWEST_SORTING}
        viewsOptions={[]}
        itemsSelected={false}
        allSelected={false}
        deleteAction={true}
        xPadding={false}
        sortingCallback={handleSorting}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <SharedTable
            columns={HeartBeatColumns(handleActionClick)}
            rows={heartbeats}
            sortBy={selectedSorting.value}
            sortDirection={
              selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
            }
            allSelected={false}
            loading={loading}
            selectedItemsIds={[]}
            xPadding={false}
            onRequestSort={handleTableSorting}
            viewOnly={true}
          />

          {heartbeats.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <ReportInformationDrawer
        header="Heartbeat Details"
        open={reportDrawer}
        handleCloseDrawer={handleCloseReportDrawer}
        selectedReport={selectedReport}
      />
    </Grid>
  );
};

export default HeartbeatsMessages;
