import Tags, { TagsProps } from '../../components/Tags/Tags';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';

const TagsContainer: React.FC<Omit<TagsProps, 'tags'>> = (props) => {
  const { userCompany } = useAppSelector((state: RootState) => state.user);
  return <Tags tags={userCompany?.device_tags} {...props} />;
};

export default TagsContainer;
