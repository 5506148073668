/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Devices, Device } from '@edgeiq/edgeiq-api-js';
import Header from '../../components/Header';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import useStyles from './styles';
import axios from 'axios';
import ReportFilter from './ReportFilters';

type TagMap = Record<string, Array<GrafanaDashboardResults>>;
export const DEVICE_METADATA_REPORT_TAG_KEY = 'dashboard_tag';

export type GrafanaDashboardResults = {
  id: number;
  uid: string;
  title: string;
  uri: string;
  url: string;
  slug: string;
  type: string;
  tags: string[];
  isStarred: boolean;
};
const ReportsPage: React.FC = () => {
  const classes = useStyles();
  const companyData = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );

  const [hasGrafana, setHasGrafana] = useState(false);
  const [host, setHostUrl] = useState('');
  const [grafanaReady, setGrafanaReady] = useState(false);
  const [dashboardsByTag, setDashboardsByTag] = useState<TagMap>();
  const [dashboard, setDashboard] = useState<string>();
  const [dashboards, setDashboards] = useState<GrafanaDashboardResults[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [grafanaError, setGrafanaError] = useState(false);
  const [device, setDevice] = useState<string>();
  const [filterDates, setFilterDates] = useState([
    { display: 'Last 5 minutes', value: 'now-5m' },
    { display: 'Last 15 minutes', value: 'now-15m' },
    { display: 'Last 30 minutes', value: 'now-30m' },
    { display: 'Last 1 hour', value: 'now-1h' },
    { display: 'Last 3 hours', value: 'now-3h' },
    { display: 'Last 6 hours', value: 'now-6h' },
    { display: 'Last 12 hours', value: 'now-12h' },
    { display: 'Last 24 hours', value: 'now-24h' },
    { display: 'Last 2 days', value: 'now-2d' },
    { display: 'Last 7 days', value: 'now-7d' },
  ]);

  const [frameParams, setFrameParams] = useState({
    eiqgu: undefined,
    from: 'now-6h',
    kiosk: 1,
    orgId: 1,
    refresh: '5m',
    to: 'now',
  });

  useEffect(() => {
    if (companyData) {
      const grafanaUrl =
        companyData.metadata && (companyData.metadata.grafana_url as string);
      const grafanaAuth =
        (companyData.metadata && companyData.metadata.eiqgu) || undefined;
      const filterDatesCmp = companyData.portal_configuration.reports_options
        .time_filter_options
        ? companyData.portal_configuration.reports_options.time_filter_options
        : filterDates;
      let defaultFilterValue: any = frameParams.from;
      if (
        companyData.metadata &&
        companyData.metadata.report_default_filter_value
      ) {
        defaultFilterValue = companyData.metadata.report_default_filter_value;
      }

      if (grafanaUrl) {
        setHasGrafana(true);
        setHostUrl(grafanaUrl);
        setFilterDates(filterDatesCmp);

        setFrameParams({
          ...frameParams,
          eiqgu: grafanaAuth as any,
          from: defaultFilterValue,
        });

        loadGrafanaUser(grafanaUrl, grafanaAuth).then((result) => {
          const userResult = result.data;
          if (userResult && userResult.orgId) {
            setFrameParams({
              ...frameParams,
              orgId: userResult.orgId,
            });
            loadDashboardData(grafanaUrl, grafanaAuth);
          } else {
            setHasGrafana(false);
          }
        });
      } else {
        setHasGrafana(false);
      }
    }
  }, [companyData]);

  const loadDashboardData = (grafanaUrl: string, grafanaAuth: any): void => {
    if (!companyData) {
      return;
    }
    const dashboardPromise = axios({
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
      params: {
        eiqgu: grafanaAuth,
        folderIds: 0,
        query: '',
        starred: false,
        type: 'dash-db',
      },
      url: `${grafanaUrl}/api/search`,
    });
    const tagsPromise = axios({
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
      params: {
        eiqgu: grafanaAuth,
      },
      url: `${grafanaUrl}/api/dashboards/tags`,
    });
    const promises = Promise.all([
      Devices.list({
        company_id: { operator: 'eq', value: companyData._id },
      }),
      tagsPromise,
      dashboardPromise,
    ]);
    promises
      .then(([device_result, _tags_result, dashboard_result]) => {
        const resultDevices = device_result.devices;
        // const tags = tags_result.data;
        let grafanaDashboards: GrafanaDashboardResults[] =
          dashboard_result.data;

        const dashboardsByTags = mapTagsToDashboards(grafanaDashboards);
        const devicesWithTag = devicesWithCorrespondingGrafanaTag(
          resultDevices,
          dashboardsByTags,
        );
        if (devicesWithTag && devicesWithTag.length !== null) {
          console.info(
            `found ${devicesWithTag.length} devices with a corresponding dashboard tag`,
          );
        }

        const defaultDevice =
          devicesWithTag[0] && devicesWithTag[0].metadata
            ? devicesWithTag[0].metadata[DEVICE_METADATA_REPORT_TAG_KEY]
            : null;
        const defaultDeviceDashboards = dashboardsByTags[defaultDevice];

        if (
          defaultDevice &&
          Array.isArray(defaultDeviceDashboards) &&
          defaultDeviceDashboards.length > 0
        ) {
          grafanaDashboards = defaultDeviceDashboards;
        }

        if (Array.isArray(grafanaDashboards) && grafanaDashboards.length > 0) {
          setGrafanaReady(true);
          setDashboardsByTag(dashboardsByTags);
          setDashboard(grafanaDashboards[0].url);
          setDashboards(grafanaDashboards);
          setDevices(devicesWithTag);
          setDevice(defaultDevice);
        } else {
          setGrafanaError(true);
        }
      })
      .catch((error) => {
        console.info('Error preparing report view:', error);
        setGrafanaError(true);
      });
  };

  const devicesWithCorrespondingGrafanaTag = (
    devicesArr: Device[],
    tagMap: TagMap,
  ): any => {
    const filteredDevices =
      devicesArr == null
        ? []
        : devicesArr.filter((deviceEle) => {
            const metaTag: any = deviceEle.metadata
              ? deviceEle.metadata[DEVICE_METADATA_REPORT_TAG_KEY]
              : null;
            return tagMap[deviceEle.unique_id] || tagMap[metaTag as string];
          });
    return filteredDevices;
  };

  const mapTagsToDashboards = (
    allDashboards: GrafanaDashboardResults[],
  ): TagMap => {
    const tagsToDashboards =
      allDashboards == null
        ? []
        : allDashboards.reduce((acc: any, ds) => {
            const tags = ds.tags;
            if (tags && tags.length) {
              tags.forEach((tag) => {
                if (typeof acc[tag] !== 'object') {
                  acc[tag] = [];
                }
                acc[tag].push(ds);
              });
            }
            return acc;
          }, {});
    return tagsToDashboards;
  };

  const loadGrafanaUser = (
    grafanaUrl: string,
    grafanaAuth: any,
  ): Promise<any> => {
    return axios({
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
      params: {
        eiqgu: grafanaAuth,
      },
      url: `${grafanaUrl}/api/user`,
    });
  };

  const buildFrameUrl = (): string => {
    const query = Object.keys(frameParams).reduce(
      (acc: Array<string>, param: any) => {
        if ((frameParams as any)[param] !== undefined) {
          acc.push(
            `${param}=${encodeURIComponent((frameParams as any)[param])}`,
          );
        }
        return acc;
      },
      [],
    );
    return `${host}${dashboard}?${query.join('&')}`;
  };

  const handleFilterChange = (prop: string, value: string): void => {
    switch (prop) {
      case 'dashboard':
        setDashboard(value);
        break;
      case 'device':
        if (dashboardsByTag) {
          let dashboardObj = null;
          const dashboardsForDevice = dashboardsByTag[value];
          if (dashboardsForDevice && dashboardsForDevice[0]) {
            dashboardObj = dashboardsForDevice[0].url;
            setDashboard(dashboardObj);
          }

          setDashboards(dashboardsForDevice);
          setDevice(value);
        }
        break;
      case 'timeframe':
        setFrameParams({
          ...frameParams,
          from: value,
        });
        break;
      default:
        break;
    }
  };

  const renderFrame = (): JSX.Element => {
    return (
      <div>
        {grafanaReady && dashboard && (
          <iframe
            className={classes.frame}
            src={buildFrameUrl()}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        )}
      </div>
    );
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="Reports" />
      {hasGrafana && grafanaReady && (
        <ReportFilter
          dashboards={dashboards}
          dashboard={dashboard}
          devices={devices}
          filterDates={filterDates}
          handleFilterChange={handleFilterChange}
          timeframeSelected={frameParams?.from}
          selectedDevice={device || ''}
        />
      )}

      <Grid container columnSpacing={3} direction="row" className="py-9 px-9">
        <Grid
          item
          xs={12}
          md={12}
          className="overflow-y scrollbar"
          direction={'column'}
        >
          {!hasGrafana && (
            <div className={classes.errorMsg}>
              No dashboards found for this company account.
            </div>
          )}

          {hasGrafana && grafanaError && (
            <div className={classes.errorMsg}>
              Error trying to get dashboards for company account.
            </div>
          )}

          {hasGrafana && grafanaReady && renderFrame()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportsPage;
