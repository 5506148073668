import {
  Company,
  DeviceType,
  DeviceTransferRequest,
} from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../components/SharedTable';
import { deviceTransferStatus } from '../../app/constants';
import { getCompanyDescription } from '../../helpers/utils';

export const DeviceTransferColumns = (
  userCompanies: Company[],
  deviceTypes: DeviceType[],
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const transfer = item as DeviceTransferRequest;
        return getCompanyDescription(
          transfer.from_company_id ?? '',
          userCompanies,
        );
      },
      id: 'from_company_id',
      label: 'Company From',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const transfer = item as DeviceTransferRequest;
        return getCompanyDescription(transfer.to_company_id, userCompanies);
      },
      id: 'to_company_id',
      label: 'Company To',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const deviceTransfer = item as DeviceTransferRequest;
        return deviceTransferStatus[deviceTransfer?.status || ''];
      },
      id: 'status',
      label: 'Status',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const deviceTypeItem = item as DeviceTransferRequest;
        const deviceType = deviceTypes?.find((type) => {
          return type._id === deviceTypeItem.device_type_id;
        });
        return deviceType?.name ?? item._id;
      },
      id: 'device_type_id',
      label: 'Device Type',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const deviceTransfer = item as DeviceTransferRequest;
        return deviceTransfer?.escrow_device_ids.length.toString() || '0';
      },
      id: 'escrow_device_ids',
      label: 'Device Count',
      type: 'text',
    },
  ];
};
