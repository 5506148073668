import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';
import PasswordInput from '../../../components/PasswordInput';

interface SmtpIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  hideEmptySensitiveFields?: boolean;
}

const SmtpIntegrationSection: React.FC<SmtpIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
  hideEmptySensitiveFields,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Server"
          prop="server"
          value={newIntegration.server}
          onInputChange={onInputChange}
        />
      </Grid>
      {(!hideEmptySensitiveFields || newIntegration.username) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="Username"
            prop="username"
            value={newIntegration.username}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
      {(!hideEmptySensitiveFields || newIntegration.password) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="Password"
            prop="password"
            value={newIntegration.password}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
    </>
  );
};

export default SmtpIntegrationSection;
