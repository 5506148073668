import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  DeviceConfig,
  DeviceConfigInput,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';

interface DeviceConfigFormProps {
  disabledDeviceType?: boolean;
  deviceConfig?: DeviceConfig;
  deviceConfigInput?: DeviceConfigInput;
  deviceTypes: DeviceType[];
  onInputChange: (prop: string, value: string | number) => void;
}

const DeviceConfigForm: React.FC<DeviceConfigFormProps> = ({
  disabledDeviceType,
  deviceConfig,
  deviceConfigInput,
  deviceTypes,
  onInputChange,
}) => {
  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} className="mt-6 ">
          <TextInput
            prop="name"
            label="Name*"
            placeholder="Name"
            type="text"
            required={true}
            value={deviceConfig?.name || deviceConfigInput?.name}
            onInputChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12} className="mt-6 ">
          <SelectInput
            label="Type*"
            prop="device_type_id"
            disabled={disabledDeviceType}
            value={
              deviceConfig?.device_type_id || deviceConfigInput?.device_type_id
            }
            onSelectChange={onInputChange}
            options={deviceTypes.map((type) => (
              <MenuItem dense key={type._id} value={type._id}>
                {type.name}
              </MenuItem>
            ))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceConfigForm;
