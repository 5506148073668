import React from 'react';
import { Tooltip, Typography, TypographyVariant } from '@mui/material';
import {
  Cloud as CloudNativeIcon,
  Router as GatewayIcon,
  SettingsRemote as Lwm2mIcon,
  SettingsInputAntenna as ModemIcon,
  Sensors as SensorIcon,
} from '@mui/icons-material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import { capitalize } from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

const icons = {
  cloud_native: <CloudNativeIcon fontSize="small" />,
  gateway: <GatewayIcon fontSize="small" />,
  lwm2m: <Lwm2mIcon fontSize="small" />,
  modem: <ModemIcon fontSize="small" />,
  sensor: <SensorIcon fontSize="small" />,
};

export interface DeviceTypeIconNameProps {
  deviceType?: DeviceType;
  labelVariant: TypographyVariant;
  className?: string;
}

const DeviceTypeIconName: React.FC<DeviceTypeIconNameProps> = ({
  deviceType,
  labelVariant,
  className,
}) => {
  const classes = useStyles();
  return deviceType ? (
    <Tooltip
      placement="top"
      title={`Role: ${capitalize(deviceType.role)}. Type: ${capitalize(
        deviceType.type,
      )}`}
    >
      <div
        data-testid="tooltip-container"
        className={clsx(classes.container, className)}
      >
        {icons[deviceType.type]}
        <Typography
          variant={labelVariant}
          className="ml-2"
          data-cy="device-type-icon-name"
        >
          {deviceType.name}
        </Typography>
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};

export default DeviceTypeIconName;
