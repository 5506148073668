import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { bacnetObjectTypes } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface BacnetSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
}

const BacnetSection: React.FC<BacnetSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Host"
          placeholder="Examples: 192.168.10.44, 192.168.0.1"
          prop="listener.host"
          value={newIngestor.listener.host ?? ''}
          onInputChange={onInputChange}
          disabled={disabled}
          required={true}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Port"
          prop="listener.port"
          placeholder=""
          type="number"
          value={newIngestor.listener.port ?? ''}
          onInputChange={onInputChange}
          disabled={disabled}
          required={true}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Timeout (seconds)"
          prop="listener.timeout"
          placeholder=""
          value={newIngestor.listener.timeout ?? 0}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Poll Interval"
          placeholder=""
          prop="listener.poll_interval"
          value={newIngestor.listener.poll_interval ?? 0}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <SelectInput
          label="Object Type"
          prop="params.object_type"
          value={newIngestor.listener.params?.object_type ?? ''}
          onSelectChange={onInputChange}
          disabled={disabled}
          required={true}
          options={Object.keys(bacnetObjectTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {bacnetObjectTypes[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Object Instance"
          placeholder=""
          prop="params.object_instance"
          type="number"
          value={newIngestor.listener.params?.object_instance ?? 0}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Property Id"
          placeholder=""
          prop="params.property_id"
          value={newIngestor.listener.params?.property_id ?? ''}
          onInputChange={onInputChange}
          disabled={disabled}
          required={true}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Value"
          placeholder=""
          prop="params.value"
          value={newIngestor.listener.params?.value ?? ''}
          onInputChange={onInputChange}
          disabled={disabled}
          required={true}
        />
      </Grid>
    </>
  );
};

export default BacnetSection;
