import { Grid, Button, Typography, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';

interface LoadMoreProps {
  onLoadMore: () => Promise<void>;
}

const LoadMore: React.FC<LoadMoreProps> = ({ onLoadMore }) => {
  const [loadingMore, toggleLoading] = useState(false);

  const handleLoading = async (): Promise<void> => {
    toggleLoading(true);
    await onLoadMore();
    toggleLoading(false);
  };

  return (
    <Grid item xs={12} className={clsx('my-4 loading-container')}>
      <Button variant="outlined" size="large" onClick={handleLoading}>
        {!loadingMore ? (
          <Typography variant="button">Load more</Typography>
        ) : (
          <CircularProgress size={25} />
        )}
      </Button>
    </Grid>
  );
};

export default LoadMore;
