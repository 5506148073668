import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Box, Paper } from '@mui/material';
import { Commands, Command, Device, Devices } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getCommandSelector,
  setActualCommand,
  setNewCommand,
} from '../../redux/reducers/commands.reducer';
import Header from '../../containers/HeaderWithActionButton';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { LocationState } from '../../models/common';
import ContentHeader from '../../components/ContentHeader';
import FooterBar from '../../components/FooterBar';
import ActionDialog from '../../components/ActionDialog';
import CommandForm from '../../containers/Forms/CommandForm';
import { commandsSenderTypes, errorHighlight } from '../../app/constants';
import {
  handleCommandDynamicValues,
  formatedObjectOptions,
} from '../../containers/Forms/CommandForm/helper';
import SelectDevicesDrawer from '../../containers/RightDrawer/SelectDevices/SelectDevicesDrawer';
import ExecuteGcpCommandDrawer from '../../containers/RightDrawer/ExecuteCommand/ExecuteCommandDrawer';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import {
  areJsonKeysDeletedOrRenamed,
  dispatchError,
  isNotFoundFallback,
} from '../../helpers/utils';
import CommandExecutionList from './commandExecutionList';

const CommandContent: React.FC = () => {
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const commandListRef = useRef<{
    refreshExecutionList: () => void;
  }>();
  const commandData = useAppSelector((state: RootState) =>
    getCommandSelector(state.commands, id),
  );
  const newCommand = useAppSelector(
    (state: RootState) => state.commands.newCommand,
  );
  const [optionsKeys, setOptionsKeys] = useState<string[]>([]);
  const [optionsRows, setOptionsRows] = useState<{ [key: string]: string }[]>(
    [],
  );
  const [commandToExecute, setCommandToExecute] = useState<Command>();
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(false);
  const [invalidSenderJson, setInvalidSenderJson] = useState(false);
  const [invalidOptionJson, setInvalidOptionJson] = useState(false);
  const [executeCommandDrawer, setExecuteCommandDrawer] = useState(false);
  const [deviceDrawerOpen, setDeviceDrawerOpen] = useState(false);
  const [executeCommandDialog, setExecuteCommandDialog] = useState(false);
  const [shellCommand, setShellCommand] = useState('');
  const errorDispatcher = dispatchError(dispatch);
  const [commandCompany] = useFetchCompany(
    commandData?.company_id,
    errorDispatcher,
  );
  const fallbackToMainCommand = (): void => {
    navigate('/commands');
  };

  const handleOpenDeviceDrawer = (): void => {
    setDeviceDrawerOpen(true);
  };

  const handleCloseDeviceDrawer = (): void => {
    setDeviceDrawerOpen(false);
  };

  useEffect(() => {
    if (newCommand) {
      setCommandToExecute(newCommand);
    }
  }, [newCommand]);

  useEffect(() => {
    if (commandData && commandData._id === id) {
      setOptionsKeyRows(commandData);
      dispatch(setActualCommand(commandData));
      if (commandData.sender) {
        setShellCommand(String(commandData.sender.command));
      }
    } else if (id) {
      Commands.getOneById(id)
        .then((response) => {
          setOptionsKeyRows(response);
          dispatch(setActualCommand(response));
          if (response.sender) {
            setShellCommand(String(response.sender.command));
          }
        })
        .catch((err) => {
          isNotFoundFallback(fallbackToMainCommand, err.message);
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [id]);

  const setOptionsKeyRows = (command: Command): void => {
    const options = (command?.options as { [key: string]: string }) || {};

    let newOptionsKeys: string[] = [];
    let newOptionsRows: { [key: string]: string }[] = [];
    Object.keys(options).forEach((item) => {
      const rows = options[item];
      newOptionsKeys = [...newOptionsKeys, item];
      newOptionsRows = [
        ...newOptionsRows,
        rows as unknown as { [key: string]: string },
      ];
    });

    setOptionsKeys(newOptionsKeys);
    setOptionsRows(newOptionsRows);
  };

  const handleDeleteCommand = (): void => {
    if (!commandData) {
      return;
    }
    setLoading(true);

    Commands.delete(commandData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete command',
            message: 'Command successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/commands');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setInvalidJsonValue = (prop: string, value: boolean): void => {
    switch (prop) {
      case 'sender':
        setInvalidSenderJson(value);
        break;
      case 'options':
        setInvalidOptionJson(value);
        break;
    }
  };

  const handleValueChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    switch (prop) {
      case 'sender':
      case 'options':
        try {
          const formattedJson = JSON.parse(value as string);
          if (formattedJson.command) {
            setShellCommand(formattedJson.command);
          }
          dispatch(
            setNewCommand({
              ...(newCommand as Command),
              [prop]: formattedJson,
            }),
          );
          setInvalidJsonValue(prop, false);
        } catch (e) {
          if (!value) {
            setInvalidJsonValue(prop, false);
            return;
          }
          setInvalidJsonValue(prop, true);
        }
        break;
      case 'save_command_output':
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            save_command_output: !newCommand?.save_command_output,
          }),
        );
        break;
      case 'generate_child_command_executions':
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            generate_child_command_executions:
              !newCommand?.generate_child_command_executions,
          }),
        );
        break;
      case 'shellCommand':
        setShellCommand(value as string);
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            sender: {
              command: value,
            },
          }),
        );
        break;
      default:
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            [prop]: value,
          }),
        );
        break;
    }
  };

  const handleSaveChanges = (): void => {
    if (newCommand && newCommand !== commandData) {
      const optionsUpdated = areJsonKeysDeletedOrRenamed(
        commandData?.options,
        newCommand?.options,
      );
      const senderUpdated = areJsonKeysDeletedOrRenamed(
        commandData?.sender,
        newCommand?.sender,
      );

      const payload = { ...newCommand };
      setLoading(true);

      if (optionsUpdated) {
        payload.options = null as never;
      }
      if (senderUpdated) {
        payload.sender = null as never;
      }

      const handleResponse = (response: Command): void => {
        setOptionsKeyRows(response);
        dispatch(setActualCommand(response));
        dispatch(setNewCommand(response));
        dispatch(
          setAlert({
            highlight: 'Update command',
            message: 'Command successfully updated.',
            type: 'success',
          }),
        );
      };

      Commands.update(payload)
        .then((res) => {
          if (senderUpdated || optionsUpdated) {
            Commands.update(newCommand).then((response) => {
              handleResponse(response);
            });
          } else {
            handleResponse(res);
          }
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    dispatch(
      setNewCommand(
        handleCommandDynamicValues(
          newCommand as Command,
          prop,
          value,
          field,
          index,
          optionsKeys || [],
          optionsRows || [],
        ) as Command,
      ),
    );
  };

  const handleAddRow = (prop: string): void => {
    switch (prop) {
      case 'attributes':
        const attbArray = newCommand?.sender?.attributes || {};
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            sender: {
              ...newCommand?.sender,
              [`attributes`]: {
                ...(attbArray as { [key: string]: string }),
                '': '',
              },
            },
          }),
        );
        break;
      case 'options':
        const emptyElement = optionsKeys.findIndex((item) => item === '');
        if (emptyElement < 0) {
          setOptionsKeys([...optionsKeys, '']);
          setOptionsRows([...optionsRows, ...[{ default: '', type: '' }]]);
          dispatch(
            setNewCommand({
              ...(newCommand as Command),
              options: {
                ...newCommand?.options,
                [``]: {
                  '': '',
                },
              },
            }),
          );
        }
        break;
    }
  };

  const handleRemoveRow = (prop: string, item: string): void => {
    switch (prop) {
      case 'attributes':
        const attbArray: { [key: string]: string } =
          (newCommand?.sender?.attributes as { [key: string]: string }) || {};
        delete attbArray[item];
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            sender: {
              ...newCommand?.sender,
              [`attributes`]: {
                ...(attbArray as { [key: string]: string }),
              },
            },
          }),
        );
        break;
      case 'options':
        const removedOptionsKeys = [...optionsKeys];
        removedOptionsKeys.splice(Number(item), 1);
        const removedOptionsRows = [...optionsRows];
        removedOptionsRows.splice(Number(item), 1);
        setOptionsKeys(removedOptionsKeys);
        setOptionsRows(removedOptionsRows);
        const formatttedOptionsResult = formatedObjectOptions(
          removedOptionsKeys,
          removedOptionsRows,
        );
        dispatch(
          setNewCommand({
            ...(newCommand as Command),
            options: formatttedOptionsResult,
          }),
        );
        break;
    }
  };

  const handleDeviceCallback = (devices: Device[]): void => {
    handleCloseDeviceDrawer();
    setSelectedDevices(devices);

    if (newCommand && newCommand.sender_type === 'gcp_pubsub_sender') {
      return setExecuteCommandDrawer(true);
    }

    setExecuteCommandDialog(true);
  };

  const handleCloseExecuteCommandDrawer = (): void => {
    setExecuteCommandDrawer(false);
  };

  const handleCloseExecuteCommandDialog = (): void => {
    setExecuteCommandDialog(false);
    setSelectedDevices([]);
  };

  const manageExecuteError = (error: Error): void => {
    setExecuteCommandDialog(false);
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: error.message,
        type: 'error',
      }),
    );
  };

  const handleExecuteCommand = (): void => {
    if (commandToExecute && selectedDevices.length) {
      if (selectedDevices.length === 1) {
        Commands.executeOnDevice(
          commandToExecute._id,
          selectedDevices[0]._id,
          commandToExecute.options as {
            [key: string]: string | number;
          },
        )
          .then((commandExecution) => {
            dispatch(
              setAlert({
                highlight: `Command Execution ID: ${commandExecution._id}`,
                message: `Command executed with success on device ${selectedDevices[0].name}`,
                type: 'success',
              }),
            );
            if (commandListRef.current) {
              commandListRef.current.refreshExecutionList();
            }
            handleCloseExecuteCommandDialog();
          })
          .catch((error) => {
            manageExecuteError(error as Error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Devices.bulkExecuteCommand(
          commandToExecute._id,
          selectedDevices.map((d) => d._id),
          commandToExecute.options as {
            [key: string]: string | number;
          },
          commandToExecute.generate_child_command_executions,
        )
          .then((_res) => {
            dispatch(
              setAlert({
                link: 'messages#bulk-jobs',
                linkText: 'bulk job',
                message:
                  'A <link> has been created to issue the command to the selected devices',
                type: 'success',
              }),
            );
            if (commandListRef.current) {
              commandListRef.current.refreshExecutionList();
            }
            // Only reset the devices when the command was successfuly excuted
            handleCloseExecuteCommandDialog();
          })
          .catch((error) => {
            manageExecuteError(error as Error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleOptionChange = (prop: string, value: string | number): void => {
    if (commandToExecute) {
      const auxOptionsObj = { ...commandToExecute.options };
      auxOptionsObj[prop] = value;
      setCommandToExecute({ ...commandToExecute, options: auxOptionsObj });
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return (
      isEqual(newCommand, commandData) &&
      !invalidSenderJson &&
      !invalidOptionJson
    );
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        model="command"
        title="Command content"
        goBack={goBackUrl ? goBackUrl : 'commands'}
        goBackLabel={goBackLabel || `Commands`}
      />
      {newCommand && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="command"
            title={newCommand.name}
            commandType={commandsSenderTypes[newCommand?.sender_type] as string}
            subtitle={newCommand._id}
            extraImage={commandCompany?.branding?.logo_url}
            extraTitle={commandCompany?.name}
            extraSubtitle={commandCompany?._id}
            hideOverline={false}
            copySubtitleToClipboard={true}
          />
          <Grid
            container
            columnSpacing={3}
            direction="row"
            className="py-6 px-8"
          >
            <Grid item xs={12} md={8}>
              <Paper className="p-7 shadow">
                <CommandForm
                  invalidSenderJson={invalidSenderJson}
                  invalidOptionJson={invalidOptionJson}
                  newCommand={newCommand as Command}
                  onInputChange={handleValueChange}
                  onAddRow={handleAddRow}
                  onRemoveRow={handleRemoveRow}
                  onDynamicChange={handleDynamicChange}
                  shellCommand={shellCommand}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className="p-7 shadow">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleOpenDeviceDrawer}
                >
                  Execute Command
                </Button>
                <CommandExecutionList
                  ref={commandListRef}
                  commandId={id as string}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this device profile"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteCommand}
      />

      <SelectDevicesDrawer
        actionLabel="Execute"
        open={deviceDrawerOpen}
        selectedDevices={[]}
        onCloseDrawer={handleCloseDeviceDrawer}
        onChoosingDevices={handleDeviceCallback}
        companyId={commandCompany?._id}
      />

      <ActionDialog
        open={executeCommandDialog}
        loading={loading}
        onDeleteCallback={handleExecuteCommand}
        onCloseCallback={handleCloseExecuteCommandDialog}
        content={`You are about to execute the command: ${newCommand?.name}`}
        actionButtonLabel="Execute"
      />

      <ExecuteGcpCommandDrawer
        open={executeCommandDrawer}
        command={commandToExecute}
        handleCloseDrawer={handleCloseExecuteCommandDrawer}
        onExecuteCommand={handleExecuteCommand}
        onOptionChange={handleOptionChange}
      />
    </Grid>
  );
};

export default CommandContent;
