import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardGrid: {
      [theme.breakpoints &&
      theme.breakpoints.between &&
      theme.breakpoints.between(1200, 1500)]: {
        '-ms-flex-preferred-size': '33.33%',
        '-webkit-flex-basis': '33.33%',
        flexBasis: '33.33%',
        maxWidth: '33.33%',
      },
    },
  }),
);

export default useStyles;
