import {
  DerivedValue,
  Lwm2mRequest,
  RuleAction,
  RuleCondition,
  RuleConditionType,
  RuleInput,
  WhereCondition,
  WhereConditionOperators,
  WhereConditionTypes,
} from '@edgeiq/edgeiq-api-js';

import {
  DEVICE_ERROR,
  EQUAL_CONDITION,
  IN_CONDITION,
  MOVING_AVERAGE,
  MOVING_AVERAGE_NEW_CONDITION,
  NOT_IN_CONDITION,
  PROPERTY_EVALUATION,
} from '../../../constants/policies';

export type DerivedValues =
  | (('count' | 'min' | 'max' | 'avg' | 'sum' | 'convert_indexed_keys') &
      string[] &
      WhereCondition[])
  | undefined;

export const verifySubmitEnable = (newPolicy: RuleInput): boolean => {
  if (!newPolicy) {
    return false;
  }

  return true;
};

export const checkConditionDisabled = (
  type: string | boolean,
  condition: string,
): boolean => {
  if (type === 'false' || type === false) {
    return (
      condition === 'heartbeat_status_changed' || condition === 'status_changed'
    );
  }
  return false;
};

// This function is responsible for the simple values change in the policy form.
export const changeDetailsValues = (
  newPolicy: RuleInput,
  prop: string,
  value: string | number | string[] | boolean,
): RuleInput => {
  return { ...newPolicy, [prop]: value };
};

// START OF RULE CONDITION MODULE--------------------------------------------------------------
// The secret here is that it checks if the conditions are already an array.
// Inside of the prop it will have the conditionIndex (if exists) and the valueIndex (if exists),
// by this two information it will grab the prop name and update.
export const changeRuleConditionValues = (
  newPolicy: RuleInput,
  prop: string,
  value: string | number | string[] | boolean,
): RuleInput => {
  const propsArray = prop.split('.');
  switch (value) {
    case DEVICE_ERROR:
      delete newPolicy.rule_condition.rule_conditions;

      return {
        ...newPolicy,
        rule_condition: {
          error_type: 'system',
          type: value,
        },
      };
    case PROPERTY_EVALUATION:
      delete newPolicy.rule_condition.rule_conditions;

      return {
        ...newPolicy,
        rule_condition: {
          type: EQUAL_CONDITION,
          value: '',
        },
      };
    default:
      delete newPolicy.rule_condition.error_type;
      const ruleConditions = newPolicy.rule_condition.rule_conditions;
      if (propsArray[0] === 'policyType') {
        delete newPolicy.rule_condition.rule_conditions;
      }

      // When there are multiple rule conditions
      if (Array.isArray(ruleConditions)) {
        if (propsArray[0] === 'value') {
          let valueIndex = 0;
          let ruleConditionIndex = 0;
          const auxRuleConditionsArray = [...ruleConditions]; // [{property,type,value, valueType}, ....]
          // If the type of the evaluation property has multiple values then the props array will have 3 items, 'value' at 0, the index in the array of values at 1
          // and the rule condition index at 2.
          // Otherwise it will have only 2. The 'value' at 0, and the rule condition index at 1.
          if (propsArray.length > 2) {
            valueIndex = parseInt(propsArray[1]);
            ruleConditionIndex = parseInt(propsArray[2]);
          } else {
            ruleConditionIndex = parseInt(propsArray[1]);
          }
          const auxRuleCondition = auxRuleConditionsArray[
            ruleConditionIndex
          ] as RuleCondition;
          if (propsArray.length > 2) {
            const auxValuesArray = [
              ...(ruleConditions[ruleConditionIndex]?.value as string[]),
            ]; // ['a','b',...]
            auxValuesArray[valueIndex] = value as string;
            auxRuleCondition.value = auxValuesArray;
          } else {
            auxRuleCondition.value = value as string;
          }

          return {
            ...newPolicy,
            rule_condition: {
              ...newPolicy.rule_condition,
              rule_conditions: auxRuleConditionsArray,
            },
          };
        }

        const auxRuleConditionsArray = [...ruleConditions];
        if (propsArray[0] === 'type') {
          // if PropsArray length is 1 then the user is changing between ALL and ANY
          if (propsArray.length === 1) {
            newPolicy.rule_condition.type = value as RuleConditionType;
          } else {
            auxRuleConditionsArray[parseInt(propsArray[1])] = {
              ...conditionTypeChange(
                value as RuleConditionType,
                ruleConditions[parseInt(propsArray[1])] as RuleCondition,
              ),
            };
          }
        } else {
          const auxRuleConditionObj = {
            ...ruleConditions[parseInt(propsArray[1])],
          };
          auxRuleConditionObj[propsArray[0] as keyof RuleCondition] =
            value as never;
          auxRuleConditionsArray[parseInt(propsArray[1])] =
            auxRuleConditionObj as RuleCondition;
        }

        return {
          ...newPolicy,
          rule_condition: {
            ...newPolicy.rule_condition,
            rule_conditions: auxRuleConditionsArray,
          },
        };
      }

      // When there is only one rule condition
      if (propsArray[0] === 'value') {
        const multipleValues = isMultipleValuesCondition(
          newPolicy.rule_condition.type,
        );
        let newValue = null;
        if (multipleValues) {
          const auxValuesArray = [
            ...(newPolicy.rule_condition.value as string[]),
          ];
          auxValuesArray[parseInt(propsArray[1])] = value as string;
          newValue = auxValuesArray;
        } else {
          newValue = value;
        }
        return {
          ...newPolicy,
          rule_condition: {
            ...newPolicy.rule_condition,
            value: multipleValues
              ? (newValue as string[])
              : (newValue as string),
          },
        };
      }

      // If changing the type, resetting and updating the type of value
      if (propsArray[0] === 'type') {
        return {
          ...newPolicy,
          rule_condition: {
            ...conditionTypeChange(
              value as RuleConditionType,
              newPolicy.rule_condition,
            ),
          },
        };
      }

      return {
        ...newPolicy,
        rule_condition: {
          ...newPolicy.rule_condition,
          [propsArray[0]]: value,
        },
      };
  }
};

// Every time a whole condition is added this function is trigger to check if is already an array and added it.
// If its already an array it will only update the array, else it will create the new array and replace the current object.
export const addNewCondition = (newPolicy: RuleInput): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.rule_conditions)) {
    const auxRuleConditionsArray = [
      ...newPolicy.rule_condition.rule_conditions,
      {
        property: '',
        type: EQUAL_CONDITION,
        value: '',
      } as RuleCondition,
    ];

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        rule_conditions: auxRuleConditionsArray,
      },
    };
  }

  const auxNewRuleConditionsArray = [
    { ...newPolicy.rule_condition },
    {
      property: '',
      type: EQUAL_CONDITION,
      value: '',
    } as RuleCondition,
  ];

  return {
    ...newPolicy,
    rule_condition: {
      rule_conditions: auxNewRuleConditionsArray,
      type: 'and',
    },
  };
};

// Every time a condition is removed this function is trigger to remove it.
// It will always receive the conditionIndex to remove it and if its an array of 2,
// it will transform the array in an object back again.
export const removeCondition = (
  newPolicy: RuleInput,
  conditionIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.rule_conditions)) {
    const auxRuleConditionsArray = [
      ...newPolicy.rule_condition.rule_conditions,
    ];
    auxRuleConditionsArray.splice(conditionIndex, 1);

    if (auxRuleConditionsArray.length > 1) {
      return {
        ...newPolicy,
        rule_condition: {
          ...newPolicy.rule_condition,
          rule_conditions: auxRuleConditionsArray,
        },
      };
    }

    return {
      ...newPolicy,
      rule_condition: { ...(auxRuleConditionsArray[0] as RuleCondition) },
    };
  }

  return newPolicy;
};

// Adds a new value to a rule condition
export const addNewValue = (
  newPolicy: RuleInput,
  conditionIndex?: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.rule_conditions)) {
    const auxRuleConditionsArray = [
      ...newPolicy.rule_condition.rule_conditions,
    ];

    const auxValuesArray = [
      ...(newPolicy.rule_condition.rule_conditions[conditionIndex ?? 0]
        ?.value as string[]),
      '',
    ];
    const auxRuleCondition = auxRuleConditionsArray[
      conditionIndex ?? 0
    ] as RuleCondition;
    auxRuleCondition.value = auxValuesArray;

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        rule_conditions: auxRuleConditionsArray,
      },
    };
  }

  const simpleValueArray = [
    ...((newPolicy.rule_condition?.value as string[]) ?? []),
    '',
  ];

  return {
    ...newPolicy,
    rule_condition: { ...newPolicy.rule_condition, value: simpleValueArray },
  };
};

// Every time a value is removed from the condition this function is trigger
// to check if is an array or just a simple condition an remove it.
// It will always receive an index to check which value its supposed to remove.
// If its an array it will receive a conditionIndex to get the correct condition.
export const removeValue = (
  newPolicy: RuleInput,
  index: number,
  conditionIndex?: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.rule_conditions)) {
    const auxRuleConditionsArray = [
      ...newPolicy.rule_condition.rule_conditions,
    ];

    const auxValuesArray = [
      ...(newPolicy.rule_condition.rule_conditions[conditionIndex ?? 0]
        ?.value as string[]),
    ];
    auxValuesArray.splice(index, 1);

    const auxRuleCondition = auxRuleConditionsArray[
      conditionIndex ?? 0
    ] as RuleCondition;
    auxRuleCondition.value = auxValuesArray;

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        rule_conditions: auxRuleConditionsArray,
      },
    };
  }

  const auxValuesArray = [...(newPolicy.rule_condition.value as string[])];
  auxValuesArray.splice(index, 1);

  return {
    ...newPolicy,
    rule_condition: { ...newPolicy.rule_condition, value: auxValuesArray },
  };
};
// END OF RULE CONDITION MODULE-----------------------------------------------------------

//START OF DERIVED VALUES MODULE----------------------------------------------------------
// The difference it will always be an array, so it has to be checked before any update.
// After checking, should check which prop its being updated, if the prop its a where_condition,
// the update occurs inside of the where_conditions array, so it has to have an index to tell which object its being updated.
export const changeDerivedValues = (
  newPolicy: RuleInput,
  prop: string,
  value: string | number | string[] | boolean,
): RuleInput => {
  const propsArray = prop.split('.');

  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    const auxDerivedValuesArray = [...newPolicy.rule_condition.derived_values];
    const auxDerivedValueObj = auxDerivedValuesArray[parseInt(propsArray[0])];

    if (
      propsArray[1] === 'where_condition' &&
      auxDerivedValueObj.where_conditions
    ) {
      switch (propsArray[3]) {
        case 'type':
          auxDerivedValueObj.where_conditions[parseInt(propsArray[2])].type =
            value as WhereConditionTypes;
          break;
        case 'property':
          auxDerivedValueObj.where_conditions[
            parseInt(propsArray[2])
          ].property = value as string;
          break;
        case 'operator':
          auxDerivedValueObj.where_conditions[
            parseInt(propsArray[2])
          ].operator = value as WhereConditionOperators;
          break;
        case 'value':
          auxDerivedValueObj.where_conditions[parseInt(propsArray[2])].value =
            value as string;
          break;
      }
    } else if (
      propsArray[1] === 'properties' &&
      auxDerivedValueObj.properties
    ) {
      auxDerivedValueObj.properties[parseInt(propsArray[2])] = value as string;
    } else {
      auxDerivedValueObj[propsArray[1] as keyof DerivedValue] =
        value as DerivedValues;
    }

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        derived_values: auxDerivedValuesArray,
      },
    };
  }

  return newPolicy;
};

// Add a new derived value to the existing array
export const addNewDerivedValue = (newPolicy: RuleInput): RuleInput => {
  let auxDerivedValuesArray: DerivedValue[] = [];
  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    auxDerivedValuesArray = newPolicy.rule_condition.derived_values;
  }

  auxDerivedValuesArray.push({
    data_set: '',
    name: '',
    properties: [''],
    property: '',
    type: 'count',
    where_conditions: [
      {
        operator: EQUAL_CONDITION,
        property: '',
        type: 'where',
        value: '',
      },
    ],
  } as DerivedValue);

  return {
    ...newPolicy,
    rule_condition: {
      ...newPolicy.rule_condition,
      derived_values: auxDerivedValuesArray,
    },
  };
};

// Removes the whole derived value
export const removeDerivedValue = (
  newPolicy: RuleInput,
  derivedValueIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    const auxDerivedValuesArray = [...newPolicy.rule_condition.derived_values];

    auxDerivedValuesArray.splice(derivedValueIndex, 1);

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        derived_values: auxDerivedValuesArray,
      },
    };
  }

  return newPolicy;
};

// Adds a property to its array inside of the derived values object
export const addNewProperty = (
  newPolicy: RuleInput,
  derivedValueIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    const auxDerivedValuesArray = [...newPolicy.rule_condition.derived_values];

    const auxPropertiesArray = [
      ...(newPolicy.rule_condition.derived_values[derivedValueIndex ?? 0]
        ?.properties as string[]),
      '',
    ];
    const auxDerivedValue = auxDerivedValuesArray[
      derivedValueIndex ?? 0
    ] as DerivedValue;
    auxDerivedValue.properties = auxPropertiesArray;

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        derived_values: auxDerivedValuesArray,
      },
    };
  }

  return newPolicy;
};

// Removes the property from the array inside of the derives values
export const removeProperty = (
  newPolicy: RuleInput,
  derivedValueIndex: number,
  propertyIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    const auxDerivedValuesArray = [...newPolicy.rule_condition.derived_values];

    const auxPropertiesArray = [
      ...(newPolicy.rule_condition.derived_values[derivedValueIndex ?? 0]
        ?.properties as string[]),
    ];
    auxPropertiesArray.splice(propertyIndex, 1);

    const auxDerivedValue = auxDerivedValuesArray[
      derivedValueIndex ?? 0
    ] as DerivedValue;
    auxDerivedValue.properties = auxPropertiesArray;

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        derived_values: auxDerivedValuesArray,
      },
    };
  }

  return newPolicy;
};

// Adds a new where condition to its array inside of the derives values
export const addNewWhereCondition = (
  newPolicy: RuleInput,
  derivedValueIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    const auxDerivedValuesArray = [...newPolicy.rule_condition.derived_values];

    const auxWhereConditionsArray = [
      ...(newPolicy.rule_condition.derived_values[derivedValueIndex ?? 0]
        ?.where_conditions as WhereCondition[]),
      {
        operator: EQUAL_CONDITION,
        property: '',
        type: 'where',
        value: '',
      } as WhereCondition,
    ];
    const auxDerivedValue = auxDerivedValuesArray[
      derivedValueIndex ?? 0
    ] as DerivedValue;
    auxDerivedValue.where_conditions = auxWhereConditionsArray;

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        derived_values: auxDerivedValuesArray,
      },
    };
  }

  return newPolicy;
};

// Removes the where condition from the array inside of the derived values
export const removeWhereCondition = (
  newPolicy: RuleInput,
  derivedValueIndex: number,
  whereConditionIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.rule_condition.derived_values)) {
    const auxDerivedValuesArray = [...newPolicy.rule_condition.derived_values];

    const auxWhereConditionsArray = [
      ...(newPolicy.rule_condition.derived_values[derivedValueIndex ?? 0]
        ?.where_conditions as WhereCondition[]),
    ];
    auxWhereConditionsArray.splice(whereConditionIndex, 1);

    const auxDerivedValue = auxDerivedValuesArray[
      derivedValueIndex ?? 0
    ] as DerivedValue;
    auxDerivedValue.where_conditions = auxWhereConditionsArray;

    return {
      ...newPolicy,
      rule_condition: {
        ...newPolicy.rule_condition,
        derived_values: auxDerivedValuesArray,
      },
    };
  }

  return newPolicy;
};
// END OF DERIVED VALUES MODULE --------------------------------------------------------

// START OF THEN ACTIONS MODULE -------------------------------------------------------

// Changes the values inside of the then actions array, should be passed an index in the prop params.
export const changeThenActionsValues = (
  newPolicy: RuleInput,
  prop: string,
  value: string | number | string[] | boolean | { [key: string]: string },
): RuleInput => {
  const propsArray = prop.split('.');

  if (Array.isArray(newPolicy.then_actions)) {
    const auxThenActionsArray = [...newPolicy.then_actions];
    const auxThenActionObj = auxThenActionsArray[parseInt(propsArray[0])];

    if (auxThenActionObj) {
      if (propsArray.length === 2) {
        auxThenActionObj[propsArray[1] as keyof RuleAction] = value as never;
      } else if (propsArray[1] === 'request') {
        if (!auxThenActionObj.request) {
          auxThenActionObj.request = {} as never;
        }
        auxThenActionObj.request[propsArray[2] as keyof Lwm2mRequest] =
          value as never;
      } else if (propsArray[1] === 'header') {
        auxThenActionObj.headers = value as { [key: string]: string };
      }
    }

    return {
      ...newPolicy,
      then_actions: auxThenActionsArray,
    };
  }

  return newPolicy;
};

// Adds a new then action to the array
export const addNewThenAction = (newPolicy: RuleInput): RuleInput => {
  let auxActionsArray: RuleAction[] = [];
  if (Array.isArray(newPolicy.then_actions)) {
    auxActionsArray = newPolicy.then_actions as RuleAction[];
  }
  auxActionsArray.push({
    retry_timeout: 0,
    type: 'aws_iot',
  });
  return {
    ...newPolicy,
    then_actions: auxActionsArray as RuleAction[],
  };
};

// Removes a whole then action from the array
export const removeThenAction = (
  newPolicy: RuleInput,
  actionIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.then_actions)) {
    const auxActionsArray = [...newPolicy.then_actions];
    auxActionsArray.splice(actionIndex, 1);
    return {
      ...newPolicy,
      then_actions: auxActionsArray,
    };
  }

  return newPolicy;
};

// Adds a new header object inside of the then actions array
export const addNewHeaderThenActions = (
  newPolicy: RuleInput,
  actionIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.then_actions)) {
    const auxActionsArray = [...newPolicy.then_actions];

    const auxItem = auxActionsArray[actionIndex];
    if (auxItem && !auxItem.headers) {
      auxItem.headers = {};
    }

    const auxObjHeaders = auxItem?.headers;

    if (auxObjHeaders) {
      if (auxObjHeaders[`newKey${Object.keys(auxObjHeaders).length}`]) {
        auxObjHeaders[`newKey${Object.keys(auxObjHeaders).length + 1}`] =
          'newValue';
      } else {
        auxObjHeaders[`newKey${Object.keys(auxObjHeaders).length}`] =
          'newValue';
      }
    }

    return {
      ...newPolicy,
      then_actions: auxActionsArray as RuleAction[],
    };
  }

  return newPolicy;
};

// Removes the header item inside of the then actions array, should be passed the headerKey and the actionIndex
export const removeHeaderThenAction = (
  newPolicy: RuleInput,
  actionIndex: number,
  headerkey: string,
): RuleInput => {
  if (Array.isArray(newPolicy.then_actions)) {
    const auxActionsArray = [...newPolicy.then_actions];

    const auxObjHeaders = auxActionsArray[actionIndex]?.headers;

    if (auxObjHeaders) {
      delete auxObjHeaders[headerkey];
    }

    return {
      ...newPolicy,
      then_actions: auxActionsArray,
    };
  }

  return newPolicy;
};
// END OF THE THEN ACTIONS MODULE -------------------------------------------------

// START OF THE ELSE ACTIONS MODULE --------------------------------------------------
// Changes the values inside of the else actions array, should be pass the index in the prop param.
export const changeElseActionsValues = (
  newPolicy: RuleInput,
  prop: string,
  value: string | number | string[] | boolean,
): RuleInput => {
  const propsArray = prop.split('.');

  if (Array.isArray(newPolicy.else_actions)) {
    const auxThenActionsArray = [...newPolicy.else_actions];
    const auxThenActionObj = auxThenActionsArray[parseInt(propsArray[0])];

    if (auxThenActionObj) {
      auxThenActionObj[propsArray[1] as keyof RuleAction] = value as never;
    }

    return {
      ...newPolicy,
      else_actions: auxThenActionsArray,
    };
  }

  return newPolicy;
};

// Adds a whole new else action to the array.
export const addNewElseAction = (newPolicy: RuleInput): RuleInput => {
  if (Array.isArray(newPolicy.else_actions)) {
    const auxActionsArray = [
      ...newPolicy.else_actions,
      {
        retry_timeout: 0,
        type: 'aws_iot',
      },
    ];

    return {
      ...newPolicy,
      else_actions: auxActionsArray as RuleAction[],
    };
  }

  return newPolicy;
};

// Remove a whole else action from the array
export const removeElseAction = (
  newPolicy: RuleInput,
  actionIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.else_actions)) {
    const auxActionsArray = [...newPolicy.else_actions];

    auxActionsArray.splice(actionIndex, 1);

    return {
      ...newPolicy,
      else_actions: auxActionsArray,
    };
  }

  return newPolicy;
};

// Adds a header object inside the else actions array.
export const addNewHeaderElseActions = (
  newPolicy: RuleInput,
  actionIndex: number,
): RuleInput => {
  if (Array.isArray(newPolicy.else_actions)) {
    const auxActionsArray = [...newPolicy.else_actions];

    const auxObjHeaders = auxActionsArray[actionIndex]?.headers;

    if (auxObjHeaders) {
      if (auxObjHeaders[`newKey${Object.keys(auxObjHeaders).length}`]) {
        auxObjHeaders[`newKey${Object.keys(auxObjHeaders).length + 1}`] =
          'newValue';
      } else {
        auxObjHeaders[`newKey${Object.keys(auxObjHeaders).length}`] =
          'newValue';
      }
    }

    return {
      ...newPolicy,
      else_actions: auxActionsArray as RuleAction[],
    };
  }

  return newPolicy;
};

// Removes a whole header object from the else actions array.
export const removeHeaderElseAction = (
  newPolicy: RuleInput,
  actionIndex: number,
  headerkey: string,
): RuleInput => {
  if (Array.isArray(newPolicy.else_actions)) {
    const auxActionsArray = [...newPolicy.else_actions];

    const auxObjHeaders = auxActionsArray[actionIndex]?.headers;

    if (auxObjHeaders) {
      delete auxObjHeaders[headerkey];
    }

    return {
      ...newPolicy,
      else_actions: auxActionsArray,
    };
  }

  return newPolicy;
};
// END OF THE ELSE ACTIONS MODULE ------------------------------------

const conditionTypeChange = (
  type: RuleConditionType,
  ruleCondition: RuleCondition,
): RuleCondition => {
  if (type === MOVING_AVERAGE) {
    return MOVING_AVERAGE_NEW_CONDITION;
  }
  const multipleValues = isMultipleValuesCondition(type);
  const conditionValue = ruleCondition.value;
  const isArrayValue = Array.isArray(conditionValue);
  return {
    ...ruleCondition,
    type,
    value:
      (isArrayValue && multipleValues) || (!isArrayValue && !multipleValues) // Value is array and we changed to another array type condition
        ? conditionValue // then keep the values. Or it's not an array values and we changed to another single value type condition, keep the same value as well.
        : !isArrayValue && multipleValues // Is not an array value, we create array and keep the old value as the first
        ? [conditionValue as string]
        : conditionValue && (conditionValue as string[])[0], // by default the last case, the value is an array and we changed to non array type, keep the first value in the array as new value
  };
};

export const isMultipleValuesCondition = (type: RuleConditionType): boolean => {
  return type === IN_CONDITION || type === NOT_IN_CONDITION;
};
