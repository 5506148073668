import React, { useState } from 'react';
import { Typography, Button, IconButton } from '@mui/material';
import { Device, DevicesFilters } from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';

import SelectDevicesDrawer from '../RightDrawer/SelectDevices/SelectDevicesDrawer';
import useStyles from './styles';
import { EntitiesSectionItemType } from '.';

interface DevicesEntitiesSectionProps {
  newItem: EntitiesSectionItemType;
  selectedDevices: Device[];
  filters?: DevicesFilters;
  onChangeDevices: (devices: Device[]) => void;
}

const DevicesEntitiesSection: React.FC<DevicesEntitiesSectionProps> = ({
  newItem,
  selectedDevices,
  filters,
  onChangeDevices,
}) => {
  const classes = useStyles();
  const [deviceDrawerOpen, setDeviceDrawerOpen] = useState(false);

  const handleDeviceCallback = (devices: Device[]): void => {
    onChangeDevices(devices);
    handleCloseDeviceDrawer();
  };

  const handleOpenDeviceDrawer = (): void => {
    setDeviceDrawerOpen(true);
  };

  const handleCloseDeviceDrawer = (): void => {
    setDeviceDrawerOpen(false);
  };

  const handleDeleteDevice = (i: number): void => {
    const newDevices = selectedDevices.filter((_d, index) => i !== index);
    handleDeviceCallback([...newDevices]);
  };

  return (
    <>
      {selectedDevices.length === 0 ? (
        <Typography
          component="div"
          data-cy="no-devices-selected-label"
          variant="overline"
          className={clsx('mt-8', classes.configSubtitle)}
        >
          No devices selected.
        </Typography>
      ) : (
        <div className={clsx('scrollbar', classes.container)}>
          {selectedDevices.map((entity, i) => (
            <Typography
              noWrap
              key={entity._id}
              variant="button"
              component="div"
              className={clsx('py-2 fw-700', classes.item)}
            >
              {entity.name}
              <IconButton
                color="primary"
                size="large"
                className="p-0"
                onClick={(): void => handleDeleteDevice(i)}
              >
                <DeleteIcon />
              </IconButton>
            </Typography>
          ))}
        </div>
      )}
      <Button
        className="mt-8"
        color="primary"
        data-cy="attach-devices-button"
        size="large"
        type="button"
        variant="contained"
        onClick={handleOpenDeviceDrawer}
      >
        <Typography variant="button">Attach Devices</Typography>
      </Button>

      <SelectDevicesDrawer
        open={deviceDrawerOpen}
        selectedDevices={selectedDevices.map((device) => device._id)}
        companyId={newItem?.company_id}
        filters={filters}
        onCloseDrawer={handleCloseDeviceDrawer}
        onChoosingDevices={handleDeviceCallback}
      />
    </>
  );
};

export default DevicesEntitiesSection;
