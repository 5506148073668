import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { modbusRequestsTypes } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface TcpModbusSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
}

const TcpModbusSection: React.FC<TcpModbusSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Host"
          placeholder="Examples: 192.168.10.44, 192.168.0.1"
          prop="listener.host"
          value={newIngestor.listener.host}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Port"
          prop="listener.port"
          placeholder=""
          value={newIngestor.listener.port}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Slave Id"
          prop="listener.slave_id"
          placeholder=""
          value={newIngestor.listener.slave_id}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Timeout (seconds)"
          prop="listener.timeout"
          placeholder=""
          value={newIngestor.listener.timeout}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Poll Interval"
          placeholder=""
          prop="listener.poll_interval"
          value={newIngestor.listener.poll_interval}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <SelectInput
          label="Request Type"
          prop="params.request_type"
          value={newIngestor?.listener?.params?.request_type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(modbusRequestsTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {modbusRequestsTypes[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Address"
          placeholder=""
          prop="params.address"
          value={newIngestor?.listener?.params?.address}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Quantity"
          placeholder=""
          prop="params.quantity"
          value={newIngestor?.listener?.params?.quantity}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Value"
          placeholder=""
          prop="params.value"
          value={newIngestor?.listener?.params?.value}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="And Mask"
          placeholder=""
          prop="params.and_mask"
          value={newIngestor?.listener?.params?.and_mask}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Or Mask"
          placeholder=""
          prop="params.or_mask"
          value={newIngestor?.listener?.params?.or_mask}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>
    </>
  );
};

export default TcpModbusSection;
