import React from 'react';
import { RuleInput } from '@edgeiq/edgeiq-api-js';
import { Grid, MenuItem } from '@mui/material';

import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';
import SwitchButton from '../../../../components/SwitchButton';
import { POLICY_TYPE_LABEL } from '../../../../constants/policies';

interface PolicyDetailsSectionProps {
  newPolicy: RuleInput;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const PolicyDetailsSection: React.FC<PolicyDetailsSectionProps> = ({
  newPolicy,
  onInputChange,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={7} className="mt-2">
        <TextInput
          label="Policy Description"
          prop="description"
          placeholder=""
          value={newPolicy.description}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={3} className="mt-2">
        <SelectInput
          label="Policy Type"
          prop="cloud_rule"
          value={newPolicy.cloud_rule.toString()}
          onSelectChange={onInputChange}
          options={Object.keys(POLICY_TYPE_LABEL).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {POLICY_TYPE_LABEL[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={2} className="mt-4 d-flex flex-items-center">
        <SwitchButton
          label="Active"
          onSwitchChange={onInputChange}
          prop="active"
        />
      </Grid>
    </Grid>
  );
};

export default PolicyDetailsSection;
