import { UserType } from '@edgeiq/edgeiq-api-js';

export const UsersTypes = {
  SET_USER_TYPES: 'SET_USER_TYPES',
};

export const setStateUserTypes = (userTypes: UserType[]): UserTypesAction => ({
  data: userTypes,
  type: UsersTypes.SET_USER_TYPES,
});

export type UserTypesState = {
  userTypes: UserType[];
};

// INITIAL STATE
const usersInitialState: UserTypesState = {
  userTypes: [],
};

export type UserTypesAction = {
  data: UserType[] | UserType;
  type: string;
};

const userTypesReducer = (
  state = usersInitialState,
  action: UserTypesAction,
): UserTypesState => {
  switch (action.type) {
    case UsersTypes.SET_USER_TYPES:
      return {
        ...state,
        userTypes: action.data as UserType[],
      };

    default:
      return state;
  }
};

export default userTypesReducer;
