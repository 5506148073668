import { Company } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';

export const CompaniesColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const company = item as Company;
      return company.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    link: (item: TableItemType): string => {
      const company = item as Company;
      return `/account/${company._id}`;
    },
    type: 'link',
  },
];
