import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountContainer: {
      '&.selected': {
        backgroundColor: theme.palette.grey.A200,
      },
      '&:hover': {
        '& .checkboxContainer': {
          display: 'inline-block',
        },
        '& .MuiFormControlLabel-root': {
          display: 'inline-flex',
        },
        backgroundColor: theme.palette.grey.A100,
      },
      cursor: 'pointer',
      display: 'flex',
      padding: '8px 8px 8px 0',
    },
    accountName: {
      cursor: 'pointer',
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    accountNameContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    breadcrumb: {
      color: theme.palette.grey[100],
    },
    container: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 56px)',
    },
    levelContainer: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
      borderRight: `1px solid ${theme.palette.grey.A200}`,
      height: '100%',
      minWidth: '350px',
      overflow: 'auto',
    },
    levelsContainer: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
      display: 'flex',
      height: 'calc(100% - 36px)',
      overflowX: 'auto',
    },
    loadMoreContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    switchLabel: {
      '&.showLabel': {
        display: 'inline-flex',
      },
      display: 'none',
      fontSize: '10px',
      marginLeft: 0,
    },
  }),
);

export default useStyles;
