import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Device,
  Devices,
  GatewayCommand,
  GatewayCommands,
} from '@edgeiq/edgeiq-api-js';
import UploadIcon from '@mui/icons-material/Upload';
import { useParams } from 'react-router-dom';

import { errorHighlight } from '../../../app/constants';
import parseFilters from '../../../helpers/parseFilters';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';

import TabsPage from '../../../components/TabsPage';

const SoftwarePackagesLogDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams<string>();
  const [selectedGatewayCommand, setSelectedGatewayCommand] = useState<
    GatewayCommand & { device?: Device }
  >();

  const columns: GridColDef[] = [
    {
      field: 'device_name',
      flex: 0.3,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.device?.name}</span>
      ),
    },
    {
      field: 'device_unique_id',
      flex: 0.3,
      headerName: 'Unique ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.device_unique_id}</span>
      ),
    },
    {
      field: 'update_status',
      flex: 0.3,
      headerName: 'Update Status',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.statuses[params.row.device_unique_id].status}</span>
      ),
    },
    {
      field: 'tags',
      flex: 0.3,
      headerName: 'Tags',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.device?.tags}</span>
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      let command: GatewayCommand;
      setLoading(true);
      GatewayCommands.getOneById(id)
        .then((res) => {
          command = res;
          return Devices.list(
            parseFilters({
              unique_id: command.device_unique_id ?? '',
            }),
          );
        })
        .then((res) => {
          return setSelectedGatewayCommand({
            ...command,
            device: res.devices[0],
          });
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedGatewayCommand ? [selectedGatewayCommand] : []}
        tableTitle="Logs"
        loading={loading}
        addButtonIconToShow={<UploadIcon />}
      />
    </Box>
  );
};

export default SoftwarePackagesLogDetails;
