import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Paper, Tooltip, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Workflows } from '@edgeiq/edgeiq-api-js';
import { capitalize } from 'lodash';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getWorkflowSelector,
  setActualWorkflow,
} from '../../redux/reducers/workflows.reducer';
import { dispatchError } from '../../helpers/utils';
import { getToken } from '../../helpers/storage';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import WorkflowExecutionList from '../../containers/WorkflowExecutionList';
import useExpandStyles from '../../pages/commandContent/commandExecutionList/expandButtonStyles';

const WorkflowContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const errorDispatcher = dispatchError(dispatch);
  const stateUser = useAppSelector((state: RootState) => state.user);
  const expandClasses = useExpandStyles({
    branding: stateUser.userCompany?.branding,
  });
  const workflowState = useAppSelector((state: RootState) => state.workflows);
  const { workflow } = workflowState;
  const workflowData = useAppSelector((state: RootState) =>
    getWorkflowSelector(state.workflows, id),
  );
  const [listExpanded, setListExpanded] = useState(false);

  useEffect(() => {
    if (workflowData) {
      dispatch(setActualWorkflow(workflowData));
    } else if (id && id !== 'undefined') {
      Workflows.list({
        _id: {
          operator: 'eq',
          value: id,
        },
      })
        .then((response) => {
          const workflowFound = response.workflows.find((wf) => wf._id === id);
          if (workflowFound) {
            dispatch(setActualWorkflow(workflowFound));
          }
        })
        .catch((err) => {
          errorDispatcher(err.message);
        });
    }
  }, [workflowData]);

  const handleListExpanded = (): void => {
    setListExpanded(!listExpanded);
  };

  return (
    <Grid container spacing={0}>
      <Header title="Workflow Content" goBack="workflows" model="workflows" />
      <Grid item xs={12}>
        {workflow && (
          <Box className="content-page-container">
            <ContentHeader
              contentType="workflow"
              title={capitalize(workflow.name)}
              subtitle={workflow._id}
              copySubtitleToClipboard
              hideTitleImage={false}
            />
            <div className="py-6 px-8 d-flex flex-row">
              <div
                className={clsx(
                  expandClasses.mainContentContainer,
                  expandClasses.withTransition,
                  {
                    ['collapsed']: listExpanded,
                    ['expanded']: !listExpanded,
                  },
                )}
              >
                <Paper className="shadow">
                  <iframe
                    className="w-100"
                    src={`${
                      workflow?.workflow_url
                    }?Authorization=${getToken()}`}
                    style={{
                      border: 'none',
                      height: `calc(100vh - 260px)`,
                    }}
                  />
                </Paper>
              </div>
              <div
                className={clsx(
                  expandClasses.listContainer,
                  expandClasses.withTransition,
                  {
                    ['collapsed']: !listExpanded,
                    ['expanded']: listExpanded,
                  },
                )}
              >
                <Paper className="p-4 shadow">
                  <div
                    data-cy="collapse-right-list-button"
                    className={clsx(expandClasses.expandButtonContainer, {
                      ['collapsed']: !listExpanded,
                    })}
                  >
                    <Tooltip
                      title={
                        listExpanded ? 'Collapse' : 'Expand Execution Records'
                      }
                      placement="left"
                    >
                      <IconButton
                        color="inherit"
                        aria-label="expand list"
                        edge="start"
                        onClick={handleListExpanded}
                        className={expandClasses.expandButton}
                      >
                        {listExpanded ? <ChevronRight /> : <ChevronLeft />}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <WorkflowExecutionList
                    collapsed={!listExpanded}
                    isWorkflowPage={true}
                  />
                </Paper>
              </div>
            </div>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default WorkflowContent;
