import React, { ReactElement } from 'react';
import { Grid, MenuItem, Paper, Typography } from '@mui/material';
import { Setting } from '@edgeiq/edgeiq-api-js';

import SettingsForm from '../../containers/Forms/SettingsForm';
import { SettingsEditorData } from '../../models/settings';
import SelectInput from '../../components/SelectInput';
import useStyles from './styles';

interface SettingsDetailsProps {
  settings?: Setting;
  settingsEditorData: SettingsEditorData;
  versions: number;
  onInputChange: (prop: string, value: string) => void;
  onSettingsEditorChange: (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ) => void;
}

const SettingsDetails: React.FC<SettingsDetailsProps> = ({
  settings,
  settingsEditorData,
  versions,
  onInputChange,
  onSettingsEditorChange,
}) => {
  const classes = useStyles();
  const getVersionItems = (): ReactElement[] => {
    const items: ReactElement[] = [];
    for (let i = 0; i < versions; i++) {
      items.push(
        <MenuItem
          dense
          key={`settings-version-select-item-${i + 1}`}
          value={i + 1}
        >
          {i + 1}
        </MenuItem>,
      );
    }
    return items;
  };

  return (
    <Paper className="p-6 shadow">
      {settings && (
        <>
          {versions > 1 && (
            <Grid item xs={12} sm={6} md={4} lg={3} className="mb-6">
              <div className={classes.settingVersionLabel}>
                <Typography variant="h6" className="mr-3" component="span">
                  Setting Version
                </Typography>
              </div>
              <div className={classes.settingVersion}>
                <SelectInput
                  prop="settings-version"
                  fullWidth={false}
                  value={settings.version.toString()}
                  options={getVersionItems()}
                  onSelectChange={onInputChange}
                />
              </div>
            </Grid>
          )}
          <SettingsForm
            action="save"
            settings={settings}
            settingsEditorData={settingsEditorData}
            onInputChange={onInputChange}
            onSettingsEditorChange={onSettingsEditorChange}
          />
        </>
      )}
    </Paper>
  );
};

export default SettingsDetails;
