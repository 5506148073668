import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Typography } from '@mui/material';
import { IssueSoftwareUpdateInput } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import SwitchButton from '../../../components/SwitchButton';
import DatePicker from '../../../components/DatePicker';
import SelectInput from '../../../components/SelectInput';
import { DateTime } from 'luxon';
import { setNewIssueSoftwareUpdateInput } from '../../../redux/reducers/softwareUpdates.reducer';

interface SoftwarePackageFormProps {
  onInputChange: (prop: string, value: string | DateTime) => void;
}

const retryIntervals = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
];

const IssueUpdateForm: React.FC<SoftwarePackageFormProps> = ({
  onInputChange,
}) => {
  const dispatch = useAppDispatch();

  const [scheduleUpdate, setScheduleUpdate] = useState(false);
  const newIssueSoftwareUpdateInput = useAppSelector(
    (state: RootState) => state.softwareUpdates.newIssueSoftwareUpdateInput,
  );

  useEffect(() => {
    if (scheduleUpdate) {
      setInitialStartAndEndDate();
    } else {
      dispatch(
        setNewIssueSoftwareUpdateInput({
          ...newIssueSoftwareUpdateInput,
          schedule: null,
        } as unknown as IssueSoftwareUpdateInput),
      );
    }
  }, [scheduleUpdate]);

  const setInitialStartAndEndDate = (): void => {
    dispatch(
      setNewIssueSoftwareUpdateInput({
        ...newIssueSoftwareUpdateInput,
        schedule: {
          end_datetime: DateTime.local().plus({
            day: 1,
          }),
          retry_interval: 'daily',
          start_datetime: DateTime.local(),
        },
      } as unknown as IssueSoftwareUpdateInput),
    );
  };

  return (
    <Box>
      {/* Row 1 */}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} className="mt-2">
          <SwitchButton
            label="Schedule Update"
            value={scheduleUpdate}
            prop="scheduleUpdate"
            onSwitchChange={(): void => setScheduleUpdate(!scheduleUpdate)}
          />
          <Typography className="mt-4" variant="subtitle2">
            {scheduleUpdate
              ? 'Perform Update Between...'
              : 'Perform Update Right Away'}
          </Typography>
        </Grid>

        {scheduleUpdate && (
          <>
            <Grid item xs={12} className="mt-2">
              <FormControl fullWidth>
                <DatePicker
                  value={
                    newIssueSoftwareUpdateInput?.schedule
                      ?.start_datetime as string
                  }
                  prop="schedule.start_datetime"
                  onInputChange={onInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className="custom-label mb-0">
                And
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DatePicker
                  value={
                    newIssueSoftwareUpdateInput?.schedule
                      ?.end_datetime as string
                  }
                  prop="schedule.end_datetime"
                  onInputChange={onInputChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} className="mt-6">
              <SelectInput
                label="If the device is unreachable, retry"
                prop="schedule.retry_interval"
                value={
                  newIssueSoftwareUpdateInput?.schedule?.retry_interval ?? ''
                }
                onSelectChange={onInputChange}
                required={true}
                options={retryIntervals.map((d, index) => (
                  <MenuItem dense key={index} value={d.value}>
                    {d.value}
                  </MenuItem>
                ))}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default IssueUpdateForm;
