import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { dbusHandlerTypes } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface DbusSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const DbusSection: React.FC<DbusSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Index"
          prop="handler.index"
          placeholder=""
          value={newIngestor.handler.index}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>
      <Grid item xs={6} className="mt-2">
        <SelectInput
          label="Type"
          prop="handler.type"
          value={newIngestor.handler.type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(dbusHandlerTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {dbusHandlerTypes[key]}
            </MenuItem>
          ))}
        />
      </Grid>
    </>
  );
};

export default DbusSection;
