import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    account: {
      alignItems: 'center',
      display: 'flex',
    },
    boxContainer: {
      backgroundColor: '#fff',
    },
    cardContainer: {
      backgroundColor: theme.palette.common.white,
    },
    input: {
      height: '44px',
      width: '100%',
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    subtitle: {
      color: theme.palette.grey[100],
    },
  }),
);

export default useStyles;
