import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 700,
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
