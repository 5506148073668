import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Device } from '@edgeiq/edgeiq-api-js';
import {
  GENRE_ACTIVE,
  GENRE_DISCOVERED,
  GENRE_ESCROW,
} from '../../app/constants';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  ApplySettingsDrawer,
  IssueCommandDrawer,
  IssueWorkflowDrawer,
} from '../../containers/RightDrawer';
import IssueTransferDrawer from './escrowDevices/IssueTransfer';
import PromoteDevicesDrawer from './discoveredDevices/PromoteDevices';

interface DevicesExtraActionsProps {
  selectedDevicesIds?: string[];
  selectedDevices?: Device[];
  showWorkflowAction?: boolean;
  onRemoveDevice?: (deviceId: string) => void;
}

const DevicesExtraActions: React.FC<DevicesExtraActionsProps> = ({
  selectedDevicesIds,
  selectedDevices,
  showWorkflowAction,
  onRemoveDevice,
}) => {
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;
  const isEscrow = devicesState.devicesGenre === GENRE_ESCROW;
  const isDiscovered = devicesState.devicesGenre === GENRE_DISCOVERED;

  const [openIssueCommand, setOpenIssueCommand] = useState(false);
  const [openIssueWorkflow, setOpenIssueWorkflow] = useState(false);
  const [openTransfers, setOpenTransfers] = useState(false);
  const [openPromote, setOpenPromote] = useState(false);
  const [openApplySettings, setOpenApplySettings] = useState(false);

  const handleOpen = (type: string) => (): void => {
    switch (type) {
      case 'command':
        setOpenIssueCommand(true);
        break;
      case 'workflow':
        setOpenIssueWorkflow(true);
        break;
      case 'transfer':
        setOpenTransfers(true);
        break;
      case 'promote':
        setOpenPromote(true);
        break;
      case 'settings':
        setOpenApplySettings(true);
        break;
      default:
        break;
    }
  };

  const handleCloseIssueCommand = (): void => {
    setOpenIssueCommand(false);
  };

  const handleCloseIssueWorkflow = (): void => {
    setOpenIssueWorkflow(false);
  };

  const handleCloseTransfers = (): void => {
    setOpenTransfers(false);
  };

  const handleClosePromote = (): void => {
    setOpenPromote(false);
  };

  const handleCloseApplySettings = (): void => {
    setOpenApplySettings(false);
  };

  const handleIssueCommandCallback = (): void => {
    handleCloseIssueCommand();
  };

  const handleIssueWorkflowCallback = (): void => {
    handleCloseIssueWorkflow();
  };

  return isActive ? (
    <>
      <Button
        data-cy="issue-command-button"
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('command')}
      >
        Issue Command
      </Button>
      <IssueCommandDrawer
        open={openIssueCommand}
        devices={selectedDevices}
        handleCloseDrawer={handleCloseIssueCommand}
        handleSubmitSuccess={handleIssueCommandCallback}
        onRemoveDevice={onRemoveDevice}
      />
      <Button
        data-cy="apply-settings-button"
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('settings')}
      >
        Apply Settings
      </Button>
      <ApplySettingsDrawer
        open={openApplySettings}
        isBulk={true}
        commands={[]}
        devices={selectedDevices}
        handleCloseDrawer={handleCloseApplySettings}
        onRemoveDevice={onRemoveDevice}
      />
      {showWorkflowAction && (
        <>
          <Button
            data-cy="issue-workflow-button"
            variant="contained"
            size="medium"
            className="ml-4"
            onClick={handleOpen('workflow')}
          >
            Issue Workflow
          </Button>
          <IssueWorkflowDrawer
            open={openIssueWorkflow}
            devices={selectedDevices}
            handleCloseDrawer={handleCloseIssueWorkflow}
            handleSubmitSuccess={handleIssueWorkflowCallback}
            onRemoveDevice={onRemoveDevice}
          />
        </>
      )}
    </>
  ) : isEscrow ? (
    <>
      <Button
        data-cy="issue-transfer-button"
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('transfer')}
      >
        Issue Transfer
      </Button>
      <IssueTransferDrawer
        open={openTransfers}
        escrowDevices={devicesState.escrowDevices.filter((escrowDevice) =>
          selectedDevicesIds?.includes(escrowDevice._id),
        )}
        handleCloseDrawer={handleCloseTransfers}
      />
    </>
  ) : isDiscovered ? (
    <>
      <Button
        data-cy="promote-button"
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('promote')}
      >
        Promote
      </Button>
      <PromoteDevicesDrawer
        open={openPromote}
        discoveredDevices={devicesState.discoveredDevices.filter((device) =>
          selectedDevicesIds?.includes(device._id),
        )}
        handleCloseDrawer={handleClosePromote}
      />
    </>
  ) : (
    <></>
  );
};

export default DevicesExtraActions;
