import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { SoftwareUpdates, SoftwareUpdate } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getSoftwareUpdatesSelector,
  setActualSoftwareUpdate,
} from '../../redux/reducers/softwareUpdates.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { dispatchError } from '../../helpers/utils';
import { LocationState } from '../../models/common';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import FooterBar from '../../components/FooterBar';
import SoftwarePackageDetails from './softwarePackageDetails';

const SoftwarePackageContent: React.FC = () => {
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const softwareUpdateData = useAppSelector((state: RootState) =>
    getSoftwareUpdatesSelector(state.softwareUpdates, id),
  );
  const newSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.newSoftwareUpdate,
  );
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const errorDispatcher = dispatchError(dispatch);
  const [softwareUpdateCompany] = useFetchCompany(
    softwareUpdateData?.company_id,
    errorDispatcher,
  );

  const setSoftwareUploadedFiles = (filesData: SoftwareUpdate): void => {
    const softwareUpdateDatafiles = filesData.files;
    const toSetUploadedFiles =
      (softwareUpdateDatafiles &&
        (softwareUpdateDatafiles.filter(
          (file) => !!file,
        ) as unknown as File[])) ??
      ([] as File[]);
    setUploadedFiles(toSetUploadedFiles);
  };

  useEffect(() => {
    if (softwareUpdateData) {
      dispatch(setActualSoftwareUpdate(softwareUpdateData));
      setSoftwareUploadedFiles(softwareUpdateData);
    } else if (id) {
      SoftwareUpdates.getOneById(id)
        .then((response) => {
          dispatch(setActualSoftwareUpdate(response));
          setSoftwareUploadedFiles(response);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [softwareUpdateData]);

  const handleDeleteDevice = (): void => {
    if (!softwareUpdateData) {
      return;
    }
    setLoading(true);
    SoftwareUpdates.delete(softwareUpdateData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete package',
            message: 'Package successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/software-updates');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newSoftwareUpdate) {
      const updateFiles =
        newSoftwareUpdate.files &&
        newSoftwareUpdate.files.filter(
          (file) =>
            file &&
            uploadedFiles.find((uploaded) => uploaded.name === file.name),
        );
      setLoading(true);
      SoftwareUpdates.update({
        ...newSoftwareUpdate,
        files: updateFiles,
      })
        .then(async (response) => {
          try {
            const files = await SoftwareUpdates.uploadFile(
              response._id,
              uploadedFiles ?? [],
            );
            response.files = files;
            dispatch(setActualSoftwareUpdate(response));

            dispatch(
              setAlert({
                highlight: 'Update package',
                message: 'Package successfully updated.',
                type: 'success',
              }),
            );
          } catch (error: unknown) {
            const { message } = error as { message: string };
            dispatch(
              setAlert({
                highlight: errorHighlight,
                message,
                type: 'error',
              }),
            );
          }
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newSoftwareUpdate, softwareUpdateCompany);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        model="software_update"
        title="Software Package content"
        goBack={goBackUrl ? goBackUrl : 'software-updates'}
        goBackLabel={goBackLabel || `Software Packages`}
      />
      {softwareUpdateData && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="software-package"
            title={softwareUpdateData.name}
            subtitle={softwareUpdateData._id}
            extraImage={softwareUpdateCompany?.branding?.logo_url}
            extraTitle={softwareUpdateCompany?.name}
            extraSubtitle={softwareUpdateCompany?._id}
            hideTitleImage={true}
            copySubtitleToClipboard
          />
          <Grid
            container
            columnSpacing={3}
            direction="row"
            className="py-6 px-8"
          >
            <SoftwarePackageDetails
              editableSoftwareUpdate={newSoftwareUpdate}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
            />
          </Grid>
        </Box>
      )}
      <FooterBar
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDevice}
      />
    </Grid>
  );
};

export default SoftwarePackageContent;
