import React, { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { DeviceTypes, PollableAttribute } from '@edgeiq/edgeiq-api-js';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  setOriginalPollableAttributes,
  setSelectedPollableAttributes,
} from '../../../redux/reducers/pollableAttributes.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { PollableAttributesDrawer } from '../../../containers/RightDrawer';
import { ContentPageTabProps } from '../../../models/common';
import TabsPage from '../../../components/TabsPage';
import useStyles from '../../deviceContent/styles';
import TypographyWithCopy from '../../../components/TypographyWithCopy';

const DeviceTypePollableAttributes: React.FC<ContentPageTabProps> = ({
  goToItem,
}) => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedPollableAttributes = useAppSelector(
    (state: RootState) => state.pollableAttributes.selectedPollableAttributes,
  );
  const originalPollableAttributes = useAppSelector(
    (state: RootState) => state.pollableAttributes.originalPollableAttributes,
  );
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  const columns: GridColDef[] = [
    {
      field: '_id',
      flex: 0.7,
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <TypographyWithCopy
          dataCy={`device-type-pollable-${params.row._id}`}
          text={params.row._id}
          typographyVariant="button"
        />
      ),
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
          onClick={goToItem('pollable-attribute', params.row._id)}
        >
          {params.row.description}
        </Typography>
      ),
    },
    {
      field: 'type',
      flex: 0.3,
      headerName: 'Type',
    },
    {
      field: 'interval',
      flex: 0.3,
      headerName: 'Interval',
    },
  ];

  const handleOpenPollableDrawer = (): void => {
    setOpen(true);
  };

  const handleClosePollableDrawer = (): void => {
    setOpen(false);
  };

  const handleChoosePollableAttributes = (
    newPollableAttributes: PollableAttribute[],
  ): void => {
    dispatch(setSelectedPollableAttributes(newPollableAttributes));
    setOpen(false);
    setLoading(true);

    const attachPollableAttributes = newPollableAttributes.filter(
      (newPollableAttribute) =>
        originalPollableAttributes.every(
          (originalPollableAttribute) =>
            newPollableAttribute._id !== originalPollableAttribute._id,
        ),
    );
    const detachPollableAttributes = originalPollableAttributes.filter(
      (originalPollableAttribute) =>
        newPollableAttributes.every(
          (newPollableAttribute) =>
            originalPollableAttribute._id !== newPollableAttribute._id,
        ),
    );

    Promise.all([
      Promise.all(
        attachPollableAttributes.map(async (attachPollableAttribute) => {
          await DeviceTypes.attachPollableAttribute(
            id as string,
            attachPollableAttribute._id,
          );
        }),
      ),
      Promise.all(
        detachPollableAttributes.map(async (detachPollableAttribute) => {
          await DeviceTypes.detachPollableAttribute(
            id as string,
            detachPollableAttribute._id,
          );
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalPollableAttributes(newPollableAttributes));
        dispatch(
          setAlert({
            highlight: 'Managing Pollable Attributes',
            message: 'Pollable Attributes successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing pollable attributes.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      DeviceTypes.getPollableAttribute(editableDeviceType._id)
        .then((response) => {
          dispatch(setOriginalPollableAttributes(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedPollableAttributes}
        addButtonIcon={false}
        addButtonLabel="Manage Pollable Attributes"
        onAddClick={handleOpenPollableDrawer}
        tableTitle="Pollable attributes attached"
        loading={loading}
      />
      <PollableAttributesDrawer
        open={open}
        itemPollableAttributes={true}
        handleCloseDrawer={handleClosePollableDrawer}
        onChoosingPollableAttributes={handleChoosePollableAttributes}
      />
    </Box>
  );
};

export default DeviceTypePollableAttributes;
