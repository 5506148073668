import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    infoIcon: {
      fontSize: '16px',
      verticalAlign: 'middle',
    },
    resetBtn: {
      height: '32px',
      verticalAlign: 'top',
    },
  }),
);

export default useStyles;
