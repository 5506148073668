import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Device, DevicesFilters, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import VerticalTabs from '../../components/VerticalTabs';

import useStyles from './styles';
import DevicesEntitiesSection from './DevicesEntitiesSection';
import DeviceProfilesEntitiesSection from './DeviceProfilesEntitiesSection';
import { EntitiesSectionItemType } from '.';

const EntitiesTabsLabel = {
  deviceProfiles: 'Device Profiles',
  devices: 'Devices',
};

interface EntitiesSectionProps {
  newInput: EntitiesSectionItemType;
  // If only one of the 2 entities is needed just send the one you need.
  selectedDevices?: Device[];
  selectedDeviceTypes?: DeviceType[];
  title?: string;
  subTitle?: string;
  mixedTypes?: boolean;
  showMixedTypes?: boolean;
  shadowContainer?: boolean;
  deviceFilters?: DevicesFilters;
  onChangeDevices?: (devices: Device[]) => void;
  onChangeDeviceTypes?: (deviceTypes: DeviceType[]) => void;
}

const EntitiesSection: React.FC<EntitiesSectionProps> = ({
  newInput,
  selectedDevices,
  selectedDeviceTypes,
  title = 'Select entities',
  subTitle = 'You can choose Devices and Device Profiles.',
  mixedTypes,
  showMixedTypes,
  shadowContainer,
  deviceFilters,
  onChangeDevices,
  onChangeDeviceTypes,
}) => {
  const classes = useStyles();
  /**
   * PLEASE KEEP THE ORDER THE WAY IT IS AND IGNORE THE LINTING ORDER
   */
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {};

    if (onChangeDevices && selectedDevices) {
      tabs.devices = (
        <DevicesEntitiesSection
          newItem={newInput}
          selectedDevices={selectedDevices}
          filters={deviceFilters}
          onChangeDevices={onChangeDevices}
        />
      );
    }

    if (onChangeDeviceTypes && selectedDeviceTypes) {
      tabs.deviceProfiles = (
        <DeviceProfilesEntitiesSection
          newItem={newInput}
          selectedDeviceProfiles={selectedDeviceTypes}
          onChangeDeviceTypes={onChangeDeviceTypes}
        />
      );
    }

    return tabs;
  };

  const defaultTab =
    typeof selectedDevices !== 'undefined' ? 'devices' : 'deviceProfiles';

  return (
    <Grid container>
      {newInput && (
        <Grid
          item
          xs={12}
          component={shadowContainer ? Paper : Grid}
          className={clsx({
            ['p-7 shadow']: shadowContainer,
          })}
        >
          <Typography
            variant="button"
            component="div"
            className={clsx('mb-1', classes.configTitle)}
          >
            {title}
          </Typography>
          <Typography
            variant="overline"
            component="div"
            className={clsx('mb-2', classes.configSubtitle)}
          >
            {subTitle}
          </Typography>
          {showMixedTypes && selectedDevices?.length ? (
            <Box className="mb-4">
              <Typography component="div" variant="overline">
                {mixedTypes
                  ? "Only LWM2M devices alone support 'Gateway command' action type."
                  : "'Gateway command' action type enabled."}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <VerticalTabs
            defaultTab={defaultTab}
            tabsLabel={EntitiesTabsLabel}
            tabs={getTabs()}
            padding={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EntitiesSection;
