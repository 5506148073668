import { User } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getUserSelector = createSelector(
  (state: UsersState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.users.length) {
      return state.currentUser;
    }
    return state.users.find((user: User) => user._id === id);
  },
);

export const UsersTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_NEW_USER: 'SET_NEW_USER',
  SET_USERS: 'SET_USERS',
};

export const setStateUsers = (users: User[]): UsersAction => ({
  data: users,
  type: UsersTypes.SET_USERS,
});

export const setStateCurrentUser = (user: User): UsersAction => ({
  data: user,
  type: UsersTypes.SET_CURRENT_USER,
});

export const setStateNewUser = (user: User): UsersAction => ({
  data: user,
  type: UsersTypes.SET_NEW_USER,
});

export type UsersState = {
  currentUser: User | null;
  newUser: User | null;
  users: User[];
};

// INITIAL STATE
const usersInitialState: UsersState = {
  currentUser: null,
  newUser: null,
  users: [],
};

export type UsersAction = {
  data: User[] | User;
  type: string;
};

const usersReducer = (
  state = usersInitialState,
  action: UsersAction,
): UsersState => {
  switch (action.type) {
    case UsersTypes.SET_USERS:
      return {
        ...state,
        users: action.data as User[],
      };

    case UsersTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.data as User,
        newUser: action.data as User,
      };

    case UsersTypes.SET_NEW_USER:
      return {
        ...state,
        newUser: action.data as User,
      };

    default:
      return state;
  }
};

export default usersReducer;
