import { Command, CommandInput } from '@edgeiq/edgeiq-api-js';

export const handleCommandDynamicValues = (
  command: Command | CommandInput,
  prop: string,
  value: string | number,
  field: string,
  index: string,
  optionsKeys: string[],
  optionsRows: { [key: string]: string }[],
): Command | CommandInput => {
  switch (prop) {
    case 'gcp_topic':
      return {
        ...(command as CommandInput | Command),
        sender: {
          ...command?.sender,
          [`gcp_topic`]: value,
        },
      };
      break;
    case 'integration_id':
      return {
        ...(command as CommandInput | Command),
        sender: {
          ...command?.sender,
          [`integration_id`]: value,
        },
      };
      break;
    case 'attributes':
      const attbArray =
        (command.sender?.attributes as { [key: string]: string }) || {};
      const attributeObj = { ...attbArray };
      const tempArray = Object.entries(attributeObj);
      if (field === 'name') {
        tempArray[Number(index)][0] = value as string;
      }

      if (field === 'value') {
        tempArray[Number(index)][1] = value as string;
      }
      return {
        ...(command as CommandInput | Command),
        sender: {
          ...command?.sender,
          [`attributes`]: formatObjectAttributes(tempArray) || {},
        },
      };
      break;
    case 'options':
      if (field === 'key') {
        optionsKeys[Number(index)] = value as string;
      }

      if (field === 'default') {
        optionsRows[Number(index)].default = value as string;
      }

      if (field === 'type') {
        optionsRows[Number(index)].type = value as string;
      }

      const formatttedOptionsResult = formatedObjectOptions(
        optionsKeys,
        optionsRows,
      );
      return {
        ...(command as CommandInput | Command),
        options: formatttedOptionsResult,
      };
      break;
    default:
      return command;
      break;
  }
};

const formatObjectAttributes = (
  tempArray: [string, string][],
): { [key: string]: string } => {
  return tempArray.reduce((acc, current) => {
    const key = current[0];
    const value = current[1];
    return { ...acc, [key]: value };
  }, {});
};

export const formatedObjectOptions = (
  optionsKeysParam: string[],
  optionsRows: { [key: string]: string }[],
): { [key: string]: string } => {
  return optionsKeysParam.reduce((acc, current, index) => {
    return { ...acc, ...{ [current]: optionsRows[index] } };
  }, {});
};
