import { Companies, Company } from '@edgeiq/edgeiq-api-js';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';

export const useFetchCompany = (
  id: string | undefined,
  cb: (errorMessage: string) => void,
): [Company | undefined] => {
  const { userCompanies } = useAppSelector((state: RootState) => state.user);
  const [company, setCompany] = useState<Company | undefined>();
  useEffect(() => {
    const getCompany = async (): Promise<void> => {
      if (id && id !== 'machineshop') {
        try {
          let stateCompany: Company | undefined = undefined;
          if (userCompanies && userCompanies.length !== 0) {
            stateCompany = userCompanies.find((item) => item._id === id);
          }
          if (stateCompany) {
            setCompany(stateCompany);
          } else {
            const fetchedCompany = await Companies.getOneById(id);
            setCompany(fetchedCompany);
          }
        } catch (error) {
          const { message: errorMessage } = error as { message: string };
          cb(errorMessage);
        }
      }
    };
    getCompany();
  }, [id]);

  return [company] as [Company | undefined];
};
