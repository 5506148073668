import { Secret } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../components/SharedTable';

export const SecretColumns = (): TableColumn[] => {
  return [
    {
      // type: 'link',
      // link: (item: TableItemType): string => {
      //   const secret = item as Secret;
      //   return `secret/${secret._id}`;
      // },
      cellValue: (item: TableItemType): string => {
        const secret = item as Secret;
        return `${secret.name}`;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const secret = item as Secret;
        return secret.hash ?? '--';
      },
      id: 'hash',
      label: 'Hash',
      type: 'text',
    },
  ];
};
