import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'start',
    },
    icon: {
      fontSize: '16px',
    },
    iconButton: {
      cursor: 'pointer',
    },
    tooltip: {
      margin: '0',
      textAlign: 'center',
    },
  }),
);

export default useStyles;
