import React, { useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { ConnectionConfig } from '@edgeiq/edgeiq-api-js';

import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import SwitchButton from '../../../../components/SwitchButton';
import {
  deviceConfigConnectionWifeAuth,
  deviceConfigConnectionWifiEncryption,
  deviceConfigConnectionWifiMode,
  deviceConfigConnectionAssignLabel,
} from '../../../../app/constants';
import {
  EMPTY_CONFIG_CONNECTION_WAN,
  EMPTY_CONFIG_CONNECTION_WANLAN,
} from '../../../../constants/configConnections';

interface DeviceConfigConnectionFormProps {
  deviceConnection?: ConnectionConfig;
  configIndex: number;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
}

const DeviceConfigConnectionWifiForm: React.FC<
  DeviceConfigConnectionFormProps
> = ({ configIndex, deviceConnection, onInputChange }) => {
  const [configConnection, setConfigConnection] = useState<ConnectionConfig>(
    deviceConnection ||
      EMPTY_CONFIG_CONNECTION_WAN ||
      EMPTY_CONFIG_CONNECTION_WANLAN,
  );

  const handerLocalConfigConnection = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    setConfigConnection((currentConfig) => {
      return (currentConfig = {
        ...currentConfig,
        [prop]: value,
      });
    });
  };

  const handleDynamicChange =
    (prop: string, index: number) =>
    (field: string, value: string | number): void => {
      handerLocalConfigConnection(prop, value as string);
      onInputChange(prop, value, field, index);
    };

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    handerLocalConfigConnection(prop, value as boolean);
    onInputChange(prop, value as number, '', configIndex);
  };

  return (
    <>
      <Grid container direction="row" className="mt-2" spacing={2}>
        <Grid item xs={6}>
          <SwitchButton
            label="Enabled"
            value={configConnection?.wifi_enable === 1}
            prop="wifi_enable"
            onSwitchChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SwitchButton
            label="Hidden Network"
            value={configConnection?.wifi_hidden === 1}
            prop="wifi_hidden"
            onSwitchChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            prop="wifi_ssid"
            label="SSID"
            placeholder=""
            type="text"
            value={configConnection?.wifi_ssid}
            onInputChange={handleDynamicChange('wifi_ssid', configIndex)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            prop="wifi_psk"
            label="Password Secret"
            placeholder=""
            type="password"
            value={configConnection?.wifi_psk}
            onInputChange={handleDynamicChange('wifi_psk', configIndex)}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="WIFI Authentication"
            prop="wifi_auth"
            value={configConnection?.wifi_auth}
            onSelectChange={handleDynamicChange('wifi_auth', configIndex)}
            options={Object.keys(deviceConfigConnectionWifeAuth).map(
              (key, index) => (
                <MenuItem className="m-4 p-2" key={index} dense value={key}>
                  {deviceConfigConnectionWifeAuth[key]}
                </MenuItem>
              ),
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Encryption"
            prop="wifi_crypt"
            value={configConnection?.wifi_crypt}
            onSelectChange={handleDynamicChange('wifi_crypt', configIndex)}
            options={Object.keys(deviceConfigConnectionWifiEncryption).map(
              (key, index) => (
                <MenuItem className="m-4 p-2" key={index} dense value={key}>
                  {deviceConfigConnectionWifiEncryption[key]}
                </MenuItem>
              ),
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            label="Wifi Mode"
            prop="wifi_mode"
            value={configConnection?.wifi_mode}
            onSelectChange={handleDynamicChange('wifi_mode', configIndex)}
            options={Object.keys(deviceConfigConnectionWifiMode).map(
              (key, index) => (
                <MenuItem className="m-4 p-2" key={index} dense value={key}>
                  {deviceConfigConnectionWifiMode[key]}
                </MenuItem>
              ),
            )}
          />
        </Grid>
        {configConnection?.wifi_mode === 'client' && (
          <Grid item xs={12}>
            <SelectInput
              label="IP Assignment"
              prop="net_ip_assign"
              value={configConnection?.net_ip_assign}
              onSelectChange={handleDynamicChange('net_ip_assign', configIndex)}
              options={Object.keys(deviceConfigConnectionAssignLabel).map(
                (key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {deviceConfigConnectionAssignLabel[key]}
                  </MenuItem>
                ),
              )}
            />
          </Grid>
        )}
        {configConnection?.wifi_mode === 'accesspoint' && (
          <Grid item xs={12}>
            <TextInput
              prop="wifi_channel"
              label="Channel (1-14)"
              placeholder=""
              type="number"
              value={configConnection?.wifi_channel}
              onInputChange={handleDynamicChange('wifi_channel', configIndex)}
            />
          </Grid>
        )}

        {configConnection?.wifi_mode !== 'accesspoint' &&
          configConnection?.net_ip_assign === 'static' && (
            <>
              <Grid item xs={6}>
                <TextInput
                  prop="net_ip"
                  label="IP Address"
                  placeholder=""
                  type="text"
                  value={configConnection?.net_ip}
                  onInputChange={handleDynamicChange('net_ip', configIndex)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  prop="net_gateway"
                  label="Gateway IP"
                  placeholder=""
                  type="text"
                  value={configConnection?.net_gateway}
                  onInputChange={handleDynamicChange(
                    'net_gateway',
                    configIndex,
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  prop="net_mask"
                  label="Net Mask"
                  placeholder=""
                  type="text"
                  value={configConnection?.net_mask}
                  onInputChange={handleDynamicChange('net_mask', configIndex)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  prop="net_broadcast"
                  label="Broadcast IP"
                  placeholder=""
                  type="text"
                  value={configConnection?.net_broadcast}
                  onInputChange={handleDynamicChange(
                    'net_broadcast',
                    configIndex,
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  prop="net_dns_1"
                  label="DNS 1"
                  placeholder=""
                  type="text"
                  value={configConnection?.net_dns_1}
                  onInputChange={handleDynamicChange('net_dns_1', configIndex)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  prop="net_dns_2"
                  label="DNS 2"
                  placeholder=""
                  type="text"
                  value={configConnection?.net_dns_2}
                  onInputChange={handleDynamicChange('net_dns_2', configIndex)}
                />
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};

export default DeviceConfigConnectionWifiForm;
