import React, { useState } from 'react';
import { CircularProgress, Grid, Paper } from '@mui/material';
import Header from '../../components/Header';

import BulkJobsChart from './BulkJobsChart';
import DeviceByTypeChart from './DeviceByTypeChart';
import GatewayCommandsChart from './GatewayCommandsChart';
import HeartbeatChart from './HeartbeatChart';
import SoftwareUpdatesChart from './SoftwareUpdatesChart';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import HeartbeatTable from './HeartbeatsTable';
import AlertsTable from './AlertsTable';
import DeviceErrorsChart from './DeviceErrors';

const PaperWrapper: React.FC = ({ children }) => (
  <Paper className="p-6 shadow">{children}</Paper>
);

const Dashboard: React.FC = () => {
  const [loading, _setLoading] = useState(false);
  const stateUser = useAppSelector((state: RootState) => state.user);

  return (
    <Grid container direction="column" spacing={0}>
      {loading && (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      )}
      {!loading && (
        <>
          <Header
            title={`Hi ${stateUser.user?.first_name}`}
            subtitle="Here what's happening with your devices"
          />

          <Grid container spacing={5} className="px-8 py-8">
            <Grid item xs={12} sm={12} lg={6}>
              <PaperWrapper>
                <HeartbeatChart />
              </PaperWrapper>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <PaperWrapper>
                <DeviceByTypeChart />
              </PaperWrapper>
            </Grid>

            <Grid item xs={12}>
              <PaperWrapper>
                <DeviceErrorsChart />
              </PaperWrapper>
            </Grid>

            <Grid item xs={12} lg={8}>
              <PaperWrapper>
                <SoftwareUpdatesChart />
              </PaperWrapper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper className="p-6">
                <AlertsTable />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={8}>
              <PaperWrapper>
                <BulkJobsChart />
              </PaperWrapper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <PaperWrapper>
                <HeartbeatTable />
              </PaperWrapper>
            </Grid>

            <Grid item xs={12}>
              <PaperWrapper>
                <GatewayCommandsChart />
              </PaperWrapper>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Dashboard;
