import { GatewayCommand } from '@edgeiq/edgeiq-api-js';
import { gatewayActions } from '../RightDrawer/IssueCommand/constants';

export const getGatewayCommandName = (command: GatewayCommand): string => {
  for (let i = 0; i < gatewayActions.length; i++) {
    const action = gatewayActions[i];
    if (action.action === command.command_type) {
      return action.label;
    }
  }
  return '';
};
