import React, { useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface AccordionProps {
  content: JSX.Element;
  title: string;
  titleVariant?: 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  key?: string;
  openByDefault?: boolean;
  nextTitle?: React.ReactElement;
}

const Accordion: React.FC<AccordionProps> = ({
  content,
  title,
  titleVariant = 'h6',
  className,
  openByDefault = false,
  key,
  nextTitle,
}) => {
  const [expanded, setExpanded] = useState(openByDefault);

  const handleChange = (): void => {
    setExpanded(!expanded);
  };

  return (
    <MuiAccordion
      square={true}
      data-cy={key}
      expanded={expanded}
      onChange={handleChange}
      className={className}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant={titleVariant} className="mr-3 va-middle">
          {title}
        </Typography>
        {nextTitle && nextTitle}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
