import React from 'react';
import { Company } from '@edgeiq/edgeiq-api-js';
import { Grid, Typography, Paper } from '@mui/material';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setNewCompany } from '../../../redux/reducers/companies.reducer';
import SwitchButton from '../../../components/SwitchButton';
import { PAGE_OPTIONS_LABEL } from '../../../constants/uiConfigurations';
import { PagesOptionsIndexes } from '../../../models/uiConfiguration';

const AccountUIConfiguration: React.FC = () => {
  const dispatch = useAppDispatch();
  const editableCompany = useAppSelector(
    (state: RootState) => state.companies.newCompany,
  );

  const handleAbilitiesSwitchChange = (
    prop: string,
    checked: boolean,
  ): void => {
    dispatch(
      setNewCompany({
        ...editableCompany,
        ui_configuration: {
          ...editableCompany?.ui_configuration,
          navigation_visibility: {
            ...editableCompany?.ui_configuration.navigation_visibility,
            [prop]: !checked,
          },
        },
      } as Company),
    );
  };

  return (
    <Paper className={clsx('p-7 br-1 shadow')}>
      {editableCompany && (
        <>
          <Typography
            variant="h5"
            className="mb-6"
            data-cy="ui-navigation-header"
          >
            UI Navigation
          </Typography>
          <Grid container>
            {editableCompany.ui_configuration.navigation_visibility
              ? Object.keys(
                  editableCompany.ui_configuration.navigation_visibility,
                ).map((key) => (
                  <Grid item xs={12} sm={6} md={4} key={key}>
                    <SwitchButton
                      label={
                        PAGE_OPTIONS_LABEL[key as PagesOptionsIndexes] || key
                      }
                      value={
                        !(editableCompany.ui_configuration.navigation_visibility
                          ? editableCompany.ui_configuration
                              .navigation_visibility[key as PagesOptionsIndexes]
                          : false)
                      }
                      prop={key}
                      onSwitchChange={handleAbilitiesSwitchChange}
                    />
                  </Grid>
                ))
              : null}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default AccountUIConfiguration;
