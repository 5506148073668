import { Secret } from '@edgeiq/edgeiq-api-js';

export const SecretsTypes = {
  SET_SECRETS: 'SET_SECRETS',
};

export const setStateSecrets = (secrets: Secret[]): SecretsAction => ({
  data: secrets,
  type: SecretsTypes.SET_SECRETS,
});

export type SecretsState = {
  secrets: Secret[];
};

// INITIAL STATE
const secretsInitialState: SecretsState = {
  secrets: [],
};

export type SecretsAction = {
  data: Secret[] | Secret;
  type: string;
};

const secretsReducer = (
  state = secretsInitialState,
  action: SecretsAction,
): SecretsState => {
  switch (action.type) {
    case SecretsTypes.SET_SECRETS:
      return {
        ...state,
        secrets: action.data as Secret[],
      };
    default:
      return state;
  }
};

export default secretsReducer;
