import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Paper } from '@mui/material';
import {
  Configuration,
  Configurations,
  Device,
  Devices,
  DeviceType,
  DeviceTypes,
  Setting,
  Settings,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { getConfigurationSelector } from '../../redux/reducers/configuration.reducer';
import {
  dispatchError,
  getAttachError,
  getFetchError,
} from '../../helpers/utils';
import {
  initialSettingsEditorData,
  SettingsEditorData,
} from '../../models/settings';
import { LocationState } from '../../models/common';
import { DETAILS_DEFAULT_TAB, settingsTabsLabel } from '../../app/constants';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import Header from '../../containers/HeaderWithActionButton';
import EntitiesSection from '../../containers/EntitiesSection';
import SettingsDetails from './SettingsDetails';

const SettingsContent: React.FC = () => {
  const { id, configId } = useParams<string>();
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const stateConfiguration = useAppSelector((state: RootState) =>
    getConfigurationSelector(state.configurations, configId),
  );
  const [configurationData, setConfigurationData] = useState<
    Configuration | undefined | null
  >(stateConfiguration);
  const [settingVersions, setSettingVersions] = useState<Setting[]>([]);
  const [settings, setSettings] = useState<Setting>();
  const [newSettings, setNewSettings] = useState<Setting>();
  const [editorData, setEditorData] = useState<SettingsEditorData>({
    ...initialSettingsEditorData,
  });
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [configurationDeviceTypes, setConfigurationDeviceTypes] = useState<
    DeviceType[]
  >([]);
  const [loading, setLoading] = useState(true);

  const setSettingVersion = (versionSetting: Setting): void => {
    setSettings(versionSetting);
    setNewSettings(versionSetting);
    if (versionSetting.values) {
      let jsonText = '';
      try {
        jsonText = JSON.stringify(versionSetting.values, null, 2);
      } catch (error) {
        console.info(error);
      }
      setEditorData({
        ...editorData,
        jsonContent: jsonText,
        jsonTree: versionSetting.values,
        manualCreation: true,
      });
    }
  };

  const getSettingsData = (): void => {
    if (id) {
      // getting version from hash
      const hash = location.hash;
      const version = hash.split('#')[1];
      Settings.list({
        _id: {
          operator: 'eq',
          value: id,
        },
      })
        .then((response) => {
          const returnSettings = version
            ? response.settings.find((s) => s.version === parseInt(version))
            : response.settings[response.settings.length - 1];
          if (returnSettings) {
            setSettingVersion(returnSettings);
          }
          setSettingVersions(response.settings);
          return Settings.getDevices(id);
        })
        .then((response) => {
          setSelectedDevices(response);
          return Settings.getDeviceTypes(id);
        })
        .then((response) => {
          setSelectedDeviceTypes(response);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('settings data'),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!configurationData && configId) {
      Configurations.getOneById(configId)
        .then((response) => {
          setConfigurationData(response);
          getSettingsData();
          return Configurations.getDeviceTypes(configId);
        })
        .then((response) => {
          setConfigurationDeviceTypes(response);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('configuration data'),
          );
        });
    } else {
      getSettingsData();
    }
  }, [location]);

  const handleDeleteSetting = (): void => {
    if (!settings) {
      return;
    }

    setLoading(true);
    Settings.delete(settings._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete setting',
            message: 'Setting successfully deleted.',
            type: 'success',
          }),
        );
        navigate(`/configuration/${configId}`);
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          'Error deleting settings',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    const versionChange =
      newSettings?.name !== settings?.name ||
      !isEqual(newSettings?.values, settings?.values);

    setLoading(true);
    Settings.update(newSettings as Setting, versionChange)
      .then((response) => {
        setSettings(response);
        dispatch(
          setAlert({
            highlight: 'Updated setting',
            message: 'Setting successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          'Error updating settings',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    switch (prop) {
      case 'settings-version':
        const version = settingVersions.find((s) => s.version === value);
        if (version) {
          setSettingVersion(version);
          window.location.hash = `#${value}`;
        }
        break;

      default:
        setNewSettings({
          ...newSettings,
          [prop]: value,
        } as Setting);
        break;
    }
  };

  const handleSettingsEditorChange = (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ): void => {
    setEditorData({
      ...editorData,
      [prop]: value,
    });
    if (prop === 'jsonTree') {
      setNewSettings({
        ...newSettings,
        values: value,
      } as Setting);
    }
  };

  const handleChangeDevices = (devices: Device[]): void => {
    const devicesToDetach = selectedDevices.filter(
      (device) => !devices.find((d) => d._id === device._id),
    );
    const devicesToAttach = devices.filter(
      (device) => !selectedDevices.find((d) => d._id === device._id),
    );
    if (id) {
      Promise.all([
        Promise.all(
          devicesToAttach.map(async (device) => {
            await Devices.attachSettings(device._id, id);
          }),
        ),
        Promise.all(
          devicesToDetach.map(async (device) => {
            await Devices.detachSettings(device._id, id);
          }),
        ),
      ])
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Updated attached devices',
              type: 'success',
            }),
          );
          setSelectedDevices(devices);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('devices', 'configuration'),
          );
        });
    }
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    const deviceTypesToDetach = selectedDeviceTypes.filter(
      (dt) => !deviceTypes.find((d) => d._id === dt._id),
    );
    const deviceTypesToAttach = deviceTypes.filter(
      (dt) => !selectedDeviceTypes.find((d) => d._id === dt._id),
    );
    if (id) {
      Promise.all([
        Promise.all(
          deviceTypesToAttach.map(async (deviceType) => {
            await DeviceTypes.attachSettings(deviceType._id, id);
            // If the configuration doesn't have the device type attached it will be
            if (
              !configurationDeviceTypes.find(
                (dt) => dt._id === deviceType._id,
              ) &&
              configurationData?._id
            ) {
              await DeviceTypes.attachConfiguration(
                deviceType._id,
                configurationData._id,
              );
            }
          }),
        ),
        Promise.all(
          deviceTypesToDetach.map(async (deviceType) => {
            await DeviceTypes.detachSettings(deviceType._id, id);
          }),
        ),
      ])
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Updated attached device profiles',
              type: 'success',
            }),
          );
          setSelectedDeviceTypes(deviceTypes);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('device profiles', 'configuration'),
          );
        });
    }
  };

  /**
   * PLEASE KEEP THE ORDER THE WAY IT IS AND IGNORE THE LINTING ORDER
   */
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: (
        <SettingsDetails
          settings={newSettings}
          settingsEditorData={editorData}
          versions={settingVersions.length}
          onInputChange={handleInputChange}
          onSettingsEditorChange={handleSettingsEditorChange}
        />
      ),
      devices_and_device_profiles: (
        <Paper className="shadow p-8">
          {newSettings && (
            <EntitiesSection
              newInput={newSettings}
              selectedDevices={selectedDevices}
              selectedDeviceTypes={selectedDeviceTypes}
              deviceFilters={{
                device_type_id: {
                  operator: 'in',
                  value: configurationDeviceTypes.map((c) => c._id),
                },
              }}
              onChangeDevices={handleChangeDevices}
              onChangeDeviceTypes={handleChangeDeviceTypes}
            />
          )}
        </Paper>
      ),
    };
    return tabs;
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newSettings, settings);
  };

  return (
    <Grid container direction="column" spacing={0} className="prueba">
      <Header
        goBack={
          goBackUrl ? goBackUrl : `configuration/${configurationData?._id}`
        }
        goBackLabel={goBackLabel || `Configuration: ${configurationData?.name}`}
        isCreatePage={false}
        model="setting"
      />
      <Box className="content-page-container">
        <ContentHeader
          title={newSettings?.name || ''}
          contentType="configuration"
          subtitle={newSettings?._id}
        />
        <VerticalTabs
          tabs={getTabs()}
          defaultTab={DETAILS_DEFAULT_TAB}
          tabsLabel={settingsTabsLabel}
        />
      </Box>
      <FooterBar
        deleteModalContent="You are about to delete this setting"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteSetting}
      />
    </Grid>
  );
};

export default SettingsContent;
