import React from 'react';
import { Tooltip, Typography, Box } from '@mui/material';
import { PollableAttribute } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import useStyles from '../../../components/RightDrawer/styles';
import { getCompanySelector } from '../../../redux/reducers/user.reducer';

interface PollableAttributeCardProps {
  pollableAttribute: PollableAttribute;
}

const PollableAttributeCard: React.FC<PollableAttributeCardProps> = ({
  pollableAttribute,
}) => {
  const companyData = useAppSelector((state: RootState) =>
    getCompanySelector(state.user, pollableAttribute.company_id),
  );
  const classes = useStyles({ accountImage: companyData?.branding?.logo_url });

  return (
    <>
      <Tooltip placement="top" title={pollableAttribute.name}>
        <Typography
          variant="h6"
          component="div"
          noWrap
          className={clsx('mb-2')}
        >
          {pollableAttribute.name}
        </Typography>
      </Tooltip>
      <Typography
        variant="caption"
        component="div"
        className={clsx('mb-1', classes.yellowSubtitle)}
      >
        INTERVAL {pollableAttribute.interval}
      </Typography>
      <Typography variant="overline" component="div" className={clsx('mb-1')}>
        {pollableAttribute.type}
      </Typography>
      <Box className={clsx('mb-1', classes.accountBox)}>
        <Box className={clsx(classes.imageBox)}></Box>
        <Typography variant="overline" component="div" className="ml-2">
          {companyData?.name}
        </Typography>
      </Box>
    </>
  );
};

export default PollableAttributeCard;
