import { Company, EIQLoginUser, User, UserType } from '@edgeiq/edgeiq-api-js';
import { parsePermissions } from '../../helpers/permissions';
import { createSelector } from 'reselect';
import {
  removeToken,
  setInitialFilters,
  setToken,
} from '../../helpers/storage';

export const getCompanySelector = createSelector(
  (state: UserState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    return state.userCompanies.find((company: Company) => company._id === id);
  },
);

export const UserTypes = {
  LOGOUT_USER: 'LOGOUT_USER',
  SET_USER_COMPANIES: 'SET_USER_COMPANIES',
  SET_USER_COMPANY: 'SET_USER_COMPANY',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_TYPE: 'SET_USER_TYPE',
};

export const setUserData = (user: EIQLoginUser | User): UserAction => {
  if ((user as EIQLoginUser).session_token) {
    setToken((user as EIQLoginUser).session_token);
  }
  return {
    data: user,
    type: UserTypes.SET_USER_DATA,
  };
};

export const setUserCompany = (company: Company): UserAction => ({
  data: company,
  type: UserTypes.SET_USER_COMPANY,
});

export const setUserCompanies = (companies: Company[]): UserAction => ({
  data: companies,
  type: UserTypes.SET_USER_COMPANIES,
});

export const setUserType = (userType: UserType): UserAction => ({
  data: userType,
  type: UserTypes.SET_USER_TYPE,
});

export const logoutUser = (): UserAction => {
  removeToken();
  setInitialFilters(true);
  return {
    data: null,
    type: UserTypes.LOGOUT_USER,
  };
};

export type UserState = {
  isLoggedIn: boolean;
  permissions: {
    [key: string]: {
      create: boolean;
      update: boolean;
      read: boolean;
      delete: boolean;
    };
  };
  user: EIQLoginUser | User | null;
  userCompanies: Company[];
  userCompany: Company | null;
  userType: UserType | null;
};

// INITIAL STATE
const userInitialState: UserState = {
  isLoggedIn: false,
  permissions: {},
  user: null,
  userCompanies: [],
  userCompany: null,
  userType: null,
};

export type UserAction = {
  data: EIQLoginUser | User | Company | Company[] | UserType | null;
  type: string;
};

const userReducer = (
  state = userInitialState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case UserTypes.SET_USER_DATA:
      return {
        ...state,
        isLoggedIn: true,
        user: action.data as EIQLoginUser | User,
      };

    case UserTypes.SET_USER_COMPANY:
      return {
        ...state,
        userCompany: action.data as Company,
      };

    case UserTypes.SET_USER_COMPANY:
      return {
        ...state,
        userCompany: action.data as Company,
      };

    case UserTypes.SET_USER_COMPANIES:
      return {
        ...state,
        userCompanies: action.data as Company[],
      };

    case UserTypes.SET_USER_TYPE:
      const userType = action.data as UserType;
      return {
        ...state,
        permissions: parsePermissions(userType.abilities),
        userType,
      };

    case UserTypes.LOGOUT_USER:
      return {
        ...state,
        ...userInitialState,
      };

    default:
      return state;
  }
};

export default userReducer;
