import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

interface StyleProps {
  accountImage?: string;
  integrationInfoImage?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    accountBox: {
      alignItems: 'center',
      display: 'flex',
    },
    cardTitle: {
      color: theme.palette.grey[100],
      width: '200px',
    },
    closeDrawerIcon: {
      color: theme.palette.grey[100],
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
      flexGrow: 1,
      maxHeight: 'calc(100vh - 88px)',
      overflow: 'auto',
    },
    drawerDeviceContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey.A100,
      display: 'flex',
      justifyContent: 'space-between',
    },
    drawerSelectedDevices: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
      maxHeight: '200px',
      overflow: 'auto',
    },
    footer: {
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.grey.A200}`,
      display: 'flex',
      flexBasis: 'auto',
      justifyContent: 'space-between',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      flexBasis: 'auto',
      justifyContent: 'space-between',
    },
    imageBox: (props?) => ({
      backgroundImage: `url(${props?.accountImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '50%',
      height: '20px',
      width: '20px',
    }),
    imageLayout: (props?) => ({
      backgroundImage: `url(${props?.integrationInfoImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: '5px',
      height: '340px',
      width: '100%',
    }),
    infoContainer: {
      backgroundColor: theme.palette.info.dark,
      display: 'flex',
    },
    infoIcon: {
      color: theme.palette.common.white,
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
      width: '300px',
    },
    statusContainer: {
      position: 'absolute',
      right: '0',
      top: '16px ',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    tag: {
      alignItems: 'center',
      color: theme.palette.grey[100],
      display: 'flex',
    },
    uniqueId: {
      width: '225px',
    },
    yellowSubtitle: {
      color: theme.palette.warning.dark,
    },
  }),
);

export default useStyles;
