import { SortingOption } from '../models/common';

export const LAST_UPDATED_SORTING: SortingOption = {
  label: 'Last Updated',
  value: '-updated_at',
};

export const CREATED_OLDEST_SORTING: SortingOption = {
  label: 'Created (Oldest first)',
  value: 'created_at',
};

export const CREATED_NEWEST_SORTING: SortingOption = {
  label: 'Created (Newest first)',
  value: '-created_at',
};

export const GENERIC_SORTING: SortingOption[] = [
  LAST_UPDATED_SORTING,
  CREATED_OLDEST_SORTING,
  CREATED_NEWEST_SORTING,
];

export const OLDEST_NEWEST_SORTING: SortingOption[] = [
  CREATED_OLDEST_SORTING,
  CREATED_NEWEST_SORTING,
];

export const SORTING_OPTIONS_GENERIC_NAME: SortingOption[] = [
  ...GENERIC_SORTING,
  {
    label: '(A-Z)',
    value: 'name',
  },
];

export const SORTING_OPTIONS_OLDEST_NEWEST_NAME: SortingOption[] = [
  ...OLDEST_NEWEST_SORTING,
  {
    label: '(A-Z)',
    value: 'name',
  },
];

export const SORTING_OPTIONS_DEVICES: SortingOption[] = [
  ...GENERIC_SORTING,
  {
    label: 'ID (A-Z)',
    value: 'unique_id',
  },
  {
    label: '(A-Z)',
    value: 'name',
  },
];

export const SORTING_OPTIONS_USERS: SortingOption[] = [
  ...GENERIC_SORTING,
  {
    label: '(A-Z)',
    value: 'first_name',
  },
];
