import { TranslatorInput } from '@edgeiq/edgeiq-api-js';

export const COMMAND_TRANSLATOR_TYPES = [
  {
    key: 'template_cmd',
    label: 'Command Translator',
  },
];

export const TRANSLATOR_JAVASCRIPT_TYPE = `var Translator={
	isValid: function(e) {
		//evaluate validity and return true or false
		return true;
	},
	getMfrId: function(e) {
		//extract the device's id and return it
		return e.split(" ")[0];
	},
	translate: function(e) {
		//perform translation, return JSON
		var parts = e.split(",");
		var ret = {
			"device_id": deviceId,
			"device_datetime": ISODateString(new Date()),
			"payload": {
				"device_name": parts[1],
				"data_type": parts[3],
				"ph_value": Number(parts[4]),
				"orp_value": Number(parts[5]),
				"temperature": {
					"value": Number(parts[6].slice(0,5)),
					"unit": parts[6].slice(5,6)
				}
			},
			"raw_data": e
		};
		return JSON.stringify(ret);
	}
};`;

export const TRANSLATOR_TEMPLATE_TYPE = `{
  "device_id": "{{.gateway_unique_id}}",
  "payload": {{ json_object .json }}
}`;

export const TRANSLATOR_TYPES = [
  {
    key: 'javascript',
    label: 'JavaScript',
  },
  {
    key: 'template',
    label: 'Template',
  },
  {
    key: 'altair',
    label: 'SSI Smart Label Translator',
  },
  {
    key: 'gs',
    label: 'Globalstar Device Translator',
  },
  {
    key: 'inmarsat',
    label: 'Inmarsat Device Translator',
  },
  {
    key: 'orbcomm',
    label: 'Orbcomm Device Translator',
  },
  {
    key: 'assetlink',
    label: 'Assetlink Device Translator',
  },
];

export const ALL_TRANSLATOR_TYPES = [
  ...TRANSLATOR_TYPES,
  ...COMMAND_TRANSLATOR_TYPES,
];

export const EMPTY_TRANSLATOR: TranslatorInput = {
  company_id: '',
  name: '',
  script: TRANSLATOR_JAVASCRIPT_TYPE,
  type: 'javascript',
};

export const EMPTY_COMMAND_TRANSLATOR = {
  company_id: '',
  name: '',
  script: TRANSLATOR_TEMPLATE_TYPE,
  type: 'template_cmd',
};

export const TEXT_AREA_TYPES = {
  javascript: 'javascript',
  template: 'template',
  template_cmd: 'template_cmd',
} as const;
