import { Notification } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import timeHelpers from '../../../helpers/timeHelpers';

export const AlertsMessagesColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const alert = item as Notification;
      return `${timeHelpers.getDate(alert.created_at)}`;
    },
    id: 'created_at',
    label: 'Date',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const alert = item as Notification;
      return alert.message;
    },
    id: 'message',
    label: 'Message',
    type: 'text',
  },
];
