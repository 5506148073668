import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
    },
    message: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // width: '75%'
    },
    status: {
      position: 'absolute',
      right: 0,
    },
  }),
);

export default useStyles;
