import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chosenTypeButton: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.primary.main,
    },
    drawerDeviceContainer: {
      backgroundColor: theme.palette.grey.A100,
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterInput: {
      height: '44px',
      width: '100%',
    },
    importTemplateContainer: {
      backgroundColor: theme.palette.info.dark,
      display: 'flex',
    },
    infoIcon: {
      color: theme.palette.common.white,
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
    linkButton: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    tableTitle: {
      cursor: 'pointer',
      maxWidth: '250px',
    },
    tag: {
      alignItems: 'center',
      color: theme.palette.grey[100],
      display: 'flex',
    },
    typeButton: {
      color: theme.palette.grey[200],
    },
  }),
);

export default useStyles;
