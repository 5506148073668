import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dragContainer: {
      alignItems: 'center',
      border: '2px dashed #ECF1F7',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      height: '160px',
      justifyContent: 'center',
      marginBottom: '24px',
      marginTop: '24px',
    },
    fileContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey.A100,
      display: 'flex',
      justifyContent: 'space-between',
    },
    fileName: {
      alignItems: 'center',
      display: 'flex',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
  }),
);

export default useStyles;
