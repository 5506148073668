import { Configuration } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../components/SharedTable';
import TypographyWithCopy from '../../components/TypographyWithCopy';

export const ConfigurationColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const configuration = item as Configuration;
      return configuration.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    link: (item: TableItemType): string => {
      const configuration = item as Configuration;
      return `/configuration/${configuration._id}`;
    },
    type: 'link',
  },
  {
    id: '_id',
    label: 'Configuration ID',
    parser: (item): string | React.ReactElement => {
      const configuration = item as Configuration;
      return (
        <TypographyWithCopy
          component="div"
          dataCy={`copy-configuration-id-${configuration._id}-table`}
          text={configuration._id}
          textToCopy={configuration._id}
          tooltipText={configuration._id}
          typographyVariant="button"
          textMaxWidth={250}
        />
      );
    },
    type: 'custom',
  },
  {
    id: 'unique_id',
    label: 'Unique Identifier',
    parser: (item): string | React.ReactElement => {
      const configuration = item as Configuration;
      return (
        <TypographyWithCopy
          component="div"
          dataCy={`copy-configuration-unique-id-${configuration.unique_identifier}-table`}
          text={configuration.unique_identifier}
          textToCopy={configuration.unique_identifier}
          tooltipText={configuration.unique_identifier}
          typographyVariant="button"
          textMaxWidth={250}
        />
      );
    },
    type: 'custom',
  },
];
