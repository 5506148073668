import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import AwsIntegrationSection from './AwsIntegrationSection';
import AzureIntegrationSection from './AzureIntegrationSection';
import GcpIntegrationSection from './GcpIntegrationSection';
import GsIntegrationSection from './GsIntegrationSection';
import InmarsatIntegrationSection from './InmarsatIntegrationSection';
import OrbcommIntegrationSection from './OrbcommIntegrationSection';
import PostmarkIntegrationSection from './PostmarkIntegrationSection';
import SmtpIntegrationSection from './SmtpIntegrationSection';
import TwilioIntegrationSection from './TwilioIntegrationSection';

interface IntegrationDynamicSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
  hideEmptySensitiveFields?: boolean;
}

const IntegrationDynamicSection: React.FC<IntegrationDynamicSectionProps> = ({
  newIntegration,
  onInputChange,
  disabled,
  hideEmptySensitiveFields,
}) => {
  switch (newIntegration.type) {
    case 'aws_device_integrations':
      return (
        <AwsIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          disabled={disabled}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'azure_device_integrations':
      return (
        <AzureIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          disabled={disabled}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'gcp_cloud_native_device_integrations':
      return (
        <GcpIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'gs_cloud_native_device_integrations':
      return (
        <GsIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'inmarsat_cloud_native_device_integrations':
      return (
        <InmarsatIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'orbcomm_cloud_native_device_integrations':
      return (
        <OrbcommIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'postmark_rule_action_integrations':
      return (
        <PostmarkIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'smtp_rule_action_integrations':
      return (
        <SmtpIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    case 'twilio_rule_action_integrations':
      return (
        <TwilioIntegrationSection
          newIntegration={newIntegration}
          onInputChange={onInputChange}
          hideEmptySensitiveFields={hideEmptySensitiveFields}
        />
      );
    default:
      return <></>;
  }
};

export default IntegrationDynamicSection;
