import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    configSubtitle: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
    },
    configTitle: {
      color: theme.palette.grey[100],
    },
    container: {
      // The height of 4 items
      height: '228px',
      overflow: 'auto',
    },
    item: {
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.grey.A200}`,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export default useStyles;
