import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import {
  DeviceType,
  DeviceTypesFilters,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { RootState } from '../../../redux/store';
import { setOptionsDeviceTypes } from '../../../redux/reducers/deviceTypes.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import DeviceProfileCard from '../../../pages/devicesProfiles/DeviceProfileCard';

interface SelectDeviceProfilesDrawerProps {
  selectedDeviceTypes: string[];
  open: boolean;
  onCloseDrawer: () => void;
  onChoosingDeviceTypes: (deviceTypes: DeviceType[]) => void;
  companyId?: string;
}

const SelectDeviceProfilesDrawer: React.FC<SelectDeviceProfilesDrawerProps> = ({
  selectedDeviceTypes,
  open,
  onCloseDrawer,
  onChoosingDeviceTypes,
  companyId,
}) => {
  const dispatch = useAppDispatch();

  const attachDeviceTypes = useAppSelector(
    (state: RootState) => state.deviceTypes.optionsDeviceTypes,
  );

  const [deviceTypesList, setDeviceTypesList] = useState<DeviceType[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedDeviceTypes ?? [],
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedItems(selectedDeviceTypes);
  }, [selectedDeviceTypes]);

  const getDeviceTypes = (searchName?: string): void => {
    setLoading(true);

    const filters: DeviceTypesFilters = {
      company_id: { operator: 'eq', value: companyId || '' },
    };

    if (searchName) {
      filters.name = {
        operator: 'like',
        value: searchName,
      };
    }

    DeviceTypes.list(filters, optionsPaginationsFilter)
      .then((result) => {
        if (!attachDeviceTypes.length) {
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        }
        setDeviceTypesList(result.deviceTypes);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (attachDeviceTypes.length > 0) {
      return setDeviceTypesList(attachDeviceTypes);
    }
    getDeviceTypes();
  }, [selectedDeviceTypes]);

  const handleOnChangeCallback = (value: string): void => {
    getDeviceTypes(value);
  };

  const checkDeviceTypesCallback =
    (deviceTypeId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedItems([...selectedItems, deviceTypeId]);
      } else {
        setSelectedItems(selectedItems.filter((item) => item !== deviceTypeId));
      }
    };

  const handleDeviceTypeCallback = (): void => {
    onChoosingDeviceTypes(
      attachDeviceTypes.filter((deviceType) =>
        selectedItems.includes(deviceType._id),
      ),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedItems.length !== deviceTypesList.length) {
      setSelectedItems(deviceTypesList.map((deviceType) => deviceType._id));
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Attach"
      title="Select Device Profiles"
      disableAction={selectedItems.length === 0}
      actionCallback={handleDeviceTypeCallback}
      onCloseDrawer={onCloseDrawer}
      size="1000"
      content={
        <AttachItemsLayout
          allSelected={selectedItems.length === deviceTypesList.length}
          itemsSelected={selectedItems.length !== 0}
          hasItems={deviceTypesList.length !== 0}
          searchPlaceholder="Search device profiles"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={deviceTypesList.map((deviceType) => (
                  <AttachItemCard
                    content={
                      <DeviceProfileCard
                        key={deviceType._id}
                        deviceType={deviceType}
                      />
                    }
                    checked={selectedItems.includes(deviceType._id)}
                    checkboxCallback={checkDeviceTypesCallback}
                    id={deviceType._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default SelectDeviceProfilesDrawer;
