// Library documentation https://xrutayisire.github.io/react-js-cron/?path=/docs/reactjs-cron--demo
import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cron, CronError } from 'react-js-cron';
import { Info as InfoIcon, Warning as WarningIcon } from '@mui/icons-material';
import clsx from 'clsx';
import 'react-js-cron/dist/styles.css';

import TextInput from '../TextInput';
import useStyles from './styles';
import './CronSpec.css';
import { DEFAULT_CRON_SPEC } from '../../app/constants';

interface CronSpecProps {
  defaultValue?: string;
  hardReset?: boolean; // Reset to '* * * * *' cron spec
  onUpdateValue: (value: string) => void;
  setCronSpecInvalid?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CronSpec: React.FC<CronSpecProps> = ({
  defaultValue,
  hardReset = false,
  onUpdateValue,
  setCronSpecInvalid,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(
    defaultValue ? defaultValue : DEFAULT_CRON_SPEC,
  );
  const [error, onError] = useState<CronError>();

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (setCronSpecInvalid) {
      setCronSpecInvalid(typeof error !== 'undefined');
    }
    onUpdateValue(value);
  }, [value, error]);

  const handleInputChange = (
    _prop: string,
    newValue: string | number,
  ): void => {
    setValue(newValue as string);
    onUpdateValue(newValue as string);
  };

  const handleReset = (): void => {
    const newValue = hardReset
      ? DEFAULT_CRON_SPEC
      : defaultValue
      ? defaultValue
      : DEFAULT_CRON_SPEC;
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} className="mb-4">
        <TextInput
          placeholder="Cron expression"
          prop="cron_expression"
          classes="mb-3"
          value={value}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Cron
          value={value}
          clearButton={false}
          humanizeLabels={true}
          humanizeValue={false}
          setValue={setValue}
          onError={onError}
        />
        <Button
          className={clsx('ml-4', classes.resetBtn)}
          variant="outlined"
          size="small"
          onClick={handleReset}
        >
          Reset
        </Button>
        <div>
          <InfoIcon
            className={clsx('mr-2', classes.infoIcon)}
            fontSize="small"
          />
          <Typography variant="caption">
            Double click on a dropdown option to automatically select / unselect
            a periodicity
          </Typography>
        </div>
      </Grid>
      {error?.description && (
        <Grid item xs={12}>
          <Typography variant="caption">
            <WarningIcon
              className={clsx('mr-2', classes.infoIcon)}
              fontSize="small"
            />
            {error.description}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CronSpec;
