import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    invalidJson: {
      border: '1px solid red',
    },
    invalidLabel: {
      color: 'red',
    },
    resizePayload: {
      resize: 'both',
    },
  }),
);

export default useStyles;
