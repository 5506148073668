import { Secret } from '@edgeiq/edgeiq-api-js';
import { Box, Typography, Tooltip } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';

interface SecretCardProps {
  secret: Secret;
}

const SecretCard: React.FC<SecretCardProps> = ({ secret }) => {
  const { name, created_at } = secret;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.cardContainer)}>
      <Tooltip placement="top" title={name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-1', classes.title)}
        >
          {name}
        </Typography>
      </Tooltip>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', classes.subtitle)}
      >
        {created_at}
      </Typography>
    </Box>
  );
};

export default SecretCard;
