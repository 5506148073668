import { Report } from '@edgeiq/edgeiq-api-js';

export const HeartBeatTypes = {
  SET_HEARTBEATS: 'SET_HEARTBEATS',
  SET_SELECTED_HEARTBEATS: 'SET_SELECTED_HEARTBEATS',
};

export const setStateHeartbeats = (hearbeats: Report[]): HeartbeatsAction => ({
  data: hearbeats,
  type: HeartBeatTypes.SET_HEARTBEATS,
});

export const setSelectedHeartbeats = (
  hearbeats: Report[],
): HeartbeatsAction => ({
  data: hearbeats,
  type: HeartBeatTypes.SET_SELECTED_HEARTBEATS,
});

export type HeartbeatsState = {
  heartbeats: Report[];
  originalHearbeats: Report[];
  selectedHearbeats: Report[];
};

// INITIAL STATE
const heartbeatsInitialState: HeartbeatsState = {
  heartbeats: [],
  originalHearbeats: [],
  selectedHearbeats: [],
};

export type HeartbeatsAction = {
  data: Report[] | Report;
  type: string;
};

const hearbeatsReducer = (
  state = heartbeatsInitialState,
  action: HeartbeatsAction,
): HeartbeatsState => {
  switch (action.type) {
    case HeartBeatTypes.SET_HEARTBEATS:
      return {
        ...state,
        heartbeats: action.data as Report[],
      };
    case HeartBeatTypes.SET_SELECTED_HEARTBEATS:
      return {
        ...state,
        selectedHearbeats: action.data as Report[],
      };
    default:
      return state;
  }
};

export default hearbeatsReducer;
