import React from 'react';
import { Box, Grid } from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../components/TextInput';

interface DeviceFormProps {
  deviceType: DeviceType;
  onInputChange: (prop: string, value: string | number) => void;
}

const DeviceTypeForm: React.FC<DeviceFormProps> = ({
  deviceType,
  onInputChange,
}) => (
  <Box>
    {/* Row 1 */}
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Profile Name"
          prop="name"
          value={deviceType.name}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Manufacturer"
          prop="manufacturer"
          value={deviceType.manufacturer}
          onInputChange={onInputChange}
        />
      </Grid>
    </Grid>

    {/* Row 2 */}
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Model"
          prop="model"
          value={deviceType.model}
          onInputChange={onInputChange}
        />
      </Grid>
    </Grid>

    {/* Row 3 */}
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} className="mt-6 mb-6">
        <TextInput
          classes="full-height"
          label="Description"
          prop="long_description"
          placeholder="Device profile description"
          value={deviceType.long_description}
          onInputChange={onInputChange}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  </Box>
);

export default DeviceTypeForm;
