import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Device,
  DevicesFilters,
  DeviceType,
  DeviceTypes,
  IssueSoftwareUpdateInput,
  SoftwareUpdates,
} from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  setOptionsDeviceTypes,
  setStateDeviceTypes,
} from '../../../redux/reducers/deviceTypes.reducer';
import { setNewIssueSoftwareUpdateInput } from '../../../redux/reducers/softwareUpdates.reducer';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  optionsPaginationsFilter,
  errorHighlight,
} from '../../../app/constants';
import { dispatchError } from '../../../helpers/utils';
import { useFetchDevicesList } from '../../../hooks/useFetchDevicesList';
import DeviceCard from '../../../pages/devices/activeDevices/ActiveDeviceCard';
import CardsGrid from '../../../components/CardsGrid';
import LoadMore from '../../../components/Loader';
import AttachItemCard from '../AttachItems/AttachItemCard';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import IssueUpdateForm from './IssueUpdateForm';

interface IssueUpdateDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
}

const IssueUpdateDrawer: React.FC<IssueUpdateDrawerProps> = ({
  open,
  handleCloseDrawer,
}) => {
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const editableSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.newSoftwareUpdate,
  );
  const newIssueSoftwareUpdateInput = useAppSelector(
    (state: RootState) => state.softwareUpdates.newIssueSoftwareUpdateInput,
  );
  const [loading, setLoading] = useState(false);
  const [devicesList, pagination, fetchDevicesList] =
    useFetchDevicesList(errorDispatcher);
  const [localFilterInput, setLocalFilterInput] = useState<string>();
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );
  const initializeDevicesFilter: DevicesFilters = {
    device_type_id: {
      operator: 'eq',
      value: editableSoftwareUpdate?.device_type_id ?? '',
    },
  };
  const [fetchDevicesfilter, setFetchDevicesFilter] = useState<DevicesFilters>(
    initializeDevicesFilter,
  );
  const totalWithFilter = pagination?.total ?? 0;
  const [allDevicesLoaded, setAllDeviceLoaded] = useState(false);
  const moreToFetch = devicesList.length < totalWithFilter;

  useEffect(() => {
    if (
      pagination &&
      pagination.total === devicesList.length &&
      !localFilterInput
    ) {
      setAllDeviceLoaded(true);
    }
  }, [pagination, devicesList]);

  useEffect(() => {
    if (!devicesTypes.length) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setStateDeviceTypes(result.deviceTypes));
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const handleInputChange = (
    prop: string,
    value: Array<string> | string | DateTime,
  ): void => {
    if (editableSoftwareUpdate?._id) {
      if (prop.includes('schedule.')) {
        const key: string = prop.split('.')[1];
        dispatch(
          setNewIssueSoftwareUpdateInput({
            ...newIssueSoftwareUpdateInput,
            schedule: {
              ...(newIssueSoftwareUpdateInput?.schedule ?? {}),
              [key]: value,
            },
          } as IssueSoftwareUpdateInput),
        );
      } else {
        dispatch(
          setNewIssueSoftwareUpdateInput({
            ...newIssueSoftwareUpdateInput,
            [prop]: value,
          } as IssueSoftwareUpdateInput),
        );
      }
    }
  };

  const fetchDevices = (filters: DevicesFilters): void => {
    if (editableSoftwareUpdate?.device_type_id) {
      fetchDevicesList(filters, false).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchDevices(initializeDevicesFilter);
  }, [editableSoftwareUpdate?.device_type_id]);

  const handleSelectAll = (): void => {
    if (
      newIssueSoftwareUpdateInput &&
      newIssueSoftwareUpdateInput.ids.length !== devicesList.length
    ) {
      handleInputChange(
        'ids',
        devicesList.map((device) => device._id),
      );
    } else {
      handleInputChange('ids', []);
    }
  };

  const checkProfileCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        handleInputChange('ids', [
          ...(newIssueSoftwareUpdateInput?.ids ?? []),
          deviceId,
        ]);
      } else if (
        newIssueSoftwareUpdateInput &&
        newIssueSoftwareUpdateInput.ids
      ) {
        handleInputChange(
          'ids',
          newIssueSoftwareUpdateInput.ids.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSaveChanges = (): void => {
    if (newIssueSoftwareUpdateInput) {
      setLoading(true);
      SoftwareUpdates.bulkSoftwareUpdate({
        ...newIssueSoftwareUpdateInput,
        software_update_id: editableSoftwareUpdate?._id || '',
      })
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Update package',
              message: 'The update processes have successfully begun..',
              type: 'success',
            }),
          );
          handleCloseDrawer();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const cleanupClose = (): void => {
    handleInputChange('ids', []);
    setLocalFilterInput('');
    setFetchDevicesFilter(initializeDevicesFilter);
    fetchDevices(initializeDevicesFilter);
    handleCloseDrawer();
  };

  const handleOnChangeCallback = (value: string): void => {
    setLocalFilterInput(value);

    if (allDevicesLoaded) {
      return;
    }
    setLoading(true);
    const updateDevicesFilters: DevicesFilters = {
      ...initializeDevicesFilter,
      search: { operator: 'like', value },
    };
    setFetchDevicesFilter(updateDevicesFilters);
    fetchDevices(updateDevicesFilters);
  };

  const handleLoadMore = async (): Promise<void> => {
    return await fetchDevicesList(fetchDevicesfilter, true);
  };

  const devices = ((): Device[] => {
    if (!localFilterInput) {
      return devicesList;
    }
    const locallyFilteredDeviceList = devicesList.filter((device) => {
      const parsedInput = localFilterInput.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&',
      );
      const matchInput = new RegExp(parsedInput, 'i');
      return (
        device.name.match(matchInput) || device.unique_id.match(matchInput)
      );
    });
    return locallyFilteredDeviceList;
  })();

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      actionLoading={loading}
      title="Command Execution Information"
      disableAction={
        (newIssueSoftwareUpdateInput &&
          newIssueSoftwareUpdateInput?.ids?.length <= 0) as boolean
      }
      actionCallback={handleSaveChanges}
      onCloseDrawer={cleanupClose}
      content={
        <Box>
          <AttachItemsLayout
            allSelected={
              newIssueSoftwareUpdateInput?.ids?.length === devices.length
            }
            itemsSelected={!!newIssueSoftwareUpdateInput?.ids?.length}
            hasItems={!!devices.length}
            searchPlaceholder="Search device"
            onChangeCallback={handleOnChangeCallback}
            selectAllCallback={handleSelectAll}
            disableDelay={!moreToFetch}
            showLoadMore={moreToFetch}
            loadMoreHanlder={handleLoadMore}
            grid={
              loading ? (
                <Grid container className="loading-container">
                  <CircularProgress size={75} thickness={5} />
                </Grid>
              ) : (
                <CardsGrid
                  twoColumns={true}
                  containerPadding={false}
                  cards={devices.map((device) => (
                    <AttachItemCard
                      content={
                        <DeviceCard
                          key={device._id}
                          device={device}
                          deviceType={devicesTypes.find(
                            (deviceType) =>
                              deviceType._id === device.device_type_id,
                          )}
                        />
                      }
                      checked={
                        !newIssueSoftwareUpdateInput
                          ? false
                          : newIssueSoftwareUpdateInput.ids?.includes(
                              device._id,
                            )
                      }
                      checkboxCallback={checkProfileCallback}
                      id={device._id}
                    />
                  ))}
                />
              )
            }
          />
          {moreToFetch && <LoadMore onLoadMore={handleLoadMore} />}
          <Typography variant="h5">
            Schedule this Update, or Update ASAP
          </Typography>
          {editableSoftwareUpdate && (
            <IssueUpdateForm onInputChange={handleInputChange} />
          )}
        </Box>
      }
    />
  );
};

export default IssueUpdateDrawer;
