import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      position: 'relative',
      whiteSpace: 'nowrap',
    },
    container: {
      marginLeft: '0',
      width: '100%',
    },
    deviceName: {
      padding: '0 !important',
    },
    executionInfo: {
      color: theme.palette.common.white,
      fontFamily: 'Inter, arial, sans-serif',
    },
    executionInfoContainer: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: '4px',
      marginBottom: '10px',
      marginTop: '10px',
      overflow: 'auto',
      padding: '10px',
    },
    listContainer: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px',
        height: '8px',
        width: '0px',
      },
      '&:hover': {
        '&::-webkit-scrollbar': {
          height: '8px',
          width: '8px',
        },
      },
      overflow: 'auto',
    },
    statusContainer: {
      position: 'absolute',
      right: '0',
      top: '0',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: 700,
    },
  }),
);

export default useStyles;
