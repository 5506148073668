import React, { useState, useEffect, ReactElement } from 'react';
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Devices,
  Device,
  DevicesFilters,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setActualDevice } from '../../../redux/reducers/devices.reducer';
import {
  ENDPOINT_ROLE,
  GATEWAY_ROLE,
  errorHighlight,
} from '../../../app/constants';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import TabsPage from '../../../components/TabsPage';
import { AttachEndpointsDrawer } from '../../../containers/RightDrawer';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useNavigate } from 'react-router-dom';
import useStyles from '../styles';

type Props = {
  deviceType: DeviceType;
};
const DeviceEndpoints: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { deviceType } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [endpointsList, setEndpointsList] = useState<Device[]>();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const classes = useStyles();
  const navigate = useNavigate();

  const goToLinkAction = (path: string) => (): void => {
    navigate(`/device/${path}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Link
          onClick={goToLinkAction(params.row._id)}
          className={classes.linkButton}
          underline="none"
        >
          <strong>{params.row.name}</strong>
        </Link>
      ),
    },
    {
      field: 'heartbeat_status',
      flex: 0.3,
      headerName: 'Status',
    },
    {
      field: 'unique_id',
      flex: 0.3,
      headerName: 'Unique ID',
    },
    {
      field: 'action',
      flex: 0.1,
      headerName: 'Action',
      renderCell: (params: GridRenderCellParams): ReactElement => {
        return (
          <Tooltip placement="top" title="Detach device">
            <IconButton
              aria-label="detach-device"
              onClick={handleDetachDevice(params.row._id)}
            >
              <LinkOffIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleDetachDevice = (deviceId: string) => (): void => {
    const newAttachedDevices = editableDevice?.attached_device_ids?.filter(
      (id) => id !== deviceId,
    );

    const newEndPoints = (newAttachedDevices || []).map((e) => {
      return (endpointsList || []).find((p) => p._id === e);
    });

    handleManageEndpoints(newEndPoints as Device[]);
  };

  useEffect(() => {
    if (!endpointsList) {
      if (
        !editableDevice?.parent_device_id &&
        deviceType.role === ENDPOINT_ROLE
      ) {
        setEndpointsList([]);
        return;
      } else if (
        !editableDevice?.attached_device_ids?.length &&
        deviceType.role !== ENDPOINT_ROLE
      ) {
        setEndpointsList([]);
        return;
      }

      const filters: DevicesFilters = {
        _id: {
          operator: 'in',
          value:
            deviceType.role === ENDPOINT_ROLE
              ? editableDevice?.parent_device_id
                ? [editableDevice?.parent_device_id]
                : []
              : (editableDevice?.attached_device_ids as string[]),
        },
      };

      Devices.list(filters)
        .then((response) => {
          setEndpointsList(response.devices);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [editableDevice]);

  const handleManageEndpoints = (endpoints: Device[]): void => {
    setOpen(false);
    setLoading(true);
    setEndpointsList(endpoints);

    const newEndpoints = endpoints.map((endpoint) => endpoint._id);

    let payload = {
      ...editableDevice,
    };
    if (deviceType.role === ENDPOINT_ROLE) {
      payload = {
        ...editableDevice,
        parent_device_id: newEndpoints.length ? newEndpoints[0] : '',
      };
    } else {
      payload = {
        ...editableDevice,
        attached_device_ids: newEndpoints,
      };
    }

    Devices.update(payload as Device)
      .then((response) => {
        dispatch(setActualDevice(response));
        dispatch(
          setAlert({
            highlight: 'Endpoints updated',
            message: 'Device endpoints successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={endpointsList}
        addButtonLabel={
          deviceType.role === ENDPOINT_ROLE
            ? 'Update Parent Device'
            : 'Update Endpoints'
        }
        addButtonIcon={false}
        onAddClick={(): void => setOpen(true)}
        tableTitle={
          deviceType.role === ENDPOINT_ROLE
            ? 'Attached parent device'
            : 'Endpoints Added'
        }
        loading={loading}
      />
      {endpointsList && (
        <AttachEndpointsDrawer
          device={editableDevice as Device}
          open={open}
          handleCloseDrawer={(): void => setOpen(false)}
          onChoosingEndpoints={handleManageEndpoints}
          deviceType={deviceType}
          title={
            deviceType.role === GATEWAY_ROLE
              ? 'Select Endpoints'
              : 'Select Parent Device'
          }
        />
      )}
    </Box>
  );
};

export default DeviceEndpoints;
