import React, { ReactElement, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Devices, EIQFile } from '@edgeiq/edgeiq-api-js';
import UploadIcon from '@mui/icons-material/Upload';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { downloadEIQFile } from '../../../helpers/utils';
import { getFileSizeText } from '../../../helpers/mimetype';
import TabsPage from '../../../components/TabsPage';
import DeviceFileDrawer from './NewDeviceFileDrawer';

const DeviceFiles: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [filesList, setFilesList] = useState<EIQFile[]>();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );

  const [showFilesDrawer, setShowFilesDrawer] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.3,
      headerName: 'Name',
    },
    {
      field: 'mime_type',
      flex: 0.3,
      headerName: 'Mime Type',
    },
    {
      field: 'size',
      flex: 0.3,
      headerName: 'Size',
      renderCell: (params: GridRenderCellParams): ReactElement => {
        if (!params.row.size) {
          return <></>;
        }
        return <span>{getFileSizeText(params.row.size)}</span>;
      },
    },
    {
      field: 'options',
      flex: 0.1,
      headerName: 'Download file',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Tooltip placement="top" title="Download file">
          <IconButton
            aria-label="download-file"
            onClick={handleDownloadFile(params.row)}
            size="small"
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      ),
      sortable: false,
    },
  ];

  const getDeviceFiles = (): void => {
    if (editableDevice) {
      setLoading(true);
      Devices.getFiles(editableDevice?._id)
        .then((res) => {
          setFilesList(res);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getDeviceFiles();
  }, [editableDevice]);

  const handleCloseFilesDrawer = (): void => {
    setShowFilesDrawer(false);
  };

  const handleOpenFilesDrawer = (): void => {
    setShowFilesDrawer(true);
  };

  const hanldeFileUploadSuccess = (): void => {
    getDeviceFiles();
    handleCloseFilesDrawer();
  };

  const handleDownloadFile = (file: EIQFile) => (): void => {
    downloadEIQFile(file);
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={filesList}
        addButtonLabel="Upload new file"
        onAddClick={handleOpenFilesDrawer}
        tableTitle="Files"
        loading={loading}
        addButtonIconToShow={<UploadIcon />}
      />

      {editableDevice && (
        <DeviceFileDrawer
          device={editableDevice}
          openDrawer={showFilesDrawer}
          handleCloseDrawer={handleCloseFilesDrawer}
          handleUploadSuccess={hanldeFileUploadSuccess}
        />
      )}
    </Box>
  );
};

export default DeviceFiles;
