import React from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Company, User, Users } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { stringAvatar } from '../../../helpers/avatar';
import { dispatchError, getDetachError } from '../../../helpers/utils';
import TypographyWithCopy from '../../../components/TypographyWithCopy';

interface UserAccountsProps {
  accounts: Company[];
  loadingAccounts: boolean;
  user: User;
  total: number;
  onLoadMore: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAccountRemoved: (accountId: string) => void;
}

const UserAccountsList: React.FC<UserAccountsProps> = ({
  accounts,
  loadingAccounts,
  user,
  total,
  onLoadMore,
  onAccountRemoved,
}) => {
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);

  const removeAccount = (account: Company) => (): void => {
    Users.removeCompanyId(account._id, user._id)
      .then(() => {
        onAccountRemoved(account._id);
        dispatch(
          setAlert({
            highlight: 'Account detached',
            message: 'Account detached from user.',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          getDetachError('user', 'account'),
        );
      });
  };

  return (
    <div className="scrollbar">
      <List
        sx={{
          bgcolor: 'background.paper',
          maxHeight: 360,
          overflow: 'auto',
          paddingTop: '0',
          width: '100%',
        }}
      >
        {accounts.map((account: Company, index: number) => {
          return (
            <Box key={account._id}>
              <ListItem
                alignItems="flex-start"
                data-cy="user-additional-account-container"
                sx={{
                  paddingLeft: '0',
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="link off"
                    data-cy="detach-additional-account-button"
                    onClick={removeAccount(account)}
                  >
                    <LinkOffIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  {account.branding?.logo_url ? (
                    <Avatar
                      alt={account.name}
                      src={account.branding?.logo_url}
                    />
                  ) : (
                    <Avatar {...stringAvatar(account.name)} />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={account.name}
                  secondary={
                    <TypographyWithCopy
                      component="div"
                      dataCy={`copy-attach-account-id-${account._id}`}
                      text={account._id}
                      textToCopy={account._id}
                      tooltipText={account._id}
                      typographyVariant="button"
                      textMaxWidth={200}
                    />
                  }
                />
              </ListItem>
              {index !== accounts.length - 1 && (
                <Divider variant="inset" component="li" />
              )}
            </Box>
          );
        })}
        {accounts.length !== total && (
          <div className="mb-6 loading-container">
            <Button variant="outlined" size="large" onClick={onLoadMore}>
              {!loadingAccounts ? (
                <Typography variant="button">Load more</Typography>
              ) : (
                <CircularProgress size={25} />
              )}
            </Button>
          </div>
        )}
      </List>
    </div>
  );
};

export default UserAccountsList;
