import { Command, CommandExecution } from '@edgeiq/edgeiq-api-js';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { TableColumn, TableItemType } from '../../../components/SharedTable';

const getCommandName = (commandId: string, commands: Command[]): string => {
  const foundCommand = commands.find((command) => command._id === commandId);
  if (foundCommand) {
    return foundCommand.name;
  }
  return '--';
};

export const CommandsExecutionsColumns = (
  commands: Command[],
  handleActionClick: (item: CommandExecution, action: 'details') => void,
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const commandExecution = item as CommandExecution;
        return getCommandName(commandExecution.command_id, commands);
      },
      id: 'command_name',
      label: 'Command name',
      type: 'text',
    },
    // {
    //   cellValue: (item: TableItemType): string => {
    //     const gatwayCommand = item as Command;
    //     return gatwayCommand.sender_type ?? '--';
    //   },
    //   id: 'device_name',
    //   label: 'Device name',
    //   type: 'text',
    // },
    {
      cellValue: (item: TableItemType): string => {
        const commandExecution = item as CommandExecution;
        return `${DateTime.fromISO(commandExecution.created_at).toFormat(
          'TT',
        )} ${DateTime.fromISO(commandExecution.created_at).toLocaleString(
          DateTime.DATE_SHORT,
        )}`;
      },
      id: 'created_at',
      label: 'Executed at',
      type: 'text',
    },
    {
      id: 'action',
      label: 'Action',
      parser: (item: TableItemType): string | React.ReactElement => {
        const commandExecution = item as CommandExecution;
        return (
          <Typography
            variant="button"
            style={{ cursor: 'pointer' }}
            onClick={(): void => handleActionClick(commandExecution, 'details')}
          >
            View Details
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};
