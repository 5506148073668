import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Device, DevicesFilters, DeviceTypes } from '@edgeiq/edgeiq-api-js';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setOptionsDeviceTypes } from '../../../redux/reducers/deviceTypes.reducer';
import { useFetchDevicesList } from '../../../hooks/useFetchDevicesList';
import { dispatchError } from '../../../helpers/utils';
import { optionsPaginationsFilter } from '../../../app/constants';
import CardsGrid from '../../../components/CardsGrid';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import DeviceCard from '../../../pages/devices/activeDevices/ActiveDeviceCard';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';

interface SelectDevicesDrawerProps {
  selectedDevices: string[];
  filters?: DevicesFilters;
  open: boolean;
  onCloseDrawer: () => void;
  onChoosingDevices: (devices: Device[]) => void;
  companyId?: string;
  hideSelectAll?: boolean;
  actionLabel?: string;
}

const SelectDevicesDrawer: React.FC<SelectDevicesDrawerProps> = ({
  selectedDevices,
  filters,
  open,
  onCloseDrawer,
  onChoosingDevices,
  companyId,
  hideSelectAll = true,
  actionLabel,
}) => {
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const deviceTypes = useAppSelector(
    (state: RootState) => state.deviceTypes.optionsDeviceTypes,
  );

  const [devicesList, pagination, fetchDevicesList] =
    useFetchDevicesList(errorDispatcher);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedDevices ?? [],
  );

  const [loading, setLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState<DevicesFilters>(
    filters ?? {},
  );

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    setSelectedItems(selectedDevices);
  }, [selectedDevices]);

  useEffect(() => {
    if (companyId) {
      setSearchFilters({
        ...filters,
        company_id: { operator: 'eq', value: companyId },
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (searchFilters.company_id) {
      fetchDevicesList(searchFilters, false).finally(() => setLoading(false));
    }
  }, [searchFilters]);

  const handleOnChangeCallback = (value: string): void => {
    setLoading(true);
    setSearchFilters({
      ...searchFilters,
      search: { operator: 'like', value },
    });
  };

  const checkDeviceCallback =
    (deviceId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedItems([...selectedItems, deviceId]);
      } else {
        setSelectedItems(selectedItems.filter((item) => item !== deviceId));
      }
    };

  const handleDeviceCallback = (): void => {
    onChoosingDevices(
      devicesList.filter((device) => selectedItems.includes(device._id)),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedItems.length !== devicesList.length) {
      setSelectedItems(devicesList.map((device) => device._id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleLoadMore = async (): Promise<void> => {
    return await fetchDevicesList(searchFilters, true);
  };

  const searchValue = searchFilters.search?.value as string;

  return (
    <RightDrawer
      open={open}
      actionLabel={actionLabel ?? 'Attach'}
      title="Select Devices"
      disableAction={selectedItems.length === 0}
      actionCallback={handleDeviceCallback}
      onCloseDrawer={onCloseDrawer}
      size="800"
      content={
        <AttachItemsLayout
          allSelected={selectedItems.length === devicesList.length}
          itemsSelected={selectedItems.length !== 0}
          hasItems={devicesList.length !== 0}
          hideSelectAll={hideSelectAll}
          searchPlaceholder="Search devices"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          showLoadMore={
            devicesList.length < (pagination?.total ?? 0) && !loading
          }
          loadMoreHanlder={handleLoadMore}
          noItemsLabel={`No devices found related to this account${
            !searchValue ? '.' : ` with name or unique_id: ${searchValue}`
          }`}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={devicesList.map((device) => (
                  <AttachItemCard
                    content={
                      <DeviceCard
                        key={device._id}
                        device={device}
                        deviceType={deviceTypes.find(
                          (deviceType) =>
                            deviceType._id === device.device_type_id,
                        )}
                        searchText={searchValue ?? ''}
                      />
                    }
                    checked={selectedItems.includes(device._id)}
                    checkboxCallback={checkDeviceCallback}
                    id={device._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default SelectDevicesDrawer;
