import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allBreadcrumb: {
      cursor: 'none',
    },
    breadcrumb: {
      color: theme.palette.grey[100],
      cursor: 'pointer',
    },
    hierarchyContainer: {
      position: 'relative',
    },
    hierarchySubtitle: {
      color: theme.palette.grey[200],
      paddingLeft: '40px',
    },
    hierarchyTitle: {
      color: theme.palette.grey[100],
      cursor: 'pointer',
    },
    hierarchyTitleDisabled: {
      color: theme.palette.grey[300],
    },
    icon: {
      color: theme.palette.grey[100],
    },
    nestedItem: {
      '&:hover': {
        '& .hierarchyTitle': {
          color: theme.palette.grey[100],
          fontWeight: 600,
        },
        '& > .nestedItemAddButton': {
          display: 'inline-flex',
        },
      },
      marginBottom: '10px',
      whiteSpace: 'nowrap',
    },
    nestedItemAddButton: {
      display: 'none',
      minWidth: 'auto',
      padding: '0',
    },
    nestedItemDataContainer: {
      alignItems: 'center',
    },
    nestedItemLine: {
      backgroundColor: theme.palette.primary.main,
      height: 'calc(100% - 25px)',
      left: '14px',
      position: 'absolute',
      top: '25px',
      width: '2px',
    },
  }),
);

export default useStyles;
