import React, { useState } from 'react';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Devices, DiscoveredDevices } from '@edgeiq/edgeiq-api-js';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { RootState } from '../../redux/store';
import ImportDevices from './ImportDevices';
import {
  activeDevicesFileName,
  discoveredDevicesFileName,
  errorHighlight,
  GENRE_ACTIVE,
  GENRE_DISCOVERED,
} from '../../app/constants';
import parseFilters from '../../helpers/parseFilters';
import { downloadBlobFile } from '../../helpers/utils';

interface DevicesHeaderActionsProps {
  onImportSuccess: () => void;
}

const DevicesHeaderActions: React.FC<DevicesHeaderActionsProps> = ({
  onImportSuccess,
}) => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const stateUser = useAppSelector((state: RootState) => state.user);
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;
  const isDiscovered = devicesState.devicesGenre === GENRE_DISCOVERED;

  const [openImport, setOpenImport] = useState(false);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleOpenImport = (): void => {
    setOpenImport(true);
  };

  const handleCloseImport = (): void => {
    setOpenImport(false);
  };

  const handleImportSucces = (): void => {
    handleCloseImport();
    onImportSuccess();
  };

  const handleExport = (): void => {
    if (isActive) {
      Devices.csvBulkDownload(parseFilters(filters.devices.filters ?? {}))
        .then((result) => {
          downloadBlobFile(result, `${activeDevicesFileName}.csv`);
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    } else if (isDiscovered) {
      DiscoveredDevices.csvBulkDownload(
        parseFilters(
          filters.discoveredDevices.filters ?? {},
          filters.case_sensitive,
        ),
      )
        .then((result) => {
          downloadBlobFile(result, `${discoveredDevicesFileName}.csv`);
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  };

  const canImport =
    (isActive &&
      stateUser &&
      stateUser.permissions &&
      stateUser.permissions.device &&
      stateUser.permissions.device.create) ||
    (isDiscovered &&
      stateUser &&
      stateUser.permissions &&
      stateUser.permissions.discovered_device &&
      stateUser.permissions.discovered_device.create);

  return (
    <>
      {canImport && (
        <Button
          variant="outlined"
          size="large"
          startIcon={<FileUploadIcon />}
          onClick={handleOpenImport}
        >
          {isActive ? 'Import' : 'CSV Promote'}
        </Button>
      )}
      <Button
        variant="outlined"
        size="large"
        className="ml-4"
        startIcon={<FileDownloadIcon />}
        onClick={handleExport}
      >
        Export
      </Button>
      <ImportDevices
        openDrawer={openImport}
        handleCloseDrawer={handleCloseImport}
        handleImportSucces={handleImportSucces}
      />
    </>
  );
};

export default DevicesHeaderActions;
