import React from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';

interface SecretsFiltersProps {
  total: number;
}

const SecretsFilters: React.FC<SecretsFiltersProps> = ({ total }) => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.secrets.filters;

  const changeFilters = (prop: string, value: string): void => {
    // TODO: check if the values are the same before dispatching
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'secrets',
      ),
    );
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search secrets by Name"
      inputKey="name"
      filterModel="secrets"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
    />
  );
};

export default SecretsFilters;
