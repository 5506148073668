import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { snmpVersions } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';
import Tags from '../../../../containers/Tags';

interface SnmpPollingSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
}

const SnmpPollingSection: React.FC<SnmpPollingSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Host"
          placeholder="Examples: 192.168.10.44, 192.168.0.1"
          prop="listener.host"
          value={newIngestor.listener.host}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Community"
          placeholder=""
          prop="listener.community"
          value={newIngestor.listener.community}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <SelectInput
          label="Version"
          prop="listener.version"
          value={newIngestor.listener.version}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(snmpVersions).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {snmpVersions[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Timeout (seconds)"
          prop="listener.timeout"
          placeholder=""
          value={newIngestor.listener.timeout}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Retries"
          placeholder=""
          prop="listener.retries"
          value={newIngestor.listener.retries}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Poll Interval"
          placeholder=""
          prop="listener.poll_interval"
          value={newIngestor.listener.poll_interval}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <Tags
          key="oid-tags"
          originalTags={(newIngestor.listener.oids as string[]) || []}
          onChangeTags={onInputChange}
          label="OIDs"
          placeholder="Type to add OID..."
        />
      </Grid>
    </>
  );
};

export default SnmpPollingSection;
