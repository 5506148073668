import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import { Device, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useFetchCompany } from '../../../hooks/useFetchCompany';
import timeHelpers from '../../../helpers/timeHelpers';
import { heartbeatColorThemeMap } from '../../../app/constants';
import { highlightTextPart } from '../../../helpers/utils';
import ColoredBox from '../../../components/ColoredBox';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import useStyles from '../../../components/Card/styles';

interface DeviceCardProps {
  device: Device;
  deviceType?: DeviceType;
  searchText?: string;
}

const DeviceCard: React.FC<DeviceCardProps> = ({
  device,
  deviceType,
  searchText,
}) => {
  const cardClasses = useStyles();
  const [deviceCompany] = useFetchCompany(
    device.company_id,
    (message: string) => console.info(message),
  );

  return (
    <Box>
      <Tooltip placement="top" title={device.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', cardClasses.title, {
            ['fw-400']: searchText !== undefined && searchText !== '',
          })}
        >
          {searchText
            ? highlightTextPart(device.name, searchText)
            : device.name}
        </Typography>
      </Tooltip>
      <TypographyWithCopy
        dataCy={`copy-device-id-${device.unique_id}`}
        containerClassName="mb-2"
        textClassName={cardClasses.subtitle}
        component="div"
        text={`Id: ${device.unique_id}`}
        textToCopy={device.unique_id}
        typographyVariant="button"
        searchText={searchText}
      />
      <Typography
        noWrap
        variant="overline"
        component="div"
        className={clsx('mb-2 tag', cardClasses.tag)}
      >
        <DevicesIcon fontSize="small" className="mr-2" />
        {deviceType?.name}
      </Typography>
      <Typography
        noWrap
        variant="overline"
        component="div"
        className={clsx('mb-2 tag', cardClasses.tag)}
      >
        Last Heartbeat:{' '}
        {device.heartbeat_status === 'never_reported'
          ? '--'
          : device.heartbeat_at
          ? timeHelpers.getDate(device.heartbeat_at)
          : ''}
      </Typography>
      <Typography
        noWrap
        variant="overline"
        component="div"
        className={clsx('mb-2 tag', cardClasses.tag)}
      >
        {deviceCompany ? 'Account:' : 'Account ID:'}{' '}
        {deviceCompany?.name ?? device.company_id}
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-2 tag', cardClasses.tag)}
      >
        <FiberSmartRecordIcon fontSize="small" className="mr-2" />
        {device.active ? 'Active' : 'Inactive'}
      </Typography>
      {device.heartbeat_status && (
        <div className={cardClasses.statusContainer}>
          <ColoredBox
            type="heartbeat_status"
            value={device.heartbeat_status}
            colorTheme={heartbeatColorThemeMap[device.heartbeat_status]}
          />
        </div>
      )}
    </Box>
  );
};

export default DeviceCard;
