import React, { ReactElement, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { Company } from '@edgeiq/edgeiq-api-js';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import useStyles from './styles';

interface AccountSelectProps {
  isDisabled?: boolean;
  isFilter?: boolean;
  isMultiple?: boolean;
  parentAccountId?: string;
  placeHolder?: string;
  selectedAccount?: string;
  selectedAccounts?: string[];
  onAccountChange?: (companyId: string) => void;
  onMultipleAccountChange?: (companyIds: string[]) => void;
}

const AccountSelect: React.FC<AccountSelectProps> = ({
  isDisabled = false,
  isFilter = false,
  isMultiple = false,
  placeHolder,
  parentAccountId,
  selectedAccount,
  selectedAccounts = [],
  onAccountChange,
  onMultipleAccountChange,
}) => {
  const classes = useStyles();
  const { userCompanies } = useAppSelector((state: RootState) => state.user);

  const [accountsSelected, setAccountsSelected] =
    useState<string[]>(selectedAccounts);
  const [accountsChildren, setAccountsChildren] = useState<Company[]>([]);

  const handleChangeAccount = (event: SelectChangeEvent): void => {
    if (onAccountChange) {
      onAccountChange(event.target.value as string);
    }
  };
  const allAccountsSelected =
    accountsSelected.length === accountsChildren.length;

  if (selectedAccounts.length !== accountsSelected.length) {
    setAccountsSelected(selectedAccounts);
  }

  useEffect(() => {
    if (parentAccountId) {
      const childCompanies = userCompanies.filter(
        (company) => company.company_id === parentAccountId,
      );
      setAccountsChildren(childCompanies);
    } else {
      setAccountsChildren(userCompanies);
    }
  }, [userCompanies]);

  const callMultipleAccount = (accounts: string[]): void => {
    if (onMultipleAccountChange) {
      onMultipleAccountChange(accounts);
    }
  };

  const handleChangeMultipleAccount = (
    event: SelectChangeEvent<string[]>,
  ): void => {
    const {
      target: { value },
    } = event;
    if (value[value.length - 1] === 'all' && !allAccountsSelected) {
      const accountsSelectedByUser = accountsChildren.map(
        (company) => company._id,
      );
      setAccountsSelected(accountsSelectedByUser);
      callMultipleAccount(accountsSelectedByUser);
    } else if (
      (value[value.length - 1] === 'all' && allAccountsSelected) ||
      value[value.length - 1] === undefined
    ) {
      setAccountsSelected([]);
      callMultipleAccount([]);
    } else {
      const targetValue = value as string[];
      setAccountsSelected(targetValue);
      callMultipleAccount(targetValue);
    }
  };

  const renderOptions = (): React.ReactElement[] => {
    return accountsChildren.map((company, index) => (
      <MenuItem dense key={`account-filter-${index}`} value={company._id}>
        {isMultiple && (
          <Checkbox checked={accountsSelected.indexOf(company._id) > -1} />
        )}
        {company.branding?.logo_url ? (
          <div className={clsx('mr-2', classes.logo)}>
            <img
              src={company.branding?.logo_url}
              className={classes.logoImage}
            />
          </div>
        ) : (
          <div className={clsx('mr-2', classes.logo, classes.nameLogo)}>
            <Typography variant="h5" noWrap color="white">
              {company.name.charAt(0)}
              {company.name.charAt(1)}
            </Typography>
          </div>
        )}
        {company.name}
      </MenuItem>
    ));
  };

  const simpleSelector = (): React.ReactElement => (
    <Select
      disabled={isDisabled}
      displayEmpty
      data-cy="account-selector"
      value={selectedAccount}
      onChange={handleChangeAccount}
      className={clsx({
        [classes.filter]: isFilter,
        [classes.notFilter]: !isFilter,
      })}
      renderValue={(selected): string | ReactElement => {
        const company = accountsChildren.find(
          (accoundChild) => accoundChild._id === selected,
        );
        return company ? (
          <div className={classes.container}>
            {company.branding?.logo_url ? (
              <div
                className={clsx('mr-2', classes.logo, {
                  [classes.filterLogo]: isFilter,
                })}
              >
                <img
                  src={company.branding?.logo_url}
                  className={classes.logoImage}
                />
              </div>
            ) : (
              <div
                className={clsx('mr-2', classes.logo, classes.nameLogo, {
                  [classes.filterLogo]: isFilter,
                })}
              >
                <Typography
                  variant={isFilter ? 'button' : 'h5'}
                  noWrap
                  color="white"
                >
                  {company.name.charAt(0)}
                  {company.name.charAt(1)}
                </Typography>
              </div>
            )}
            <Typography variant="button">{company.name}</Typography>
          </div>
        ) : (
          <Typography variant="button">Account</Typography>
        );
      }}
    >
      <MenuItem dense value="">
        Account
      </MenuItem>
      {renderOptions()}
    </Select>
  );

  const multipleSelector = (): React.ReactElement => (
    <Select
      disabled={isDisabled}
      multiple
      displayEmpty
      data-cy="account-multiple-filter"
      value={accountsSelected}
      onChange={handleChangeMultipleAccount}
      className={clsx({
        [classes.filter]: isFilter,
        [classes.notFilter]: !isFilter,
      })}
      renderValue={(selected): string[] | React.ReactElement => {
        if (!selected.length) {
          return (
            <Typography variant="button">{placeHolder ?? 'Account'}</Typography>
          );
        }

        if (selected.length > 0) {
          return (
            <Typography variant="button">
              {`Accounts selected (${selected.length})`}
            </Typography>
          );
        }

        return <Typography variant="button">{selected}</Typography>;
      }}
    >
      {accountsChildren.length > 0 ? (
        <MenuItem dense value="all">
          {accountsChildren.length > 0 && (
            <Checkbox
              checked={allAccountsSelected}
              indeterminate={!!accountsSelected.length && !allAccountsSelected}
            />
          )}
          {allAccountsSelected ? 'Deselect All' : 'Select All'}
        </MenuItem>
      ) : (
        <Typography className={clsx('mr-6 ml-6')}>No Accounts</Typography>
      )}
      {renderOptions()}
    </Select>
  );

  return (
    <FormControl fullWidth>
      {isMultiple ? multipleSelector() : simpleSelector()}
    </FormControl>
  );
};

export default AccountSelect;
