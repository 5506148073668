import React from 'react';

const AnalyticsPage: React.FC = () => {
  return (
    <div
      style={{ height: '100%' }}
      dangerouslySetInnerHTML={{
        __html:
          "<iframe style='width:100%' height='100%' src='http://ec2-3-12-148-53.us-east-2.compute.amazonaws.com:8080/sources/1/dashboards/1?refresh=Paused&lower=now%28%29%20-%2015m' />",
      }}
    />
  );
};

export default AnalyticsPage;
