import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    filterSortWrapper: {
      position: 'sticky',
      top: 0,
      zIndex: 30,
    },
    input: {
      height: '44px',
    },
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    subtitle: {
      color: theme.palette.grey[100],
    },
    tagContainer: {
      display: 'flex',
    },
    tagIcon: {
      color: theme.palette.grey[200],
    },
    tagName: {
      color: theme.palette.grey[200],
    },
    tagsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tagValue: {
      color: theme.palette.grey[100],
    },
    title: {
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      color: theme.palette.grey[100],
    },
  }),
);

export default useStyles;
