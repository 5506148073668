import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import { BulkResponses } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { setAlert } from '../../redux/reducers/alert.reducer';
import { useAppDispatch } from '../../redux/hooks';
import { errorHighlight } from '../../app/constants';
import { BulkJob } from '../../models/common';
import timeHelpers from '../../helpers/timeHelpers';
import { mapBulkResponseToBulkJobs } from '../messages/bulkJobs/BulkJobs';

const BulkJobsChart: React.FC = () => {
  const [bulkJobsChartData, setBulkJobsChartData] = useState<BulkJob[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    BulkResponses.list({}, { itemsPerPage: 6, page: 1 })
      .then((res) => {
        const mappedData = mapBulkResponseToBulkJobs(res.bulkResponses);
        setBulkJobsChartData(mappedData);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoBulkJobs = (): void => {
    navigate(`/messages#bulk-jobs`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Bulk Jobs
        <Button
          className="p-0"
          color="primary"
          variant="text"
          type="button"
          onClick={handleGoBulkJobs}
        >
          <Typography variant="button">View More</Typography>
        </Button>
      </Typography>

      <div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Date/Time</TableCell>
                <TableCell align="center">Summary</TableCell>
                <TableCell align="center">Successes</TableCell>
                <TableCell align="right">Failures</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkJobsChartData.map((row) => (
                <TableRow
                  key={row.datetime}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    {row.datetime && (
                      <>{timeHelpers.getDate(row.datetime, false, false)}</>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.summary}
                  </TableCell>
                  <TableCell align="center">
                    <Typography color={row.successes > 0 ? '#609884' : ''}>
                      {row.successes}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={row.failures > 0 ? '#DE4F48' : ''}>
                      {row.failures}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BulkJobsChart;
