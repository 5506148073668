import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import {
  DeviceConfigs,
  DeviceTypes,
  DeviceConfig,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getDevieConfigConnectionSelector,
  setActualDevieConfigConnection,
} from '../../redux/reducers/configs.reducer';
import { setStateDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import { errorHighlight } from '../../app/constants';
import FooterBar from '../../components/FooterBar';
import useStyles from './styles';
import ConfigDetails from './configDetails';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { dispatchError } from '../../helpers/utils';

const ConfigContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { id } = useParams<string>();
  const currentDeviceConfigConnetion = useAppSelector((state: RootState) =>
    getDevieConfigConnectionSelector(state.configs, id),
  );
  const { deviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const { newDeviceConfigConnection } = useAppSelector(
    (state: RootState) => state.configs,
  );
  const [loading, setLoading] = useState(false);
  const errorDispatcher = dispatchError(dispatch);
  const [configurationCompany] = useFetchCompany(
    currentDeviceConfigConnetion?.company_id,
    errorDispatcher,
  );

  useEffect(() => {
    if (!deviceTypes.length) {
      DeviceTypes.list({})
        .then((result) => {
          dispatch(setStateDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    if (currentDeviceConfigConnetion) {
      dispatch(setActualDevieConfigConnection(currentDeviceConfigConnetion));
    } else if (id) {
      DeviceConfigs.getOneById(id)
        .then((response) => {
          dispatch(setActualDevieConfigConnection(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [currentDeviceConfigConnetion]);

  const handleDeleteUser = (): void => {
    if (!currentDeviceConfigConnetion) {
      return;
    }
    setLoading(true);

    DeviceConfigs.delete(currentDeviceConfigConnetion._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Config Deleted',
            message: 'Device config successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/configs');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    setLoading(true);
    DeviceConfigs.update(newDeviceConfigConnection as DeviceConfig)
      .then((response) => {
        dispatch(setActualDevieConfigConnection(response));
        dispatch(
          setAlert({
            highlight: 'Config Updated',
            message: 'Device Config successfully updated',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const _isAbleToBeSaved = (): boolean => {
    return isEqual(newDeviceConfigConnection, currentDeviceConfigConnetion);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={12}>
        <Header title="Config Content" goBack="configs" model="config" />
      </Grid>
      <Grid item xs={12}>
        {currentDeviceConfigConnetion && (
          <Box className={classes.contentContainer}>
            <ContentHeader
              contentType="config-connection"
              title={currentDeviceConfigConnetion.name}
              subtitle={currentDeviceConfigConnetion._id}
              copySubtitleToClipboard
              extraImage={configurationCompany?.branding?.logo_url}
              extraTitle={configurationCompany?.name}
              extraSubtitle={configurationCompany?._id}
            />
            <ConfigDetails
              newDeviceConfigConnection={newDeviceConfigConnection}
              deviceTypes={deviceTypes}
            />
          </Box>
        )}
      </Grid>
      <FooterBar
        deleteModalContent="You are about to delete the configuration"
        loading={loading}
        disableSaveButton={false}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteUser}
      />
    </Grid>
  );
};

export default ConfigContent;
