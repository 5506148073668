import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';

interface WorkflowsFiltersProps {
  total: number;
}

const WorkflowsFilters: React.FC<WorkflowsFiltersProps> = ({ total }) => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.workflows.filters;

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'workflows',
      ),
    );
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search workflows by name"
      inputKey="name"
      filterModel="workflows"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
    />
  );
};

export default WorkflowsFilters;
