import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    errorMsg: {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'Saira, sans-serif',
      height: '100%',
      justifyContent: 'center',
    },
    frame: {
      height: '80vh',
    },
    input: {
      height: '44px',
    },
  }),
);

export default useStyles;
