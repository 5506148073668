import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordStrengthBar from 'react-password-strength-bar';
import copyTextToClipboard from '../../helpers/copyTextToClipboard';
import TextInput from '../TextInput';

export interface PasswordInputProps {
  label?: string;
  value?: string;
  prop: string;
  placeholder?: string;
  required?: boolean;
  onInputChange?: (prop: string, value: string | number) => void;
  classes?: string;
  disabled?: boolean;
  hasCopyButton?: boolean;
  hasPasswordStrengthBar?: boolean;
  onCopy?: () => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  value,
  prop,
  required,
  disabled,
  placeholder,
  onInputChange,
  classes,
  hasCopyButton,
  hasPasswordStrengthBar = true,
  onCopy,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  const alertOnCopy = (): void => {
    if (onCopy) {
      onCopy();
    }
  };

  return (
    <>
      <TextInput
        disabled={disabled}
        type={showPassword ? 'text' : 'password'}
        label={label}
        placeholder={placeholder}
        prop={prop}
        required={required}
        classes={classes}
        value={value ?? ''}
        onInputChange={onInputChange}
        endAdornment={
          <>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                className="mr-0"
                edge="end"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            {hasCopyButton && value && (
              <InputAdornment position="end">
                <IconButton
                  data-testid="copy-password-button"
                  aria-label="copy password"
                  className="mr-0"
                  edge="end"
                  onClick={copyTextToClipboard(value, alertOnCopy)}
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
      />
      {hasPasswordStrengthBar && value && (
        <PasswordStrengthBar password={value} />
      )}
    </>
  );
};

export default PasswordInput;
