import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Command,
  Configuration,
  Configurations,
  Device,
  Devices,
  DeviceType,
  DeviceTypes,
  GatewayCommandRequest,
  Setting,
  Settings,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { dispatchError, getFetchError } from '../../../helpers/utils';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import CodeEditor from '../../../components/CodeEditor';
import SelectInput from '../../../components/SelectInput';
import DeviceTypeIconName from '../../../components/DeviceTypeIconName';
import useStyles from '../../../components/RightDrawer/styles';

interface ApplySettingsDrawerProps {
  open: boolean;
  setting?: Setting; // The setting to apply, null when isBulk is true
  configuration?: Configuration; // The configuration of the setting, null when isBulk is true
  commands: Command[]; // The send commands of the configuration, in the config page we already have them, no need to refetch them.
  // if we are on the device or devices page, commands will be an empty array.
  devices?: Device[]; // on the device page this will be the device, no need to show a device selection.
  deviceTypes?: DeviceType[];
  isBulk?: boolean;
  handleCloseDrawer: () => void;
  onRemoveDevice?: (deviceId: string) => void;
}

const ApplySettingsDrawer: React.FC<ApplySettingsDrawerProps> = ({
  open,
  setting,
  configuration,
  commands,
  devices,
  deviceTypes,
  isBulk = false,
  handleCloseDrawer,
  onRemoveDevice,
}) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const { optionsDeviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const anchorRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [loadingConfigurations, setLoadingConfigurations] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [originalSettingValues, setOriginalSettingValues] = useState('');
  const [settingValues, setSettingValues] = useState('');
  const [commandOptions, setCommandOptions] = useState<Command[]>(commands);
  const [sendCommand, setSendCommand] = useState<Command>();
  const [sendCommandId, setSendCommandId] = useState('');
  const [deviceOptions, setDeviceOptions] = useState<Device[]>([]);
  const [chosenDevices, setChosenDevices] = useState<string[]>(
    isBulk && devices
      ? devices.map((d) => d._id)
      : !isBulk && devices?.length === 1
      ? [devices[0]._id]
      : [],
  );
  const [configOptions, setConfigOptions] = useState<Configuration[]>([]);
  const [chosenSettings, setChosenSettings] = useState<Setting | undefined>(
    setting,
  );
  const [settingsVersions, setSettingsVersions] = useState<Setting[]>([]);
  const [chosenConfig, setChosenConfig] = useState<Configuration>();
  const [settingsOptions, setSettingsOptions] = useState<Setting[]>([]);

  const getConfigCommands = (config?: Configuration): void => {
    if (config) {
      Configurations.getSendCommands(config._id)
        .then((options) => {
          setCommandOptions(options);
          if (options.length === 1) {
            setSendCommand(options[0]);
            setSendCommandId(options[0]._id);
          }
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('commands.'),
          );
        });
    }
  };

  const getDevices = async (): Promise<void> => {
    // Get the devices related to the setting and the devices related to the device types related to the setting.
    if (setting) {
      // First we set up the devices related to the setting
      let settingDevices: Device[] = await Settings.getDevices(setting._id);
      // Then we get the device types related to the setting
      const settingDeviceTypes: DeviceType[] = await Settings.getDeviceTypes(
        setting._id,
      );
      // Then for each device type we get its devices
      for (let i = 0; i < settingDeviceTypes.length; i++) {
        const settingDeviceType = settingDeviceTypes[i];
        const deviceTypeDevices = await Devices.list({
          device_type_id: {
            operator: 'eq',
            value: settingDeviceType._id,
          },
        });
        // Then we do a union with the devices we have before.
        settingDevices = [
          ...settingDevices,
          ...deviceTypeDevices.devices.filter(
            (a) => !settingDevices.some((b) => b._id === a._id),
          ),
        ];
      }
      // And lastly we set these devices to the state deviceOptions
      setDeviceOptions(settingDevices);
    }
  };

  const getConfigurations = async (): Promise<void> => {
    setLoadingConfigurations(true);
    // This function is only called when the drawer is opened from the devices page, to bulk apply settings to multiple devices.
    // The available configurations from which the user will choose from, are the configurations which all the device types of the chosen devices
    // are attached to. So first we get the device types of the chosen devices, logically they ALL should be available in the state object as deviceTypes.optionsDeviceTypes
    const chosenDeviceTypes: DeviceType[] = [];
    const chosenDeviceTypesIds: string[] = []; // To avoid mapping the previous array everytime we check if it has already been adden
    if (isBulk && devices) {
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        let deviceTypeFound = false;
        for (let j = 0; j < optionsDeviceTypes.length; j++) {
          const deviceType = optionsDeviceTypes[j];
          if (device.device_type_id === deviceType._id) {
            deviceTypeFound = true;
            if (!chosenDeviceTypesIds.includes(deviceType._id)) {
              chosenDeviceTypes.push(deviceType);
              chosenDeviceTypesIds.push(deviceType._id);
              break;
            }
          }
        }
        // In theory, and practice, the deviceType should be found in the optionsDeviceTypes, as this array is populated in the loading of the Devices page
        // and as mentioned before this code is only called when we are in the devices page (follow isBulk parameter logic)
        if (!deviceTypeFound) {
          const deviceType = await DeviceTypes.getOneById(
            device.device_type_id,
          );
          chosenDeviceTypes.push(deviceType);
          chosenDeviceTypesIds.push(deviceType._id);
        }
      }
    }

    // By this point chosenDeviceTpes should have values
    let configurations: Configuration[] = [];
    for (let i = 0; i < chosenDeviceTypes.length; i++) {
      const deviceType = chosenDeviceTypes[i];
      const deviceTypeConfigurations = await DeviceTypes.getConfigurations(
        deviceType._id,
      );
      if (i === 0) {
        configurations = [...deviceTypeConfigurations];
      } else {
        // Only keep the configurations that are attached to the previous deviceTypes, intersection of sets basically
        configurations = configurations.filter((a) =>
          deviceTypeConfigurations.some((b) => b._id === a._id),
        );
      }
    }
    setConfigOptions(configurations);
    setLoadingConfigurations(false);
  };

  const getSettings = async (): Promise<void> => {
    if (chosenConfig && devices) {
      setLoadingSettings(true);
      // We get the settings of the chosen config for each device chosen, and the settings of its device type
      // and we intersect them to get the ones in common
      let settings: Setting[] = [];
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        // The settings that can be applied to a device are the settings attached to it and to its device type.
        const deviceSettings = await Devices.getSettings(
          device._id,
          chosenConfig._id,
        );
        const deviceTypeSettings = await DeviceTypes.getSettings(
          device.device_type_id,
          chosenConfig._id,
        );
        // We do a union of sets to make sure no settings are repeated, for settings that's "_id" and "version"
        const mergedSettings = [
          ...deviceSettings,
          ...deviceTypeSettings.filter(
            (a) =>
              !deviceSettings.some(
                (b) => b._id === a._id && b.version === a.version,
              ),
          ),
        ];
        if (i === 0) {
          // For the first iteration we just set the settings we got before
          settings = [...mergedSettings];
        } else {
          // For later iterations we always intersect the final result array with the mergedSettings at hand in the iteration.
          settings = settings.filter((a) =>
            mergedSettings.some(
              (b) => b._id === a._id && b.version === a.version,
            ),
          );
        }
      }
      setSettingsOptions(settings);
      setLoadingSettings(false);
    }
  };

  useEffect(() => {
    if (commands.length === 1) {
      setSendCommand(commands[0]);
      setSendCommandId(commands[0]._id);
    } else if (commands.length === 0) {
      getConfigCommands(configuration);
    }

    if (setting) {
      Settings.list({
        _id: {
          operator: 'eq',
          value: setting._id,
        },
      })
        .then((response) => {
          if (response.settings.length > 1) {
            setSettingsVersions(response.settings);
          }
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            'Error getting settings',
          );
          setLoading(false);
        });

      // If we want to apply a setting in the configuration page, then we want to show the devices that we can apply the settings to.
      // and to know this case, it is when the devices prop is an empty array
      if (devices?.length === 0) {
        getDevices();
      }
    }

    if (isBulk) {
      getConfigurations();
    }
  }, []);

  useEffect(() => {
    if (isBulk) {
      setChosenConfig(undefined);
      setChosenSettings(undefined);
      getConfigurations();
    }
  }, [devices]);

  useEffect(() => {
    if (chosenSettings) {
      try {
        const values = JSON.stringify(chosenSettings.values, null, 2);
        setOriginalSettingValues(values);
        setSettingValues(values);
      } catch (error) {
        console.info(error);
      }
    }
  }, [chosenSettings]);

  useEffect(() => {
    // Once the user chooses a configuration. We get the settings related to the devices and their device types using the configuration id to filter
    if (isBulk) {
      setChosenSettings(undefined);
      getSettings();
      getConfigCommands(chosenConfig);
    }
  }, [chosenConfig]);

  const applySetting = (version: string): void => {
    const executeOnDevices = isBulk // From Devices page
      ? devices?.map((d) => d._id)
      : devices?.length === 1 // From Device Content page
      ? [devices[0]._id]
      : chosenDevices; // From Settings List in configuration content page

    const gatewayRequest: GatewayCommandRequest = {
      command_id: sendCommandId,
      command_type: 'setting',
      settings_id: chosenSettings?._id as string,
      version,
    };

    if (executeOnDevices?.length === 1) {
      Devices.processGatewayCommand(executeOnDevices[0], gatewayRequest)
        .then(() => {
          setChosenDevices([]);
          handleCloseDrawer();
          dispatch(
            setAlert({
              highlight: 'Apply Settings',
              message: 'Setting applied with success.',
              type: 'success',
            }),
          );
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            'Error applying settings',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (executeOnDevices) {
      Devices.bulkExecuteGatewayCommand(executeOnDevices, gatewayRequest)
        .then(() => {
          handleCloseDrawer();
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to apply the settings to the selected devices',
              type: 'success',
            }),
          );
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            'Error applying settings',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleActionCallback = (): void => {
    setLoading(true);
    if (settingValues !== originalSettingValues) {
      let newValues = {};
      try {
        newValues = JSON.parse(settingValues);
      } catch (error) {
        console.info(error);
      }
      const now = DateTime.utc().toISO();
      Settings.update(
        {
          ...chosenSettings,
          created_at: now,
          updated_at: now,
          values: newValues,
        } as Setting,
        true,
      )
        .then((response) => {
          applySetting(response.version.toString());
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            'Error updating settings',
          );
          setLoading(false);
        });
    } else if (chosenSettings) {
      applySetting(chosenSettings.version.toString());
    }
  };

  const handleSettingsChange = (_prop: string, value: string): void => {
    setSettingValues(value);
  };

  const renderMenuItem = (value: string, label: string): JSX.Element => (
    <MenuItem className="m-4 p-2" dense key={value} value={value}>
      {label}
    </MenuItem>
  );

  const renderUserCommandsOptions = (): JSX.Element[] =>
    commandOptions.map((commandOption) =>
      renderMenuItem(commandOption._id, commandOption.name),
    );

  const renderSettingsVersions = (): JSX.Element[] =>
    settingsVersions
      .sort((a, b) => {
        return a.version - b.version;
      })
      .map((settingVersion) =>
        renderMenuItem(
          settingVersion.version.toString(),
          settingVersion.version.toString(),
        ),
      );

  const onUserCommandChange = (_prop: string, value: string | number): void => {
    setSendCommand(
      commandOptions.find((commandOption) => commandOption._id === value),
    );
    setSendCommandId(value as string);
  };

  const onSettingVersionChange = (
    _prop: string,
    value: string | number,
  ): void => {
    setChosenSettings(
      settingsVersions.find(
        (settingVersion) => settingVersion.version.toString() === value,
      ) ?? setting,
    );
  };

  const onConfigurationChange = (
    _prop: string,
    value: string | number,
  ): void => {
    setChosenConfig(configOptions.find((c) => c._id === value));
  };

  const onSettingsChange = (_prop: string, value: string | number): void => {
    setChosenSettings(
      settingsOptions.find((s) => `${s._id}_${s.version}` === value),
    );
  };

  const getDeviceType = (id: string): DeviceType | undefined =>
    deviceTypes?.find((deviceType) => deviceType._id === id);

  const checkDevice = (deviceId: string) => (): void => {
    setChosenDevices(
      chosenDevices.includes(deviceId)
        ? chosenDevices.filter((id) => id !== deviceId)
        : [...chosenDevices, deviceId],
    );
  };

  const handleRemoveDevice = (deviceId: string) => (): void => {
    if (onRemoveDevice) {
      onRemoveDevice(deviceId);
    }
  };

  const renderDevices = (): JSX.Element => (
    <div className={clsx('scrollbar mb-4', classes.drawerSelectedDevices)}>
      {(!isBulk ? deviceOptions : devices ? devices : []).map(
        (deviceOption) => (
          <div
            key={`apply-setting-device-${deviceOption._id}`}
            data-cy="device-container"
            className={clsx('mb-2 br-1 p-2', classes.drawerDeviceContainer)}
          >
            <Typography
              variant="subtitle2"
              component="div"
              data-cy="device-name"
            >
              {!isBulk && (
                <Checkbox
                  className="p-0 mr-2"
                  checked={chosenDevices.includes(deviceOption._id)}
                  onChange={checkDevice(deviceOption._id)}
                />
              )}
              {deviceOption.name}
            </Typography>
            <div className="d-flex flex-items-center">
              <DeviceTypeIconName
                labelVariant="subtitle2"
                deviceType={getDeviceType(deviceOption.device_type_id)}
              />
              {isBulk && onRemoveDevice && (
                <IconButton
                  aria-label="close-drawer"
                  data-cy="remove-device-button"
                  onClick={handleRemoveDevice(deviceOption._id)}
                >
                  <CloseIcon className={classes.closeDrawerIcon} />
                </IconButton>
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );

  const renderSettingsVersion = (): JSX.Element =>
    settingsVersions.length > 1 && chosenSettings ? (
      <Grid item xs={12}>
        <Typography variant="h6" className="mb-2">
          Settings Version
        </Typography>
        <SelectInput
          prop="settings-version"
          fullWidth={false}
          classes={'w-50'}
          value={chosenSettings.version.toString()}
          options={renderSettingsVersions()}
          onSelectChange={onSettingVersionChange}
        />
      </Grid>
    ) : (
      <></>
    );

  const renderSettingsChangeWarning = (): JSX.Element => (
    <Grid item xs={12}>
      <div className={clsx('br-1 p-4 my-4', classes.infoContainer)}>
        <InfoIcon className={clsx('mr-4', classes.infoIcon)} />
        <Typography component="div" variant="button" color={'#fff'}>
          Updating the values and applying them will create a new version for
          these settings.
        </Typography>
      </div>
    </Grid>
  );

  const renderSettingsValues = (): JSX.Element => (
    <Grid item xs={12} className="mb-4">
      <Box ref={anchorRef}>
        {settingValues !== '' ? (
          <CodeEditor
            height={200}
            label=""
            mode="json"
            prop="json_schema"
            value={settingValues}
            width={anchorRef.current?.clientWidth}
            onInputChange={handleSettingsChange}
          />
        ) : (
          <Typography variant="button">
            The settings selected have no values
          </Typography>
        )}
      </Box>
    </Grid>
  );

  const renderSendCommand = (): JSX.Element => (
    <Grid item xs={12} className="mb-4">
      {commandOptions.length === 1 && (
        <Typography variant="h6">
          {`Send using command: ${sendCommand?.name}`}
        </Typography>
      )}
      {commandOptions.length > 1 && (
        <SelectInput
          prop="chosenUserCommandId"
          label="Send using command"
          labelVariant="h6"
          value={sendCommandId}
          onSelectChange={onUserCommandChange}
          options={[
            <MenuItem dense value="" key="no-value-command">
              Select a command
            </MenuItem>,
            ...renderUserCommandsOptions(),
          ]}
        />
      )}
    </Grid>
  );

  /**
   * Disable when:
   * - no sendCommandId is found or chosen (from any flow)
   * - it's not a bulk action, in settings page, and no devices has been chosen
   * - it's not a bulk action, in device content page, chosenDevices should have the device we're seeing.
   */
  const disableAction =
    !sendCommandId ||
    (!isBulk && chosenDevices.length === 0) ||
    (isBulk && !chosenSettings);

  return (
    <RightDrawer
      open={open}
      actionLabel="Apply"
      title="Apply Settings"
      disableAction={loading || disableAction}
      actionLoading={loading}
      actionCallback={handleActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            {!isBulk && (
              <>
                <Typography
                  data-cy="settings-to-apply-title"
                  variant="h6"
                  className="mb-2"
                >
                  {devices?.length === 1
                    ? `Apply settings: ${chosenSettings?.name} to device ${devices[0].name}.`
                    : `Settings to apply: ${chosenSettings?.name}`}
                </Typography>
                {configuration && (
                  <>
                    {renderSettingsVersion()}
                    {renderSettingsChangeWarning()}
                    {renderSettingsValues()}
                    {renderSendCommand()}
                    {devices?.length !== 1 && (
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          component="div"
                          className="mb-3"
                          data-cy="select-devices-to-apply-setting-title"
                        >
                          Select the devices to apply the selected settings to.
                        </Typography>
                        {renderDevices()}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
            {isBulk && (
              <>
                <Typography
                  variant="h6"
                  component="div"
                  className="mb-3"
                  data-cy="selected-devices-count"
                >
                  {devices?.length === 1
                    ? '1 Device '
                    : `${devices?.length} Devices `}
                  selected
                </Typography>
                {renderDevices()}
                {configOptions.length ? (
                  <>
                    <Grid item xs={12} className="mb-3">
                      <Typography variant="h6" className="mb-2">
                        Configuration
                      </Typography>
                      <SelectInput
                        prop="confiuguration"
                        fullWidth={true}
                        value={chosenConfig?._id ?? ''}
                        options={[
                          renderMenuItem('', 'Select a configuration'),
                          ...configOptions.map((c) =>
                            renderMenuItem(c._id, c.name),
                          ),
                        ]}
                        onSelectChange={onConfigurationChange}
                      />
                    </Grid>
                    {chosenConfig && settingsOptions.length ? (
                      <>
                        <Grid item xs={12} className="mb-3">
                          <Typography variant="h6" className="mb-2">
                            Settings
                          </Typography>
                          <SelectInput
                            prop="settings"
                            fullWidth={true}
                            value={
                              chosenSettings
                                ? `${chosenSettings._id}_${chosenSettings.version}`
                                : ''
                            }
                            options={[
                              renderMenuItem('', 'Select settings'),
                              ...settingsOptions.map((s) =>
                                renderMenuItem(
                                  `${s._id}_${s.version}`,
                                  `${s.name} v${s.version}`,
                                ),
                              ),
                            ]}
                            onSelectChange={onSettingsChange}
                          />
                        </Grid>
                        {chosenSettings && (
                          <>
                            {renderSettingsChangeWarning()}
                            {renderSettingsValues()}
                            {renderSendCommand()}
                          </>
                        )}
                      </>
                    ) : chosenConfig && !loadingSettings ? (
                      <Typography variant="button">
                        The chosen configuration has no settings{' '}
                        {devices?.length === 1
                          ? 'available for the selected device.'
                          : 'shared between the selected devices.'}
                      </Typography>
                    ) : (
                      loadingSettings && (
                        <Typography variant="button">
                          Loading Settings
                          <CircularProgress size={10} className="ml-2" />
                        </Typography>
                      )
                    )}
                  </>
                ) : !loadingConfigurations ? (
                  <Typography variant="button">
                    There are no configurations{' '}
                    {devices?.length === 1
                      ? 'available for the selected device.'
                      : 'shared between the selected devices.'}
                  </Typography>
                ) : (
                  <Typography variant="button">
                    Loading Configurations
                    <CircularProgress size={10} className="ml-2" />
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default ApplySettingsDrawer;
