import { Integration } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';
import { integrationTypesMap } from '../../app/constants';

export const IntegrationsColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const integration = item as Integration;
      return integration.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    link: (item: TableItemType): string => {
      const integration = item as Integration;
      return `/integration/${integration._id}`;
    },
    type: 'link',
  },
  {
    cellValue: (item: TableItemType): string => {
      const integration = item as Integration;
      return integrationTypesMap[integration.type];
    },
    id: 'type',
    label: 'Type',
    type: 'text',
  },
];
