import React from 'react';
import { useUserWithType } from '../../hooks/useUserWithType';
import CommandExecutionDrawer from '../../components/CommandExecutionDrawer';
import { CommandExecutionDrawerProps } from '../../components/CommandExecutionDrawer/CommandExecutionDrawer';

const CommandExecutionDrawerContainer: React.FC<CommandExecutionDrawerProps> = (
  props: CommandExecutionDrawerProps,
) => {
  const { user } = useUserWithType(props.executedBy);

  return (
    <CommandExecutionDrawer
      {...props}
      executedBy={user ? `${user?.first_name} ${user?.last_name}` : ''}
    />
  );
};

export default CommandExecutionDrawerContainer;
