import { IntegrationEvent } from '@edgeiq/edgeiq-api-js';

export const IntegrationEventsTypes = {
  SET_INTEGRATION_EVENT: 'SET_INTEGRATION_EVENT',
  SET_SELECTED_INTEGRATION_EVENT: 'SET_SELECTED_INTEGRATION_EVENT',
};

export const setIntegrationEvent = (
  hearbeats: IntegrationEvent[],
): IntegrationEventAction => ({
  data: hearbeats,
  type: IntegrationEventsTypes.SET_INTEGRATION_EVENT,
});

export const setSelectedIntegrationEvents = (
  hearbeats: IntegrationEvent[],
): IntegrationEventAction => ({
  data: hearbeats,
  type: IntegrationEventsTypes.SET_SELECTED_INTEGRATION_EVENT,
});

export type IntegrationEventState = {
  integrationEvents: IntegrationEvent[];
  selectedIntegrationEvents: IntegrationEvent[];
};

// INITIAL STATE
const integrationEventInitialState: IntegrationEventState = {
  integrationEvents: [],
  selectedIntegrationEvents: [],
};

export type IntegrationEventAction = {
  data: IntegrationEvent[] | IntegrationEvent;
  type: string;
};

const integrationEventsReducer = (
  state = integrationEventInitialState,
  action: IntegrationEventAction,
): IntegrationEventState => {
  switch (action.type) {
    case IntegrationEventsTypes.SET_INTEGRATION_EVENT:
      return {
        ...state,
        integrationEvents: action.data as IntegrationEvent[],
      };
    case IntegrationEventsTypes.SET_SELECTED_INTEGRATION_EVENT:
      return {
        ...state,
        selectedIntegrationEvents: action.data as IntegrationEvent[],
      };
    default:
      return state;
  }
};

export default integrationEventsReducer;
