import { useState, useEffect, ReactElement } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Users, User } from '@edgeiq/edgeiq-api-js';

import TabsPage from '../../../components/TabsPage';
import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import MetadataDrawer, {
  MetadataListProps,
} from '../../../containers/RightDrawer/AttachMetadata/MetadataDrawer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import {
  setStateCurrentUser,
  setStateNewUser,
} from '../../../redux/reducers/users.reducer';

const columns: GridColDef[] = [
  {
    field: 'key',
    flex: 0.5,
    headerName: 'Key',
    renderCell: (params: GridRenderCellParams): ReactElement => (
      <strong>{params.row.key}</strong>
    ),
  },
  {
    field: 'value',
    flex: 0.5,
    headerName: 'Value',
  },
];

const UserMetadata: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metadataList, setMetadataList] = useState<MetadataListProps[]>();
  const newUser = useAppSelector((state: RootState) => state.users.newUser);

  useEffect(() => {
    const auxArray = [];

    if (newUser?.metadata) {
      for (const [key, value] of Object.entries(newUser?.metadata)) {
        auxArray.push({ key, value });
      }
    }
    setMetadataList(auxArray);
  }, [newUser]);

  const handleAddMetadata = (metadata: MetadataListProps[]): void => {
    const metadataObj = metadata.length
      ? metadata.reduce(
          (prevMetadata, nextMetadata) => ({
            ...prevMetadata,
            [nextMetadata.key as string]: nextMetadata.value,
          }),
          { [metadata[0].key as string]: metadata[0].value },
        )
      : {};

    setOpen(false);
    setLoading(true);

    // TODO: Verify why the API doesn't accept remove metadata
    Users.update({ ...newUser, metadata: metadataObj } as User)
      .then((response) => {
        dispatch(setStateCurrentUser(response));
        dispatch(setStateNewUser(response));
        dispatch(
          setAlert({
            highlight: 'Metadata updated',
            message: 'User metadata successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={metadataList}
        addButtonLabel="Update Metadata"
        addButtonIcon={false}
        onAddClick={(): void => setOpen(true)}
        tableTitle="Metadata Added"
        loading={loading}
      />
      {metadataList && (
        <MetadataDrawer
          open={open}
          metadata={metadataList}
          handleCloseDrawer={(): void => setOpen(false)}
          onMetadataAdd={handleAddMetadata}
        />
      )}
    </Box>
  );
};

export default UserMetadata;
