import { PollableAttributeInput } from '@edgeiq/edgeiq-api-js';

export const POLLABLE_DATA_TYPES = [
  {
    key: 'choose_type',
    label: 'CHOOSE TYPE',
  },
  {
    key: 'snmp_mib_integer',
    label: 'SNMP MIB Integer',
  },
  {
    key: 'snmp_mib_octet_string',
    label: 'SNMP MIB Octet String',
  },
];
export const EMPTY_POLLABLE_ATTRIBUTES: PollableAttributeInput = {
  attributes: [],
  company_id: '',
  defaults: {},
  interval: 16,
  mib: {
    identity: '',
  },
  name: '',
  type: 'snmp_mib',
};
