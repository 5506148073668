import { Report } from '@edgeiq/edgeiq-api-js';

export const ReportsTypes = {
  SET_REPORTS: 'SET_REPORTS',
  SET_SELECTED_REPORTS: 'SET_SELECTED_REPORTS',
};

export const setStateMessageReports = (
  hearbeats: Report[],
): MessageReportsAction => ({
  data: hearbeats,
  type: ReportsTypes.SET_REPORTS,
});

export const setSelectedMessageReports = (
  hearbeats: Report[],
): MessageReportsAction => ({
  data: hearbeats,
  type: ReportsTypes.SET_SELECTED_REPORTS,
});

export type MessageReportsState = {
  reports: Report[];
  originalReports: Report[];
  selectedReports: Report[];
};

// INITIAL STATE
const messageReportsInitialState: MessageReportsState = {
  originalReports: [],
  reports: [],
  selectedReports: [],
};

export type MessageReportsAction = {
  data: Report[] | Report;
  type: string;
};

const messageReportsReducer = (
  state = messageReportsInitialState,
  action: MessageReportsAction,
): MessageReportsState => {
  switch (action.type) {
    case ReportsTypes.SET_REPORTS:
      return {
        ...state,
        reports: action.data as Report[],
      };
    case ReportsTypes.SET_SELECTED_REPORTS:
      return {
        ...state,
        selectedReports: action.data as Report[],
      };
    default:
      return state;
  }
};

export default messageReportsReducer;
