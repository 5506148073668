import React from 'react';
import { Box, Typography } from '@mui/material';
import { DeviceType, DeviceTypeInput } from '@edgeiq/edgeiq-api-js';
import Tags from '../../../../containers/Tags';

interface DeviceTypeObservablePathsProps {
  deviceType: DeviceType | DeviceTypeInput;
  onInputChange: (prop: string, value: string[]) => void;
}

const DeviceTypeObservablePaths: React.FC<DeviceTypeObservablePathsProps> = ({
  deviceType,
  onInputChange,
}) => (
  <Box>
    <Typography variant="h6" className="custom-label mt-5">
      Lwm2m Observable Paths
    </Typography>
    <Tags
      key="device-type-observable-paths"
      originalTags={
        (deviceType.capabilities.observable_paths as string[]) ?? []
      }
      onChangeTags={onInputChange}
      placeholder="Type to add an observable path"
      width={300}
      tagsOptions={false}
    />
  </Box>
);

export default DeviceTypeObservablePaths;
