import { Ingestor, IngestorInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';
import { EMPTY_INGESTOR } from '../../constants/ingestors';

interface AdaptedIngestor extends Ingestor {
  is_from_device_type?: boolean;
}

export const IngestorsTypes = {
  CLEAN_NEW_INGESTORS_INPUT: 'CLEAN_NEW_COMMAND_INPUT',
  SET_ACTUAL_INGESTOR: 'SET_ACTUAL_INGESTOR',
  SET_INGESTORS: 'SET_INGESTORS',
  SET_NEW_INGESTOR: 'SET_NEW_INGESTOR',
  SET_ORIGINAL_INGESTORS: 'SET_ORIGINAL_INGESTORS',
  SET_SELECTED_INGESTORS: 'SET_SELECTED_INGESTORS',
};

export const getIngestorSelector = createSelector(
  (state: IngestorsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.ingestors.length) {
      return state.ingestor;
    }
    return state.ingestors.find((ingestor: Ingestor) => ingestor._id === id);
  },
);

export const cleanNewIngestor = (): IngestorsAction => ({
  data: EMPTY_INGESTOR as IngestorInput,
  type: IngestorsTypes.CLEAN_NEW_INGESTORS_INPUT,
});

export const setStateIngestors = (ingestors: Ingestor[]): IngestorsAction => ({
  data: ingestors,
  type: IngestorsTypes.SET_INGESTORS,
});

export const setActualIngestor = (ingestor: Ingestor): IngestorsAction => ({
  data: ingestor,
  type: IngestorsTypes.SET_ACTUAL_INGESTOR,
});

export const setSelectedIngestors = (
  ingestors: Ingestor[],
): IngestorsAction => ({
  data: ingestors,
  type: IngestorsTypes.SET_SELECTED_INGESTORS,
});

export const setOriginalIngestors = (
  ingestors: Ingestor[],
): IngestorsAction => ({
  data: ingestors,
  type: IngestorsTypes.SET_ORIGINAL_INGESTORS,
});

export const setNewIngestor = (
  ingestor: Ingestor | IngestorInput,
): IngestorsAction => ({
  data: ingestor,
  type: IngestorsTypes.SET_NEW_INGESTOR,
});

export type IngestorsState = {
  ingestor: Ingestor | null;
  ingestors: Ingestor[];
  newIngestor: Ingestor | IngestorInput | null;
  originalIngestors: AdaptedIngestor[];
  selectedIngestors: Ingestor[];
};

// INITIAL STATE
const ingestorsInitialState: IngestorsState = {
  ingestor: null,
  ingestors: [],
  newIngestor: null,
  originalIngestors: [],
  selectedIngestors: [],
};

export type IngestorsAction = {
  data: Ingestor[] | Ingestor | IngestorInput;
  type: string;
};

const ingestorsReducer = (
  state = ingestorsInitialState,
  action: IngestorsAction,
): IngestorsState => {
  switch (action.type) {
    case IngestorsTypes.SET_INGESTORS:
      return {
        ...state,
        ingestors: action.data as Ingestor[],
      };

    case IngestorsTypes.SET_ACTUAL_INGESTOR:
      return {
        ...state,
        ingestor: action.data as Ingestor,
        newIngestor: action.data as Ingestor,
      };
    case IngestorsTypes.SET_NEW_INGESTOR:
      return {
        ...state,
        newIngestor: action.data as Ingestor | IngestorInput,
      };

    case IngestorsTypes.SET_SELECTED_INGESTORS:
      return {
        ...state,
        selectedIngestors: action.data as Ingestor[],
      };
    case IngestorsTypes.SET_ORIGINAL_INGESTORS:
      return {
        ...state,
        originalIngestors: action.data as Ingestor[],
        selectedIngestors: action.data as Ingestor[],
      };

    case IngestorsTypes.CLEAN_NEW_INGESTORS_INPUT:
      return {
        ...state,
        newIngestor: action.data as IngestorInput,
      };
    default:
      return state;
  }
};

export default ingestorsReducer;
