import React from 'react';
import { MenuItem } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import { activeFilterLabel, ruleTypesFilterLabel } from '../../app/constants';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';
import SelectInput from '../../components/SelectInput';

interface PoliciesFiltersProps {
  total: number;
}

const PoliciesFilters: React.FC<PoliciesFiltersProps> = ({ total }) => {
  const dispatch = useAppDispatch();
  const policiesFilters = useAppSelector(
    (state: RootState) => state.filters.policies.filters,
  );
  const activeTypesOptions = Object.keys(activeFilterLabel);
  const ruleTypesOptions = Object.keys(ruleTypesFilterLabel);

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...policiesFilters,
          [prop]: value,
        },
        'policies',
      ),
    );
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search policies by name"
      inputKey="description"
      filterModel="policies"
      firstFilter={
        <AccountFilter
          accountsSelected={policiesFilters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <SelectInput
          prop="active"
          value={policiesFilters?.active ?? ''}
          placeholder="Status"
          options={activeTypesOptions.map((type, key) => (
            <MenuItem dense key={key} value={type}>
              {activeFilterLabel[type]}
            </MenuItem>
          ))}
          onSelectChange={changeFilters}
        />
      }
      thirdFilter={
        <SelectInput
          prop="cloud_rule"
          value={policiesFilters?.cloud_rule ?? ''}
          placeholder="Policy Location"
          options={ruleTypesOptions.map((type, key) => (
            <MenuItem dense key={key} value={type}>
              {ruleTypesFilterLabel[type]}
            </MenuItem>
          ))}
          onSelectChange={changeFilters}
        />
      }
    />
  );
};

export default PoliciesFilters;
