import React from 'react';
import { Grid, Paper } from '@mui/material';
import { Integration } from '@edgeiq/edgeiq-api-js';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNewPolicy } from '../../../redux/reducers/policies.reducer';
import {
  addNewElseAction,
  changeElseActionsValues,
  removeElseAction,
} from '../../../containers/Forms/PolicyForm/helper';
import PolicyElseActionsSection from '../../../containers/Forms/PolicyForm/PolicyActionsSection/PolicyElseActionsSection';

interface PolicyElseActionsSectionWrapperProps {
  integrations: Integration[];
  mixedTypes: boolean;
}
const PolicyElseActionsSectionWrapper: React.FC<
  PolicyElseActionsSectionWrapperProps
> = ({ integrations, mixedTypes }) => {
  const dispatch = useAppDispatch();
  const newPolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );

  // START OF ELSE ACTIONS MODULE
  const handleElseActionValueChange = (
    prop: string,
    value: string | number | string[] | boolean | { [key: string]: string },
  ): void => {
    dispatch(
      setNewPolicy(changeElseActionsValues(newPolicy, prop, value as string)),
    );
  };

  const handleAddNewElseAction = (): void => {
    return dispatch(setNewPolicy(addNewElseAction(newPolicy)));
  };

  const handleRemoveElseAction = (actionIndex: number): void => {
    return dispatch(setNewPolicy(removeElseAction(newPolicy, actionIndex)));
  };
  // END OF ELSE ACTIONS MODULE

  return (
    <Grid container>
      {newPolicy && (
        <Grid component={Paper} item xs={12} className={'p-7 shadow'}>
          <PolicyElseActionsSection
            newPolicy={newPolicy}
            integrations={integrations}
            mixedTypes={mixedTypes}
            onInputChange={handleElseActionValueChange}
            onAddNewAction={handleAddNewElseAction}
            onRemoveAction={handleRemoveElseAction}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PolicyElseActionsSectionWrapper;
