import {
  DeviceConfig,
  DeviceConfigInput,
  IPTableAction,
} from '@edgeiq/edgeiq-api-js';

import { EMPTY_IP_TABLE } from '../../../constants/configConnections';

// Every time a whole condition is added this function is trigger to check if is already an array and added it.
// If its already an array it will only update the array, else it will create the new array and replace the current object.
export const addNewIPTable = (
  newDeviceConfig: DeviceConfigInput | DeviceConfig,
): DeviceConfigInput | DeviceConfig => {
  if (Array.isArray(newDeviceConfig.iptables)) {
    const auxIpTabalesArray = [
      ...newDeviceConfig.iptables,
      {
        ...EMPTY_IP_TABLE,
      } as IPTableAction,
    ];

    return {
      ...newDeviceConfig,
      iptables: auxIpTabalesArray,
    };
  }

  return {
    ...newDeviceConfig,
    iptables: [
      {
        ...EMPTY_IP_TABLE,
      },
    ],
  };
};

// Every time a condition is removed this function is trigger to remove it.
// It will always receive the conditionIndex to remove it and if its an array of 2,
// it will transform the array in an object back again.
export const removeNewIPTable = (
  newDeviceConfig: DeviceConfigInput | DeviceConfig,
  index: number,
): DeviceConfigInput | DeviceConfig => {
  if (Array.isArray(newDeviceConfig.iptables)) {
    const auxIpTabalesArray = [...newDeviceConfig.iptables];

    auxIpTabalesArray.splice(index, 1);

    if (auxIpTabalesArray.length > 0) {
      return {
        ...newDeviceConfig,
        iptables: auxIpTabalesArray,
      };
    }

    return {
      ...newDeviceConfig,
      iptables: [],
    };
  }

  return newDeviceConfig;
};

// Every time a whole condition is added this function is trigger to check if is already an array and added it.
// If its already an array it will only update the array, else it will create the new array and replace the current object.
export const addNewDeviceIPTable = (
  newDeviceConfig: DeviceConfig,
): DeviceConfig => {
  if (Array.isArray(newDeviceConfig.iptables)) {
    const auxIpTabalesArray = [
      ...newDeviceConfig.iptables,
      {
        ...EMPTY_IP_TABLE,
      } as IPTableAction,
    ];

    return {
      ...newDeviceConfig,
      iptables: auxIpTabalesArray,
    };
  }

  return {
    ...newDeviceConfig,
    iptables: [
      {
        ...EMPTY_IP_TABLE,
      },
    ],
  };
};

// Every time a condition is removed this function is trigger to remove it.
// It will always receive the conditionIndex to remove it and if its an array of 2,
// it will transform the array in an object back again.
export const removeNewDeviceConfigIPTable = (
  newDeviceConfig: DeviceConfig,
  index: number,
): DeviceConfig => {
  if (Array.isArray(newDeviceConfig.iptables)) {
    const auxIpTabalesArray = [...newDeviceConfig.iptables];

    auxIpTabalesArray.splice(index, 1);

    if (auxIpTabalesArray.length > 0) {
      return {
        ...newDeviceConfig,
        iptables: auxIpTabalesArray,
      };
    }

    return {
      ...newDeviceConfig,
      iptables: [],
    };
  }

  return newDeviceConfig;
};
