import React, { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Device,
  DeviceType,
  LWM2M,
  Lwm2mStateObject,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { RootState } from '../../../redux/store';
import { errorHighlight } from '../../../app/constants';
import Accordion from '../../../components/Accordion';
import timeHelpers from '../../../helpers/timeHelpers';
import useStyles from './styles';

interface DeviceLwm2mDetailProps {
  deviceType: DeviceType;
}

interface AdaptedLwm2mStateObject extends Lwm2mStateObject {
  key: string;
}

const DeviceLwm2mDetail: React.FC<DeviceLwm2mDetailProps> = ({
  deviceType,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [loadingState, setLoadingState] = useState(true);

  const stateDevice = useAppSelector(
    (state: RootState) => state.devices.device,
  );
  const [lwm2mState, setLwm2mState] = useState<AdaptedLwm2mStateObject[]>([]);
  const [device, setDevice] = useState<Device>(stateDevice as Device);

  useEffect(() => {
    setDevice(stateDevice as Device);
  }, [stateDevice]);

  useEffect(() => {
    if (device) {
      LWM2M.listStates({
        device_id: {
          operator: 'eq',
          value: device?._id as string,
        },
        withModels: true,
      })
        .then((res) => {
          // there should be only one item, based on the device filter above
          const stateItem = res === null ? null : res[0];
          if (stateItem && stateItem.state) {
            const items: AdaptedLwm2mStateObject[] = [];
            for (const key in stateItem.state) {
              if (Object.prototype.hasOwnProperty.call(stateItem.state, key)) {
                const element = stateItem.state[key];
                items.push({
                  key,
                  ...(element as Lwm2mStateObject),
                });
              }
            }
            // console.info(items);
            setLwm2mState(items);
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => setLoadingState(false));
    }
  }, [stateDevice]);

  const handleReadResource = (): void => {
    console.info('Read action');
  };

  const handleObserveResource = (): void => {
    console.info('Observe action');
  };

  const handleWriteResource = (): void => {
    console.info('Write action');
  };

  const handleExecuteResource = (): void => {
    console.info('Execute action');
  };

  const handleResourceParams = (): void => {
    console.info('Params action');
  };

  const renderResourceHeader = (
    title: string,
    width: number,
  ): React.ReactElement => (
    <Grid item xs={width}>
      <Typography variant="button" className={classes.header}>
        {title}
      </Typography>
    </Grid>
  );

  const renderReadAndObserve = (path: string): React.ReactElement => {
    return (
      <>
        <Tooltip placement="top" title="Read">
          <Button
            data-cy={`read-${path}`}
            color="primary"
            variant="text"
            type="button"
            className={classes.operationButton}
            onClick={handleReadResource}
          >
            <Typography variant="overline">R</Typography>
          </Button>
        </Tooltip>
        {deviceType?.capabilities?.observable_paths?.includes(path) && (
          <Tooltip placement="top" title="Observe">
            <Button
              data-cy={`observe-${path}`}
              color="primary"
              variant="text"
              type="button"
              className={classes.operationButton}
              onClick={handleObserveResource}
            >
              <Typography variant="overline">OBS</Typography>
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  const renderResourceActions = (
    actions: string,
    path: string,
  ): React.ReactElement => {
    return (
      <>
        {actions.indexOf('R') !== -1 && renderReadAndObserve(path)}
        {actions.indexOf('W') !== -1 && (
          <Tooltip placement="top" title="Write">
            <Button
              data-cy={`write-${path}`}
              color="primary"
              variant="text"
              type="button"
              className={classes.operationButton}
              onClick={handleWriteResource}
            >
              <Typography variant="overline">W</Typography>
            </Button>
          </Tooltip>
        )}
        {actions.indexOf('E') !== -1 && (
          <>
            <Tooltip placement="top" title="Write">
              <Button
                data-cy={`execute-${path}`}
                color="primary"
                variant="text"
                type="button"
                className={classes.operationButton}
                onClick={handleExecuteResource}
              >
                <Typography variant="overline">EXE</Typography>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Params">
              <Button
                data-cy={`config-${path}`}
                color="primary"
                variant="text"
                type="button"
                className={classes.operationButton}
                onClick={handleResourceParams}
              >
                <SettingsIcon fontSize="small" />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  const renderResourceMultipleValue = (resource: {
    [key: string]: unknown;
  }): React.ReactElement => {
    const values = [];
    for (const valueKey in resource) {
      if (Object.prototype.hasOwnProperty.call(resource, valueKey)) {
        values.push(resource[valueKey]);
      }
    }
    return (
      <Tooltip placement="top" title="Multiple Values Resource">
        <Typography variant="button">{values.join(', ')}</Typography>
      </Tooltip>
    );
  };

  const renderStateInstances = (
    state: AdaptedLwm2mStateObject,
    parentIndex: number,
  ): React.ReactElement => {
    console.info(state);
    if (Object.keys(state.instances).length) {
      const instances = [];
      const instancesKeys: string[] = [];
      for (const key in state.instances) {
        if (Object.prototype.hasOwnProperty.call(state.instances, key)) {
          instancesKeys.push(key);
          instances.push(state.instances[key]);
        }
      }
      return (
        <div className={classes.instancesContainer}>
          {instances.map((instance, index) => {
            const instanceObject = instance as { [key: string]: unknown };
            const resources = [];
            const resourcesKeys: string[] = [];
            for (const key in state.model?.resources) {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.model?.resources,
                  key,
                )
              ) {
                resourcesKeys.push(key);
                resources.push(state.model?.resources[key]);
              }
            }
            return (
              <div
                key={`state-${parentIndex}-instance-${instancesKeys[index]}`}
              >
                <div>
                  <Typography variant="button" className="mr-3 va-middle">
                    Instance {instancesKeys[index]}
                  </Typography>
                  {renderReadAndObserve(
                    `/${state.key}/${instancesKeys[index]}`,
                  )}
                </div>
                <Grid container direction="row" className="p-4">
                  {renderResourceHeader('Resource', 4)}
                  {renderResourceHeader('Path', 2)}
                  {renderResourceHeader('Actions', 2)}
                  {renderResourceHeader('Value', 4)}
                </Grid>
                {resources.map((resource, childIndex) => {
                  const path = `/${state.key}/${instancesKeys[index]}/${resourcesKeys[childIndex]}`;
                  return (
                    <Grid
                      container
                      direction="row"
                      className={clsx('p-4', classes.resourceContainer)}
                      key={`state-${parentIndex}-instance-${instancesKeys[index]}-resource-${resourcesKeys[childIndex]}`}
                    >
                      <Grid item xs={4}>
                        <Tooltip
                          placement="top"
                          title={resource?.description ?? ''}
                        >
                          <Typography variant="button">
                            {resource?.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="button">{path}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {resource?.operations &&
                          renderResourceActions(resource.operations, path)}
                      </Grid>
                      <Grid item xs={4}>
                        {!resource?.multiple &&
                          instanceObject[resourcesKeys[childIndex]] !==
                            undefined && (
                            <Typography variant="button">
                              <>
                                {resource?.type === 'STRING' &&
                                  (instanceObject[
                                    resourcesKeys[childIndex]
                                  ] as string)}
                                {(resource?.type === 'INTEGER' ||
                                  resource?.type === 'FLOAT') &&
                                  (instanceObject[
                                    resourcesKeys[childIndex]
                                  ] as number)}
                                {resource?.type === 'BOOLEAN' &&
                                  String(
                                    instanceObject[
                                      resourcesKeys[childIndex]
                                    ] as boolean,
                                  )}
                                {resource?.type === 'TIME' &&
                                  timeHelpers.getDate(
                                    instanceObject[
                                      resourcesKeys[childIndex]
                                    ] as string,
                                  )}
                                {resource?.type === 'CORELINK' &&
                                  decodeURI(
                                    instanceObject[
                                      resourcesKeys[childIndex]
                                    ] as string,
                                  )}
                              </>
                            </Typography>
                          )}
                        {resource?.multiple &&
                          renderResourceMultipleValue(
                            instanceObject[resourcesKeys[childIndex]] as {
                              [key: string]: unknown;
                            },
                          )}
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    }
    return <></>;
  };

  return (
    <Grid container>
      {device && lwm2mState && lwm2mState.length !== 0 ? (
        lwm2mState.map((state, index) => {
          return (
            <Accordion
              key={`${index}-${state.key}`}
              className={classes.accordionContainer}
              title={`${state.key} ${state.model?.name}`}
              titleVariant="h5"
              nextTitle={renderReadAndObserve(`/${state.key}`)}
              content={
                <div>
                  <div className="mb-4">
                    <Typography variant="button">
                      {state.model?.description}.
                      {state.model?.description2 && state.model?.description2}
                    </Typography>
                  </div>
                  {renderStateInstances(state, index)}
                </div>
              }
            />
          );
        })
      ) : loadingState ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <Grid component={Paper} item xs={8} className={clsx('p-7 shadow')}>
          <Typography variant="h5">This device has no LWM2M state.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceLwm2mDetail;
