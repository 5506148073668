import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { Device, DeviceType, ScheduledJobs } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  getScheduledJobSelector,
  setActualScheduledJob,
  setNewScheduledJob,
} from '../../redux/reducers/scheduledJobs.reducer';
import Header from '../../components/Header';
import ContentHeader from '../../components/ContentHeader';
import FooterBar from '../../components/FooterBar';
import VerticalTabs from '../../components/VerticalTabs';
import EntitiesSection from '../../containers/EntitiesSection';
import {
  DETAILS_DEFAULT_TAB,
  errorHighlight,
  policyDetailsTabsLabel,
  scheduledJobTypes,
} from '../../app/constants';
import { StatusTheme } from '../../models/common';
import ScheduledJobDetails from './scheduledJobDetails';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { dispatchError } from '../../helpers/utils';

const ScheduledJobContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const { id } = useParams<string>();
  const scheduledJobData = useAppSelector((state: RootState) =>
    getScheduledJobSelector(state.scheduledJobs, id),
  );
  const newScheduledJob = useAppSelector(
    (state: RootState) => state.scheduledJobs.newScheduledJob,
  );
  const [loading, setLoading] = useState(false);
  const [originalDevices, setOriginalDevices] = useState<Device[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [originalDeviceTypes, setOriginalDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [scheduledJobCompany] = useFetchCompany(
    scheduledJobData?.company_id,
    errorDispatcher,
  );
  const [cronSpecInvalid, setCronSpecInvalid] = useState(false);

  const dispatchAlert = (
    message: string,
    type: StatusTheme,
    highlight = errorHighlight,
  ): void => {
    dispatch(
      setAlert({
        highlight,
        message,
        type,
      }),
    );
  };

  useEffect(() => {
    if (scheduledJobData && newScheduledJob && newScheduledJob._id === id) {
      dispatch(setActualScheduledJob(scheduledJobData));
      getDevicesAndDeviceTypes(id);
    } else if (id) {
      ScheduledJobs.getOneById(id)
        .then((response) => {
          dispatch(setActualScheduledJob(response));
          getDevicesAndDeviceTypes(id);
        })
        .catch((err) => {
          dispatchAlert(err.message, 'error');
        });
    }
  }, [id]);

  const getDevicesAndDeviceTypes = (ruleId: string): void => {
    ScheduledJobs.getDevices(ruleId)
      .then((res) => {
        if (res) {
          setOriginalDevices([...res]);
          setSelectedDevices([...res]);
        }
      })
      .catch((err) => {
        dispatchAlert(err.message, 'error');
      });

    ScheduledJobs.getDeviceTypes(ruleId)
      .then((res) => {
        if (res) {
          setOriginalDeviceTypes([...res]);
          setSelectedDeviceTypes([...res]);
        }
      })
      .catch((err) => {
        dispatchAlert(err.message, 'error');
      });
  };

  const handleDeleteScheduledJob = (): void => {
    if (!scheduledJobData) {
      return;
    }
    setLoading(true);

    ScheduledJobs.delete(scheduledJobData._id)
      .then(() => {
        dispatchAlert('Job successfully deleted.', 'success', 'Delete job');
        navigate('/scheduled-jobs');
      })
      .catch((err) => {
        dispatchAlert(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newScheduledJob) {
      setLoading(true);
      ScheduledJobs.update(newScheduledJob)
        .then((response) => {
          dispatch(setActualScheduledJob(response));
          dispatch(setNewScheduledJob(response));
          dispatchAlert('Job successfully updated.', 'success', 'Update job');
        })
        .catch((err) => {
          dispatchAlert(err.message, 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const saveEntities = async (
    devices = selectedDevices,
    deviceTypes = selectedDeviceTypes,
  ): Promise<void> => {
    if (newScheduledJob) {
      try {
        const removeDevices = originalDevices
          .filter((e) => {
            return !devices.find((d) => d._id === e._id);
          })
          .map((e) => e._id);
        if (removeDevices.length) {
          await ScheduledJobs.bulkDetachDevices(
            newScheduledJob._id,
            removeDevices,
          );
        }

        const removeDeviceTypes = originalDeviceTypes
          .filter((e) => {
            return !deviceTypes.find((d) => d._id === e._id);
          })
          .map((e) => e._id);
        if (removeDeviceTypes.length) {
          await ScheduledJobs.bulkDetachDeviceTypes(
            newScheduledJob._id,
            removeDeviceTypes,
          );
        }

        const deviceIds = devices.map((attachDevice) => attachDevice._id);
        const deviceTypeIds = deviceTypes.map(
          (attachDeviceTypes) => attachDeviceTypes._id,
        );

        if (deviceIds.length > 0) {
          await ScheduledJobs.bulkAttachDevices(newScheduledJob._id, deviceIds);
        }

        if (deviceTypeIds.length > 0) {
          await ScheduledJobs.bulkAttachDeviceTypes(
            newScheduledJob._id,
            deviceTypeIds,
          );
        }
      } catch (error: unknown) {
        const { message } = error as { message: string };
        dispatchAlert(message, 'error');
      }
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newScheduledJob, scheduledJobData) && !cronSpecInvalid;
  };

  const handleChangeDevices = (devices: Device[]): void => {
    setSelectedDevices([...devices]);
    saveEntities(devices, selectedDeviceTypes);
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes([...deviceTypes]);
    saveEntities(selectedDevices, deviceTypes);
  };

  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: <ScheduledJobDetails setCronSpecInvalid={setCronSpecInvalid} />,
      entities: (
        <EntitiesSection
          newInput={newScheduledJob}
          selectedDevices={selectedDevices}
          selectedDeviceTypes={selectedDeviceTypes}
          shadowContainer={true}
          onChangeDevices={handleChangeDevices}
          onChangeDeviceTypes={handleChangeDeviceTypes}
        />
      ),
    };
    return tabs;
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Scheduled job content"
        goBack="scheduled-jobs"
        goBackLabel="Scheduled jobs"
      />
      {scheduledJobData && (
        <Box className="content-page-container">
          <ContentHeader
            title={scheduledJobData.job_name}
            contentType="scheduled-job"
            jobType={scheduledJobTypes[scheduledJobData?.job_type] as string}
            subtitle={scheduledJobData._id}
            extraImage={scheduledJobCompany?.branding?.logo_url}
            extraTitle={scheduledJobCompany?.name}
            extraSubtitle={scheduledJobCompany?._id}
            hideOverline={false}
            copySubtitleToClipboard={true}
          />

          <VerticalTabs
            tabs={getTabs()}
            defaultTab={DETAILS_DEFAULT_TAB}
            tabsLabel={policyDetailsTabsLabel}
          />
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this job"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteScheduledJob}
      />
    </Grid>
  );
};

export default ScheduledJobContent;
