import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { MetadataListProps } from './ActiveDevicesMoreFilters';

interface DeviceMetadataFilterProps {
  onFiltersChange: (prop: string, value: string | MetadataListProps) => void;
}

const DeviceMetadataFilter: React.FC<DeviceMetadataFilterProps> = ({
  onFiltersChange,
}) => {
  const [metadataItems, setMetadataItems] = useState<MetadataListProps[]>([]);

  const handleChange =
    (index: number, key: string) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const auxArray = [...metadataItems];
      auxArray[index][key] = event.target.value;

      setMetadataItems(auxArray);
    };

  const handleRemove = (index: number): void => {
    const auxArray = [...metadataItems];
    auxArray.splice(index, 1);

    setMetadataItems(auxArray);
  };

  useEffect(() => {
    const metadataObj = metadataItems.length
      ? metadataItems.reduce(
          (prevMetadata, nextMetadata) => ({
            ...prevMetadata,
            [`metadata.${nextMetadata.key as string}`]:
              nextMetadata.value as string,
          }),
          {
            [`metadata.${metadataItems[0].key}` as string]: metadataItems[0]
              .value as string,
          },
        )
      : {};
    onFiltersChange('metadata', metadataObj);
  }, [metadataItems]);

  const addNewMetadata = (): void => {
    setMetadataItems([...metadataItems, { key: '', value: '' }]);
  };

  return (
    <Box>
      {metadataItems.map((item, index) => (
        <Grid
          key={index}
          container
          direction="row"
          spacing={2}
          className="mt-2"
          alignItems="center"
        >
          <Grid item xs={5}>
            <Typography variant="subtitle2" className="custom-label">
              Key Pair
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                name="name"
                placeholder="Key"
                type="text"
                required={true}
                value={item.key}
                onChange={handleChange(index, 'key')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2" className="custom-label">
              Value
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                name="location"
                placeholder="Value"
                type="text"
                required={true}
                value={item.value}
                onChange={handleChange(index, 'value')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} className="mt-6">
            <IconButton onClick={(): void => handleRemove(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4"
        onClick={addNewMetadata}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </Box>
  );
};

export default DeviceMetadataFilter;
