import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: {
      fontSize: '14px',
    },
    integrationSubtitle: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
    },
  }),
);

export default useStyles;
