import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    configTitle: {
      color: theme.palette.grey[100],
    },
  }),
);

export default useStyles;
