import React from 'react';
import { Button, Grid, IconButton, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextInput from '../../../components/TextInput';
import DeleteIcon from '@mui/icons-material/Delete';

import SelectInput from '../../../components/SelectInput';
import { commandOptions } from '../../../app/constants';

interface OptionsFormProps {
  objectRowsTypes?: { [key: string]: unknown }[] | undefined;
  prop: string;
  disabled?: boolean;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow?: (prop: string) => void;
  onRemoveRow?: (prop: string, item: string) => void;
}

const OptionsForm: React.FC<OptionsFormProps> = ({
  objectRowsTypes,
  onInputChange,
  onAddRow,
  onRemoveRow,
  prop,
  disabled,
}) => {
  const handleDynamicChange =
    (changedProp: string, index?: string) =>
    (field: string, value: string | number): void => {
      onInputChange(changedProp, value, field, index as string);
    };

  const handleClickAddRow = (addRowProp: string) => (): void => {
    if (onAddRow) {
      onAddRow(addRowProp);
    }
  };

  const handleClickRemoveRow =
    (removeRowProp: string, item: string) => (): void => {
      if (onRemoveRow) {
        onRemoveRow(removeRowProp, item);
      }
    };

  return (
    <Grid item xs={12} className="mt-6">
      {objectRowsTypes &&
        objectRowsTypes.map((item, index) => (
          <Grid
            container
            direction="row"
            className="mt-2"
            spacing={2}
            key={`option-row-grid-${index}`}
          >
            <Grid item xs={4}>
              <TextInput
                prop="key"
                placeholder=""
                value={item.key as string}
                onInputChange={handleDynamicChange(prop, String(index))}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                prop="default"
                placeholder=""
                type={item.type === 'integer' ? 'number' : 'text'}
                value={
                  item.type === 'integer'
                    ? (item.default as number)
                    : (item.default as string)
                }
                onInputChange={handleDynamicChange(prop, String(index))}
              />
            </Grid>

            <Grid item xs={3}>
              <SelectInput
                prop="type"
                value={item.type as string}
                onSelectChange={handleDynamicChange(prop, String(index))}
                options={Object.keys(commandOptions).map((key, subIndex) => (
                  <MenuItem
                    className="m-4 p-2"
                    key={subIndex}
                    dense
                    value={key}
                  >
                    {commandOptions[key]}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={handleClickRemoveRow(prop, index.toString())}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4"
        onClick={handleClickAddRow(prop)}
        startIcon={<AddIcon />}
        disabled={disabled}
      >
        Add New Options
      </Button>
    </Grid>
  );
};

export default OptionsForm;
