import React, { useEffect, useState } from 'react';
import {
  Command,
  CommandInput,
  Integration,
  Integrations,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { Grid, MenuItem, Typography } from '@mui/material';
import TextInput from '../../../components/TextInput';
import DynamicRows from '../../../components/DynamicRows';
import SelectInput from '../../../components/SelectInput';
import { errorHighlight } from '../../../app/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { RootState } from '../../../redux/store';

interface SenderCommandFormProps {
  newCommand: Command | CommandInput;
  attributes?: { [key: string]: string } | undefined;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow?: (prop: string) => void;
  onRemoveRow?: (prop: string, item: string) => void;
}

const SenderCommandForm: React.FC<SenderCommandFormProps> = ({
  newCommand,
  attributes,
  onInputChange,
  onAddRow,
  onRemoveRow,
}) => {
  const filters = useAppSelector((state: RootState) => state.filters);
  const [integrationList, setIntegrations] = useState<Integration[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getIntegrations(1);
  }, [filters.integrations]);

  const getIntegrations = (pageNumber: number): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 1000,
      page: pageNumber,
    };

    Integrations.list(
      {
        type: { operator: 'like', value: 'gcp' },
      },
      pagination,
    )
      .then((result) => {
        setIntegrations(result.integrations);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const handleDynamicChange =
    (prop: string, index?: string) =>
    (field: string, value: string | number): void => {
      onInputChange(prop, value, field, index as string);
    };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  return (
    <Grid container direction="row" className="mt-2" spacing={2}>
      <Grid item xs={6}>
        <TextInput
          prop="gcp_topic"
          label="GCP Topic"
          placeholder=""
          type="text"
          value={newCommand?.sender?.gcp_topic as string}
          onInputChange={handleDynamicChange('gcp_topic')}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          label="Integration"
          prop="integration_id"
          value={newCommand?.sender?.integration_id as string}
          onSelectChange={handleDynamicChange('integration_id')}
          options={integrationList.map((integration) => (
            <MenuItem
              className="m-4 p-2"
              key={integration._id}
              dense
              value={integration._id}
            >
              {integration.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Attributes</Typography>
        <DynamicRows
          prop="attributes"
          objectRowsTypes={
            (attributes as { [key: string]: unknown }) || undefined
          }
          itemKey="name"
          itemValue="value"
          buttonLabel="Attributes"
          useOnChangeHandlerObjectIndex={true}
          dropDownDefaultValue="choose_type"
          onInputChange={onInputChange}
          onAddRow={onAddRow}
          onRemoveRow={onRemoveRow}
        />
      </Grid>
    </Grid>
  );
};

export default SenderCommandForm;
