import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';
import PasswordInput from '../../../components/PasswordInput';

interface GsIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  hideEmptySensitiveFields?: boolean;
}

const GsIntegrationSection: React.FC<GsIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
  hideEmptySensitiveFields,
}) => {
  return (
    <>
      {(!hideEmptySensitiveFields || newIntegration.gs_username) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="GlobalStar Username"
            prop="gs_username"
            value={newIntegration.gs_username}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
      {(!hideEmptySensitiveFields || newIntegration.gs_password) && (
        <Grid item xs={6} className="mt-6">
          <PasswordInput
            label="GlobalStar Password"
            prop="gs_password"
            value={newIntegration.gs_password}
            onInputChange={onInputChange}
            hasPasswordStrengthBar={false}
          />
        </Grid>
      )}
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="GlobalStar Customer Id"
          prop="gs_customer_id"
          value={newIntegration.gs_customer_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="GlobalStar Rate Plan Id"
          prop="gs_rate_plan_id"
          value={newIntegration.gs_rate_plan_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="GlobalStar Address Id"
          prop="gs_address_id"
          value={newIntegration.gs_address_id}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default GsIntegrationSection;
