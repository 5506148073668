import { FilterObject } from '../models/common';
import { FiltersState } from '../redux/reducers/filters.reducer';

const updatedAtFilter: FilterObject = {
  sortBy: '-updated_at',
  view: 'grid',
};

const createdAtFilter: FilterObject = {
  sortBy: '-created_at',
  view: 'grid',
};

const listFilter: FilterObject = {
  sortBy: '-created_at',
  view: 'list',
};

const nameFilter: FilterObject = {
  sortBy: 'name',
  view: 'grid',
};

export const defaultFilters: FiltersState = {
  actions: listFilter,
  alerts: listFilter,
  bulkJobs: listFilter,
  case_sensitive: false,
  commands: updatedAtFilter,
  commandsExecutions: listFilter,
  companies: updatedAtFilter,
  configs: updatedAtFilter,
  configurations: updatedAtFilter,
  deviceErrors: listFilter,
  devices: updatedAtFilter,
  devicesTypes: updatedAtFilter,
  deviceTransfers: updatedAtFilter,
  discoveredDevices: updatedAtFilter,
  escrowDevices: updatedAtFilter,
  files: updatedAtFilter,
  gatewayCommands: updatedAtFilter,
  heartbeats: listFilter,
  hierarchyFilters: {
    ancestorAccountIndexes: {},
    levels: [],
    searchAccount: undefined,
    selectedAccountsIndexes: {},
  },
  ingestors: updatedAtFilter,
  integrationEvents: listFilter,
  integrations: updatedAtFilter,
  messageReports: listFilter,
  policies: updatedAtFilter,
  pollableAttributes: updatedAtFilter,
  scheduledJobs: updatedAtFilter,
  secrets: createdAtFilter,
  settingsApplicationRecord: listFilter,
  softwareUpdates: updatedAtFilter,
  translators: updatedAtFilter,
  users: updatedAtFilter,
  userTypes: updatedAtFilter,
  workflows: nameFilter,
};
