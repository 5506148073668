import React, { useState } from 'react';
import {
  AutocompleteRenderInputParams,
  Button,
  Chip,
  FilterOptionsState,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  CancelOutlined as CancelIcon,
} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import useStyles from './styles';
import clsx from 'clsx';

const filter = createFilterOptions<SharedDataItem>();

export interface SharedDataItem {
  _id: string;
  name: string;
}

export interface SharedDataProps {
  sharedDataKey: string;
  buttonLabel?: string;
  currentSharedList: SharedDataItem[];
  items: SharedDataItem[];
  itemType: string;
  placeholder?: string;
  onChangeSharedData: (prop: string, sharedDataList: string[]) => void;
}

type InputValue = string | SharedDataItem | null;

const SharedData: React.FC<SharedDataProps> = ({
  sharedDataKey,
  buttonLabel,
  currentSharedList,
  items,
  itemType,
  placeholder,
  onChangeSharedData,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<SharedDataItem | null>(null);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: InputValue,
  ): void => {
    const sharedDataValue = newValue as Exclude<InputValue, string>;
    setValue(sharedDataValue);
  };

  const filterOptions = (
    options: SharedDataItem[],
    params: FilterOptionsState<SharedDataItem>,
  ): SharedDataItem[] => {
    const filtered = filter(options, params);
    return filtered;
  };

  const renderInput = (
    params: AutocompleteRenderInputParams,
  ): React.ReactNode => <TextField {...params} placeholder={placeholder} />;

  const renderOption = (
    props: React.HtmlHTMLAttributes<HTMLElement>,
    option: SharedDataItem,
  ): React.ReactNode => <li {...props}>{option.name}</li>;

  const getOptionLabel = (option: SharedDataItem): string => option.name;

  const setFilteredItems = (): SharedDataItem[] => {
    const filteredItems: SharedDataItem[] = items.filter((item) => {
      for (const currentItem of currentSharedList) {
        if (currentItem._id === item._id) {
          return false;
        }
      }
      return true;
    });
    return filteredItems;
  };

  const addItem = (): void => {
    if (value) {
      currentSharedList = [...currentSharedList, value];
      const newItems: string[] = currentSharedList.map((item) => item._id);
      onChangeSharedData(itemType, newItems);
      setFilteredItems();
      setValue(null);
    }
  };

  const removeItem = (itemToDelete: SharedDataItem) => (): void => {
    const newItems = currentSharedList
      .filter((item) => item._id !== itemToDelete._id)
      .map((item) => item._id);
    onChangeSharedData(itemType, newItems);
    setFilteredItems();
  };

  return (
    <div>
      <div className={clsx('mb-6 mt-4', classes.inputContainer)}>
        <Autocomplete
          data-cy={`${sharedDataKey}-input`}
          id={`${sharedDataKey}-input`}
          value={value}
          onChange={handleChange}
          filterOptions={filterOptions}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={setFilteredItems()}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          sx={{ width: 400 }}
          className="mr-2"
          freeSolo
          renderInput={renderInput}
        />
        <Button
          data-cy={`${sharedDataKey}-add-button`}
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          onClick={addItem}
          sx={{ width: 200 }}
        >
          <Typography variant="button">{buttonLabel}</Typography>
        </Button>
      </div>
      <div>
        {currentSharedList.map((item) => (
          <Chip
            data-cy={`${sharedDataKey}-${item._id}-value`}
            key={item._id}
            className={clsx('br-5 mr-4 mb-8', classes.chip)}
            label={
              <Typography variant="caption" component="span" color="white">
                {item.name}
              </Typography>
            }
            onDelete={removeItem(item)}
            deleteIcon={
              <CancelIcon
                data-cy={`${sharedDataKey}-${item._id}-delete`}
                className={classes.chipIcon}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export default SharedData;
