import Header from '../../components/Header';
import { HeaderProps } from '../../components/Header/Header';
import React, { ReactElement } from 'react';
import ActionButton from '../ActionButton';
import { ActionButtonContainerProps } from '../ActionButton/ActionButton';

interface Props
  extends HeaderProps,
    Omit<ActionButtonContainerProps, 'action'> {
  extraActions?: ReactElement;
}

const HeaderWithActionButton: React.FC<Props> = (props) => {
  const {
    extraActions,
    model,
    actionLabel: label,
    link,
    actionIcon,
    actionCallback,
    ...headerProps
  } = props;

  return (
    <Header {...headerProps}>
      {extraActions}
      <ActionButton
        model={model}
        actionLabel={label}
        link={link}
        actionIcon={actionIcon}
        actionCallback={actionCallback}
      />
    </Header>
  );
};

export default HeaderWithActionButton;
