// import { NestedObjectItem } from './NestedObject';

import { NestedObjectItem } from './NestedObject';

/**
 * Gets useful indexes to access data or set it for the MIB and notifications nested items structure
 * @param {string} index is a string as it can be one digit or multiple with dots between them
 * @return {{
 *  itemIndex: string,
 *  itemRealIndex: number,
 *  itemsArrayKey: string,
 *  itemsArrayIndex: string,
 *  parentItemsArrayIndex: string,
 * }} Object with useful indexes
 *
 */
export const getNestedIndexes = (
  index: string,
): {
  itemIndex: string;
  itemRealIndex: number;
  itemsArrayIndex: string;
  itemsArrayKey: string;
  parentItemsArrayIndex: string;
} => {
  // First check if it is a single or layered index
  const indexLevels = index?.split('.');

  // itemsArrayKey => This value will be the key of the items in the level of the index
  // This key was changed from just "items" as it was creating problems with the _set lodash function
  // so no two "items" keys in "mib" will be found
  // Example: if index is 0.1 => itemsArrayKey will be "items_0_1"
  let itemsArrayKey = 'items_';

  // itemsArrayIndex => This value will be the access string to the items key depending on the index
  // Example: if index is 0.1 => itemsArrayIndex should access the items array of the object
  // on index 1 of the object on index 0 from the root => 0.items_0.1.items_0_1
  let itemsArrayIndex = '';

  // itemIndex => This value will be the access string to the object key index
  // Example: if index is 0.1 => itemIndex should access the object
  // on index 1 of the object on index 0 from the root => 0.items_0.1
  let itemIndex = '';

  // parentItemsArrayIndex => This value will be the key of the array that contains the object of the index
  // Example: if index is 0.1 => parentItemsArrayIndex should access the items array
  // of the object on index 0 from the root => 0.items_0
  let parentItemsArrayIndex = '';

  if (indexLevels?.length !== 1) {
    let accLevel = '';
    indexLevels.forEach((level, i) => {
      parentItemsArrayIndex = itemIndex;
      if (i !== 0) {
        accLevel += '_';
        itemIndex += '.';
      }
      accLevel += `${level}`;
      if (i === indexLevels.length - 1) {
        itemIndex += `${level}`;
        itemsArrayIndex = `${itemIndex}.items_${accLevel}`;
      } else {
        itemIndex += `${level}.items_${accLevel}`;
      }
    });
    itemsArrayKey += indexLevels.join('_');
  } else {
    itemIndex = index;
    parentItemsArrayIndex = index;
    itemsArrayKey += index;
    itemsArrayIndex = `${index}.${itemsArrayKey}`;
  }

  return {
    itemIndex,
    itemRealIndex: parseInt(indexLevels[indexLevels.length - 1]),
    itemsArrayIndex,
    itemsArrayKey,
    parentItemsArrayIndex,
  };
};

/**
 * After removing an object the array to which it belonged should be reordered.
 * And so the indexing and the names of the keys of the items arrays.
 * @param {NestedObjectItem[]} itemsArray
 * @param {String} index
 * @param {String} oldIndex
 */
// export const reIndexItemsArray = (
//   itemsArray: NestedObjectItem[],
//   index: string,
//   oldIndex: string,
// ): void => {
//   itemsArray.forEach((childObject, i) => {
//     let subItemsIndex = '';
//     let subItemsOldIndex = '';
//     let subItemsArrayKey = '';
//     if (typeof index === 'undefined') {
//       subItemsIndex = i.toString();
//       if (childObject[`items_${i}`]) {
//         subItemsArrayKey = `items_${i}`;
//       } else {
//         subItemsOldIndex = (i + 1).toString();
//         subItemsArrayKey = `items_${i + 1}`;
//       }
//     } else {
//       subItemsIndex = `${index}_${i}`;
//       if (childObject[`items_${subItemsIndex}`]) {
//         subItemsArrayKey = `items_${subItemsIndex}`;
//       } else if (childObject[`items_${index}_${i + 1}`]) {
//         subItemsOldIndex = `${index}_${i + 1}`;
//         subItemsArrayKey = `items_${subItemsOldIndex}`;
//       } else if (oldIndex && childObject[`items_${oldIndex}_${i}`]) {
//         subItemsOldIndex = `${oldIndex}_${i}`;
//         subItemsArrayKey = `items_${subItemsOldIndex}`;
//       } else if (oldIndex && childObject[`items_${oldIndex}_${i + 1}`]) {
//         subItemsOldIndex = `${oldIndex}_${i + 1}`;
//         subItemsArrayKey = `items_${subItemsOldIndex}`;
//       }
//     }
//     if ((childObject[subItemsArrayKey] as NestedObjectItem[]).length !== 0) {
//       reIndexItemsArray(
//         childObject[subItemsArrayKey] as NestedObjectItem[],
//         subItemsIndex,
//         subItemsOldIndex,
//       );
//     }

//     if (subItemsOldIndex) {
//       childObject[`items_${subItemsIndex}`] = childObject[subItemsArrayKey];
//       delete childObject[subItemsArrayKey];
//     }
//   });
// };

/**
 * Converts an array with items with the similar key "items" to the "items_<index>_<subIndex>_..." structure
 * So it is possible to use the _set and _get functions of lodash with these arrays
 * @param {NestedObjectItem[]} itemsArray
 * @param {String} index
 */
// export const convertItemsIndexesForUi = (
//   itemsArray: NestedObjectItem[],
//   index: string,
// ): void => {
//   itemsArray.forEach((childObject, i) => {
//     let subItemsArrayKey = '';
//     let newIndex = '';
//     if (typeof index === 'undefined') {
//       newIndex = i.toString();
//     } else {
//       newIndex = `${index}_${i}`;
//     }
//     subItemsArrayKey = `items_${newIndex}`;
//     if (childObject.items) {
//       convertItemsIndexesForUi(
//         childObject.items as NestedObjectItem[],
//         newIndex,
//       );
//       childObject[subItemsArrayKey] = childObject.items;
//       // delete childObject.items;
//     } else if (!childObject[subItemsArrayKey]) {
//       childObject[subItemsArrayKey] = [];
//     }
//   });
// };

/**
 * Converts an array with items with the "items_<index>_<subIndex>_..." structure to the similar key "items"
 * So it is possible to send the data as expected to the API
 * @param {NestedObjectItem[]} itemsArray
 * @param {String} index
 */
// export const convertItemsIndexesForSave = (
//   itemsArray: NestedObjectItem[],
//   index: string,
// ): void => {
//   itemsArray.forEach((childObject, i) => {
//     let subItemsArrayKey = null;
//     let newIndex = '';
//     if (typeof index === 'undefined') {
//       newIndex = i.toString();
//     } else {
//       newIndex = `${index}_${i}`;
//     }
//     subItemsArrayKey = `items_${newIndex}`;
//     if (
//       childObject[subItemsArrayKey] &&
//       (childObject[subItemsArrayKey] as NestedObjectItem[]).length !== 0
//     ) {
//       convertItemsIndexesForSave(
//         childObject[subItemsArrayKey] as NestedObjectItem[],
//         newIndex,
//       );
//       // childObject.items = childObject[subItemsArrayKey];
//     }
//     delete childObject[subItemsArrayKey];
//   });
// };

/**
 * If all children are enabled then the "Disable all children" checkbox will appear the the checkbox itself will be checked
 * indicating that all children are enabled. If only one is disabled then it is not enabled
 * @param {NestedObjectItem[]} items
 * @param {String} index
 */
// export const getChildrenitemsState = (
//   items: NestedObjectItem[],
//   index: string,
// ): boolean => {
//   let enabled = true;
//   for (let i = 0; i < items.length; i++) {
//     const subObject = items[i];
//     const newIndex = `${index}.${i}`;
//     const { itemsArrayKey } = getNestedIndexes(newIndex);
//     if (
//       !subObject.enabled ||
//       !getChildrenitemsState(
//         subObject[itemsArrayKey] as NestedObjectItem[],
//         newIndex,
//       )
//     ) {
//       enabled = false;
//       break;
//     }
//   }
//   return enabled;
// };

/**
 * If only one child child is enabled then the "Enable all children" checkbox will appear the the checkbox itself will be half checked
 * indicating that not all children are enabled but at least one is. If only one is enabled then it is half-enabled
 * Separated from the other function to simplify the logic and the code.
 * @param {NestedObjectItem[]} items
 * @param {String} index
 */
// export const getChildrenitemsHalfState = (
//   items: NestedObjectItem[],
//   index: string,
// ): boolean => {
//   let enabled = false;
//   for (let i = 0; i < items.length; i++) {
//     const subObject = items[i];
//     const newIndex = `${index}.${i}`;
//     const { itemsArrayKey } = getNestedIndexes(newIndex);
//     if (
//       subObject.enabled ||
//       getChildrenitemsHalfState(
//         subObject[itemsArrayKey] as NestedObjectItem[],
//         newIndex,
//       )
//     ) {
//       enabled = true;
//       break;
//     }
//   }
//   return enabled;
// };

/**
 * Change the "enabled" value the opposite of the "Disable/Enable all children" value. If not all are enabled it will enable all of them.
 * If all are enabled it will disable all of them.
 * @param {NestedObjectItem[]} items
 * @param {String} index
 * @param {String} previousState
 */
// export const toggleChildrenitemsState = (
//   items: NestedObjectItem[],
//   index: string,
//   previousState: string,
// ): void => {
//   for (let i = 0; i < items.length; i++) {
//     items[i].enabled = !previousState;
//     const newIndex = `${index}.${i}`;
//     const { itemsArrayKey } = getNestedIndexes(newIndex);
//     toggleChildrenitemsState(
//       items[i][itemsArrayKey] as NestedObjectItem[],
//       newIndex,
//       previousState,
//     );
//   }
// };

export const getNestedItemKeyPath = (index: string): string => {
  const pathLevels = index.split('.');
  return pathLevels.reduce((preVal, current, i): string => {
    return i === 0 ? `${current}` : `${preVal}.items.${current}`;
  }, '');
};

export const getItemInputPath = (index: string): string => {
  const pathLevels = index.split('.');
  return pathLevels.reduce((preVal, current, i): string => {
    return i === 0
      ? `${current}`
      : i === pathLevels.length - 1
      ? `${preVal}.data.${current}`
      : `${preVal}.items.${current}`;
  }, '');
};

export const reIndexNestedItems = (
  items: NestedObjectItem[],
  index?: string,
): NestedObjectItem[] => {
  if (!items || items.length === 0) {
    return [];
  }
  return items.map((item, subIndex) => {
    const indexToUse = index ? `${index}.${subIndex}` : `${subIndex}`;
    return {
      ...item,
      index: indexToUse,
      items: reIndexNestedItems(item.items, indexToUse),
    };
  });
};

export const getNestedItemParentPath = (index: string): string => {
  const pathLevels = index.split('.');
  if (pathLevels.length < 1) {
    return index;
  }

  return pathLevels.reduce((preVal, current, i): string => {
    return i === 0
      ? `${current}`
      : i === pathLevels.length - 1
      ? `${preVal}.items`
      : `${preVal}.items.${current}`;
  }, '');
};

export const getNestedItemsEnabledState = (item: NestedObjectItem): boolean => {
  let enabled = true;
  for (let i = 0; i < item.items.length; i++) {
    const nestedItem = item.items[i];
    if (
      !nestedItem.data.enabled ||
      (nestedItem.items.length !== 0 && !getNestedItemsEnabledState(nestedItem))
    ) {
      enabled = false;
      break;
    }
  }
  return enabled;
};

export const getNestedItemsHalfEnabledState = (
  item: NestedObjectItem,
): boolean => {
  let enabled = false;
  for (let i = 0; i < item.items.length; i++) {
    const nestedItem = item.items[i];
    if (
      nestedItem.data.enabled ||
      (nestedItem.items.length !== 0 &&
        getNestedItemsHalfEnabledState(nestedItem))
    ) {
      enabled = true;
      break;
    }
  }
  return enabled;
};

export const toggleNestedItemsState = (
  item: NestedObjectItem,
  priorState: boolean,
): NestedObjectItem => {
  const newItemState = item;
  for (let i = 0; i < item.items.length; i++) {
    item.items[i].data.enabled = !priorState;
    if (item.items[i].items.length !== 0) {
      item.items[i] = toggleNestedItemsState(item.items[i], priorState);
    }
  }
  return newItemState;
};
