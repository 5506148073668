import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Devices } from '@edgeiq/edgeiq-api-js';
import { Log } from '@edgeiq/edgeiq-api-js/dist/log/models';
import UploadIcon from '@mui/icons-material/Upload';
import { DateTime } from 'luxon';

import TabsPage from '../../../components/TabsPage';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';

import useStyles from '../styles';

type LogsRes = {
  count: number;
  files: Array<Log>;
};

const DeviceLogs: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [logsList, setLogsList] = useState<Array<Log>>();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      flex: 0.3,
      headerName: 'Date created',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <>
          {DateTime.fromISO(params.row.created_at).toFormat('TT')}
          <br />
          {DateTime.fromISO(params.row.created_at).toLocaleString(
            DateTime.DATE_SHORT,
          )}
        </>
      ),
    },
    {
      field: 'name',
      flex: 0.3,
      headerName: 'Software Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <a
          href={params.row.url}
          target={'_blank'}
          className={classes.linkButton}
        >
          <Typography variant="button">Download Log</Typography>
        </a>
      ),
    },
    {
      field: 'size',
      flex: 0.3,
      headerName: 'Size',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.size && (params.row.size / 1024).toFixed(2)} kB</span>
      ),
    },
  ];

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);
      Devices.getLogs(editableDevice._id)
        .then((res) => {
          const files = (res as unknown as LogsRes).files;
          setLogsList(files);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  const handleRequestLogs = (): void => {
    if (editableDevice) {
      Devices.requestLogs(editableDevice._id)
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Command sent to device',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={logsList}
        addButtonLabel="Request Logs"
        onAddClick={handleRequestLogs}
        tableTitle="Logs"
        loading={loading}
        addButtonIconToShow={<UploadIcon />}
      />
    </Box>
  );
};

export default DeviceLogs;
