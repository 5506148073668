import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '400px',
    },
    errorButton: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    publishContainer: {
      height: '100%',
    },
    textArea: {
      height: '300px',
    },
    title: {
      fontWeight: 700,
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
