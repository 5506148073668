import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      color: theme.palette.grey[100],
      marginBottom: '48px',
      paddingLeft: 0,
    },
    background: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.grey.A100,
      },
    },
    backgroundImage: {
      maxWidth: '200px',
    },
    backgroundMobile: {
      minHeight: '10vh',
    },
    backIcon: {
      color: '#000000',
      left: '8px',
      position: 'absolute',
      top: '20px',
    },
    body: {
      minHeight: '100vh',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '450px',
    },
    contentContainerMobile: {
      margin: '0 20px',
      minHeight: '60vh',
    },
    contentMobile: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(15),
        paddingRight: theme.spacing(15),
      },
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(5),
    },
    desktopHidden: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    mobileHidden: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      display: 'flex',
      width: '100%',
    },
  }),
);

export default useStyles;
