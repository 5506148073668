import { Company, User, DeviceConfig, DeviceType } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';

export const ConfigsColumns = (
  deviceTypes?: DeviceType[],
  userCompanies?: Company[],
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const config = item as DeviceConfig;
        return config.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const config = item;
        return `/config/${config._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const user = item as User;
        const company = userCompanies?.find(
          (userCompany) => userCompany._id === user.company_id,
        );
        return company?.name || '';
      },
      id: 'company',
      label: 'Company',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const deviceTypeItem = item as DeviceConfig;
        const deviceType = deviceTypes?.find((devType) => {
          return devType._id === deviceTypeItem.device_type_id;
        });
        return deviceType?.name ?? '';
      },
      id: 'type',
      label: 'Device Type',
      type: 'text',
    },
  ];
};
