import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { Company, Workflow } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import ColoredBox from '../../components/ColoredBox';
import useStyles from '../../components/Card/styles';
import {
  workflowStatusLabelMap,
  workflowStatusThemeMap,
} from '../../app/constants';

interface WorkflowCardProps {
  workflow: Workflow;
  companies: Company[];
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({ workflow, companies }) => {
  const cardClasses = useStyles();
  const company = companies?.find(
    (userCompany) => userCompany._id === workflow.company_id,
  );

  return (
    <Box>
      <Tooltip placement="top" title={workflow.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', cardClasses.title)}
        >
          {workflow.name}
        </Typography>
      </Tooltip>
      {company ? (
        <Tooltip placement="top" title="Account">
          <Typography
            variant="overline"
            component="div"
            className={cardClasses.tag}
          >
            {company.name}
          </Typography>
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Account ID">
          <Typography
            variant="overline"
            component="div"
            className={cardClasses.tag}
          >
            {workflow.company_id}
          </Typography>
        </Tooltip>
      )}
      {workflow.current_status && (
        <div className={cardClasses.statusContainer}>
          <ColoredBox
            type="current_status"
            value={workflowStatusLabelMap[workflow.current_status] ?? 'Active'}
            colorTheme={
              workflowStatusThemeMap[workflow.current_status] ?? 'info'
            }
          />
        </div>
      )}
    </Box>
  );
};

export default WorkflowCard;
