import React from 'react';
import { Box, Grid, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TextInput from '../../../components/TextInput';

interface MetadataListProps {
  [key: string]: string | unknown;
}

interface EscrowDeviceMetadataProps {
  metadata: MetadataListProps[];
  setMetadataList: React.Dispatch<React.SetStateAction<MetadataListProps[]>>;
}

const EscrowDeviceMetadata: React.FC<EscrowDeviceMetadataProps> = ({
  metadata,
  setMetadataList,
}) => {
  const handleChange = (
    index: number,
    key: string,
    value: string | number,
  ): void => {
    const auxArray = [...metadata];
    auxArray[index][key] = value;

    setMetadataList(auxArray);
  };

  const addNewMetadata = (): void => {
    setMetadataList([...metadata, { key: '', value: '' }]);
  };

  const handleRemove = (index: number): void => {
    const auxArray = [...metadata];
    auxArray.splice(index, 1);

    setMetadataList(auxArray);
  };

  const handleInputChange =
    (index: number, prop: string) =>
    (_name: string, value: string | number): void => {
      handleChange(index, prop, value);
    };

  return (
    <div>
      <Box>
        {metadata.map((item, index) => (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className="mt-2"
            alignItems="center"
          >
            <Grid item xs={5}>
              <TextInput
                label="Key Pair"
                prop={'name'}
                required={true}
                value={item.key as string}
                onInputChange={handleInputChange(index, 'key')}
              ></TextInput>
            </Grid>
            <Grid item xs={5}>
              <TextInput
                label="Value"
                prop={'location'}
                required={true}
                value={item.value as string}
                onInputChange={handleInputChange(index, 'value')}
              ></TextInput>
            </Grid>
            <Grid item xs={2} className="mt-6">
              <IconButton onClick={(): void => handleRemove(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          size="medium"
          className="mt-4"
          onClick={addNewMetadata}
          startIcon={<AddIcon />}
        >
          Add new
        </Button>
      </Box>
    </div>
  );
};

export default EscrowDeviceMetadata;
