import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Command, Commands, CommandsFilters } from '@edgeiq/edgeiq-api-js';

import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setStateCommands } from '../../../redux/reducers/commands.reducer';

import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';

import AttachCommandCard from './AttachCommandCard';
import { AdaptedCommand } from '../../../models/command';

interface AttachCommandsDrawerProps {
  singleSelect?: boolean;
  open: boolean;
  itemCommands: boolean;
  preSelectedCommands?: Command[];
  handleCloseDrawer: () => void;
  handleChooseCommands: (commands: Command[]) => void;
}

const AttachCommandsDrawer: React.FC<AttachCommandsDrawerProps> = ({
  singleSelect,
  open,
  itemCommands,
  preSelectedCommands,
  handleCloseDrawer,
  handleChooseCommands,
}) => {
  const dispatch = useAppDispatch();
  const stateCommands = useAppSelector((state: RootState) => state.commands);

  const [commands, setCommands] = useState<Command[]>(stateCommands.commands);
  const [selectedCommands, setSelectedCommands] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const preCommandAttachList = useRef<string>('');
  const [disableAction, setDisableAction] = useState(false);

  const getCommands = (searchName?: string): void => {
    setLoading(true);
    const filters: CommandsFilters = {};
    if (searchName) {
      filters.name = {
        operator: 'like',
        value: searchName,
      };
    }

    Commands.list(filters, optionsPaginationsFilter)
      .then((result) => {
        setCommands(result.commands);
        dispatch(setStateCommands(result.commands));
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (commands.length === 0) {
      getCommands();
    }
  }, []);

  useEffect(() => {
    if (itemCommands && stateCommands.selectedCommands.length !== 0) {
      setSelectedCommands(
        stateCommands.selectedCommands.map((command) => command._id),
      );
    } else if (preSelectedCommands && !!preSelectedCommands?.length) {
      setSelectedCommands(preSelectedCommands.map((command) => command._id));
    } else {
      setSelectedCommands([]);
    }
  }, [stateCommands.selectedCommands, preSelectedCommands, open]);

  useEffect(() => {
    preCommandAttachList.current =
      selectedCommands.length > 0 ? String(selectedCommands.length) : '';
    setDisableAction(
      (!preCommandAttachList.current && !selectedCommands.length) ||
        !commands.length,
    );
  }, [selectedCommands]);

  const handleOnChangeCallback = (value: string): void => {
    getCommands(value);
  };

  const attachedInDbButNotLocally = (): AdaptedCommand[] => {
    const result = stateCommands.selectedCommands.filter(
      ({ _id }) => !selectedCommands.some((x) => x === _id),
    );
    return result;
  };

  const attachedInDbAndLocally = (): AdaptedCommand[] => {
    const result = stateCommands.selectedCommands.filter(({ _id }) =>
      selectedCommands.some((x) => x === _id),
    );
    return result;
  };

  const handleActionLabel = (): string => {
    if (
      attachedInDbButNotLocally().length < 1 &&
      attachedInDbAndLocally().length >= selectedCommands.length &&
      stateCommands.selectedCommands.length >= 1
    ) {
      return 'Detach all';
    }
    return 'Attach';
  };

  const checkCommandCallback =
    (commandId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (!singleSelect) {
        if (checked) {
          setSelectedCommands([...selectedCommands, commandId]);
        } else {
          setSelectedCommands(
            selectedCommands.filter((item) => item !== commandId),
          );
        }
      } else {
        setSelectedCommands([commandId]);
      }
    };

  const handleCommandsCallback = (): void => {
    if (
      attachedInDbButNotLocally().length < 1 &&
      attachedInDbAndLocally().length >= selectedCommands.length
    ) {
      setSelectedCommands([]);
      handleChooseCommands([]);
    } else {
      handleChooseCommands(
        commands.filter((command) => selectedCommands.includes(command._id)),
      );
    }
  };

  const handleSelectAll = (): void => {
    if (selectedCommands.length !== commands.length) {
      setSelectedCommands(commands.map((command) => command._id));
    } else {
      setSelectedCommands([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel={handleActionLabel()}
      title="Select Commands"
      disableAction={disableAction}
      actionCallback={handleCommandsCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          allSelected={selectedCommands.length === commands.length}
          itemsSelected={selectedCommands.length !== 0}
          hasItems={commands.length !== 0}
          hideSelectAll={singleSelect}
          searchPlaceholder="Search Commands"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={commands.map((command) => (
                  <AttachItemCard
                    content={
                      <AttachCommandCard key={command._id} command={command} />
                    }
                    checked={selectedCommands.includes(command._id)}
                    checkboxCallback={checkCommandCallback}
                    id={command._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default AttachCommandsDrawer;
