import { Report } from '@edgeiq/edgeiq-api-js';
import { Typography } from '@mui/material';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import timeHelpers from '../../../helpers/timeHelpers';

export const ReportsColumns = (
  handleActionClick: (item: Report) => void,
): TableColumn[] => {
  return [
    {
      id: 'created_at',
      label: 'Date/Time',
      parser: (item: TableItemType): string | React.ReactElement => {
        const report = item as Report;
        return <>{timeHelpers.getDate(report.device_datetime, false, true)}</>;
      },
      type: 'custom',
    },
    {
      cellValue: (item: TableItemType): string => {
        const secret = item as Report;
        return secret.device_name ?? '--';
      },
      id: 'device_name',
      label: 'Device',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const secret = item as Report;
        return secret.raw_data ?? '--';
      },
      id: 'data',
      label: 'Data',
      type: 'text',
    },
    {
      id: 'action',
      label: 'Action',
      parser: (item: TableItemType): string | React.ReactElement => {
        const commandExecution = item as Report;
        return (
          <Typography
            variant="button"
            style={{ cursor: 'pointer' }}
            onClick={(): void => handleActionClick(commandExecution)}
          >
            View Details
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};
