import { useEffect, useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';
import { deviceTransferStatus } from '../../app/constants';
import useStyles from './styles';

interface DeviceTransferFiltersProps {
  accountPlaceHolder?: string;
  total?: number;
}

const DeviceTransferFilters: React.FC<DeviceTransferFiltersProps> = ({
  accountPlaceHolder,
  total,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.deviceTransfers.filters;

  const [statusSelected, setStatusSelected] = useState<string[]>([]);
  const deviceStatus = Object.keys(deviceTransferStatus);

  const allStatusSelected = statusSelected.length === deviceStatus.length;
  const isIndeterminate = statusSelected.length !== 0 && !allStatusSelected;

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'deviceTransfers',
      ),
    );
  };

  useEffect(() => {
    setStatusSelected(
      filters?.status && filters.status !== '' ? filters.status.split('|') : [],
    );
  }, [filters]);

  const handleStatusChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event;

    if (value.includes('all')) {
      return;
    }
    changeFilters(
      'status',
      typeof value === 'string'
        ? value.replaceAll(',', '|')
        : value.length > 0
        ? value.join('|')
        : '',
    );
  };

  const selectAllStatus = (): void => {
    if (!allStatusSelected) {
      changeFilters('status', deviceStatus.join('|'));
    } else {
      changeFilters('status', '');
    }
  };

  return (
    <Filters
      total={total}
      hasSearchInput={true}
      inputPlaceholder="Search transfer by account"
      inputKey="to_company_id"
      filterModel="deviceTransfers"
      firstFilter={
        <AccountFilter
          placeHolder={accountPlaceHolder}
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          multiple
          displayEmpty
          className={classes.input}
          value={statusSelected}
          onChange={handleStatusChange}
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | React.ReactElement => {
            if (!selected.length) {
              return <Typography variant="button">Status</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Types selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox
              checked={allStatusSelected}
              indeterminate={isIndeterminate}
              onClick={selectAllStatus}
            />
            <ListItemText primary="All" />
          </MenuItem>
          {deviceStatus.map((status, key) => (
            <MenuItem dense key={key} value={status}>
              <Checkbox checked={statusSelected.indexOf(status) > -1} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default DeviceTransferFilters;
