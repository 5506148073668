import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    settingVersion: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    settingVersionLabel: {
      display: 'inline-block',
    },
  }),
);

export default useStyles;
