import React, { useRef } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { Company } from '@edgeiq/edgeiq-api-js';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import clsx from 'clsx';
import useStyles from './styles';

export interface AccountsExplorerProps {
  totalSelectedAccountsIds: number;
  ancestorId?: string;
  searchAccountAncestors: Company[];
  loading: boolean;
  levels: ExplorerLevel[];
  containerRef: React.Ref<HTMLDivElement>;
  handleCheckboxClick: (
    index: number,
    levelIndex: number,
    explorerItem: string,
  ) => () => void;
  handleChooseAccount: (
    index: number,
    explorerItem: ExplorerItem,
  ) => () => void;
  handleSwitchChange: (
    index: number,
    levelIndex: number,
    accountId: string,
  ) => () => void;
  handleLoadMore: (index: number) => () => void;
}

export interface ExplorerItem {
  item: Company;
  selectBranch: boolean;
  selected: boolean;
}

export interface ExplorerLevel {
  children: ExplorerItem[];
  chosenItem?: ExplorerItem;
  total?: number;
  page: number;
  loading?: boolean;
}

type RefType = Record<number, HTMLDivElement>;

const AccountsExplorer: React.FC<AccountsExplorerProps> = ({
  totalSelectedAccountsIds,
  ancestorId,
  searchAccountAncestors,
  loading,
  levels,
  containerRef,
  handleCheckboxClick,
  handleChooseAccount,
  handleSwitchChange,
  handleLoadMore,
}) => {
  const classes = useStyles();
  const levelsRef = useRef<RefType>({});

  if (loading) {
    return (
      <Grid container className="loading-container">
        <CircularProgress size={75} thickness={5} />
      </Grid>
    );
  }

  return (
    <>
      <div className={clsx('mt-2', classes.container)}>
        <div className="mb-3">
          {searchAccountAncestors.map((ancestor, index) => (
            <Typography
              data-testid={`breadcumb-${ancestor._id}`}
              key={`breadcrumb-${ancestor._id}-${index}`}
              className={classes.breadcrumb}
              variant="overline"
            >
              {`${!index ? '' : ' > '}${ancestor.name}`}
            </Typography>
          ))}
          {levels.map((level, index) =>
            level.chosenItem ? (
              <Typography
                data-testid={`chosen-item-breadcumb-${level.chosenItem.item._id}`}
                key={`breadcrumb-${level.chosenItem.item._id}-${index}`}
                className={classes.breadcrumb}
                variant="overline"
              >
                {`${level.chosenItem.item.name}${
                  index !== levels.length - 1 ? ' > ' : ''
                }`}
              </Typography>
            ) : (
              <div key={`breadcrumb-${index}`}></div>
            ),
          )}
        </div>
        <div
          className={clsx('mt-2 scrollbar w-100', classes.levelsContainer)}
          ref={containerRef}
        >
          {levels.map((level, index) => (
            <div
              key={`account-filter-level-${index}`}
              className={clsx(
                `scrollbar pr-3 ${index !== 0 ? 'ml-3' : ''}`,
                classes.levelContainer,
              )}
              ref={(el): void => {
                levelsRef.current[index] = el as HTMLDivElement;
              }}
            >
              {!level.children.length && level.loading && (
                <Grid container className="loading-container">
                  <CircularProgress size={75} thickness={5} />
                </Grid>
              )}
              {level.children.map((explorerItem, levelIndex) => (
                <div
                  className={clsx('mt-2 br-1', classes.accountContainer, {
                    ['selected']:
                      explorerItem.item._id === level.chosenItem?.item._id,
                  })}
                  key={`account-filter-level-${index}-account-${levelIndex}`}
                >
                  <div className="mr-2">
                    <Checkbox
                      data-prop={`checkbox-${explorerItem.item._id}`}
                      className="p-0"
                      color="primary"
                      disabled={
                        totalSelectedAccountsIds === 20 &&
                        !explorerItem.selected
                      }
                      checked={explorerItem.selected}
                      onChange={handleCheckboxClick(
                        index,
                        levelIndex,
                        explorerItem.item._id,
                      )}
                    />
                  </div>
                  <div className={clsx(classes.accountNameContainer)}>
                    <div
                      data-testid={`child-container-${explorerItem.item._id}`}
                      className={clsx(classes.accountName)}
                      onClick={handleChooseAccount(index, explorerItem)}
                    >
                      <div className="mb-2">
                        <Typography variant="overline" component="div">
                          {explorerItem.item.name}
                        </Typography>
                        <Typography variant="caption" component="div">
                          ID: {explorerItem.item._id}
                        </Typography>
                      </div>
                      <NavigateNextIcon fontSize="small" />
                    </div>
                    <FormControlLabel
                      disableTypography
                      data-prop={`switch-${explorerItem.item._id}`}
                      label="Include descendants"
                      className={clsx('mr-2', classes.switchLabel, {
                        ['showLabel']:
                          explorerItem.selected ||
                          ancestorId === explorerItem.item._id,
                      })}
                      control={
                        <Switch
                          data-testid="item-switch"
                          className="mr-2"
                          color="primary"
                          checked={explorerItem.selectBranch}
                          disabled={
                            ancestorId !== '' &&
                            ancestorId !== explorerItem.item._id
                          }
                          onChange={handleSwitchChange(
                            index,
                            levelIndex,
                            explorerItem.item._id,
                          )}
                        />
                      }
                    />
                  </div>
                </div>
              ))}
              {level.children.length !== 0 &&
                level.total !== level.children.length && (
                  <div className={clsx('my-4', classes.loadMoreContainer)}>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={handleLoadMore(index)}
                    >
                      {!level.loading ? (
                        <Typography variant="button">Load more</Typography>
                      ) : (
                        <CircularProgress size={25} />
                      )}
                    </Button>
                  </div>
                )}
              {!level.loading && !level.children.length && (
                <Typography
                  variant="overline"
                  data-testid="empty-children-list"
                >
                  The account has no children accounts.
                </Typography>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AccountsExplorer;
