import React from 'react';
import { Grid } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../../components/TextInput';
import CodeEditor from '../../../../../components/CodeEditor';

interface MqttMessageSectionProps {
  action: RuleAction;
  actionIndex: number;
  editorWidth: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const MqttMessageSection: React.FC<MqttMessageSectionProps> = ({
  action,
  actionIndex,
  editorWidth,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <CodeEditor
          label="Message Body"
          prop={`${actionIndex}.body_template`}
          onInputChange={onInputChange}
          value={action.body_template}
          width={editorWidth}
          height={400}
        />
      </Grid>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="MQTT broker URL"
          placeholder=""
          prop={`${actionIndex}.url`}
          value={action.url}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextInput
          label="MQTT topic"
          placeholder=""
          prop={`${actionIndex}.topic`}
          value={action.topic}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="MQTT broker username"
          placeholder=""
          prop={`${actionIndex}.username`}
          value={action.username}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="MQTT broker password"
          placeholder=""
          prop={`${actionIndex}.password`}
          value={action.password}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default MqttMessageSection;
