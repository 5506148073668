import { Integration, IntegrationType } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const IntegrationsTypes = {
  SET_ACTUAL_INTEGRATION: 'SET_ACTUAL_INTEGRATION',
  SET_INTEGRATION_TYPES: 'SET_INTEGRATION_TYPES',
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  SET_NEW_INTEGRATION: 'SET_NEW_INTEGRATION',
  SET_OPTIONS_INTEGRATIONS: 'SET_OPTIONS_INTEGRATIONS',
  SET_SELECTED_INTEGRATIONS: 'SET_SELECTED_INTEGRATIONS',
};

export const setStateIntegrations = (
  integrations: Integration[],
): IntegrationsAction => ({
  data: integrations,
  type: IntegrationsTypes.SET_INTEGRATIONS,
});

export const setActualIntegration = (
  integration: Integration,
): IntegrationsAction => ({
  data: integration,
  type: IntegrationsTypes.SET_ACTUAL_INTEGRATION,
});

export const setSelectedIntegrations = (
  integrations: Integration[],
): IntegrationsAction => ({
  data: integrations,
  type: IntegrationsTypes.SET_SELECTED_INTEGRATIONS,
});

export const setIntegrationTypes = (
  integrationTypes: IntegrationTypes[],
): IntegrationsAction => ({
  data: integrationTypes,
  type: IntegrationsTypes.SET_INTEGRATION_TYPES,
});

export const setOptionsIntegrations = (
  integrations: Integration[],
): IntegrationsAction => ({
  data: integrations,
  type: IntegrationsTypes.SET_OPTIONS_INTEGRATIONS,
});

export const setNewIntegration = (
  integration: Integration,
): IntegrationsAction => ({
  data: integration,
  type: IntegrationsTypes.SET_NEW_INTEGRATION,
});

export const getIntegrationSelector = createSelector(
  (state: IntegrationsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.integrations.length) {
      return state.integration;
    }
    return state.integrations.find(
      (integration: Integration) => integration._id === id,
    );
  },
);

export type IntegrationsState = {
  integration: Integration | null;
  integrations: Integration[];
  integrationTypes: IntegrationTypes[];
  newIntegration: Integration | null;
  optionsIntegrations: Integration[];
  selectedIntegrations: Integration[];
};

// INITIAL STATE
const integrationsInitialState: IntegrationsState = {
  integration: null,
  integrations: [],
  integrationTypes: [],
  newIntegration: null,
  optionsIntegrations: [],
  selectedIntegrations: [],
};

export type IntegrationTypes = {
  [key: string]: IntegrationType;
};

export type IntegrationsAction = {
  data: Integration[] | Integration | IntegrationTypes[];
  type: string;
};

const integrationsReducer = (
  state = integrationsInitialState,
  action: IntegrationsAction,
): IntegrationsState => {
  switch (action.type) {
    case IntegrationsTypes.SET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.data as Integration[],
      };

    case IntegrationsTypes.SET_ACTUAL_INTEGRATION:
      return {
        ...state,
        integration: action.data as Integration,
        newIntegration: action.data as Integration,
      };

    case IntegrationsTypes.SET_SELECTED_INTEGRATIONS:
      return {
        ...state,
        selectedIntegrations: action.data as Integration[],
      };
    case IntegrationsTypes.SET_INTEGRATION_TYPES:
      return {
        ...state,
        integrationTypes: action.data as IntegrationTypes[],
      };
    case IntegrationsTypes.SET_OPTIONS_INTEGRATIONS:
      return {
        ...state,
        optionsIntegrations: action.data as Integration[],
      };
    case IntegrationsTypes.SET_NEW_INTEGRATION:
      return {
        ...state,
        newIntegration: action.data as Integration,
      };
    default:
      return state;
  }
};

export default integrationsReducer;
