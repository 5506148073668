import React from 'react';

import AccountSelect from './AccountSelect';

interface AccountFilterProps {
  accountsSelected?: string;
  placeHolder?: string;
  updateFilters: (prop: string, value: string) => void;
}

const AccountFilter: React.FC<AccountFilterProps> = ({
  accountsSelected = '',
  placeHolder,
  updateFilters,
}) => {
  const handleChange = (ids: string[]): void => {
    updateFilters('company_id', ids.join('|'));
  };

  return (
    <AccountSelect
      isMultiple={true}
      isFilter={true}
      placeHolder={placeHolder}
      selectedAccounts={accountsSelected ? accountsSelected.split('|') : []}
      onMultipleAccountChange={handleChange}
    />
  );
};

export default AccountFilter;
