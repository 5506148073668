import { User, UserType, UserTypes, Users } from '@edgeiq/edgeiq-api-js';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';
import { getUserSelector } from '../redux/reducers/users.reducer';
import { setStateUserTypes } from '../redux/reducers/userTypes.reducer';

export interface UseUserWithTypeOutput {
  user?: User;
  userType?: UserType;
  error?: Error;
}

export const useUserWithType = (
  userId: string,
  withType = true,
): UseUserWithTypeOutput => {
  const dispatch = useAppDispatch();
  const stateUser = useAppSelector((state: RootState) =>
    getUserSelector(state.users, userId),
  );
  // Added `?? undefined` to avoid adding null as type, because the redux selector returns User | undefined | null (TO DO: change this part of the redux selectors)
  const [user, setUser] = useState<User | undefined>(stateUser ?? undefined);
  const [userType, setUserType] = useState<UserType | undefined>();
  const userTypesState = useAppSelector((state: RootState) => state.userTypes);
  const [error, setError] = useState<Error | undefined>();

  const getUserType = (userTypeId: string): void => {
    const foundUserType = userTypesState.userTypes.find(
      (t) => t._id === userTypeId,
    );
    if (foundUserType) {
      setUserType(foundUserType);
    } else {
      fetchUserTypeById(userTypeId);
    }
  };

  const fetchUserById = (): void => {
    Users.getOneById(userId as string)
      .then((result) => {
        setUser(result);
      })
      .catch((e) => {
        setError(e);
      });
  };

  const fetchUserTypeById = (userTypeId: string): void => {
    UserTypes.getOneById(userTypeId)
      .then((result) => {
        setUserType(result);
        dispatch(setStateUserTypes([...userTypesState.userTypes, result]));
      })
      .catch((e) => {
        setError(e);
      });
  };

  useEffect(() => {
    if (user && withType) {
      getUserType(user.user_type_id);
    }
  }, [user]);

  useEffect(() => {
    if (userId && !user) {
      fetchUserById();
    } else {
      setError(new Error('user id not provided'));
    }
  }, [userId]);

  return {
    error,
    user,
    userType,
  };
};
