import { Box, Grid } from '@mui/material';
import React from 'react';
import {
  DETAILS_DEFAULT_TAB,
  deviceDetailsTabsLabel,
} from '../../app/constants';
import ContentHeader from '../../components/ContentHeader';
import Header from '../../containers/HeaderWithActionButton';
import VerticalTabs from '../../components/VerticalTabs';
import SoftwarePackagesLogDetails from './softwarePackagesLogDetails/SoftwarePackagesLogDetails';

const SoftwarePackagesLog: React.FC = () => {
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: <SoftwarePackagesLogDetails />,
    };
    return tabs;
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Software Package content"
        goBackLabel={'Software Packages'}
        goBack="software-updates"
        model="software_update"
      />
      <Box>
        <ContentHeader
          title={'Devices in this update'}
          contentType="software-package"
          hideTitleImage
        />
        <VerticalTabs
          tabs={getTabs()}
          defaultTab={DETAILS_DEFAULT_TAB}
          tabsLabel={deviceDetailsTabsLabel}
        />
      </Box>
    </Grid>
  );
};

export default SoftwarePackagesLog;
