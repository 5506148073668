import {
  CommandExecution,
  Execution,
  GatewayCommand,
  WorkflowExecution,
} from '@edgeiq/edgeiq-api-js';
import { ChildExecution } from '../models/commandExecution';

export const getExecutionStatus = (statuses: Execution[]): string => {
  if (statuses?.length) {
    const statusIndex = statuses.findIndex(
      (e) => e.status === 'success' || e.status === 'error',
    );
    if (statusIndex >= 0) {
      return statuses[statusIndex].status;
    }
    return statuses[0].status;
  }
  return '';
};

export const getExecutionStatusObject = (
  statuses: Execution[],
): Execution | undefined => {
  if (statuses?.length) {
    const statusIndex = statuses.findIndex(
      (e) => e.status === 'success' || e.status === 'error',
    );
    if (statusIndex >= 0) {
      return statuses[statusIndex];
    }
    return statuses[0];
  }
};

export const sortExecutionStatuses = (
  execution: CommandExecution | WorkflowExecution,
): Execution[] => {
  if (execution.execution_statuses && execution.execution_statuses.length > 1) {
    const statuses = execution.execution_statuses.sort((a, b) => {
      const keyA = new Date(a.created_at);
      const keyB = new Date(b.created_at);
      // Compare the 2 dates
      if (keyA < keyB) {
        return 1;
      }
      if (keyA > keyB) {
        return -1;
      }
      return 0;
    });
    return statuses;
  }
  return execution.execution_statuses;
};

export const getCommandExecutionStatus = (command: GatewayCommand): string => {
  return command.device_unique_id
    ? command.statuses[command.device_unique_id].status
    : '';
};

export const getChildExecutions = (
  command: GatewayCommand,
): ChildExecution[] => {
  const arr: ChildExecution[] = Object.keys(command.statuses).map((c) => {
    return {
      deviceId: c,
      message: command.statuses[c].status_message || '',
      status: command.statuses[c].status || '',
    };
  });
  return arr;
};
