import React, { ReactElement } from 'react';
import { HandlerType, IngestorInput } from '@edgeiq/edgeiq-api-js';
import DelimitedSection from './DelimitedSection';
import SingleLengthSection from './SingleLengthSection';
import RouterSection from './RouterSection';
import DbusSection from './DbusSection';

interface ListenerTypeDynamicSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  disabled?: boolean;
  addRoute: () => void;
  removeRoute: (index: number) => void;
}

const HandlerTypeDynamicSections: React.FC<ListenerTypeDynamicSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
  addRoute,
  removeRoute,
}) => {
  const renderSection = (type: HandlerType): ReactElement => {
    switch (type) {
      case 'delimited':
        return (
          <DelimitedSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'fixed':
        return (
          <SingleLengthSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'router':
        return (
          <RouterSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
            addRoute={addRoute}
            removeRoute={removeRoute}
          />
        );
      case 'dbus':
        return (
          <DbusSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      default:
        return <></>;
    }
  };
  return renderSection(newIngestor.handler_type);
};

export default HandlerTypeDynamicSections;
