import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { baudRates } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface LinuxDevSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
}

const LinuxDevSection: React.FC<LinuxDevSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Dev"
          placeholder="/dev/ttyS1"
          prop="listener.dev"
          value={newIngestor.listener.dev}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <SelectInput
          label="Baud rate"
          prop="listener.baud"
          value={newIngestor.listener.baud}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={baudRates.map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {key}
            </MenuItem>
          ))}
        />
      </Grid>
    </>
  );
};

export default LinuxDevSection;
