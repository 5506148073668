import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { Users, User, UserType, UserTypes } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  getUserSelector,
  setStateCurrentUser,
} from '../../redux/reducers/users.reducer';
import { setStateUserTypes } from '../../redux/reducers/userTypes.reducer';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import {
  userDetailsTabsLabel,
  errorHighlight,
  optionsPaginationsFilter,
  DETAILS_DEFAULT_TAB,
} from '../../app/constants';
import FooterBar from '../../components/FooterBar';
import UserMetadata from './userMetadata';
import UserDetails from './userDetails';
import { validateEmail } from '../../helpers/validateEmail';

const UserContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const currentUser = useAppSelector((state: RootState) =>
    getUserSelector(state.users, id),
  );
  const newUser = useAppSelector((state: RootState) => state.users.newUser);
  const loggedUser = useAppSelector((state: RootState) => state.user.user);
  const userTypesState = useAppSelector((state: RootState) => state.userTypes);
  const [userTypes, setUserTypes] = useState<UserType[]>(
    userTypesState.userTypes,
  );
  const [loading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getUserTypes = (): void => {
    if (userTypes.length === 0) {
      UserTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          const { userTypes: resultUserTypes } = result;
          setUserTypes(resultUserTypes);
          dispatch(setStateUserTypes(resultUserTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  };

  useEffect(() => {
    getUserTypes();
  }, []);

  useEffect(() => {
    if (newUser) {
      setIsValidEmail(validateEmail(newUser.email));
    }
  }, [newUser]);

  useEffect(() => {
    if (currentUser) {
      dispatch(setStateCurrentUser(currentUser));
    } else if (id) {
      if (id === loggedUser?._id) {
        dispatch(setStateCurrentUser(loggedUser));
      } else {
        Users.getOneById(id)
          .then((response) => {
            dispatch(setStateCurrentUser(response));
          })
          .catch((err) => {
            dispatch(
              setAlert({
                highlight: errorHighlight,
                message: err.message,
                type: 'error',
              }),
            );
          });
      }
    }
  }, [currentUser]);

  const handleDeleteUser = (): void => {
    if (!currentUser) {
      return;
    }
    setLoading(true);
    Users.delete(currentUser._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'User Deleted',
            message: 'User successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/users');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (!isEqual(newUser, currentUser)) {
      setLoading(true);
      Users.update(newUser as User)
        .then((response) => {
          dispatch(setStateCurrentUser(response));
          dispatch(
            setAlert({
              highlight: 'User Updated',
              message: 'User successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.messages || error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newUser, currentUser);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="User Content" goBack="users" model="user" />
      {currentUser && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="user"
            nameInitials={
              currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0)
            }
            title={`${currentUser.first_name} ${currentUser.last_name}`}
            profileName={currentUser.company_id}
            subtitle={currentUser._id}
            hideOverline={false}
            copySubtitleToClipboard
          />

          <VerticalTabs
            tabs={{
              details: (
                <UserDetails
                  user={newUser ?? undefined}
                  userTypes={userTypes}
                  isValidEmail={isValidEmail}
                />
              ),
              metadata: <UserMetadata />,
            }}
            defaultTab={DETAILS_DEFAULT_TAB}
            tabsLabel={userDetailsTabsLabel}
          />
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this user"
        loading={loading}
        disableSaveButton={isAbleToBeSaved() || !isValidEmail}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteUser}
      />
    </Grid>
  );
};

export default UserContent;
