import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Command, Commands } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setStateCommands } from '../../../redux/reducers/commands.reducer';
import {
  optionsPaginationsFilter,
  errorHighlight,
  warningHighlight,
} from '../../../app/constants';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import IssueCommandCard from './IssueCommandCard';
import { CircularProgress, Grid } from '@mui/material';

interface IssueCommandsDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  onChoosingCommands: (commands: Command[]) => void;
}

const IssueCommandsDrawer: React.FC<IssueCommandsDrawerProps> = ({
  open,
  handleCloseDrawer,
  onChoosingCommands,
}) => {
  const dispatch = useAppDispatch();
  const stateCommands = useAppSelector((state: RootState) => state.commands);
  const [commands, setCommands] = useState<Command[]>(stateCommands.commands);
  const [selectedCommands, setSelectedCommands] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const preCommandAttachList = useRef<string>('');

  useEffect(() => {
    if (commands.length === 0) {
      Commands.list({}, optionsPaginationsFilter)
        .then((result) => {
          setCommands(result.commands);
          dispatch(setStateCommands(result.commands));
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (stateCommands.selectedCommands.length !== 0) {
      setSelectedCommands(
        stateCommands.selectedCommands.map((command) => command._id),
      );
    } else {
      setSelectedCommands([]);
    }
    preCommandAttachList.current =
      selectedCommands.length > 0 ? String(selectedCommands.length) : '';
  }, [stateCommands.selectedCommands]);

  const handleOnChangeCallback = (_value: string): void => {
    // TODO: call commands with filter
  };

  const checkCommandCallback =
    (commandId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const fromDeviceType = stateCommands.originalSelectedCommands.find(
        (originalCommand) =>
          originalCommand._id === commandId &&
          originalCommand.is_from_device_type,
      );

      if (fromDeviceType) {
        return dispatch(
          setAlert({
            highlight: warningHighlight,
            message:
              "You can't remove a command that belongs to the Device Profile.",
            type: 'warning',
          }),
        );
      }

      if (checked) {
        setSelectedCommands([...selectedCommands, commandId]);
      } else {
        setSelectedCommands(
          selectedCommands.filter((item) => item !== commandId),
        );
      }
    };

  const handleCommandsCallback = (): void => {
    onChoosingCommands(
      commands.filter((command) => selectedCommands.includes(command._id)),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedCommands.length !== commands.length) {
      setSelectedCommands(commands.map((command) => command._id));
    } else {
      setSelectedCommands([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel={
        !!preCommandAttachList && selectedCommands.length === 0
          ? 'Dettach All'
          : 'Attach'
      }
      title="Select Commands"
      disableAction={
        (!preCommandAttachList && selectedCommands.length === 0) ||
        !commands.length
      }
      actionCallback={handleCommandsCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          allSelected={selectedCommands.length === commands.length}
          itemsSelected={selectedCommands.length !== 0}
          hasItems={commands.length !== 0}
          searchPlaceholder="Search Commands"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={commands.map((command) => (
                  <AttachItemCard
                    content={
                      <IssueCommandCard key={command._id} command={command} />
                    }
                    checked={selectedCommands.includes(command._id)}
                    checkboxCallback={checkCommandCallback}
                    id={command._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default IssueCommandsDrawer;
