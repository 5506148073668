import React from 'react';
import { Typography, Grid, Paper, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  AwsThingGroup,
  DeviceType,
  ImportantMetadata,
} from '@edgeiq/edgeiq-api-js';
import { set, cloneDeep } from 'lodash';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setNewDeviceType } from '../../../redux/reducers/deviceTypes.reducer';
import { updateThingGroupOnChange } from '../../../helpers/utils';
import { LWM2M_TYPE } from '../../../app/constants';
import IntegrationConfig from '../../../containers/IntegrationConfig';
import TextInput from '../../../components/TextInput';
import DeviceTypeForm from './deviceTypeForm';
import DeviceTypeOptions from './deviceTypeOptions';
import DeviceTypeObservablePaths from './deviceTypeObservablePaths';

const DeviceTypeDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  const handleInputChange = (
    prop: string,
    value: string | boolean | number,
  ): void => {
    switch (prop) {
      case 'firmware-upgrade':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            capabilities: {
              ...editableDeviceType?.capabilities,
              firmware: {
                ...editableDeviceType?.capabilities.firmware,
                upgrade: value,
              },
            },
          } as DeviceType),
        );
        break;
      case 'ip-tables':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            capabilities: {
              ...editableDeviceType?.capabilities,
              iptables: value,
            },
          } as DeviceType),
        );
        break;
      default:
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            [prop]: value,
          } as DeviceType),
        );
    }
  };

  const handleIntegrationChange = (prop: string, value: string): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        [prop]: value,
      } as DeviceType),
    );
  };

  const handleDefaultThingGroupChange = (value: AwsThingGroup): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        default_thing_groups: updateThingGroupOnChange(
          editableDeviceType?.default_thing_groups,
          value,
        ),
      } as DeviceType),
    );
  };

  const updateImportantMetadata = (metadata: ImportantMetadata[]): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        important_metadata: metadata,
      } as DeviceType),
    );
  };

  const onImportantMetadataChange = (
    prop: string,
    value: string | number,
  ): void => {
    if (editableDeviceType?.important_metadata) {
      // prop in this case will always be 'value.<index>' or 'label.<index>'
      const propsArray = prop.split('.');
      // Creating an object this way as it is necessary for the lodash set function to work properly
      const metadataObject = {
        important_metadata: [...editableDeviceType.important_metadata],
      };
      set(
        metadataObject,
        `important_metadata.${propsArray[1]}.${propsArray[0]}`,
        value,
      );
      updateImportantMetadata(metadataObject.important_metadata);
    }
  };

  const handleRemoveImportantMetadata = (index: number) => (): void => {
    if (editableDeviceType?.important_metadata) {
      const updatedMetadata = cloneDeep(editableDeviceType.important_metadata);
      updatedMetadata.splice(index, 1);
      updateImportantMetadata(updatedMetadata);
    }
  };

  const addImportantMetadata = (): void => {
    const newImportantMetadata = {
      label: '',
      value: '',
    };
    if (
      editableDeviceType?.important_metadata &&
      editableDeviceType?.important_metadata.length
    ) {
      updateImportantMetadata([
        ...editableDeviceType.important_metadata,
        newImportantMetadata,
      ]);
    } else {
      updateImportantMetadata([newImportantMetadata]);
    }
  };

  const handleObservablePathsChange = (
    _prop: string,
    value: string[],
  ): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        capabilities: {
          ...editableDeviceType?.capabilities,
          observable_paths: value,
        },
      } as DeviceType),
    );
  };

  return (
    <Grid container>
      {editableDeviceType && (
        <>
          <Grid component={Paper} item xs={8} className={clsx('p-7 shadow')}>
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <DeviceTypeForm
              deviceType={editableDeviceType}
              onInputChange={handleInputChange}
            />
            {/* Integration Form */}
            <IntegrationConfig
              deviceType={editableDeviceType}
              onIntegrationChange={handleIntegrationChange}
              onDefaultThingGroupChange={handleDefaultThingGroupChange}
            />
            <DeviceTypeOptions
              deviceType={editableDeviceType}
              onSwitchChange={handleInputChange}
            />
            {/* Important metadata */}
            <Typography
              variant="h6"
              className="mt-6 mb-3"
              data-cy="important-data-title"
            >
              Important Metadata
            </Typography>
            <p className="mt-3" data-cy="important-data-subtitle">
              Add a list of device metadata to display more prominently on the
              detail view of devices assigned this profile:
            </p>
            {editableDeviceType.important_metadata?.map(
              (imoportantMetadata, index) => (
                <Grid
                  key={index}
                  container
                  direction="row"
                  spacing={2}
                  className="mt-2 ml-0"
                  alignItems="center"
                >
                  <Grid item xs={5} className="pl-0">
                    <TextInput
                      placeholder="Value"
                      prop={`value.${index}`}
                      value={imoportantMetadata.value}
                      onInputChange={onImportantMetadataChange}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <TextInput
                      placeholder="Label"
                      prop={`label.${index}`}
                      value={imoportantMetadata.label}
                      onInputChange={onImportantMetadataChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={handleRemoveImportantMetadata(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ),
            )}
            <Button
              variant="outlined"
              size="medium"
              className="mt-4"
              onClick={addImportantMetadata}
              startIcon={<AddIcon />}
            >
              Add new
            </Button>

            {editableDeviceType.type === LWM2M_TYPE && (
              <DeviceTypeObservablePaths
                deviceType={editableDeviceType}
                onInputChange={handleObservablePathsChange}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeviceTypeDetails;
