import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import isEqual from 'lodash.isequal';
import clsx from 'clsx';

import { SettingsEditorData } from '../../../models/settings';
import DropZone from '../../../components/DropZone';
import CodeEditor from '../../../components/CodeEditor';
import JsonTreeView from '../../../components/JsonTreeView';
import useStyles from './styles';

interface SettingsEditor {
  action: string;
  data: SettingsEditorData;
  onInputChange: (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ) => void;
}

// If we are creating the configuration the json_schema won't have a value.
// So in this code we are managing both creating and editing, when the user creates they will more probably upload a key value json file, not the schema file.
// On the editing end we will end up showing the schema in this component
// And the file used to create the schema will be used to create a first settings.

const SettingsEditor: React.FC<SettingsEditor> = ({
  action,
  data,
  onInputChange,
}) => {
  const classes = useStyles();
  const { chosenView, jsonContent, jsonTree, manualCreation } = data;
  const [uploadedFile, setUploadedFile] = useState<File>();
  const anchorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const getJsonContent = async (file: File): Promise<string> => {
    return await file.text();
  };

  useEffect(() => {
    if (uploadedFile) {
      try {
        getJsonContent(uploadedFile)
          .then((text) => {
            onInputChange('jsonContent', text);
          })
          .catch((error) => console.info(error));
      } catch (error) {
        console.info(error);
      }
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (jsonContent) {
      try {
        onInputChange('jsonTree', JSON.parse(jsonContent));
      } catch (error) {
        console.info(error);
      }
    } else if (jsonContent === '' && !isEqual(jsonTree, {})) {
      onInputChange('jsonTree', {});
    }
  }, [jsonContent]);

  const handleView = (
    _event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ): void => {
    if (newView) {
      onInputChange('chosenView', newView);
    }
  };

  const handleChangeJsonCreation = (): void => {
    onInputChange('manualCreation', true);
  };

  const onAddFile = (file: File): void => {
    setUploadedFile(file);
  };

  const onRemoveFile = (): void => {
    setUploadedFile(undefined);
  };

  const handleJsonContentChange = (_prop: string, value: string): void => {
    onInputChange('jsonContent', value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setUploadedFile(fileObj);
    // 👇️ reset file input
    event.target.value = '';
  };

  const handleReUploadFile = (): void => {
    // 👇️ open file input box on click of another element
    inputRef?.current?.click();
  };

  const handleTreeViewUpdate = (dataTree: Record<string, unknown>): void => {
    try {
      onInputChange('jsonContent', JSON.stringify(dataTree, null, 2));
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <Grid item xs={12}>
      <Box className={clsx('mb-6', classes.viewContainer)}>
        <div>
          <Typography variant="button" className="mr-3">
            Select a view:
          </Typography>
          <ToggleButtonGroup
            value={chosenView}
            exclusive
            onChange={handleView}
            aria-label="settings editor view"
          >
            <ToggleButton
              aria-label="json view"
              color="primary"
              data-cy="json-code-editor-button"
              disabled={!jsonContent && !manualCreation}
              size="small"
              value="json"
            >
              <Typography variant="button">JSON code editor</Typography>
            </ToggleButton>
            <ToggleButton
              aria-label="tree view"
              color="primary"
              data-cy="tree-view-editor-button"
              disabled={!jsonContent && !manualCreation}
              size="small"
              value="tree"
            >
              <Typography variant="button">Tree view editor</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {(manualCreation || jsonContent) && (
          <div>
            <input
              style={{ display: 'none' }}
              accept=".json"
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <Button
              data-cy="re-upload-json-file"
              variant="contained"
              size="medium"
              onClick={handleReUploadFile}
            >
              Upload JSON file
            </Button>
          </div>
        )}
      </Box>
      {chosenView === 'json' && (
        <Box ref={anchorRef}>
          {!manualCreation && action === 'create' && !uploadedFile && (
            <DropZone
              acceptTypes={['application/json']}
              stateFiles={uploadedFile ? [uploadedFile] : []}
              specialLink="Or create settings manually"
              specialLinkCallback={handleChangeJsonCreation}
              onFileUpload={onAddFile}
              onRemoveFile={onRemoveFile}
            />
          )}
          {(manualCreation || jsonContent) && (
            <CodeEditor
              label=""
              prop="json_schema"
              mode="json"
              onInputChange={handleJsonContentChange}
              value={jsonContent}
              width={anchorRef.current?.clientWidth}
              height={400}
            />
          )}
        </Box>
      )}
      {chosenView === 'tree' && (
        <Box className={clsx('p-3 br-1', classes.jsonViewContainer)}>
          <JsonTreeView data={jsonTree} onUpdateData={handleTreeViewUpdate} />
        </Box>
      )}
    </Grid>
  );
};

export default SettingsEditor;
