import React from 'react';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  GENRE_ACTIVE,
  GENRE_ESCROW,
  GENRE_DISCOVERED,
} from '../../app/constants';
import ActiveDevices from './activeDevices/ActiveDevices';
import EscrowDevicesPage from './escrowDevices/EscrowDevices';
import DiscoveredDevicesPage from './discoveredDevices/DiscoveredDevices';

const DevicesPage: React.FC = () => {
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;
  const isEscrow = devicesState.devicesGenre === GENRE_ESCROW;
  const isDiscovered = devicesState.devicesGenre === GENRE_DISCOVERED;

  return isActive ? (
    <ActiveDevices />
  ) : isEscrow ? (
    <EscrowDevicesPage />
  ) : isDiscovered ? (
    <DiscoveredDevicesPage />
  ) : (
    <></>
  );
};

export default DevicesPage;
