import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import SelectInput from '../../../../../components/SelectInput';
import { HEALTH_STATUS_OPTIONS } from '../../../../../constants/policies';

interface HealthStatusSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const HealthStatusSection: React.FC<HealthStatusSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
}) => {
  return (
    <Grid item xs={12}>
      <SelectInput
        label="Health Status"
        value={action.health_status}
        prop={`${actionIndex}.health_status`}
        onSelectChange={onInputChange}
        options={Object.keys(HEALTH_STATUS_OPTIONS).map((key) => (
          <MenuItem className="m-4 p-2" key={key} dense value={key}>
            {HEALTH_STATUS_OPTIONS[key]}
          </MenuItem>
        ))}
        disabled={disabled}
      />
    </Grid>
  );
};

export default HealthStatusSection;
