import { objectKeys } from './utils';
import { Ingestor } from '@edgeiq/edgeiq-api-js';

export const getDefaultHandlerTypes = (
  listenerTypesMap: Record<string, string>,
): {
  [key: string]: Ingestor['handler_type'];
} =>
  objectKeys(listenerTypesMap).reduce((defaultHandlerTypes, listener) => {
    const reCloudListener = /cloud/g;
    const reHTTP = /http/g;

    if (listener === 'dbus_signal') {
      return { ...defaultHandlerTypes, [listener]: 'dbus' };
    }

    if (listener.match(reCloudListener)) {
      return { ...defaultHandlerTypes, [listener]: 'passthrough' };
    }

    if (listener.match(reHTTP)) {
      return { ...defaultHandlerTypes, [listener]: 'router' };
    }

    return { ...defaultHandlerTypes, [listener]: 'fixed' };
  }, {});
