import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../../components/TextInput';
import SelectInput from '../../../../../components/SelectInput';
import {
  GENERATED_AT_FORMAT_OPTIONS,
  LOCATION_VIA_OPTIONS,
} from '../../../../../constants/policies';

interface LocationObservationSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const LocationObservationSection: React.FC<LocationObservationSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Latitude Template"
          placeholder=""
          prop={`${actionIndex}.latitude_template`}
          value={action.latitude_template}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Longitude Template"
          placeholder=""
          prop={`${actionIndex}.longitude_template`}
          value={action.longitude_template}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Accuracy Radius (Meters) Template"
          placeholder=""
          prop={`${actionIndex}.radius_template`}
          value={action.radius_template}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Generated At Template"
          placeholder=""
          prop={`${actionIndex}.generated_at_template`}
          value={action.generated_at_template}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectInput
          label="Generated At Format"
          value={action.generated_at_format}
          prop={`${actionIndex}.generated_at_format`}
          onSelectChange={onInputChange}
          options={Object.keys(GENERATED_AT_FORMAT_OPTIONS).map((key) => (
            <MenuItem className="m-4 p-2" key={key} dense value={key}>
              {GENERATED_AT_FORMAT_OPTIONS[key]}
            </MenuItem>
          ))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          label="Location Via"
          value={action.location_via}
          prop={`${actionIndex}.location_via`}
          onSelectChange={onInputChange}
          options={Object.keys(LOCATION_VIA_OPTIONS).map((key) => (
            <MenuItem className="m-4 p-2" key={key} dense value={key}>
              {LOCATION_VIA_OPTIONS[key]}
            </MenuItem>
          ))}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default LocationObservationSection;
