import React from 'react';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
} from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import { Box } from '@mui/system';
import clsx from 'clsx';

import { logsTypesMap, GATEWAY_TYPE } from '../../../../app/constants';

import useStyles from './styles';

interface DeviceLogConfigProps {
  localLog?: string;
  forwardLog?: string;
  deviceType?: DeviceType | null;
  onChangeLogConfig: (prop: string, value: string) => void;
}

const DeviceLogConfig: React.FC<DeviceLogConfigProps> = ({
  localLog,
  forwardLog,
  deviceType,
  onChangeLogConfig,
}) => {
  const classes = useStyles();

  const logsTypes = Object.keys(logsTypesMap);

  const handleChangeLog =
    (porp: string) =>
    (event: SelectChangeEvent): void => {
      onChangeLogConfig(porp, event.target.value as string);
    };

  const isDisabled = !deviceType || deviceType.type !== GATEWAY_TYPE;

  return (
    <Box>
      <Typography className="mt-3 mb-3" variant="h5">
        Logs configuration
      </Typography>
      {isDisabled ? (
        <Typography
          variant="button"
          component="div"
          className={clsx('mb-1', classes.configTitle)}
        >
          {!deviceType
            ? 'Choose a device profile to configure logging.'
            : `There is no logging configuration for device profile: ${deviceType?.name}.`}
        </Typography>
      ) : (
        <>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="button"
                component="div"
                className={clsx('mb-1', classes.configTitle)}
              >
                Select device local logs
              </Typography>
              <FormControl className="mb-3" fullWidth>
                <Select
                  displayEmpty
                  value={localLog ?? 'error'}
                  onChange={handleChangeLog('local_level')}
                >
                  {logsTypes.map((logType, index) => (
                    <MenuItem dense key={index} value={logType}>
                      {logsTypesMap[logType]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="button"
                component="div"
                className={clsx('mb-1', classes.configTitle)}
              >
                Forward Log Level
              </Typography>
              <FormControl className="mb-3" fullWidth>
                <Select
                  displayEmpty
                  value={forwardLog ?? 'error'}
                  onChange={handleChangeLog('forward_level')}
                >
                  {logsTypes.map((logType, index) => (
                    <MenuItem dense key={index} value={logType}>
                      {logsTypesMap[logType]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default DeviceLogConfig;
