import React, { ReactElement, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Configurations,
  Configuration,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { deleteHighlight, errorHighlight } from '../../../app/constants';
import TabsPage from '../../../components/TabsPage';
import ActionDialog from '../../../components/ActionDialog';
import { ContentPageTabProps } from '../../../models/common';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import useStyles from '../../deviceContent/styles';

const DeviceTypeConfigurations: React.FC<ContentPageTabProps> = ({
  goToItem,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [updatesList, setUpdatesList] = useState<Configuration[]>([]);
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const [selectedConfiguration, setSelectedConfiguration] = useState<string>();
  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };
  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: '_id',
      flex: 0.7,
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <TypographyWithCopy
          dataCy={`device-type-configuration-${params.row._id}`}
          text={params.row._id}
          typographyVariant="button"
        />
      ),
    },
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
          onClick={goToItem('configuration', params.row._id)}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'action',
      flex: 0.1,
      headerName: 'Action',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <IconButton onClick={(): void => handleClickRemoveRow(params)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleClickRemoveRow = (prop: GridRenderCellParams): void => {
    openDeleteModal();
    setSelectedConfiguration(prop.row._id);
  };

  const handleDelete = (): void => {
    setLoading(true);
    Configurations.delete(selectedConfiguration as string)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(1, 'Configuration', 'Configurations'),
            type: 'success',
          }),
        );
        setUpdatesList(
          updatesList.filter(
            (configuration) => selectedConfiguration !== configuration._id,
          ),
        );
        setSelectedConfiguration('');
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        closeDeleteModal();
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);
      DeviceTypes.getConfigurations(editableDeviceType._id)
        .then((configs) => {
          setUpdatesList(configs);
        })
        .catch((err) => {
          dispatchError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleCreateConfiguration = (): void => {
    goToItem('configuration')();
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={updatesList}
        addButtonLabel="Create Configuration"
        onAddClick={handleCreateConfiguration}
        tableTitle="Configuration added"
        loading={loading}
      />
      <ActionDialog
        open={ActionDialogOpen}
        loading={loading}
        content={
          <>
            <span>You are about to delete this configuration:</span>
            <ul>
              {updatesList
                .filter(
                  (configuration) =>
                    selectedConfiguration === configuration._id,
                )
                .map((update) => (
                  <li key={update._id}>{update.name}</li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleDelete}
      />
    </Box>
  );
};

export default DeviceTypeConfigurations;
