export interface SettingsEditorData {
  chosenView: string;
  jsonContent: string;
  jsonTree: Record<string, unknown>;
  manualCreation: boolean;
}

export const initialSettingsEditorData = {
  chosenView: 'json',
  jsonContent: '',
  jsonTree: {},
  manualCreation: false,
};
