import { DeviceError } from '@edgeiq/edgeiq-api-js';

export const DeviceErrorTypes = {
  SET_DEVICE_ERRORS: 'SET_GATEWAY_COMMAND',
  SET_SELECTED_DEVICE_ERRORS: 'SET_GATEWAY_SELECTED_COMMANDS',
};

export const setDeviceErrors = (
  hearbeats: DeviceError[],
): DeviceErrorsAction => ({
  data: hearbeats,
  type: DeviceErrorTypes.SET_DEVICE_ERRORS,
});

export const setSelectedDeviceErrors = (
  hearbeats: DeviceError[],
): DeviceErrorsAction => ({
  data: hearbeats,
  type: DeviceErrorTypes.SET_SELECTED_DEVICE_ERRORS,
});

export type DeviceErrorState = {
  deviceErrors: DeviceError[];
  selectedDeviceErrors: DeviceError[];
};

// INITIAL STATE
const deviceErrorsInitialState: DeviceErrorState = {
  deviceErrors: [],
  selectedDeviceErrors: [],
};

export type DeviceErrorsAction = {
  data: DeviceError[] | DeviceError;
  type: string;
};

const deviceErrorsReducer = (
  state = deviceErrorsInitialState,
  action: DeviceErrorsAction,
): DeviceErrorState => {
  switch (action.type) {
    case DeviceErrorTypes.SET_DEVICE_ERRORS:
      return {
        ...state,
        deviceErrors: action.data as DeviceError[],
      };
    case DeviceErrorTypes.SET_SELECTED_DEVICE_ERRORS:
      return {
        ...state,
        selectedDeviceErrors: action.data as DeviceError[],
      };
    default:
      return state;
  }
};

export default deviceErrorsReducer;
