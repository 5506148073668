import React, { ReactElement, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { set } from 'lodash';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  FiltersState,
  setCaseSensitive,
  setFilters,
  setHierarchyFilters,
} from '../../redux/reducers/filters.reducer';
import { FilterObject } from '../../models/common';
import MoreFiltersDrawer from './MoreFilters/MoreFiltersDrawer';
import MainFilterInput from './MainFilterInput';
import FiltersChips from './Chips/FiltersChips';
import useStyles from './styles';

interface FiltersProps {
  filterModel: string; // The model in the redux filters. Example: 'devices'
  total?: number; // The total results of the request made with or without filters
  hasSearchInput: boolean; // Indicates if the filters will have a search input or not
  inputKey?: string; // The key to use for the seach input
  inputPlaceholder?: string; // The placeholder in the search input
  firstFilter?: ReactElement; // The 1st filter to appear after the search input
  secondFilter?: ReactElement; // The 2nd filter to appear after the search input
  thirdFilter?: ReactElement; // The 3rd filter to appear after the search input
  moreFilters?: ReactElement; // The content inside the advanced filters drawer
  moreFiltersValue?: {
    [key: string]: string;
  }; // key value object with the filters that should be applied once the user confirms the action
  hideFiltersAppliedRow?: boolean;
  moreFiltersApplyLoading?: boolean;
  showCaseSensitive?: boolean;
}

const Filters: React.FC<FiltersProps> = ({
  filterModel,
  total = 0,
  hasSearchInput,
  inputKey,
  inputPlaceholder,
  firstFilter,
  secondFilter,
  thirdFilter,
  moreFilters,
  moreFiltersValue,
  hideFiltersAppliedRow = false,
  moreFiltersApplyLoading = false,
  showCaseSensitive = true,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const hierarchyFiltersState = useAppSelector(
    (state: RootState) => state.filters.hierarchyFilters,
  );
  const filters = stateFilters[
    filterModel as keyof FiltersState
  ] as FilterObject;
  let filtersApplied = false;

  if (filters.filters) {
    for (const key in filters.filters) {
      if (Object.prototype.hasOwnProperty.call(filters.filters, key)) {
        const element = filters.filters[key];
        if (key !== inputKey && element !== '') {
          filtersApplied = true;
          break;
        }
      }
    }
  }
  const [filtersOpened, setFiltersOpened] = useState(false);

  const updateHierarchyFilters = (
    accountId?: string,
    isAncestor = false,
  ): void => {
    const levelsObject = {
      levels: [...hierarchyFiltersState.levels],
    };
    // Before even considering updating, must check if there's even levels in the hierarchy filter to be updated
    // The case where there might not be, is when we have the filters, the user reload the page and the clears the filters,
    // by then there is a posibility where we might not have the levels loaded.
    if (levelsObject.levels && levelsObject.levels.length !== 0) {
      let selectedAccountsIndexes =
        hierarchyFiltersState.selectedAccountsIndexes;
      let ancestorAccountIndexes = hierarchyFiltersState.ancestorAccountIndexes;
      // First case is for clearing all filters. In this case no id is provided
      if (!accountId) {
        for (const id in selectedAccountsIndexes) {
          if (
            Object.prototype.hasOwnProperty.call(selectedAccountsIndexes, id)
          ) {
            const indexes = selectedAccountsIndexes[id];
            set(
              levelsObject,
              `levels.${indexes.index}.children.${indexes.levelIndex}.selected`,
              false,
            );
          }
        }
        selectedAccountsIndexes = {};
        for (const id in ancestorAccountIndexes) {
          if (
            Object.prototype.hasOwnProperty.call(ancestorAccountIndexes, id)
          ) {
            const indexes = ancestorAccountIndexes[id];
            set(
              levelsObject,
              `levels.${indexes.index}.children.${indexes.levelIndex}.selectBranch`,
              false,
            );
          }
        }
        ancestorAccountIndexes = {};
        // Second case is when no ID is provided. We check if it's a normal account filter or the ancestor account filter
      } else if (
        isAncestor &&
        ancestorAccountIndexes &&
        ancestorAccountIndexes[accountId]
      ) {
        const indexes = ancestorAccountIndexes[accountId];
        set(
          levelsObject,
          `levels.${indexes.index}.children.${indexes.levelIndex}.selectBranch`,
          false,
        );
        delete ancestorAccountIndexes[accountId];
      } else if (
        selectedAccountsIndexes &&
        selectedAccountsIndexes[accountId]
      ) {
        const indexes = selectedAccountsIndexes[accountId];
        set(
          levelsObject,
          `levels.${indexes.index}.children.${indexes.levelIndex}.selected`,
          false,
        );
        delete selectedAccountsIndexes[accountId];
      }

      dispatch(
        setHierarchyFilters({
          ...hierarchyFiltersState,
          ancestorAccountIndexes,
          levels: levelsObject.levels,
          selectedAccountsIndexes,
        }),
      );
    }
  };

  const resetFilters = (): void => {
    dispatch(setFilters({}, filterModel as keyof FiltersState));
    updateHierarchyFilters();
  };

  const openMoreFilters = (): void => {
    setFiltersOpened(true);
  };

  const closeMoreFilters = (): void => {
    setFiltersOpened(false);
  };

  const inputSearch = (text: string): void => {
    dispatch(
      setFilters(
        {
          ...filters.filters,
          [inputKey as string]: text,
        },
        filterModel as keyof FiltersState,
      ),
    );
  };

  const handleMoreFiltersApply = (): void => {
    dispatch(
      setFilters(
        {
          ...filters.filters,
          ...moreFiltersValue,
        },
        filterModel as keyof FiltersState,
      ),
    );
    closeMoreFilters();
  };

  const onClearFilterChip = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters.filters,
          [prop]: value,
        },
        filterModel as keyof FiltersState,
      ),
    );
    if (prop === 'company_id') {
      updateHierarchyFilters(value);
    }
    if (prop === 'ancestor_company_id') {
      updateHierarchyFilters(value, true);
    }
  };

  const toggleCaseSensitive = (): void => {
    dispatch(
      setCaseSensitive(
        !stateFilters.case_sensitive,
        filterModel as keyof FiltersState,
      ),
    );
  };

  return (
    <Grid item xs={12} className={clsx('px-8 shadow', classes.container)}>
      <div className={clsx('py-6', classes.content)}>
        <Grid container spacing={2}>
          {hasSearchInput && (
            <Grid item xs={12} md={4}>
              <MainFilterInput
                placeholder={inputPlaceholder ?? ''}
                defaultInput={
                  filters.filters ? filters.filters[inputKey as string] : ''
                }
                caseSensitive={stateFilters.case_sensitive}
                showCaseSensitive={showCaseSensitive}
                searchCallback={inputSearch}
                toggleCaseSensitive={toggleCaseSensitive}
              />
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {firstFilter && (
                <Grid item xs={12} md={3}>
                  {firstFilter}
                </Grid>
              )}
              {secondFilter && (
                <Grid item xs={12} md={3}>
                  {secondFilter}
                </Grid>
              )}
              {thirdFilter && (
                <Grid item xs={12} md={3}>
                  {thirdFilter}
                </Grid>
              )}
              {moreFilters && (
                <Grid item xs={12} md={3}>
                  <Button
                    variant="outlined"
                    size="large"
                    endIcon={<FilterListIcon />}
                    onClick={openMoreFilters}
                  >
                    More Filters
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!hideFiltersAppliedRow && (
            <Grid item xs={12} className={classes.resultsAndChips}>
              <Typography
                data-cy="search-total-results"
                variant="overline"
                className="mr-2"
              >
                Total results: {total}.
              </Typography>
              {filtersApplied && (
                <>
                  <Typography variant="overline" className="mr-2">
                    Filters:
                  </Typography>
                  <div className={clsx('scrollbar', classes.chipsContainer)}>
                    <FiltersChips
                      filters={filters.filters ?? {}}
                      onChangeFiltersChips={onClearFilterChip}
                      filterModel={filterModel}
                      mainFilterInputKey={inputKey}
                    />
                  </div>
                  <Button
                    data-cy="clear-all-button"
                    color="primary"
                    variant="text"
                    type="button"
                    onClick={resetFilters}
                  >
                    <Typography variant="button">Clear all</Typography>
                  </Button>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </div>
      {moreFilters && (
        <MoreFiltersDrawer
          open={filtersOpened}
          content={moreFilters}
          loading={moreFiltersApplyLoading}
          handleCloseDrawer={closeMoreFilters}
          applyFilters={handleMoreFiltersApply}
        />
      )}
    </Grid>
  );
};

export default Filters;
