import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { Companies, Company } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setActualCompany } from '../../redux/reducers/companies.reducer';
import {
  setUserCompanies,
  setUserCompany,
} from '../../redux/reducers/user.reducer';
import FooterBar from '../../components/FooterBar';
import { setAlert } from '../../redux/reducers/alert.reducer';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import {
  heartbeatColorThemeMap,
  accountsDetailsTabsLabel,
  errorHighlight,
} from '../../app/constants';
import AccountDetails from './accountDetails';
import AccountSharedData from './accountSharedData';
import AccountMetadata from './accountMetaData';
import UIConfiguration from './uiConfiguration';

const AccountContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userCompaniesState = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );
  const selectedUserCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const { id } = useParams<string>();
  const companyData = useAppSelector(
    (state: RootState) => state.companies.company,
  );
  const newCompany = useAppSelector(
    (state: RootState) => state.companies.newCompany,
  );
  const { user } = useAppSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState<File>();

  const userCompanyParent = (parentId: string | null): Company | null => {
    const parent: Company | null =
      userCompaniesState?.find(
        (company: Company) => company._id === parentId,
      ) || null;
    return parent;
  };
  const parentCompany = userCompanyParent(companyData?.company_id || null);
  const [tabs, setTabs] = useState<Record<string, JSX.Element>>({
    details: <AccountDetails uploadedLogo={logo} setUploadedLogo={setLogo} />,
    metadata: <AccountMetadata />,
    sharedData: <AccountSharedData />,
    uiConfiguration: <UIConfiguration />,
  });

  useEffect(() => {
    setTabs({
      ...tabs,
      details: <AccountDetails uploadedLogo={logo} setUploadedLogo={setLogo} />,
    });
  }, [logo]);

  useEffect(() => {
    if (user?.company_id === id) {
      setTabs({
        details: (
          <AccountDetails uploadedLogo={logo} setUploadedLogo={setLogo} />
        ),
        metadata: <AccountMetadata />,
        uiConfiguration: <UIConfiguration />,
      });
    }
  }, [user, id]);

  useEffect(() => {
    if (companyData && companyData._id === id) {
      dispatch(setActualCompany(companyData));
    } else if (id) {
      Companies.getOneById(id)
        .then((response) => {
          dispatch(setActualCompany(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [companyData]);

  const handleDeleteCompany = (): void => {
    if (!companyData) {
      return;
    }
    setLoading(true);
    Companies.delete(companyData._id)
      .then(() => {
        dispatch(
          setUserCompanies(
            userCompaniesState.filter(
              (company) => company._id !== companyData._id,
            ),
          ),
        );
        dispatch(
          setAlert({
            message: `Account ${companyData.name} successfully deleted.`,
            type: 'success',
          }),
        );
        navigate('/accounts');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateAccountState = (updatedContent: Company | null): void => {
    if (updatedContent) {
      if (userCompaniesState) {
        const foundIndex = userCompaniesState.findIndex(
          (e) => e._id === updatedContent._id,
        );
        userCompaniesState[foundIndex] = updatedContent;
        if (foundIndex >= 0) {
          dispatch(setUserCompanies([...userCompaniesState]));
        }
      }
    }
  };

  const handleSaveChanges = (): void => {
    if (newCompany !== companyData) {
      setLoading(true);
      Companies.update(newCompany as Company)
        .then((response) => {
          uploadCompanyLogo(response._id);
          dispatch(setActualCompany(response));
          handleUpdateAccountState(response);
          if (
            selectedUserCompany &&
            selectedUserCompany._id === newCompany?._id
          ) {
            dispatch(setUserCompany(response));
          }
          dispatch(
            setAlert({
              highlight: 'Update account',
              message: 'Account successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!!logo && newCompany) {
      setLoading(true);
      uploadCompanyLogo(newCompany._id, true);
    }
  };

  const uploadCompanyLogo = (
    newCompanyId: string,
    showMessage = false,
  ): void => {
    if (logo) {
      Companies.uploadCompanyLogo(newCompanyId, logo)
        .then((result) => {
          // The message only shows when only the logo is updated
          if (showMessage) {
            if (newCompany && newCompany.branding) {
              dispatch(
                setActualCompany({
                  ...newCompany,
                  branding: {
                    ...newCompany?.branding,
                    logo_url: result,
                  },
                }),
              );
            }
            // If the account being updated is the same as the the user account, aka the account the user belongs to,
            // then the logo on the menu should be changed and this will make sure it does
            if (
              selectedUserCompany &&
              selectedUserCompany.branding &&
              selectedUserCompany._id === newCompany?._id
            ) {
              dispatch(
                setUserCompany({
                  ...selectedUserCompany,
                  branding: {
                    ...selectedUserCompany.branding,
                    logo_url: result,
                  },
                }),
              );
            }
            dispatch(
              setAlert({
                message: 'Account logo successfully updated.',
                type: 'success',
              }),
            );
            // Once the logo is uploaded remove the file from the state
            setLogo(undefined);
          }
        })
        .catch((error) => {
          if (showMessage) {
            dispatch(
              setAlert({
                highlight: errorHighlight,
                message: error.message,
                type: 'error',
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const disableSave = (): boolean => {
    return isEqual(newCompany, companyData) && !logo;
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={12}>
        <Header title="Company content" goBack="accounts" model="company" />
      </Grid>
      <Grid item xs={12}>
        {companyData && (
          <Box className="content-page-container">
            <ContentHeader
              contentType="company"
              extraImage={parentCompany?.branding?.logo_url}
              extraSubtitle={parentCompany?._id}
              extraSubtitleTooltip={`Parent account ID: ${parentCompany?._id}`}
              extraTitle={parentCompany?.name}
              extraTitleTooltip={`Parent account name: ${parentCompany?.name}`}
              image={companyData.branding?.logo_url}
              subtitle={companyData._id}
              subtitleTooltip={companyData._id}
              tagTheme={heartbeatColorThemeMap}
              title={companyData.name}
              titleTooltip={companyData.name}
              copySubtitleToClipboard={true}
              copyExtraSubtitleToClipboard={true}
            />

            <VerticalTabs
              tabs={tabs}
              defaultTab="details"
              tabsLabel={accountsDetailsTabsLabel}
            />
          </Box>
        )}
      </Grid>
      <FooterBar
        deleteModalContent="You are about to delete this account"
        loading={loading}
        disableSaveButton={disableSave()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteCompany}
      />
    </Grid>
  );
};

export default AccountContent;
