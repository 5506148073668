import React from 'react';
import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setDeviceTransferType } from '../../redux/reducers/deviceTransfer.reducer';
import { RootState } from '../../redux/store';
import { TRANFERS_RECEIVED, TRANFERS_SENT } from '../../app/constants';
import useStyles from './styles';

const TranfersType: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const tranferState = useAppSelector(
    (state: RootState) => state.deviceTransfer,
  );
  const onChangeTranferType = (type: string) => (): void => {
    dispatch(setDeviceTransferType(type));
  };

  return (
    <>
      <Button
        variant="text"
        type="button"
        onClick={onChangeTranferType(TRANFERS_RECEIVED)}
        className={clsx('ml-4', classes.typeButton, {
          [classes.chosenTypeButton]:
            tranferState.deviceTransferType === TRANFERS_RECEIVED,
        })}
      >
        <Typography variant="button">Received</Typography>
      </Button>
      <Button
        variant="text"
        type="button"
        onClick={onChangeTranferType(TRANFERS_SENT)}
        className={clsx('ml-4', classes.typeButton, {
          [classes.chosenTypeButton]:
            tranferState.deviceTransferType === TRANFERS_SENT,
        })}
      >
        <Typography variant="button">Sent</Typography>
      </Button>
    </>
  );
};

export default TranfersType;
