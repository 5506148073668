import React from 'react';
import { Grid, IconButton, Button } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SwitchButton from '../../../../components/SwitchButton';
import TextInput from '../../../../components/TextInput';

interface GcpCloudSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
  addDeviceTypeMapping: () => void;
  removeDeviceTypeMapping: (index: number) => void;
}

const GcpCloudSection: React.FC<GcpCloudSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
  addDeviceTypeMapping,
  removeDeviceTypeMapping,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Subscription Id"
          placeholder=""
          prop="listener.subscription_id"
          required={true}
          disabled={disabled}
          value={newIngestor.listener.subscription_id}
          onInputChange={onInputChange}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Integration Id"
          placeholder=""
          prop="listener.integration_id"
          required={true}
          disabled={disabled}
          value={newIngestor.listener.integration_id}
          onInputChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <SwitchButton
          label="Auto Discover Device on Unrecognized Device ID"
          prop="listener.create_discovered_device"
          value={newIngestor.listener.create_discovered_device}
          onSwitchChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Discovered Device Heartbeat Period Keypath"
          prop="listener.heartbeat_period"
          placeholder=""
          required={true}
          disabled={disabled}
          value={newIngestor.listener.heartbeat_period}
          onInputChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <SwitchButton
          label="Auto Promote Discovered Device"
          prop="listener.auto_promote_discovered_device"
          value={newIngestor.listener.auto_promote_discovered_device}
          onSwitchChange={onInputChange}
        />
      </Grid>

      {Array.isArray(newIngestor.listener.device_type_mapping) &&
        newIngestor.listener.device_type_mapping.map((item, index) => (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className="mt-2 ml-0"
            alignItems="center"
          >
            <Grid item xs={3}>
              <TextInput
                label="Key Path"
                placeholder=""
                prop={`device_type_mapping.key.${index}`}
                value={item.key}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label="Value"
                placeholder=""
                prop={`device_type_mapping.value.${index}`}
                value={item.value}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label="Device Type Id"
                placeholder=""
                prop={`device_type_mapping.device_type_id.${index}`}
                value={item.device_type_id}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className="mt-6">
              <IconButton onClick={(): void => removeDeviceTypeMapping(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4 ml-4"
        onClick={addDeviceTypeMapping}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </>
  );
};

export default GcpCloudSection;
