import React from 'react';
import { MenuItem } from '@mui/material';

import Filters from '../../containers/Filters';
import SelectInput from '../../components/SelectInput';
import {
  DEVICE_METADATA_REPORT_TAG_KEY,
  GrafanaDashboardResults,
} from './Reports';
import { Device } from '@edgeiq/edgeiq-api-js';

type Props = {
  dashboards: GrafanaDashboardResults[];
  devices: Device[];
  filterDates: Array<{ display: string; value: string }>;
  handleFilterChange: (prop: string, value: string) => void;
  dashboard: string | undefined;
  timeframeSelected: string | undefined;
  selectedDevice: string;
};
const ReportFilter: React.FC<Props> = ({
  dashboards,
  devices,
  filterDates,
  handleFilterChange,
  dashboard = '',
  timeframeSelected = '',
  selectedDevice = '',
}) => {
  const changeFilters = (prop: string, value: string): void => {
    handleFilterChange(prop, value);
  };

  return (
    <Filters
      hideFiltersAppliedRow={true}
      hasSearchInput={false}
      inputPlaceholder="Search device profiles by Name"
      inputKey="name"
      filterModel="devicesTypes"
      firstFilter={
        <SelectInput
          prop="dashboard"
          value={dashboard}
          placeholder="Dashboard"
          options={dashboards.map((dashboardEle, key) => (
            <MenuItem dense key={key} value={dashboardEle.url}>
              {dashboardEle.title}
            </MenuItem>
          ))}
          onSelectChange={changeFilters}
        />
      }
      secondFilter={
        <SelectInput
          prop="device"
          value={selectedDevice}
          placeholder="Device"
          options={devices.map((device, key) => (
            <MenuItem
              dense
              key={key}
              value={
                (device.metadata
                  ? device.metadata[DEVICE_METADATA_REPORT_TAG_KEY]
                  : null) as string
              }
            >
              {device.name}
            </MenuItem>
          ))}
          onSelectChange={changeFilters}
        />
      }
      thirdFilter={
        <SelectInput
          prop="timeframe"
          value={timeframeSelected}
          placeholder="Time frame"
          options={filterDates.map((filterDate, key) => (
            <MenuItem dense key={key} value={filterDate.value}>
              {filterDate.display}
            </MenuItem>
          ))}
          onSelectChange={changeFilters}
        />
      }
    />
  );
};

export default ReportFilter;
