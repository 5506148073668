import React from 'react';
import { Grid } from '@mui/material';
import { Configuration } from '@edgeiq/edgeiq-api-js';

import ConfigurationForm from '../../../containers/Forms/ConfigurationForm';
import SettingApplicationList from '../../../containers/SettingApplicationList';

interface ConfigurationDetailsProp {
  configuration: Configuration;
  onInputChange: (prop: string, value: string | number) => void;
}

const ConfigurationDetails: React.FC<ConfigurationDetailsProp> = ({
  configuration,
  onInputChange,
}) => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={8}>
        <ConfigurationForm
          action="edit"
          addPaper={true}
          onInputChange={onInputChange}
          configuration={(configuration as Configuration) || undefined}
        />
      </Grid>

      <Grid item xs={4}>
        <SettingApplicationList configuration={configuration} />
      </Grid>
    </Grid>
  );
};

export default ConfigurationDetails;
