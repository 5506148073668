import { Company, User, UserType } from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../components/SharedTable';

export const UserColumns = (
  userTypes?: UserType[],
  userCompanies?: Company[],
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const user = item as User;
        return `${user.first_name} ${user.last_name}`;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const user = item as User;
        return `/user/${user._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const user = item as User;
        return user.email;
      },
      id: 'email',
      label: 'Email',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const user = item as User;
        const userType = userTypes?.find(
          (type) => type._id === user.user_type_id,
        );
        return userType?.name || '';
      },
      id: 'role',
      label: 'Role',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const user = item as User;
        const company = userCompanies?.find(
          (userCompany) => userCompany._id === user.company_id,
        );
        return company?.name || '';
      },
      id: 'company',
      label: 'Account',
      type: 'text',
    },
  ];
};
