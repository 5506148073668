import React from 'react';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import useStyles from './styles';

export interface FooterDeleteButtonProps {
  handleDelete: () => void;
}

export const FooterDeleteButton: React.FC<FooterDeleteButtonProps> = ({
  handleDelete,
}) => {
  const classes = useStyles({
    height: '76',
    sticky: true,
  });

  return (
    <Button
      data-testid="footer-delete-button"
      data-cy="footer-delete-button"
      color="error"
      size="large"
      variant="outlined"
      className={classes.errorButton}
      onClick={handleDelete}
      startIcon={<DeleteIcon />}
    >
      Delete
    </Button>
  );
};

export interface FooterSaveButtonProps {
  disabled: boolean;
  loading: boolean;
  handleSave: () => void;
}

export const FooterSaveButton: React.FC<FooterSaveButtonProps> = ({
  disabled,
  loading,
  handleSave,
}) => {
  return (
    <LoadingButton
      data-testid="footer-save-button"
      data-cy="footer-save-button"
      size="large"
      variant="contained"
      disabled={disabled}
      loading={loading}
      onClick={handleSave}
      startIcon={<SaveIcon />}
    >
      Save changes
    </LoadingButton>
  );
};
