import React from 'react';
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import clsx from 'clsx';

import BasicDialog from '../BasicDialog';
import useStyles from './styles';

export interface ActionDialogProps {
  open: boolean;
  loading: boolean;
  title?: string;
  content?: string | JSX.Element;
  cancelButtonLabel?: string;
  actionButtonLabel?: string;
  actionButtonColor?:
    | 'inherit'
    | 'primary'
    | 'success'
    | 'warning'
    | 'info'
    | 'error'
    | 'secondary'
    | undefined;
  actionButtonVariant?: 'contained' | 'outlined' | 'text' | undefined;
  onCloseCallback: () => void;
  onDeleteCallback: () => void;
  hideCancelButton?: boolean;
}

const ActionDialog: React.FC<ActionDialogProps> = ({
  open,
  loading,
  title,
  content,
  cancelButtonLabel,
  actionButtonLabel,
  actionButtonColor = 'error',
  actionButtonVariant = 'outlined',
  onCloseCallback,
  onDeleteCallback,
  hideCancelButton,
}) => {
  const classes = useStyles();

  const handleCancel = (): void => {
    onCloseCallback();
  };

  const handleDelete = (): void => {
    onDeleteCallback();
  };

  return (
    <BasicDialog
      open={open}
      onClose={handleCancel}
      title={title ?? 'Are you sure?'}
      content={content ?? 'You are about to delete this item'}
      actions={
        <Box className={clsx(classes.container)}>
          {!hideCancelButton && (
            <Button
              data-cy="dialog-button-cancel"
              data-testid="btnCancel"
              size="large"
              variant="outlined"
              onClick={handleCancel}
              fullWidth
            >
              {cancelButtonLabel ?? 'Cancel'}
            </Button>
          )}
          <LoadingButton
            data-cy="dialog-button-delete"
            loading={loading}
            fullWidth
            size="large"
            variant={actionButtonVariant}
            color={actionButtonColor}
            className={clsx('ml-6', classes.button)}
            data-testid="btnLoading"
            onClick={handleDelete}
          >
            {actionButtonLabel ?? 'Delete'}
          </LoadingButton>
        </Box>
      }
    />
  );
};

export default ActionDialog;
