import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    optionBox: {
      width: '33%',
    },
    optionsGrid: {
      alignContent: 'space-between',
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
);

export default useStyles;
