import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  MIBObject,
  PollableAttribute,
  PollableAttributeInput,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  setNewPollableAttribute,
  setNewPollableAttributesInput,
} from '../../../redux/reducers/pollableAttributes.reducer';
import TextInput from '../../../components/TextInput';
import NestedObject from '../../../components/NestedObject';
import {
  NestedObjectInput,
  NestedObjectItem,
} from '../../../components/NestedObject/NestedObject';

interface PollableAttributeMibFormProps {
  isNew?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
}

const mibInputs: NestedObjectInput[] = [
  {
    columns: 4,
    inputKey: 'name',
    isNameInput: true,
    label: 'Name',
    required: true,
    type: 'string',
  },
  {
    columns: 4,
    inputKey: 'type',
    label: 'Type',
    required: true,
    type: 'string',
  },
  {
    columns: 4,
    inputKey: 'oid',
    label: 'OID',
    required: true,
    type: 'string',
  },
  {
    columns: 12,
    defaultValue: true,
    inputKey: 'enabled',
    label: 'Enabled',
    required: true,
    type: 'boolean',
  },
  {
    columns: 12,
    inputKey: 'enabled_children',
    isEnableNestedItems: true,
    label: 'Enable children',
    required: false,
    type: 'boolean',
  },
];

const mibObjectsToNestedObject = (
  objects?: MIBObject[],
  index?: string,
): NestedObjectItem[] => {
  if (!objects || objects?.length === 0) {
    return [];
  }
  const items: NestedObjectItem[] = objects.map((mibObject, subIndex) => {
    const indexToUse = index ? `${index}.${subIndex}` : `${subIndex}`;
    return {
      collapsed: false,
      data: {
        enabled: mibObject.enabled,
        name: mibObject.name,
        oid: mibObject.oid,
        type: mibObject.type,
      },
      index: indexToUse,
      items: mibObjectsToNestedObject(mibObject.objects, indexToUse),
    };
  });

  return items;
};

const nestedObjectsToMibObjects = (items?: NestedObjectItem[]): MIBObject[] => {
  if (!items || items.length === 0) {
    return [];
  }

  const objects: MIBObject[] = items.map((nestedObjectItem) => {
    return {
      enabled: nestedObjectItem.data.enabled as boolean,
      name: nestedObjectItem.data.name as string,
      objects: nestedObjectsToMibObjects(nestedObjectItem.items),
      oid: nestedObjectItem.data.oid as string,
      type: nestedObjectItem.data.type as string,
    };
  });

  return objects;
};

const PollableAttributeMibForm: React.FC<PollableAttributeMibFormProps> = ({
  isNew = false,
  onInputChange,
}) => {
  const dispatch = useAppDispatch();
  const pollableState = useAppSelector(
    (state: RootState) => state.pollableAttributes,
  );
  const { newPollableAttribute, newPollableAttributeInput } = pollableState;

  const handleMibChange = (items: NestedObjectItem[]): void => {
    let newPollable = isNew ? newPollableAttributeInput : newPollableAttribute;
    if (newPollable) {
      newPollable = {
        ...newPollable,
        mib: {
          identity: isNew
            ? (newPollableAttributeInput?.mib?.identity as string)
            : (newPollableAttribute?.mib?.identity as string),
          objects: nestedObjectsToMibObjects(items),
        },
      };
    }
    dispatch(
      isNew
        ? setNewPollableAttributesInput(newPollable as PollableAttributeInput)
        : setNewPollableAttribute(newPollable as PollableAttribute),
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} className={clsx('mb-6')}>
        <Typography variant="h5" className="fw-700">
          MIB
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4} className={clsx('mb-6')}>
        <TextInput
          prop="mib.identity"
          label="Identity"
          placeholder="Identity"
          type="text"
          value={
            (isNew
              ? newPollableAttributeInput?.mib?.identity
              : newPollableAttribute?.mib?.identity) || ''
          }
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <NestedObject
          explorerNameKey="name"
          hasEnabledItems={true}
          items={mibObjectsToNestedObject(
            isNew
              ? newPollableAttributeInput?.mib?.objects
              : newPollableAttribute?.mib?.objects,
          )}
          inputs={mibInputs}
          onUpdatingNestedItems={handleMibChange}
        />
      </Grid>
    </Grid>
  );
};

export default PollableAttributeMibForm;
