import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      '& .checkboxContainer': {
        '&.noCheckbox': {
          display: 'none',
        },
        display: 'block',
      },
      '& .tag': {
        width: '90%',
      },
      display: 'flex',
      height: '100%',
      position: 'relative',
    },
    cardContent: {
      flexGrow: 1,
      // 28 is the width of the checkbox part
      maxWidth: 'calc(100% - 28px)',
    },
    checkboxContainer: {
      display: 'block',
    },
    highlight: {
      alignItems: 'center',
      color: theme.palette.warning.dark,
      display: 'flex',
      textTransform: 'uppercase',
    },
    hoverCard: {
      cursor: 'pointer',
    },
    scriptContainer: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.white,
      lineHeight: '20px',
    },
    statusContainer: {
      position: 'absolute',
      right: '16px',
      top: '16px',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    tag: {
      alignItems: 'center',
      color: theme.palette.grey[100],
      display: 'flex',
    },
    tagIcon: {
      color: theme.palette.grey[200],
    },
    title: {
      '&.with-status': {
        width: 'calc(100% - 50px)',
      },
      color: theme.palette.grey[100],
      fontWeight: 700,
    },
  }),
);

export default useStyles;
