import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import useStyles from './styles';

interface MainFilterInputProps {
  placeholder: string;
  defaultInput?: string | undefined;
  caseSensitive: boolean;
  showCaseSensitive?: boolean;
  searchCallback?: (text: string) => void;
  toggleCaseSensitive: () => void;
}

const MainFilterInput: React.FC<MainFilterInputProps> = ({
  placeholder,
  defaultInput,
  caseSensitive,
  showCaseSensitive = true,
  searchCallback,
  toggleCaseSensitive,
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const preInputValue = useRef<string>('');

  useEffect(() => {
    if (
      (searchValue.length > 2 || (!!preInputValue.current && !searchValue)) &&
      searchCallback
    ) {
      searchCallback(searchValue);
    }

    preInputValue.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    // When the default input changes that means the filters values themselves have been changed.
    // It is only useful when rendering or when the user clears all filters. So what we want is to set the inputValue to an empty string whenever the
    // default value changes to undefined, when the default value changes to something else, it actually came from this same component and there is no need
    // to set it back again
    if (defaultInput === undefined) {
      setInputValue('');
    } else {
      setInputValue(defaultInput);
    }
  }, [defaultInput]);

  const setSearchValueDebounce = useRef(debounce(setSearchValue, 300));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    setSearchValueDebounce.current(event.target.value);
  };

  const handleClickClear = (): void => {
    setInputValue('');
    setSearchValue('');
    if (searchCallback) {
      searchCallback('');
    }
  };

  const handleCaseSensitive = (): void => {
    toggleCaseSensitive();
  };

  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" className={classes.searchInputContainer}>
      <OutlinedInput
        className={classes.searchInput}
        placeholder={placeholder}
        type="text"
        value={inputValue}
        onChange={handleChange}
        data-cy="name-filter"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          <>
            {showCaseSensitive && (
              <InputAdornment data-cy="case-sensitive-filter" position="end">
                <Tooltip placement="top" title="Match Case">
                  <IconButton
                    aria-label="clear-name-filter"
                    edge="end"
                    size="small"
                    className={clsx(classes.matchCaseIcon, {
                      ['active']: caseSensitive,
                    })}
                    onClick={handleCaseSensitive}
                    onMouseDown={handleMouseDown}
                  >
                    <Typography variant="overline">Aa</Typography>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )}
            <InputAdornment data-cy="clear-name-filter" position="end">
              {inputValue && (
                <IconButton
                  aria-label="clear-name-filter"
                  edge="end"
                  size="small"
                  onClick={handleClickClear}
                  onMouseDown={handleMouseDown}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </InputAdornment>
          </>
        }
      />
    </FormControl>
  );
};

export default MainFilterInput;
