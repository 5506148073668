import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    account: {
      alignItems: 'center',
      display: 'flex',
    },
    avatarName: {
      color: theme.palette.grey[200],
    },
    cardContainer: {
      backgroundColor: theme.palette.common.white,
    },
    email: {
      color: theme.palette.grey[200],
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    input: {
      height: '44px',
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    role: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[100],
      display: 'inline-flex',
      textTransform: 'uppercase',
    },
  }),
);

export default useStyles;
