import { EscrowDevice } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../../components/SharedTable';
import TypographyWithCopy from '../../../components/TypographyWithCopy';

export const EscrowColumns: TableColumn[] = [
  {
    id: 'unique_id',
    label: 'Unique ID',
    parser: (item: TableItemType): string | React.ReactElement => {
      const device = item as EscrowDevice;
      if (!device.unique_id) {
        return '--';
      }
      return (
        <TypographyWithCopy
          component="div"
          dataCy={`copy-escrow-device-id-${device.unique_id}-table`}
          text={device.unique_id}
          // text={`${
          //   device.unique_id.length > 10 ? '...' : ''
          // }${device.unique_id.replace(/.(?=.{10})/g, '')}`}
          textToCopy={device.unique_id}
          tooltipText={device.unique_id}
          typographyVariant="button"
          textMaxWidth={300}
        />
      );
    },
    type: 'custom',
  },
  {
    cellValue: (item): string => {
      const device = item as EscrowDevice;
      return device.token;
    },
    id: 'token',
    label: 'Token',
    type: 'text',
  },
  {
    cellValue: (item): string => {
      const device = item as EscrowDevice;
      return device.transfer_initiated_at
        ? 'Device transfer initiated'
        : device.transfer_completed_at
        ? 'Device transfer completed'
        : 'Device transfer not yet initiated';
    },
    id: 'transfer_status',
    label: 'Transfer Status',
    type: 'text',
  },
  {
    cellValue: (item): string => {
      const device = item as EscrowDevice;
      return device.serial ?? '--';
    },
    id: 'serial',
    label: 'Serial Number',
    type: 'text',
  },
  {
    cellValue: (item): string => {
      const device = item as EscrowDevice;
      return device.part_number ?? '--';
    },
    id: 'part_number',
    label: 'Part Number',
    type: 'text',
  },
];
