import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import {
  SoftwareUpdate,
  SoftwareUpdates,
  SoftwareUpdatesFilters,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
  warningHighlight,
} from '../../../app/constants';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemCard from '../AttachItems/AttachItemCard';
import SoftwareUpdateCard from '../../../pages/softwarePackages/SoftwarePackageCard';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import { AdaptedSoftwareUpdate } from '../../../models/softwarePackage';

interface SoftwareUpdateDrawerProps {
  device_type_id?: string;
  selectedSoftwareUpdates: string[];
  filters?: SoftwareUpdatesFilters;
  open: boolean;
  onCloseDrawer: () => void;
  onChoosingSoftwareUpdate: (softwareUpdates: SoftwareUpdate[]) => void;
  hasItems?: boolean;
  hideSelectAll?: boolean;
}

const SoftwareUpdateDrawer: React.FC<SoftwareUpdateDrawerProps> = ({
  device_type_id,
  selectedSoftwareUpdates = [],
  filters,
  open,
  onCloseDrawer,
  onChoosingSoftwareUpdate,
  hideSelectAll = true,
}) => {
  const dispatch = useAppDispatch();

  const [softwareUpdateList, setSoftwareUpdateList] = useState<
    AdaptedSoftwareUpdate[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedSoftwareUpdates ? selectedSoftwareUpdates : [],
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedItems(selectedSoftwareUpdates);
  }, [selectedSoftwareUpdates]);

  const getSoftwareUpdates = (searchName?: string): void => {
    setLoading(true);
    filters = {};
    if (device_type_id) {
      filters.device_type_id = {
        operator: 'eq',
        value: device_type_id,
      };
    }

    if (searchName) {
      filters.name = {
        operator: 'like',
        value: searchName,
      };
    }

    SoftwareUpdates.list(filters, optionsPaginationsFilter)
      .then((result) => {
        let results = result.softwareUpdates;
        if (device_type_id) {
          results = results.map((sw) => {
            return {
              ...sw,
              is_from_device_type: true,
            };
          });
        }
        setSoftwareUpdateList(results);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSoftwareUpdates();
  }, []);

  const handleOnChangeCallback = (value: string): void => {
    getSoftwareUpdates(value);
  };

  const checkSoftwareUpdateCallback =
    (softwareUpdateId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const fromDeviceType = softwareUpdateList.find(
        (originalSw) =>
          originalSw._id === softwareUpdateId && originalSw.is_from_device_type,
      );
      if (fromDeviceType) {
        return dispatch(
          setAlert({
            highlight: warningHighlight,
            message:
              "You can't remove a sofware update that belongs to the Device Profile.",
            type: 'warning',
          }),
        );
      }

      if (checked) {
        setSelectedItems([softwareUpdateId]);
      } else {
        setSelectedItems([]);
      }
    };

  const handleSoftwareUpdteCallback = (): void => {
    onChoosingSoftwareUpdate(
      softwareUpdateList.filter((softwareUpdate) =>
        selectedItems.includes(softwareUpdate._id),
      ),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedItems.length !== softwareUpdateList.length) {
      setSelectedItems(
        softwareUpdateList.map((softwareUpdate) => softwareUpdate._id),
      );
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Select"
      title="Select Software Update"
      // eslint-disable-next-line no-constant-binary-expression
      disableAction={true || selectedItems.length === 0}
      actionCallback={handleSoftwareUpdteCallback}
      onCloseDrawer={onCloseDrawer}
      size="800"
      content={
        <AttachItemsLayout
          allSelected={selectedItems.length === softwareUpdateList.length}
          itemsSelected={selectedItems.length !== 0}
          hasItems={!!softwareUpdateList.length}
          hideSelectAll={hideSelectAll}
          searchPlaceholder="Search software update"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={softwareUpdateList.map((softwareUpdate) => (
                  <AttachItemCard
                    content={
                      <SoftwareUpdateCard softwareUpdate={softwareUpdate} />
                    }
                    checked={selectedItems.includes(softwareUpdate._id)}
                    checkboxCallback={checkSoftwareUpdateCallback}
                    id={softwareUpdate._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default SoftwareUpdateDrawer;
