import React, { useEffect } from 'react';
import {
  Connection,
  NetworkConnection,
  IPTableAction,
} from '@edgeiq/edgeiq-api-js';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import SideTabs from '../../../../components/SideTabs';
import Accordion from '../../../../components/Accordion';
import { DEVICE_CONNECTION_TYPE } from '../../../../constants/configConnections';
import EthernetWanSection from '../DeviceConfigConnectionSection/EthernetWanSection';
import DeviceConfigConnectionCellularForm from '../DeviceConfigConnectionSection/CellularSection';
import DeviceConfigConnectionWifiForm from '../DeviceConfigConnectionSection/WifiSection';
import DeviceConfigConnectionIPTableForm from '../DeviceConfigConnectionSection/IPTableSection';

interface DeviceConfigConnectionFormProps {
  deviceNetConnections?: Connection[] | NetworkConnection[];
  iptables?: (IPTableAction | undefined)[];
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
  onAddNewIPTable?: () => void;
  onRemoveIPTableValue: (ipTableValueIndex: number) => void;
}

interface RefactoredConnection {
  connections: Connection[];
  index: number;
}

const DeviceConfigConnectionForm: React.FC<DeviceConfigConnectionFormProps> = ({
  deviceNetConnections,
  iptables,
  onInputChange,
  onAddNewIPTable,
  onRemoveIPTableValue,
}) => {
  const mapConnectionsType = (): { [key: string]: RefactoredConnection } => {
    let connectionCounts: { [key: string]: RefactoredConnection } = {};

    deviceNetConnections?.forEach((current, index) => {
      if (!connectionCounts.hasOwnProperty(current.type as string)) {
        connectionCounts = {
          ...connectionCounts,
          ...{
            [`${current.type}`]: {
              connections: [current] as Connection[],
              index,
            } as RefactoredConnection,
          },
        };
        return;
      }

      connectionCounts = {
        ...connectionCounts,
        ...{
          [current.type]: {
            connections: [
              ...connectionCounts[current.type].connections,
              ...[current],
            ] as Connection[],
            index,
          } as RefactoredConnection,
        },
      };
    });

    return connectionCounts;
  };

  useEffect(() => {
    mapConnectionsType();
  }, [deviceNetConnections]);

  const ethernetComponentList = (): JSX.Element[] | JSX.Element => {
    const { connections, index } = mapConnectionsType()['ethernet-wan'] || {};

    if (connections) {
      return connections.map((element) => {
        return (
          <Accordion
            key={element.name}
            title={element.name}
            content={
              <EthernetWanSection
                connectionType={DEVICE_CONNECTION_TYPE.ETHERNET_WAN}
                deviceConnection={element.config || undefined}
                configIndex={index}
                onInputChange={onInputChange}
              />
            }
          />
        );
      });
    }
    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ethernetWanComponentList = (): JSX.Element[] | JSX.Element => {
    const { connections, index } =
      mapConnectionsType()['ethernet-wan-lan'] || {};
    if (connections) {
      return connections.map((element) => {
        return (
          <Accordion
            key={element.name}
            title={element.name}
            content={
              <EthernetWanSection
                connectionType={DEVICE_CONNECTION_TYPE.ETHERNET_WAN_LAN}
                deviceConnection={element.config || undefined}
                configIndex={index}
                onInputChange={onInputChange}
              />
            }
          />
        );
      });
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ethernetLanComponentList = (): JSX.Element[] | JSX.Element => {
    const { connections, index } = mapConnectionsType()['ethernet-lan'] || {};
    if (connections) {
      return connections.map((element) => {
        return (
          <Accordion
            key={element.name}
            title={element.name}
            content={
              <EthernetWanSection
                connectionType={DEVICE_CONNECTION_TYPE.ETHERNET_LAN}
                deviceConnection={element.config || undefined}
                configIndex={index}
                onInputChange={onInputChange}
              />
            }
          />
        );
      });
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const cellularComponentList = (): JSX.Element[] | JSX.Element => {
    const { connections, index } = mapConnectionsType().cellular || {};
    if (connections) {
      return connections.map((element) => {
        return (
          <Accordion
            key={element.name}
            title={element.name}
            content={
              <DeviceConfigConnectionCellularForm
                deviceConnection={element.config || undefined}
                configIndex={index}
                onInputChange={onInputChange}
              />
            }
          />
        );
      });
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const wifiComponentList = (): JSX.Element[] | JSX.Element => {
    const { connections, index } = mapConnectionsType().wifi || {};
    if (connections) {
      return connections.map((element) => {
        return (
          <Accordion
            key={element.name}
            title={element.name}
            content={
              <DeviceConfigConnectionWifiForm
                deviceConnection={element.config || undefined}
                configIndex={index}
                onInputChange={onInputChange}
              />
            }
          />
        );
      });
    }

    return (
      <strong>
        This type of connection setting isn't supported for this type of device.
      </strong>
    );
  };

  const ipTableComponentList = (): JSX.Element[] | JSX.Element => {
    return (
      <>
        <Button
          className="w-100"
          variant="outlined"
          size="large"
          onClick={onAddNewIPTable}
          startIcon={<AddIcon />}
          // disabled={disabled}
        >
          Add IP Table Entry
        </Button>
        {iptables?.map((element, index) => {
          return (
            <Accordion
              key={`ip-table-entry-${index}`}
              title={`IP Table Entry (${index})`}
              content={
                <DeviceConfigConnectionIPTableForm
                  deviceConnection={element || undefined}
                  configIndex={index}
                  onInputChange={onInputChange}
                  onRemoveIPTableValue={onRemoveIPTableValue}
                />
              }
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <SideTabs
        badge={{
          cellular: mapConnectionsType().cellular?.connections?.length,
          ethernet_lan:
            mapConnectionsType()['ethernet-lan']?.connections?.length,
          ethernet_wan:
            mapConnectionsType()['ethernet-wan']?.connections?.length,
          ethernet_wan_lan:
            mapConnectionsType()['ethernet-wan-lan']?.connections?.length,
          wifi: mapConnectionsType().wifi?.connections?.length,
        }}
        tabs={{
          cellular: <> {cellularComponentList()} </>,
          ethernet_lan: <> {ethernetLanComponentList()} </>,
          ethernet_wan: <> {ethernetComponentList()} </>,
          ethernet_wan_lan: <> {ethernetWanComponentList()} </>,
          ip_tables: <> {ipTableComponentList()} </>,
          wifi: <> {wifiComponentList()} </>,
        }}
        defaultTab="ethernet_wan"
      />
    </>
  );
};

export default DeviceConfigConnectionForm;
