import { Workflow } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getWorkflowSelector = createSelector(
  (state: WorkflowsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    return state.workflows.find((workflow: Workflow) => workflow._id === id);
  },
);

export const WorkflowsTypes = {
  SET_ACTUAL_WORKFLOW: 'SET_ACTUAL_WORKFLOW',

  SET_OPTIONS_WORKFLOWS: 'SET_OPTIONS_WORKFLOWS',
  SET_SELECTED_WORKFLOWS: 'SET_SELECTED_WORKFLOWS',
  SET_WORKFLOWS: 'SET_WORKFLOWS',
};

export const setActualWorkflow = (workflow: Workflow): WorkflowsAction => ({
  data: workflow,
  type: WorkflowsTypes.SET_ACTUAL_WORKFLOW,
});

export const setStateWorkflows = (workflows: Workflow[]): WorkflowsAction => ({
  data: workflows,
  type: WorkflowsTypes.SET_WORKFLOWS,
});

export const setSelectedWorkflows = (
  workflows: Workflow[],
): WorkflowsAction => ({
  data: workflows,
  type: WorkflowsTypes.SET_SELECTED_WORKFLOWS,
});

export const setOptionsWorkflows = (
  workflows: Workflow[],
): WorkflowsAction => ({
  data: workflows,
  type: WorkflowsTypes.SET_OPTIONS_WORKFLOWS,
});

export type WorkflowsState = {
  workflow: Workflow | null;
  workflows: Workflow[];
  optionsWorkflows: Workflow[];
  selectedWorkflows: Workflow[];
};

// INITIAL STATE
const workflowsInitialState: WorkflowsState = {
  optionsWorkflows: [],
  selectedWorkflows: [],
  workflow: null,
  workflows: [],
};

export type WorkflowsAction = {
  data: Workflow[] | Workflow;
  type: string;
};

const workflowsReducer = (
  state = workflowsInitialState,
  action: WorkflowsAction,
): WorkflowsState => {
  switch (action.type) {
    case WorkflowsTypes.SET_WORKFLOWS:
      return {
        ...state,
        workflows: action.data as Workflow[],
      };

    case WorkflowsTypes.SET_SELECTED_WORKFLOWS:
      return {
        ...state,
        selectedWorkflows: action.data as Workflow[],
      };

    case WorkflowsTypes.SET_ACTUAL_WORKFLOW:
      return {
        ...state,
        workflow: action.data as Workflow,
      };

    case WorkflowsTypes.SET_OPTIONS_WORKFLOWS:
      return {
        ...state,
        optionsWorkflows: action.data as Workflow[],
      };

    default:
      return state;
  }
};

export default workflowsReducer;
