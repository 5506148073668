import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
    },
    infoIcon: {
      color: theme.palette.grey[200],
      verticalAlign: 'middle',
    },
    logo: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
    },
    logoImage: {
      borderRadius: '50%',
      maxWidth: '100%',
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    nameLogo: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
