import { StatusTheme } from '../../models/common';

const AlertTypes = {
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT: 'SET_ALERT',
};

export const setAlert = (alert: AlertData): AlertAction => ({
  data: alert,
  type: AlertTypes.SET_ALERT,
});

export const closeAlert = (show: boolean): AlertAction => ({
  data: show,
  type: AlertTypes.CLOSE_ALERT,
});

export type AlertState = {
  highlight?: string;
  message?: string;
  show: boolean;
  type: StatusTheme;
  link?: string;
  linkText?: string;
};

// INITIAL STATE
export const alertInitialState: AlertState = {
  highlight: '',
  message: '',
  show: false,
  type: 'error',
};

type AlertData = {
  highlight?: string;
  message?: string;
  type: StatusTheme;
  link?: string;
  linkText?: string;
};

export type AlertAction = {
  data: AlertData | boolean;
  type: string;
};

const alertReducer = (
  state = alertInitialState,
  action: AlertAction,
): AlertState => {
  switch (action.type) {
    case AlertTypes.SET_ALERT:
      const alert = action.data as AlertData;
      return {
        ...state,
        highlight: alert.highlight,
        link: alert.link,
        linkText: alert.linkText,
        message: alert.message,
        show: true,
        type: alert.type,
      };

    case AlertTypes.CLOSE_ALERT:
      const { data } = action;
      return {
        ...state,
        show: data as boolean,
      };

    default:
      return state;
  }
};

export default alertReducer;
