import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { opcuaRequestsTypes } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface OpcuaSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
}

const OpcuaSection: React.FC<OpcuaSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Host"
          placeholder="Examples: 192.168.10.44, 192.168.0.1"
          prop="listener.host"
          value={newIngestor.listener.host}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Port"
          prop="listener.port"
          placeholder=""
          value={newIngestor.listener.port}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Timeout (seconds)"
          prop="listener.timeout"
          placeholder=""
          value={newIngestor.listener.timeout}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Poll Interval"
          placeholder=""
          prop="listener.poll_interval"
          value={newIngestor.listener.poll_interval}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <SelectInput
          label="Request Type"
          prop="params.request_type"
          value={newIngestor?.listener?.params?.request_type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(opcuaRequestsTypes).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {opcuaRequestsTypes[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Value"
          placeholder=""
          prop="params.value"
          value={newIngestor?.listener?.params?.value}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Node Id"
          placeholder=""
          prop="params.node_id"
          value={newIngestor?.listener?.params?.node_id}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default OpcuaSection;
