import React from 'react';
import { Button, Grid, IconButton, Typography, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectInput from '../SelectInput';
import TextInput from '../TextInput';
import { ItemList } from './constants';

/**
 * DynamicRows: handlers two inputs and a dropdown if need it, following a common pattern we have in some of the pages
 *
 **/
export interface DynamicRowsProps {
  buttonLabel?: string;
  disabled?: boolean;
  useOnChangeHandlerObjectIndex?: boolean;
  itemKey: string;
  itemValue: string;
  prop: string;
  propLabel?: string;
  itemValuePlaceHolder?: string;
  itemDescriptionPlaceHolder?: string;
  itemValueLabel?: string;
  itemDescriptionLabel?: string;
  /**
    objectRowsTypes: will create the input fields based on a Object key value pair
    @example defaults: {
        key: value;
    };
    component will map an input for the [key] value and an input for the [value] value 
  **/
  objectRowsTypes?: { [key: string]: unknown } | undefined;
  /**
    objectRowsTypes: will create the input fields based on an Array object key value pair
    @example defaults: [{
        name: value, label: value2
    }];
    component will map an input for the [name] value and an input for the [label] value 
  **/
  arrayRowsTypes?: ItemList[];

  dropdownKey?: string;

  /**
    dropDownData: will fill out the dropdown component
    TODO: props could be pass as parameter currently is expecting to have the [key/label] props
  **/
  dropDownData?: ItemList[] | undefined;
  /**
   * dropDownDefaultValue: use it in case you need to set a default value to the dropdown
   **/
  dropDownDefaultValue?: string;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  firstInputType?: string;
  secondInputType?: string;
  onAddRow?: (prop: string) => void;
  onHandleDropDownChange?: (prop: string) => void;
  onRemoveRow?: (prop: string, item: string) => void;
  onRemoveArrayRow?: (prop: string, index: number) => void;
}

const DynamicRows: React.FC<DynamicRowsProps> = ({
  buttonLabel,
  disabled,
  useOnChangeHandlerObjectIndex,
  dropDownData,
  dropDownDefaultValue,
  itemKey,
  itemValue,
  prop,
  propLabel,
  itemValuePlaceHolder,
  itemDescriptionPlaceHolder,
  itemValueLabel,
  itemDescriptionLabel,
  objectRowsTypes,
  arrayRowsTypes,
  onInputChange,
  onAddRow,
  onRemoveRow,
  onRemoveArrayRow,
  firstInputType,
  secondInputType,
  dropdownKey,
}) => {
  const handleDynamicChange =
    (changedProp: string, index: string) =>
    (field: string, value: string | number): void => {
      onInputChange(changedProp, value, field, index);
    };

  const handleClickAddRow = (addRowProp: string) => (): void => {
    if (onAddRow) {
      onAddRow(addRowProp);
    }
  };

  const handleClickRemoveRow =
    (removeRowProp: string, item: string) => (): void => {
      if (onRemoveRow) {
        onRemoveRow(removeRowProp, item);
      }
    };

  const handleClickRemoveArrayRow =
    (removeArrayRowProp: string, index: number) => (): void => {
      if (onRemoveArrayRow) {
        onRemoveArrayRow(removeArrayRowProp, index);
      }
    };

  return (
    <Grid item xs={12} className="mt-6">
      {propLabel && <Typography variant="h5">{propLabel}</Typography>}
      {objectRowsTypes &&
        Object.keys(objectRowsTypes as { [key: string]: string }).map(
          (item, index) => (
            <Grid
              key={index}
              container
              direction="row"
              spacing={2}
              className="mt-2"
              alignItems="center"
            >
              <Grid item xs={4}>
                <TextInput
                  placeholder={
                    itemValuePlaceHolder || `${propLabel} ${itemKey}`
                  }
                  label={itemValueLabel}
                  type={firstInputType || 'text'}
                  prop={itemKey}
                  value={item}
                  onInputChange={handleDynamicChange(
                    prop,
                    useOnChangeHandlerObjectIndex ? index.toString() : item,
                  )}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  placeholder={
                    itemDescriptionPlaceHolder || `${propLabel} ${itemValue}`
                  }
                  label={itemDescriptionLabel}
                  type={secondInputType || 'text'}
                  prop={itemValue}
                  value={objectRowsTypes && (objectRowsTypes[item] as string)}
                  onInputChange={handleDynamicChange(
                    prop,
                    useOnChangeHandlerObjectIndex ? index.toString() : item,
                  )}
                  disabled={disabled}
                />
              </Grid>
              {dropDownData && dropdownKey && (
                <Grid item xs={3}>
                  <SelectInput
                    prop={dropdownKey}
                    value={
                      (objectRowsTypes && (objectRowsTypes[item] as string)) ||
                      dropDownDefaultValue
                    }
                    onSelectChange={handleDynamicChange(
                      prop,
                      useOnChangeHandlerObjectIndex ? index.toString() : item,
                    )}
                    disabled={disabled}
                    options={dropDownData.map((dropdownDataItem) => (
                      <MenuItem
                        dense
                        key={dropdownDataItem.key}
                        value={dropdownDataItem.key}
                      >
                        {dropdownDataItem.label}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              )}
              <Grid item xs={1}>
                <IconButton onClick={handleClickRemoveRow(prop, item)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ),
        )}

      {arrayRowsTypes &&
        arrayRowsTypes.map((item, index) => (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className="mt-2"
            alignItems="center"
          >
            <Grid item xs={4}>
              <TextInput
                placeholder={itemValuePlaceHolder || `${propLabel} ${itemKey}`}
                prop={itemKey}
                label={itemValueLabel}
                value={item.key}
                onInputChange={handleDynamicChange(prop, String(index))}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                placeholder={
                  itemDescriptionPlaceHolder || `${propLabel} ${itemValue}`
                }
                prop={itemValue}
                label={itemDescriptionLabel}
                value={item.label}
                onInputChange={handleDynamicChange(prop, String(index))}
                disabled={disabled}
              />
            </Grid>
            {dropDownData && dropdownKey && (
              <Grid item xs={3}>
                <SelectInput
                  prop={dropdownKey}
                  value={item.type || dropDownDefaultValue}
                  onSelectChange={handleDynamicChange(prop, String(index))}
                  disabled={disabled}
                  options={dropDownData.map((dropdownDataItem) => (
                    <MenuItem
                      dense
                      key={dropdownDataItem.key}
                      value={dropdownDataItem.key}
                    >
                      {dropdownDataItem.label}
                    </MenuItem>
                  ))}
                />
              </Grid>
            )}
            <Grid item xs={1}>
              <IconButton onClick={handleClickRemoveArrayRow(prop, index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4"
        onClick={handleClickAddRow(prop)}
        startIcon={<AddIcon />}
        disabled={disabled}
      >
        Add New {buttonLabel}
      </Button>
    </Grid>
  );
};

export default DynamicRows;
