import React, { useState, useEffect, ReactElement } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Devices, Device } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setActualDevice } from '../../../redux/reducers/devices.reducer';
import { errorHighlight } from '../../../app/constants';
import TabsPage from '../../../components/TabsPage';
import { getLinkFromValue } from '../../../helpers/utils';
import MetadataDrawer, {
  MetadataListProps,
} from '../../../containers/RightDrawer/AttachMetadata/MetadataDrawer';
import useStyles from '../styles';

const DeviceMetadata: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metadataList, setMetadataList] = useState<MetadataListProps[]>();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );

  const handleOpenMetadataLink = (url: string) => (): void => {
    window.open(url, '_blank', 'noreferrer');
  };

  const columns: GridColDef[] = [
    {
      field: 'key',
      flex: 0.5,
      headerName: 'Key',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.key}</strong>
      ),
    },
    {
      field: 'value',
      flex: 0.5,
      headerName: 'Value',
      renderCell: (params: GridRenderCellParams): ReactElement => {
        const value = params.row.value;
        if (value && value.indexOf('http') !== -1) {
          return (
            <Tooltip placement="top-start" title="Open link">
              <Typography
                data-cy={`metadata-link-${params.row.key}`}
                variant="button"
                component="div"
                noWrap
                className={clsx('mb-1', classes.linkButton)}
                onClick={handleOpenMetadataLink(getLinkFromValue(value))}
              >
                {value}
              </Typography>
            </Tooltip>
          );
        }
        return <strong>{params.row.value}</strong>;
      },
    },
  ];

  useEffect(() => {
    const auxArray = [];

    if (editableDevice?.metadata) {
      for (const [key, value] of Object.entries(editableDevice.metadata)) {
        if (key) {
          auxArray.push({ key, value });
        }
      }
    }
    setMetadataList(auxArray);
  }, [editableDevice]);

  const handleOpenDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseDrawer = (): void => {
    setOpen(false);
  };

  const handleAddMetadata = (metadata: MetadataListProps[]): void => {
    const metadataObj = metadata.length
      ? metadata.reduce(
          (prevMetadata, nextMetadata) => ({
            ...prevMetadata,
            [nextMetadata.key as string]: nextMetadata.value,
          }),
          { [metadata[0].key as string]: metadata[0].value },
        )
      : {};

    handleCloseDrawer();
    setLoading(true);

    if (editableDevice) {
      Devices.deleteMetadata(editableDevice._id)
        .then(() => {
          return Devices.update({
            ...editableDevice,
            metadata: metadataObj,
          } as Device);
        })
        .then((response) => {
          dispatch(setActualDevice(response));
          dispatch(
            setAlert({
              highlight: 'Metadata updated',
              message: 'Device metadata successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={metadataList}
        addButtonLabel="Update Metadata"
        addButtonIcon={false}
        onAddClick={handleOpenDrawer}
        tableTitle="Metadata Added"
        loading={loading}
      />
      {metadataList && (
        <MetadataDrawer
          open={open}
          metadata={metadataList}
          handleCloseDrawer={handleCloseDrawer}
          onMetadataAdd={handleAddMetadata}
        />
      )}
    </Box>
  );
};

export default DeviceMetadata;
