import React from 'react';
import { RuleInput } from '@edgeiq/edgeiq-api-js';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';
import { DERIVED_VALUES_TYPES_OPTIONS } from '../../../../constants/policies';
import useStyles from '../styles';
import WhereConditionSection from './WhereConditionSection';

interface PolicyDerivedValuesSectionProps {
  newPolicy: RuleInput;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
  onAddNewProperty: (derivedValueIndex: number) => void;
  onRemoveProperty: (derivedValueIndex: number, propertyIndex: number) => void;
  onAddNewDerivedValue: () => void;
  onRemoveDerivedValue: (derivedValueIndex: number) => void;
  onAddWhereCondition: (derivedValueIndex: number) => void;
  onRemoveWhereCondition: (
    derivedValueIndex: number,
    whereConditionIndex: number,
  ) => void;
}

const PolicyDerivedValuesSection: React.FC<PolicyDerivedValuesSectionProps> = ({
  newPolicy,
  onInputChange,
  disabled,
  onAddNewProperty,
  onRemoveProperty,
  onAddNewDerivedValue,
  onRemoveDerivedValue,
  onAddWhereCondition,
  onRemoveWhereCondition,
}) => {
  const classes = useStyles();

  const handleClickAddProperty = (index: number) => (): void => {
    onAddNewProperty(index);
  };

  const handleClickRemoveProperty =
    (index: number, propertyIndex: number) => (): void => {
      onRemoveProperty(index, propertyIndex);
    };

  const handleClickAddDerivedValue = () => (): void => {
    onAddNewDerivedValue();
  };

  const handleRemoveDerivedValue = (index: number) => (): void => {
    onRemoveDerivedValue(index);
  };

  return (
    <>
      {Array.isArray(newPolicy.rule_condition.derived_values) &&
        newPolicy.rule_condition.derived_values.map((item, index) => (
          <Grid key={index} container direction="row" spacing={2}>
            <Grid item xs={6} className="d-flex flex-items-center">
              <Typography variant="h5">Derived Value {index + 1}</Typography>
            </Grid>
            <Grid item xs={6} className="d-flex flex-justify-end">
              <IconButton
                onClick={handleRemoveDerivedValue(index)}
                disabled={disabled}
                className={classes.errorButton}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6} className="mt-2">
              <TextInput
                label="Name"
                prop={`${index}.name`}
                placeholder=""
                value={item.name}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} className="mt-2">
              <SelectInput
                label="Select Type"
                prop={`${index}.type`}
                value={item.type}
                onSelectChange={onInputChange}
                disabled={disabled}
                options={Object.keys(DERIVED_VALUES_TYPES_OPTIONS).map(
                  (key) => (
                    <MenuItem className="m-4 p-2" key={key} dense value={key}>
                      {DERIVED_VALUES_TYPES_OPTIONS[key]}
                    </MenuItem>
                  ),
                )}
              />
            </Grid>

            <Grid item xs={12} className="mt-2">
              <TextInput
                label="Property"
                prop={`${index}.property`}
                placeholder=""
                value={item.property}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>

            {Array.isArray(item.properties) &&
              item.properties.map((property, propertyIndex) => (
                <Grid
                  item
                  xs={6}
                  key={`property-${propertyIndex}`}
                  className="mt-2"
                >
                  <TextInput
                    label={`Property ${propertyIndex + 1}`}
                    prop={`${index}.properties.${propertyIndex}`}
                    placeholder=""
                    value={property}
                    onInputChange={onInputChange}
                    disabled={disabled}
                    endAdornment={
                      (Array.isArray(item.properties) &&
                        item.properties.length > 1 && (
                          <IconButton
                            onClick={handleClickRemoveProperty(
                              index,
                              propertyIndex,
                            )}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )) || <></>
                    }
                  />
                </Grid>
              ))}

            <Grid item xs={6} className="mt-2 d-flex flex-items-center">
              <Button
                variant="outlined"
                size="medium"
                className="mt-4"
                onClick={handleClickAddProperty(index)}
                startIcon={<AddIcon />}
                disabled={disabled}
              >
                Add New Property
              </Button>
            </Grid>

            <Grid item xs={12} className="mt-2">
              <TextInput
                label="Data Set"
                prop={`${index}.data_set`}
                placeholder=""
                value={item.data_set}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <WhereConditionSection
                whereConditions={item.where_conditions}
                derivedValueIndex={index}
                onInputChange={onInputChange}
                disabled={disabled}
                onAddWhereCondition={onAddWhereCondition}
                onRemoveWhereCondition={onRemoveWhereCondition}
              />
            </Grid>

            <Grid item xs={12} className="mb-4">
              <Divider />
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} className="d-flex flex-items-center">
        <Button
          className="w-100"
          variant="outlined"
          size="large"
          onClick={handleClickAddDerivedValue()}
          startIcon={<AddIcon />}
          disabled={disabled}
        >
          Add Derived Value
        </Button>
      </Grid>
    </>
  );
};

export default PolicyDerivedValuesSection;
