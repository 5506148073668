import React from 'react';
import { Actions, DeviceType } from '@edgeiq/edgeiq-api-js';
import { Grid, Typography, Box, Paper } from '@mui/material';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setNewDeviceType } from '../../../redux/reducers/deviceTypes.reducer';
import useStyles from './styles';
import SwitchButton from '../../../components/SwitchButton';
import { deviceTypeAbilitiesLabel } from '../constants';

const DeviceTypeAbilites: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  const handleAbilitiesSwitchChange = (
    prop: string,
    checked: boolean,
  ): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        capabilities: {
          ...editableDeviceType?.capabilities,
          actions: {
            ...editableDeviceType?.capabilities.actions,
            [prop]: checked,
          },
        },
      } as DeviceType),
    );
  };

  // this is not super performant, but the number of capabilities is low so it should be fine
  const sortedValues = [];
  for (const key in deviceTypeAbilitiesLabel) {
    if (Object.prototype.hasOwnProperty.call(deviceTypeAbilitiesLabel, key)) {
      sortedValues.push(deviceTypeAbilitiesLabel[key]);
    }
  }
  sortedValues.sort();

  const sortedKeys: string[] = [];
  sortedValues.forEach((value) => {
    for (const key in deviceTypeAbilitiesLabel) {
      if (
        Object.prototype.hasOwnProperty.call(deviceTypeAbilitiesLabel, key) &&
        deviceTypeAbilitiesLabel[key] === value
      ) {
        sortedKeys.push(key);
      }
    }
  });

  const capabilitiesKeys = editableDeviceType?.capabilities.actions;
  return (
    <Grid container>
      {editableDeviceType && (
        <>
          <Paper className={clsx('p-7 br-1 shadow')}>
            <Typography variant="h5" className="mb-6">
              Abilities
            </Typography>

            <Box className={classes.optionsGrid}>
              {sortedKeys
                ? sortedKeys.map((key) => (
                    <Box className={classes.optionBox} key={key}>
                      <SwitchButton
                        label={deviceTypeAbilitiesLabel[key] || key}
                        value={
                          capabilitiesKeys
                            ? capabilitiesKeys[key as keyof Actions]
                            : false
                        }
                        prop={key}
                        onSwitchChange={handleAbilitiesSwitchChange}
                      />
                    </Box>
                  ))
                : null}
            </Box>
          </Paper>
        </>
      )}
    </Grid>
  );
};

export default DeviceTypeAbilites;
