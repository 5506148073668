import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DevicesIcon from '@mui/icons-material/Devices';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import SendIcon from '@mui/icons-material/Send';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import {
  Company,
  DeviceTransferRequest,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import { getCompanyDescription } from '../../helpers/utils';

import clsx from 'clsx';

import useStyles from './styles';

interface DeviceTransferRequestCardProps {
  deviceTransfer: DeviceTransferRequest;
  deviceTypes: DeviceType[];
  userCompanies: Company[];
}

const DeviceTransferRequestCard: React.FC<DeviceTransferRequestCardProps> = ({
  deviceTransfer,
  deviceTypes,
  userCompanies,
}) => {
  const classes = useStyles();

  const getDeviceTypeDescription = (item: DeviceTransferRequest): string => {
    const deviceType = deviceTypes.find(
      (device) => device._id === item.device_type_id,
    );
    return deviceType?.name || item.device_type_id;
  };

  return (
    <Box>
      <Tooltip placement="top" title="To Account">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <SendIcon fontSize="small" className="mr-2" />
          To:{' '}
          {getCompanyDescription(deviceTransfer.to_company_id, userCompanies)}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="From Account">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <PersonPinIcon fontSize="small" className="mr-2" />
          From:{' '}
          {getCompanyDescription(
            deviceTransfer?.from_company_id ?? '',
            userCompanies,
          )}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Status">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <AccessTimeIcon fontSize="small" className="mr-2" />
          Status: {deviceTransfer.status}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Device Profile">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <DevicesIcon fontSize="small" className="mr-2" />
          {getDeviceTypeDescription(deviceTransfer)}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Devices Count">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <DesktopMacIcon fontSize="small" className="mr-2" />
          {deviceTransfer.escrow_device_ids.length} devices
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default DeviceTransferRequestCard;
