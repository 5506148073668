import { SortingOption } from '../models/common';

const getInitialSorting = (
  sortingValue: string,
  sortingOptions: SortingOption[],
): SortingOption => {
  let result: SortingOption = {
    label: '',
    value: '',
  };

  sortingOptions.forEach((item) => {
    if (item.value === sortingValue) {
      result = item;
    }
  });

  // Return if there's a value in the sortings otherwise return the first option.
  if (result.value) {
    return result;
  }
  return sortingOptions[1];
};

export default getInitialSorting;
