import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
    },
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.info.main,
      display: 'flex',
      justifyContent: 'center',
      width: 'fit-content',
    },
    label: {
      alignItems: 'center',
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.dark,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      textTransform: 'uppercase',
    },
    statusContainer: {
      position: 'absolute',
      right: '0',
      top: '0',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    title: {
      color: theme.palette.grey[100],
      cursor: 'pointer',
      fontWeight: 700,
      width: '225px',
    },
  }),
);

export default useStyles;
