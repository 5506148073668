import { Rule, RuleInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';
import { EMPTY_POLICY } from '../../constants/policies';

interface AdaptedRule extends Rule {
  is_from_device_type?: boolean;
}

export const PoliciesTypes = {
  CLEAN_NEW_POLICY: 'CLEAN_NEW_POLICY',
  SET_ACTUAL_POLICY: 'SET_ACTUAL_POLICY',
  SET_NEW_POLICY: 'SET_NEW_POLICY',
  SET_ORIGINAL_SELECTED_POLICIES: 'SET_ORIGINAL_SELECTED_POLICIES',
  SET_POLICIES: 'SET_POLICIES',
  SET_SELECTED_POLICIES: 'SET_SELECTED_POLICIES',
};

export const getPolicySelector = createSelector(
  (state: PoliciesState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.policies.length) {
      return state.policy;
    }
    return state.policies.find((policy: Rule) => policy._id === id);
  },
);

export const cleanNewPolicy = (): PoliciesAction => ({
  data: EMPTY_POLICY as Rule,
  type: PoliciesTypes.CLEAN_NEW_POLICY,
});

export const setStatePolicies = (policies: Rule[]): PoliciesAction => ({
  data: policies,
  type: PoliciesTypes.SET_POLICIES,
});

export const setActualPolicy = (policy: Rule): PoliciesAction => ({
  data: policy,
  type: PoliciesTypes.SET_ACTUAL_POLICY,
});

export const setSelectedPolicies = (
  policies: AdaptedRule[],
): PoliciesAction => ({
  data: policies,
  type: PoliciesTypes.SET_SELECTED_POLICIES,
});

export const setOriginalSelectedPolicies = (
  policies: AdaptedRule[],
): PoliciesAction => ({
  data: policies,
  type: PoliciesTypes.SET_ORIGINAL_SELECTED_POLICIES,
});

export const setNewPolicy = (policy: RuleInput | Rule): PoliciesAction => ({
  data: policy,
  type: PoliciesTypes.SET_NEW_POLICY,
});

export type PoliciesState = {
  newPolicy: Rule;
  originalSelectedPolicies: AdaptedRule[];
  policies: AdaptedRule[];
  policy: Rule | null;
  selectedPolicies: Rule[];
};

// INITIAL STATE
const policiesInitialState: PoliciesState = {
  newPolicy: EMPTY_POLICY as Rule,
  originalSelectedPolicies: [],
  policies: [],
  policy: null,
  selectedPolicies: [],
};

export type PoliciesAction = {
  data: Rule[] | Rule | AdaptedRule | AdaptedRule[] | RuleInput;
  type: string;
};

const policiesReducer = (
  state = policiesInitialState,
  action: PoliciesAction,
): PoliciesState => {
  switch (action.type) {
    case PoliciesTypes.SET_POLICIES:
      return {
        ...state,
        policies: action.data as Rule[],
      };

    case PoliciesTypes.SET_ACTUAL_POLICY:
      return {
        ...state,
        policy: action.data as Rule,
      };

    case PoliciesTypes.SET_SELECTED_POLICIES:
      return {
        ...state,
        selectedPolicies: action.data as AdaptedRule[],
      };
    case PoliciesTypes.SET_ORIGINAL_SELECTED_POLICIES:
      return {
        ...state,
        originalSelectedPolicies: action.data as AdaptedRule[],
      };
    case PoliciesTypes.SET_NEW_POLICY:
      return {
        ...state,
        newPolicy: action.data as Rule,
      };
    case PoliciesTypes.CLEAN_NEW_POLICY:
      return {
        ...state,
        newPolicy: action.data as Rule,
      };
    default:
      return state;
  }
};

export default policiesReducer;
