import React from 'react';
import { Company } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper } from '@mui/material';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNewCompany } from '../../../redux/reducers/companies.reducer';
import AccountForm from './accountForm';
import AccountUsers from './accountUsers';

interface AccountDetailsProps {
  uploadedLogo?: File;
  setUploadedLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  uploadedLogo,
  setUploadedLogo,
}) => {
  const dispatch = useAppDispatch();
  const editableAccount = useAppSelector(
    (state: RootState) => state.companies.newCompany,
  );
  const accountChange = (
    prop: string,
    value: string | boolean | number,
  ): void => {
    // Check if the prop has a '.' and have one case for direct props and another for subprops (mainly for branding object here)
    const mainProp = prop.split('.');
    switch (mainProp[0]) {
      case 'branding':
        return dispatch(
          setNewCompany({
            ...editableAccount,
            branding: {
              ...editableAccount?.branding,
              [mainProp[1]]: value,
            },
          } as Company),
        );
      default:
        dispatch(
          setNewCompany({
            ...editableAccount,
            [prop]: value,
          } as Company),
        );
    }
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    accountChange(prop, value);
  };

  const handleCheckboxChange = (prop: string, value: boolean): void => {
    accountChange(prop, value);
  };

  return (
    <Grid container spacing={3}>
      {editableAccount && (
        <>
          <Grid item xs={12} md={8}>
            <Paper className="shadow p-7">
              <Typography variant="h5">Details</Typography>
              <AccountForm
                company={editableAccount}
                uploadedLogo={uploadedLogo}
                setUploadedLogo={setUploadedLogo}
                onCheckboxChange={handleCheckboxChange}
                onInputChange={handleInputChange}
              />
            </Paper>
          </Grid>
          <AccountUsers />
        </>
      )}
    </Grid>
  );
};

export default AccountDetails;
