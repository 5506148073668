import { Setting, SettingInput } from '@edgeiq/edgeiq-api-js';

export const newSettingInitialState = {
  company_id: '',
  configuration_id: '',
  name: '',
  values: {},
};

export const SettingTypes = {
  SET_ACTUAL_SETTING: 'SET_ACTUAL_SETTING',
  SET_NEW_SETTING: 'SET_NEW_SETTING',
  SET_NEW_SETTING_INPUT: 'SET_NEW_SETTING_INPUT',
};

export const setActualSetting = (setting: Setting): SettingAction => ({
  data: setting,
  type: SettingTypes.SET_ACTUAL_SETTING,
});

export const setNewSetting = (setting: Setting): SettingAction => ({
  data: setting,
  type: SettingTypes.SET_NEW_SETTING,
});

export const setNewSettingInput = (setting: SettingInput): SettingAction => ({
  data: setting,
  type: SettingTypes.SET_NEW_SETTING_INPUT,
});

export type SettingState = {
  actualSetting: Setting | null;
  newSetting: Setting | null;
  newSettingInput: SettingInput;
};

// INITIAL STATE
const settingInitialState: SettingState = {
  actualSetting: null,
  newSetting: null,
  newSettingInput: newSettingInitialState as SettingInput,
};

export type SettingAction = {
  data: Setting | SettingInput | string;
  type: string;
};

const settingReducer = (
  state = settingInitialState,
  action: SettingAction,
): SettingState => {
  switch (action.type) {
    case SettingTypes.SET_ACTUAL_SETTING:
      return {
        ...state,
        actualSetting: action.data as Setting,
        newSetting: action.data as Setting,
      };

    case SettingTypes.SET_NEW_SETTING:
      return {
        ...state,
        newSetting: action.data as Setting,
      };

    case SettingTypes.SET_NEW_SETTING_INPUT:
      return {
        ...state,
        newSettingInput: action.data as SettingInput,
      };

    default:
      return state;
  }
};

export default settingReducer;
