import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import {
  WorkflowExecution,
  WorkflowExecutions,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { defaultPaginationFilter, errorHighlight } from '../../app/constants';
import WorkflowExecutionInfoDrawer from '../RightDrawer/WorkflowExecutionInfo/WorkflowExecutionInfoDrawer';
import useStyles from '../../pages/commandContent/commandExecutionList/styles';
import WorkflowExecutionCard from './WorkflowExecutionCard';

const TIME_INTERVAL = 10000;

interface WorkflowExecutionListProps {
  collapsed?: boolean;
  fixedHeight?: string;
  isWorkflowPage: boolean;
}

const WorkflowExecutionList: React.FC<WorkflowExecutionListProps> = ({
  collapsed,
  fixedHeight,
  isWorkflowPage,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { id } = useParams<string>();
  const [workflowExecutions, setWorkflowExecutions] = useState<
    WorkflowExecution[]
  >([]);
  const [selectedWorkflowExecution, setSelectedWorkflowExecution] =
    useState<WorkflowExecution>();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);

  const getWorkflowExecutions = (
    pageNumber: number,
    addPage = false,
    workflowId = '',
    deviceId = '',
  ): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultPaginationFilter.itemsPerPage,
      page: pageNumber,
    };
    if (workflowId) {
      WorkflowExecutions.list(
        {
          workflow_id: { operator: 'eq', value: workflowId },
        },
        pagination,
      )
        .then((result) => {
          setWorkflowExecutions([]);
          const newWorkflowExecutions = addPage
            ? [...workflowExecutions, ...result.workflowExecutions]
            : result.workflowExecutions;
          setWorkflowExecutions(newWorkflowExecutions);
          setTotalAndPage(result.pagination.total, addPage);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => noLoading());
    } else if (deviceId) {
      WorkflowExecutions.list(
        {
          device_id: { operator: 'eq', value: deviceId },
        },
        pagination,
      )
        .then((result) => {
          setWorkflowExecutions([]);
          const newWorkflowExecutions = addPage
            ? [...workflowExecutions, ...result.workflowExecutions]
            : result.workflowExecutions;
          setWorkflowExecutions(newWorkflowExecutions);
          setTotalAndPage(result.pagination.total, addPage);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => noLoading());
    }
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getWorkflowExecutions(page + 1, true);
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getData = (): void => {
    if (workflowExecutions.length === 0) {
      setLoading(true);
    }
    if (isWorkflowPage) {
      // in WF page the id in the URL is the WF ID
      getWorkflowExecutions(1, false, id);
    } else {
      // in Device page the id in the URL is the Device ID
      getWorkflowExecutions(1, false, '', id);
    }
  };

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, TIME_INTERVAL);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, []);

  const noLoading = (): void => {
    setLoading(false);
  };

  const handleWorkflowExecutionCardCallback = (
    workflowExecution?: WorkflowExecution,
  ): void => {
    if (workflowExecution) {
      setSelectedWorkflowExecution(workflowExecution);
      handleOpenWorkflowExecutionDrawer();
    }
  };

  const handleOpenWorkflowExecutionDrawer = (): void => {
    setOpenInfoDrawer(true);
  };

  const handleCloseWorkflowExecutionDrawer = (): void => {
    setOpenInfoDrawer(false);
  };

  return (
    <>
      {id && (
        <Typography
          data-cy="workflow-execution-records-title"
          className="mb-4"
          variant="h5"
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          Execution Records
          {/* Only show a loader when the interval poll kicks in every TIME_INTERVAL seconds and there are already records */}
          {loading && workflowExecutions.length !== 0 && (
            <CircularProgress size={15} thickness={4} className="ml-2" />
          )}
        </Typography>
      )}
      {loading && !collapsed && workflowExecutions.length === 0 ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <Grid
          container
          direction={'row'}
          className={clsx('scrollbar w-100', classes.listContainer)}
          style={{
            height: `calc(100vh - ${fixedHeight ?? '328'}px)`,
            opacity: collapsed ? '0' : '1',
            overflowX: 'hidden',
            overflowY: collapsed ? 'hidden' : 'auto',
          }}
        >
          {workflowExecutions.map((workflowExecution, i) => {
            return (
              <WorkflowExecutionCard
                key={workflowExecution._id}
                isLast={i === workflowExecutions.length - 1}
                workflowExecution={workflowExecution}
                actionCallback={handleWorkflowExecutionCardCallback}
              />
            );
          })}
          {workflowExecutions.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button
                data-cy="load-more-records"
                variant="outlined"
                size="large"
                onClick={handleLoadMore}
              >
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
          {workflowExecutions.length === 0 && (
            <Grid item xs={12}>
              <Typography
                variant="button"
                data-cy="no-workflow-execution-records"
              >
                {isWorkflowPage
                  ? 'There are no execution records for this workflow.'
                  : 'There are no workflow execution records for this device.'}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {selectedWorkflowExecution && (
        <WorkflowExecutionInfoDrawer
          open={openInfoDrawer}
          handleCloseDrawer={handleCloseWorkflowExecutionDrawer}
          workflowExecution={selectedWorkflowExecution}
        />
      )}
    </>
  );
};

export default WorkflowExecutionList;
