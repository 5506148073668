import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import SharedData from '../../../components/SharedData';
import { RootState } from '../../../redux/store';
import {
  DeviceTypes,
  DeviceType,
  Integrations,
  Integration,
  Ingestors,
  Ingestor,
  Rules,
  Rule,
  Translators,
  PollableAttributes,
  PollableAttribute,
  Company,
} from '@edgeiq/edgeiq-api-js';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setNewCompany } from '../../../redux/reducers/companies.reducer';
import { errorHighlight } from '../../../app/constants';
import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { setTranslatorsOptions } from '../../../redux/reducers/translators.reducer';

interface SharedDataItems {
  _id: string;
  name: string;
}

const AccountSharedData: React.FC = () => {
  const dispatch = useAppDispatch();
  const { newCompany } = useAppSelector((state: RootState) => state.companies);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [integrationList, setIntegrationList] = useState<Integration[]>([]);
  const [ruleList, setRuleList] = useState<Rule[]>([]);
  const [ingestorList, setIngestorList] = useState<Ingestor[]>([]);

  const translatorsState = useAppSelector(
    (state: RootState) => state.translators,
  );
  const [translatorsOptions, setTranslatorsOptionsList] = useState(
    translatorsState.optionsTranslators || [],
  );

  const [pollableAttributesList, setPollableAttributesList] = useState<
    PollableAttribute[]
  >([]);
  const [loading, setLoading] = useState(true);
  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    if (translatorsState.optionsTranslators) {
      setTranslatorsOptionsList(translatorsState.optionsTranslators);
    }
  }, [translatorsState]);

  const getDeciveTypeList = (): void => {
    DeviceTypes.list(
      { company_id: { operator: 'eq', value: newCompany?.company_id || '' } },
      {
        itemsPerPage: 1000,
        page: 1,
      },
    )
      .then((result) => {
        setDeviceTypes(result.deviceTypes);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        noLoading();
      });
  };

  const getIntegrationsList = (): void => {
    Integrations.list(
      { company_id: { operator: 'eq', value: newCompany?.company_id || '' } },
      {
        itemsPerPage: 1000,
        page: 1,
      },
    )
      .then((result) => {
        setIntegrationList(result.integrations);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        noLoading();
      });
  };

  const getRulesList = (): void => {
    Rules.list(
      { company_id: { operator: 'eq', value: newCompany?.company_id || '' } },
      {
        itemsPerPage: 1000,
        page: 1,
      },
    )
      .then((result) => {
        setRuleList(result.rules);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        noLoading();
      });
  };

  const getIngestorList = (): void => {
    Ingestors.list(
      { company_id: { operator: 'eq', value: newCompany?.company_id || '' } },
      {
        itemsPerPage: 1000,
        page: 1,
      },
    )
      .then((result) => {
        setIngestorList(result.ingestors);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        noLoading();
      });
  };

  const getTranslatorList = (): void => {
    Translators.list(
      { company_id: { operator: 'eq', value: newCompany?.company_id || '' } },
      {
        itemsPerPage: 1000,
        page: 1,
      },
    )
      .then((result) => {
        dispatch(setTranslatorsOptions(result.translators));
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        noLoading();
      });
  };

  const getPollableAttributesList = (): void => {
    PollableAttributes.list(
      { company_id: { operator: 'eq', value: newCompany?.company_id || '' } },
      {
        itemsPerPage: 1000,
        page: 1,
      },
    )
      .then((result) => {
        setPollableAttributesList(result.pollableAttributes);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        noLoading();
      });
  };

  const noLoading = (): void => {
    setLoading(false);
  };

  useEffect(() => {
    getDeciveTypeList();
    getIntegrationsList();
    getRulesList();
    getIngestorList();
    getTranslatorList();
    getPollableAttributesList();
  }, []);

  const handleChange = (prop: string, items: string[]): void => {
    dispatch(
      setNewCompany({
        ...newCompany,
        [prop]: items,
      } as Company),
    );
  };

  const getDeviceTypesListFormat = (): SharedDataItems[] => {
    return (
      deviceTypes.map((item) => ({ _id: item._id, name: item.name })) || []
    );
  };

  const getDeviceTypesCurrentItemsFormat = (): SharedDataItems[] => {
    let currentCompanyDeviceType: SharedDataItems[] = [];
    deviceTypes.forEach((item) => {
      if (newCompany?.inherited_device_type_ids) {
        const indexFound = newCompany?.inherited_device_type_ids.findIndex(
          (tempIndex) => tempIndex === item._id,
        );
        if (indexFound >= 0) {
          currentCompanyDeviceType = [
            ...currentCompanyDeviceType,
            {
              _id: item._id,
              name: item.name,
            },
          ];
        }
      }
    });

    return currentCompanyDeviceType || [];
  };

  const getIntegrationListFormat = (): SharedDataItems[] => {
    return (
      integrationList.map((item) => ({ _id: item._id, name: item.name })) || []
    );
  };

  const getIntegrationCurrentItemsFormat = (): SharedDataItems[] => {
    let currentResult: SharedDataItems[] = [];
    integrationList.forEach((item) => {
      if (newCompany?.inherited_integration_ids) {
        const indexFound = newCompany?.inherited_integration_ids.findIndex(
          (tempIndex) => tempIndex === item._id,
        );
        if (indexFound >= 0) {
          currentResult = [
            ...currentResult,
            {
              _id: item._id,
              name: item.name,
            },
          ];
        }
      }
    });

    return currentResult || [];
  };

  const getRuleListFormat = (): SharedDataItems[] => {
    return (
      ruleList.map((item) => ({ _id: item._id, name: item.description })) || []
    );
  };

  const getRuleCurrentItemsFormat = (): SharedDataItems[] => {
    let currentResult: SharedDataItems[] = [];
    ruleList.forEach((item) => {
      if (newCompany?.inherited_rule_ids) {
        const indexFound = newCompany?.inherited_rule_ids.findIndex(
          (tempIndex) => tempIndex === item._id,
        );
        if (indexFound >= 0) {
          currentResult = [
            ...currentResult,
            {
              _id: item._id,
              name: item.description,
            },
          ];
        }
      }
    });

    return currentResult || [];
  };

  const getIngestorListFormat = (): SharedDataItems[] => {
    return (
      ingestorList.map((item) => ({ _id: item._id, name: item.name })) || []
    );
  };

  const getIngestorCurrentItemsFormat = (): SharedDataItems[] => {
    let currentResult: SharedDataItems[] = [];
    ingestorList.forEach((item) => {
      if (newCompany?.inherited_ingestor_ids) {
        const indexFound = newCompany?.inherited_ingestor_ids.findIndex(
          (tempIndex) => tempIndex === item._id,
        );
        if (indexFound >= 0) {
          currentResult = [
            ...currentResult,
            {
              _id: item._id,
              name: item.name,
            },
          ];
        }
      }
    });

    return currentResult || [];
  };

  const getTranslatorListFormat = (): SharedDataItems[] => {
    return (
      translatorsOptions.map((item) => ({ _id: item._id, name: item.name })) ||
      []
    );
  };

  const getTranslatorCurrentItemsFormat = (): SharedDataItems[] => {
    let currentResult: SharedDataItems[] = [];
    translatorsOptions.forEach((item) => {
      if (newCompany?.inherited_translator_ids) {
        const indexFound = newCompany?.inherited_translator_ids.findIndex(
          (tempIndex) => tempIndex === item._id,
        );
        if (indexFound >= 0) {
          currentResult = [
            ...currentResult,
            {
              _id: item._id,
              name: item.name,
            },
          ];
        }
      }
    });

    return currentResult || [];
  };

  const getPollableAttributeListFormat = (): SharedDataItems[] => {
    return (
      pollableAttributesList.map((item) => ({
        _id: item._id,
        name: item.name,
      })) || []
    );
  };

  const getPollableAttributesCurrentItemsFormat = (): SharedDataItems[] => {
    let currentResult: SharedDataItems[] = [];
    pollableAttributesList.forEach((item) => {
      if (newCompany?.inherited_pollable_attributes_ids) {
        const indexFound =
          newCompany?.inherited_pollable_attributes_ids.findIndex(
            (tempIndex) => tempIndex === item._id,
          );
        if (indexFound >= 0) {
          currentResult = [
            ...currentResult,
            {
              _id: item._id,
              name: item.name,
            },
          ];
        }
      }
    });

    return currentResult || [];
  };

  const renderTitle = (titleKey: string, title: string): JSX.Element => (
    <Typography variant="h5" data-cy={`${titleKey}-title`}>
      {title}
    </Typography>
  );

  return (
    <Grid container>
      <Grid component={Paper} item xs={12} className={clsx('p-7 shadow ')}>
        {newCompany && (
          <>
            {renderTitle('inherited-device-types', 'Inherit Device Types')}
            <SharedData
              sharedDataKey="inherited-device-types"
              buttonLabel="Add Device Type"
              currentSharedList={getDeviceTypesCurrentItemsFormat()}
              items={getDeviceTypesListFormat()}
              itemType="inherited_device_type_ids"
              onChangeSharedData={handleChange}
              placeholder="Add Device Type"
            />
            {renderTitle('inherited-integrations', 'Inherit Integrations')}
            <SharedData
              sharedDataKey="inherited-integrations"
              buttonLabel="Add Integration"
              currentSharedList={getIntegrationCurrentItemsFormat()}
              items={getIntegrationListFormat()}
              itemType="inherited_integration_ids"
              onChangeSharedData={handleChange}
              placeholder="Add Integrations"
            />
            {renderTitle('inherited-policies', 'Inherit Policies')}
            <SharedData
              sharedDataKey="inherited-policies"
              buttonLabel="Add Policy"
              currentSharedList={getRuleCurrentItemsFormat()}
              items={getRuleListFormat()}
              itemType="inherited_rule_ids"
              onChangeSharedData={handleChange}
              placeholder="Add Policy"
            />
            {renderTitle('inherited-ingestors', 'Inherit Ingestors')}
            <SharedData
              sharedDataKey="inherited-ingestors"
              buttonLabel="Add Ingestor"
              currentSharedList={getIngestorCurrentItemsFormat()}
              items={getIngestorListFormat()}
              itemType="inherited_ingestor_ids"
              onChangeSharedData={handleChange}
              placeholder="Add Ingestor"
            />
            {renderTitle('inherited-translators', 'Inherit Translators')}
            <SharedData
              sharedDataKey="inherited-translators"
              buttonLabel="Add Translator"
              currentSharedList={getTranslatorCurrentItemsFormat()}
              items={getTranslatorListFormat()}
              itemType="inherited_translator_ids"
              onChangeSharedData={handleChange}
              placeholder="Add Translator"
            />
            {renderTitle(
              'inherited-pollable-attributes',
              'Inherit Pollable Attributes',
            )}
            <SharedData
              sharedDataKey="inherited-pollable-attributes"
              buttonLabel="Add Pollable Attribute"
              currentSharedList={getPollableAttributesCurrentItemsFormat()}
              items={getPollableAttributeListFormat()}
              itemType="inherited_pollable_attributes_ids"
              onChangeSharedData={handleChange}
              placeholder="Add Pollable"
            />
            {loading}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AccountSharedData;
