import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopy, HomeMax } from '@mui/icons-material';
import { Command } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDeviceSelector } from '../../redux/reducers/devices.reducer';
import { setNewCommandInput } from '../../redux/reducers/commands.reducer';
import { RootState } from '../../redux/store';
import { commandsSenderTypes } from '../../app/constants';
import useStyles from '../../components/Card/styles';

interface CommandCardProps {
  command: Command;
}

const CommandCard: React.FC<CommandCardProps> = ({ command }) => {
  const cardClasses = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deviceData = useAppSelector((state: RootState) =>
    getDeviceSelector(state.devices, command.device_id),
  );

  const handleCopyCommand = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    dispatch(
      setNewCommandInput({
        ...command,
        name: `${command.name} - copy`,
      }),
    );
    navigate('/new-command');
  };

  return (
    <Box>
      <Tooltip placement="top" title={command.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', cardClasses.title)}
        >
          {command.name}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Type">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', cardClasses.tag)}
        >
          {commandsSenderTypes[command.sender_type]}
        </Typography>
      </Tooltip>
      {deviceData && (
        <Tooltip placement="top" title="Type">
          <Typography
            variant="overline"
            component="div"
            className={clsx('mb-1', cardClasses.tag)}
          >
            <HomeMax fontSize="medium" className="mr-2" />
            {deviceData.name}
          </Typography>
        </Tooltip>
      )}
      <div className={cardClasses.statusContainer}>
        <Tooltip placement="top" title="Copy Command">
          <IconButton
            size="small"
            data-y={`command-${command._id}-copy-button`}
            onClick={handleCopyCommand}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  );
};

export default CommandCard;
