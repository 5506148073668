import React, { Dispatch, useEffect, useState } from 'react';
import { Grid, IconButton, Link, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { get, set } from 'lodash';
import clsx from 'clsx';

import TextInput from '../TextInput';
import CheckboxInput from '../CheckboxInput';
import ActionDialog from '../ActionDialog';
import { NestedObjectInput, NestedObjectItem } from './NestedObject';
import {
  getItemInputPath,
  getNestedItemKeyPath,
  getNestedItemParentPath,
  getNestedItemsEnabledState,
  getNestedItemsHalfEnabledState,
  reIndexNestedItems,
  toggleNestedItemsState,
} from './helpers';
import useStyles from './styles';

interface ObjectDataProps {
  chosenItem?: NestedObjectItem;
  explorerNameKey: string;
  inputs: NestedObjectInput[];
  nestedItems: NestedObjectItem[];
  updateItems: Dispatch<React.SetStateAction<NestedObjectItem[]>>;
  setActualItem: Dispatch<React.SetStateAction<NestedObjectItem | undefined>>;
}

const ObjectData: React.FC<ObjectDataProps> = ({
  chosenItem,
  explorerNameKey,
  inputs,
  nestedItems,
  updateItems,
  setActualItem,
}) => {
  const classes = useStyles();
  const [breadcrumbs, setBreadcrumbs] = useState<NestedObjectItem[]>([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (chosenItem?.index) {
      const newBreadcrumbs: NestedObjectItem[] = [];
      const pathLevels = chosenItem.index.split('.');
      let accumulatedPath = '';
      pathLevels.forEach((level) => {
        newBreadcrumbs.push(get(nestedItems, `${accumulatedPath}${level}`));
        accumulatedPath = `${accumulatedPath}${level}.items.`;
      });
      setBreadcrumbs(newBreadcrumbs);
    } else {
      setBreadcrumbs([]);
    }
  }, [chosenItem]);

  const handleChange = (prop: string, value: string | number): void => {
    const itemsObject = {
      items: [...nestedItems],
    };
    const inputPath = getItemInputPath(prop);
    if (value === 'boolean_input') {
      const priorValue = get(itemsObject, `items.${inputPath}`);
      set(itemsObject, `items.${inputPath}`, !priorValue);
    } else {
      set(itemsObject, `items.${inputPath}`, value);
    }
    updateItems(itemsObject.items);
  };

  const handleEnableNestedItems = (priorState: boolean) => (): void => {
    if (chosenItem) {
      const itemsObject = {
        items: [...nestedItems],
      };
      const updatedItem = toggleNestedItemsState(chosenItem, priorState);
      const itemKeyPath = getNestedItemKeyPath(chosenItem.index);
      set(itemsObject, `items.${itemKeyPath}`, updatedItem);
      updateItems(itemsObject.items);
    }
  };

  const handleChooseItem = (item: NestedObjectItem) => (): void => {
    if (item.index !== chosenItem?.index) {
      setActualItem(item);
    }
  };

  const handleDeleteItem = (): void => {
    setConfirmDeleteOpen(true);
  };

  const closeDeleteModal = (): void => {
    setConfirmDeleteOpen(false);
  };

  const handleDeleteNestedItem = (): void => {
    if (chosenItem) {
      const itemsObject = {
        items: [...nestedItems],
      };
      const path = getNestedItemParentPath(chosenItem.index as string);
      if (path.indexOf('items') !== -1) {
        const parentItems = get(itemsObject, `items.${path}`);
        const levels = chosenItem.index.split('.');
        const index = levels[levels.length - 1];
        parentItems.splice(parseInt(index), 1);
        set(itemsObject, `items.${path}`, parentItems);
      } else {
        itemsObject.items.splice(parseInt(path), 1);
      }
      itemsObject.items = reIndexNestedItems(itemsObject.items);
      updateItems(itemsObject.items);
      closeDeleteModal();
      setActualItem(undefined);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="overline"
            className={clsx(classes.breadcrumb, classes.allBreadcrumb)}
          >
            All
          </Typography>
          {breadcrumbs.map((breadcrumb) => (
            <Link
              onClick={handleChooseItem(breadcrumb)}
              underline="none"
              key={`breadcrumb-${breadcrumb.index}`}
            >
              <Typography className={classes.breadcrumb} variant="overline">
                {` > ${breadcrumb.data[explorerNameKey]}`}
              </Typography>
            </Link>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            className={classes.nestedItemDataContainer}
          >
            <Grid item xs={11}>
              <Grid container spacing={2}>
                {chosenItem &&
                  inputs.map((nestedInput) => {
                    const nestedInputKey = `${chosenItem.index}.${nestedInput.inputKey}`;
                    const data = chosenItem?.data;
                    let value = null;
                    const allNestedEnabled =
                      getNestedItemsEnabledState(chosenItem);
                    const oneNestedEnabled =
                      getNestedItemsHalfEnabledState(chosenItem);
                    if (data && !nestedInput.isEnableNestedItems) {
                      value = data[nestedInput.inputKey];
                    }
                    return (
                      <Grid
                        item
                        key={nestedInputKey}
                        xs={12}
                        sm={6}
                        md={nestedInput.columns}
                      >
                        {nestedInput.type === 'string' && (
                          <TextInput
                            label={nestedInput.label}
                            onInputChange={handleChange}
                            placeholder={nestedInput.label}
                            prop={nestedInputKey}
                            type="string"
                            value={(value as string) || ''}
                          />
                        )}
                        {nestedInput.type === 'number' && (
                          <TextInput
                            label={nestedInput.label}
                            onInputChange={handleChange}
                            placeholder={nestedInput.label}
                            prop={nestedInputKey}
                            type="number"
                            value={(value as number) || ''}
                          />
                        )}
                        {nestedInput.type === 'boolean' &&
                          !nestedInput.isEnableNestedItems && (
                            <CheckboxInput
                              label={nestedInput.label}
                              checked={(value as boolean) || false}
                              prop={nestedInputKey}
                              value="boolean_input"
                              onCheckboxClick={handleChange}
                            />
                          )}
                        {nestedInput.isEnableNestedItems &&
                          chosenItem.items.length !== 0 && (
                            <CheckboxInput
                              label={nestedInput.label}
                              checked={allNestedEnabled}
                              indeterminate={
                                !allNestedEnabled && oneNestedEnabled
                              }
                              prop={nestedInputKey}
                              value="boolean_input"
                              onCheckboxClick={handleEnableNestedItems(
                                allNestedEnabled,
                              )}
                            />
                          )}
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            {chosenItem && (
              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  size="large"
                  onClick={handleDeleteItem}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ActionDialog
        content={`${
          chosenItem?.items.length !== 0
            ? ' Removing this item will also remove its nested items.'
            : ''
        }`}
        loading={false}
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleDeleteNestedItem}
        open={confirmDeleteOpen}
        title="Are you sure?"
      />
    </div>
  );
};

export default ObjectData;
