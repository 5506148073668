export const EMPTY_CONFIG_CONNECTION = {
  net_broadcast: '',
  net_dns_1: '',
  net_dns_2: '',
  net_enable: 0,
  net_gateway: '',
  net_ip: '',
  net_ip_assign: '',
  net_mask: '',
  net_metric: 1,
  net_mtu: 1500,
  net_nat: 0,
};

export const EMPTY_CONFIG_CONNECTION_WAN = {
  net_broadcast: '',
  net_dns_1: '',
  net_dns_2: '',
  net_enable: 0,
  net_gateway: '',
  net_ip: '',
  net_ip_assign: 'static',
  net_mask: '',
  net_metric: 1,
  net_mtu: 1500,
  net_nat: 0,
};

export const EMPTY_CONFIG_CONNECTION_WANLAN = {
  ...EMPTY_CONFIG_CONNECTION_WAN,
  ...{
    dhcp_dns1: '',
    dhcp_dns2: '',
    dhcp_enable: 0,
    dhcp_end: '',
    dhcp_lease: 1,
    dhcp_relay: 0,
    dhcp_start: '',
    net_mode: 'LAN',
  },
};

export const EMPTY_CONFIG_CONNECTION_LAN = {
  ...EMPTY_CONFIG_CONNECTION_WANLAN,
  ...{
    net_ip_assign: '',
    net_mode: '',
  },
};

export const EMPTY_CONFIG_CONNECTION_CELULAR = {
  cell_apn: '',
  cell_auth: 'chap',
  cell_auto_apn: 0,
  cell_enable: 0,
  cell_mtu: 1500,
  cell_pass: '',
  cell_reconn_delay: 1,
  cell_reconn_retries: 1,
  cell_user: '',
};

export const EMPTY_CONFIG_CONNECTION_WIFI = {
  net_broadcast: '',
  net_dns_1: '',
  net_dns_2: '',
  net_gateway: '',
  net_ip: '',
  net_ip_assign: 'static',
  net_mask: '',
  wifi_auth: 'wpa2psk',
  wifi_channel: 1,
  wifi_crypt: 'aes',
  wifi_enable: 0,
  wifi_hidden: 0,
  wifi_mode: 'accesspoint',
  wifi_psk: '',
  wifi_ssid: '',
};

export const EMPTY_IP_TABLE = {
  action: '',
  chain: '',
  destination: '',
  destination_port: '',
  in_interface: '',
  jump: '',
  out_interface: '',
  protocol: '',
  source: '',
  source_port: '',
  table: '',
};

export const DEVICE_CONNECTION_TYPE = {
  CELLULAR: 'cellular',
  ETHERNET_LAN: 'ethernet-lan',
  ETHERNET_WAN: 'ethernet-wan',
  ETHERNET_WAN_LAN: 'ethernet-wan-lan',
  WIFE: 'wife',
};
