import React from 'react';
import { Rule } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper } from '@mui/material';
import clsx from 'clsx';

import PolicyForm from './policyForm';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setNewPolicy } from '../../../redux/reducers/policies.reducer';

const PolicyDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const editablePolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );

  const handleInputChange = (
    prop: string,
    value: string | boolean | number,
  ): void => {
    switch (prop) {
      default:
        dispatch(
          setNewPolicy({
            ...editablePolicy,
            [prop]: value,
          } as Rule),
        );
    }
  };

  return (
    <Grid container>
      {editablePolicy && (
        <>
          <Grid component={Paper} item xs={12} className={clsx('p-7 shadow')}>
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <PolicyForm
              policy={editablePolicy}
              onInputChange={handleInputChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PolicyDetails;
