import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import ActionDialog from '../ActionDialog';
import { FooterDeleteButton, FooterSaveButton } from './FooterButtons';
import useStyles from './styles';

/**
 * Common footer bar will be used for content pages, with a delete button on the left that opens a confirmation modal,
 * and a save button on the right.
 * So the component will have this standard structure. If some custom footer elements or functions are needed, the main strcture shouldn't change
 * but evolved to have custom values that if they exist then the custom footer elements will be rendered instead of the standard ones
 */
export interface FooterBarProps {
  isCustom?: boolean; // If this element is true then the footer should have initial and end elements included.
  includeDeleteModal?: boolean; // Indicates if a delete modal is part of the footer fucntionality (default: true)
  deleteModalContent?: string; // The text to show for the delete modal
  loading?: boolean; // Loading boolean for deleting or saving changes.
  disableSaveButton?: boolean;
  sticky?: boolean; // Prop that set the bar to be sticked to the bottom part (default: true)
  height?: string; // Height of the bar (default: 76px)
  initialElements?: ReactElement; // Components that will render in the left side of the bar
  endElements?: ReactElement; // Components that will render in the right side of the bar
  handleSaveChanges?: () => void; // function to call when the save button is clicked
  handleDelete?: () => void; // function to call when the delete button is clicked in the delete modal
}

const FooterBar: React.FC<FooterBarProps> = ({
  isCustom,
  includeDeleteModal = true,
  deleteModalContent,
  loading = false,
  disableSaveButton = false,
  sticky,
  height,
  initialElements,
  endElements,
  handleSaveChanges,
  handleDelete,
}) => {
  const classes = useStyles({
    height: height ?? '76px',
    sticky: sticky ?? true,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (): void => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDeleteModal(false);
  };

  const renderFooter = (): ReactElement => (
    <Box className={clsx('px-8 py-4 shadow', classes.container)}>
      {isCustom ? (
        <>
          {initialElements}
          {endElements}
        </>
      ) : (
        <>
          {handleDelete && (
            <FooterDeleteButton handleDelete={handleOpenDeleteModal} />
          )}
          {handleSaveChanges && (
            <FooterSaveButton
              loading={loading}
              disabled={disableSaveButton}
              handleSave={handleSaveChanges}
            />
          )}
        </>
      )}
    </Box>
  );

  return includeDeleteModal ? (
    <>
      {renderFooter()}
      {handleDelete && (
        <ActionDialog
          data-testid="actionDialog"
          content={deleteModalContent}
          loading={loading}
          onCloseCallback={handleCloseDeleteModal}
          onDeleteCallback={handleDelete}
          open={openDeleteModal}
          title="Are you sure?"
        />
      )}
    </>
  ) : (
    renderFooter()
  );
};

export default FooterBar;
