import React from 'react';
import { Button, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  HTTP_REQUEST_TYPE_OPTIONS,
  HTTP_HEADERS,
} from '../../../../../constants/policies';
import TextInput from '../../../../../components/TextInput';
import SelectInput from '../../../../../components/SelectInput';
import CodeEditor from '../../../../../components/CodeEditor';

interface HttpRequestSectionProps {
  action: RuleAction;
  actionIndex: number;
  editorWidth: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  onHeaderChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
  onAddHeader: (actionIndex: number) => void;
  onRemoveHeader: (actionIndex: number, key: string) => void;
  headers: HTTP_HEADERS;
}

const HttpRequestSection: React.FC<HttpRequestSectionProps> = ({
  action,
  actionIndex,
  editorWidth,
  onInputChange,
  disabled,
  onAddHeader,
  onRemoveHeader,
  onHeaderChange,
  headers,
}) => {
  const handleClickAddHeader = () => (): void => {
    onAddHeader(actionIndex);
  };

  const handleClickRemoveHeader =
    (i: number, headerKey: string) => (): void => {
      onRemoveHeader(i, headerKey);
    };

  return (
    <>
      <Grid item xs={12}>
        <CodeEditor
          label="Request Body"
          prop={`${actionIndex}.body_template`}
          onInputChange={onInputChange}
          value={action.body_template}
          width={editorWidth}
          height={400}
        />
      </Grid>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Request URL"
          placeholder=""
          prop={`${actionIndex}.send_to`}
          value={action.send_to}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectInput
          label="Body Type"
          value={action.method}
          prop={`${actionIndex}.method`}
          onSelectChange={onInputChange}
          options={Object.keys(HTTP_REQUEST_TYPE_OPTIONS).map((key) => (
            <MenuItem className="m-4 p-2" key={key} dense value={key}>
              {HTTP_REQUEST_TYPE_OPTIONS[key]}
            </MenuItem>
          ))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} className="mt-2">
        <Typography variant="subtitle2">Request Headers</Typography>
      </Grid>
      {headers &&
        headers.map((header, i) => (
          <Grid item xs={12} className="d-flex">
            <Grid item xs={5}>
              <TextInput
                label="Key"
                placeholder=""
                prop={`${actionIndex}.header.${i}.key`}
                value={header.key}
                onInputChange={onHeaderChange}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={5} className="ml-1">
              <TextInput
                label="Value"
                placeholder=""
                prop={`${actionIndex}.header.${i}.value`}
                value={header.value}
                onInputChange={onHeaderChange}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={1} className="mt-6">
              <IconButton onClick={handleClickRemoveHeader(i, header.key)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} className="d-flex">
        <Button
          variant="outlined"
          size="large"
          onClick={handleClickAddHeader()}
          startIcon={<AddIcon />}
          disabled={disabled}
        >
          Header
        </Button>
      </Grid>
    </>
  );
};

export default HttpRequestSection;
