import React, { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { DeviceTypes, Ingestor, Translators } from '@edgeiq/edgeiq-api-js';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { errorHighlight } from '../../../app/constants';
import { AttachIngestorsDrawer } from '../../../containers/RightDrawer';
import TabsPage from '../../../components/TabsPage';
import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  setOriginalIngestors,
  setSelectedIngestors,
} from '../../../redux/reducers/ingestors.reducer';
import { setTranslatorsOptions } from '../../../redux/reducers/translators.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  INGESTOR_HANDLER_TYPES_MAP,
  INGESTOR_LISTENER_TYPES_MAP,
} from '../../../constants/ingestors';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import { ContentPageTabProps } from '../../../models/common';
import useStyles from '../../deviceContent/styles';

const DeviceTypeIngestors: React.FC<ContentPageTabProps> = ({ goToItem }) => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedIngestors = useAppSelector(
    (state: RootState) => state.ingestors.selectedIngestors,
  );
  const originalIngestors = useAppSelector(
    (state: RootState) => state.ingestors.originalIngestors,
  );
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const userCompanies = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );
  const translators = useAppSelector(
    (state: RootState) => state.translators.optionsTranslators,
  );

  const getTranslators = (): void => {
    Translators.list()
      .then((response) => {
        dispatch(setTranslatorsOptions(response.translators));
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      });
  };

  useEffect(() => {
    if (!translators.length) {
      getTranslators();
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: '_id',
      flex: 0.7,
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <TypographyWithCopy
          dataCy={`device-type-ingestor-${params.row._id}`}
          text={params.row._id}
          typographyVariant="button"
        />
      ),
    },
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
          onClick={goToItem('ingestor', params.row._id)}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'company_id',
      flex: 0.5,
      headerName: 'Account',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            userCompanies.find(
              (company) => company._id === params.row.company_id,
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'listener_type',
      flex: 0.3,
      headerName: 'Listener Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{INGESTOR_LISTENER_TYPES_MAP[params.row.listener_type]}</span>
      ),
    },
    {
      field: 'handler_type',
      flex: 0.3,
      headerName: 'Handler Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{INGESTOR_HANDLER_TYPES_MAP[params.row.handler_type]}</span>
      ),
    },
  ];

  const handleChooseIngestors = (newIngestors: Ingestor[]): void => {
    dispatch(setSelectedIngestors(newIngestors));
    handleCloseManageIngestorsDrawer();
    setLoading(true);

    const attachIngestors = newIngestors.filter((newIngestor) =>
      originalIngestors.every(
        (originalIngestor) => newIngestor._id !== originalIngestor._id,
      ),
    );
    const detachIngestors = originalIngestors.filter((originalIngestor) =>
      newIngestors.every(
        (newIngestor) => originalIngestor._id !== newIngestor._id,
      ),
    );

    Promise.all([
      Promise.all(
        attachIngestors.map(async (attachIngestor) => {
          await DeviceTypes.attachIngestor(id as string, attachIngestor._id);
        }),
      ),
      Promise.all(
        detachIngestors.map(async (detachIngestor) => {
          await DeviceTypes.detachIngestor(id as string, detachIngestor._id);
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalIngestors(newIngestors));
        dispatch(
          setAlert({
            highlight: 'Managing Ingestors',
            message: 'Ingestors successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing ingestors.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      DeviceTypes.getIngestors(editableDeviceType._id)
        .then((response) => {
          dispatch(setOriginalIngestors(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  const handleOpenManageIngestorsDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseManageIngestorsDrawer = (): void => {
    setOpen(false);
  };

  const handleCreateIngestor = (): void => {
    goToItem('ingestor')();
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedIngestors}
        addButtonIcon={false}
        addButtonLabel="Manage Ingestors"
        onAddClick={handleOpenManageIngestorsDrawer}
        onCreateClick={handleCreateIngestor}
        tableTitle="Ingestors attached"
        loading={loading}
      />
      <AttachIngestorsDrawer
        open={open}
        itemIngestors={true}
        translators={translators}
        handleCloseDrawer={handleCloseManageIngestorsDrawer}
        onChoosingIngestors={handleChooseIngestors}
      />
    </Box>
  );
};

export default DeviceTypeIngestors;
