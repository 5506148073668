import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import TextInput from '../../../components/TextInput';
import { Configuration, ConfigurationInput } from '@edgeiq/edgeiq-api-js';

import { SettingsEditorData } from '../../../models/settings';
import SettingsEditor from './SettingsEditor';

interface ConfigurationForm {
  action: string;
  addPaper?: boolean;
  configuration: Configuration | ConfigurationInput;
  settingsEditorData?: SettingsEditorData;
  onInputChange: (prop: string, value: string | number) => void;
  onExtraConfigDataChange?: (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ) => void;
}

const ConfigurationForm: React.FC<ConfigurationForm> = ({
  action,
  addPaper,
  configuration,
  settingsEditorData,
  onInputChange,
  onExtraConfigDataChange,
}) => {
  const handleInputChange = (prop: string, value: string | number): void => {
    onInputChange(prop, value);
  };

  const renderContent = (): JSX.Element => (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} md={6} className="mt-6">
        <TextInput
          type="text"
          prop="name"
          label="Name *"
          required={true}
          value={configuration?.name}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} md={6} className="mt-6">
        <TextInput
          type="text"
          prop="unique_identifier"
          label="Unique Identifier *"
          required={true}
          disabled={action === 'edit'}
          value={configuration?.unique_identifier}
          onInputChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} className="mt-6">
        <TextInput
          type="text"
          prop="description"
          label="Description"
          value={configuration?.description}
          onInputChange={handleInputChange}
        />
      </Grid>
      {action === 'create' && onExtraConfigDataChange && settingsEditorData && (
        <Grid item xs={12} className="mt-6">
          <SettingsEditor
            action="create"
            data={settingsEditorData}
            onInputChange={onExtraConfigDataChange}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <Box>
      {addPaper ? (
        <Paper className="shadow p-8">{renderContent()}</Paper>
      ) : (
        renderContent()
      )}
    </Box>
  );
};

export default ConfigurationForm;
