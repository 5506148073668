export const deviceTypeAbilitiesLabel: {
  [key: string]: string;
} = {
  activate_cloud_native_device: 'Activate Cloud Native Device',
  aws_iot: 'AWS IoT & Greengrass Support',
  azure_iot: 'Azure IoT Ability',
  backup: 'Backup',
  bacnet: 'BACnet Ability',
  bluemix_iot: 'Bluemix IoT Ability',
  data_reset: 'Data Reset',
  data_restore: 'Restore Data',
  deactivate_cloud_native_device: 'Deactivate Cloud Native Device',
  deploy_deployment: 'Deploy',
  disable_data_restriction: 'Disable Data Restrictions',
  email: 'Email Ability',
  enable_data_restriction: 'Enable Data Restrictions',
  file_transfer: 'File transfer',
  heartbeat: 'Prompt Heartbeat',
  http_request: 'HTTP Request Ability',
  log: 'Log Ability',
  log_config: 'Log Config',
  log_level: 'Log Level',
  log_upload: 'Upload Logs',
  lwm2m_request: 'LWM2M Request',
  mqtt: 'MQTT Ability',
  notification: 'Alert',
  opcua: 'OPC-UA Ability',
  provision_marmon_button: 'Provision Marmon Button',
  prtg: 'PRTG Ability',
  reboot: 'Reboot',
  relay: 'Relay Ability',
  remote_terminal: 'Remote Terminal',
  request_deployment_status: 'Request Deployment Status',
  reset: 'Reset',
  restore_backup: 'Restore Backup',
  send_config: 'Send Config',
  setting: 'Setting',
  sms: 'SMS Ability',
  sms_at_command_status_request: 'SMS AT Status Request',
  software_update: 'Software Update',
  status: 'Prompt Status',
  tcp: 'TCP Ability',
  tcp_modbus: 'TCP Modbus Ability',
  update_edge: 'Update Edge',
  update_firmware: 'Update Firmware',
};
