import React from 'react';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../../components/TextInput';

interface ShellPollingSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
}

const ShellPollingSection: React.FC<ShellPollingSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          classes="full-height"
          label="Command"
          placeholder=""
          prop="listener.command"
          value={newIngestor.listener.command}
          onInputChange={onInputChange}
          disabled={disabled}
          multiline
          rows={4}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Timeout (seconds)"
          placeholder=""
          prop="listener.timeout"
          value={newIngestor.listener.timeout}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Poll Interval"
          placeholder=""
          prop="listener.poll_interval"
          value={newIngestor.listener.poll_interval}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>
    </>
  );
};

export default ShellPollingSection;
