import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import { profileTypesMap } from '../../app/constants';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';
import useStyles from './styles';

interface DevicesProfilesFiltersProps {
  total: number;
}

const DevicesProfilesFilters: React.FC<DevicesProfilesFiltersProps> = ({
  total,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.devicesTypes.filters;

  const [typesSelected, setTypesSelected] = useState<string[]>([]);

  const profilesTypes = Object.keys(profileTypesMap);
  const allTypesSelected = typesSelected.length === profilesTypes.length;

  useEffect(() => {
    setTypesSelected(
      filters?.type && filters.type !== '' ? filters.type.split('|') : [],
    );
  }, [filters]);

  const changeFilters = (prop: string, value: string): void => {
    // TODO: check if the values are the same before dispatching
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'devicesTypes',
      ),
    );
  };

  const handleTypeChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event;

    if (value.includes('all')) {
      return;
    }
    changeFilters(
      'type',
      typeof value === 'string'
        ? value.replaceAll(',', '|')
        : value.length > 0
        ? value.join('|')
        : '',
    );
  };

  const selectAllTypes = (): void => {
    if (!allTypesSelected) {
      changeFilters('type', profilesTypes.join('|'));
    } else {
      changeFilters('type', '');
    }
  };

  return (
    <Filters
      hasSearchInput={true}
      total={total}
      inputPlaceholder="Search device profiles by Name"
      inputKey="name"
      filterModel="devicesTypes"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          multiple
          displayEmpty
          className={classes.input}
          value={typesSelected}
          onChange={handleTypeChange}
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | React.ReactElement => {
            if (selected.length === 0) {
              return <Typography variant="button">Profile Type</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Types selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox
              checked={allTypesSelected}
              indeterminate={typesSelected.length !== 0 && !allTypesSelected}
              onClick={selectAllTypes}
            />
            <ListItemText primary="All" />
          </MenuItem>
          {profilesTypes.map((profileType, key) => (
            <MenuItem dense key={key} value={profileType}>
              <Checkbox checked={typesSelected.indexOf(profileType) > -1} />
              <ListItemText primary={profileTypesMap[profileType]} />
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default DevicesProfilesFilters;
