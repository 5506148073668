import React from 'react';
import { Avatar, Typography, TypographyVariant } from '@mui/material';
import clsx from 'clsx';

import { stringAvatar } from '../../helpers/avatar';

interface UserAvatarProp {
  preAvatarText?: string;
  preAvatarTextVariant?: TypographyVariant;
  preAvatarTextClass?: string;
  logoUrl?: string;
  name?: string;
}

const UserAvatar: React.FC<UserAvatarProp> = ({
  preAvatarText,
  preAvatarTextVariant,
  preAvatarTextClass,
  logoUrl,
  name,
  // role,
}) => {
  const getAcronym = (): {
    children: string;
    sx: { bgcolor: string; width: string; height: string };
  } => {
    if (!name) {
      return {
        children: 'U',
        sx: {
          bgcolor: 'gray',
          height: '24px',
          width: '24px',
        },
      };
    }
    const nameAvatar = stringAvatar(`${name}`);
    return {
      children: nameAvatar.children,
      sx: {
        ...nameAvatar.sx,
        height: '24px',
        width: '24px',
      },
    };
  };

  return (
    <div className="d-flex flex-justify-start flex-items-center my-2">
      {preAvatarText && (
        <Typography
          noWrap
          variant={preAvatarTextVariant}
          component="span"
          className={clsx('mr-1', preAvatarTextClass)}
        >
          {preAvatarText}
        </Typography>
      )}

      {logoUrl ? (
        <Avatar alt={`${name}`} src={logoUrl} data-testid="logo-url" />
      ) : (
        <Avatar
          {...getAcronym()}
          className="mx-1"
          style={{ fontSize: '12px' }}
        />
      )}
      <Typography
        noWrap
        variant="button"
        component="span"
        // className={clsx('mr-1', preAvatarTextClass)}
      >
        {name}
      </Typography>
    </div>
  );
};

export default UserAvatar;
