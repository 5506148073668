import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import {
  Authentication,
  Companies,
  EdgeIQAPI,
  User,
  UserType,
  UserTypes,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../redux/hooks';
import {
  setUserData,
  setUserCompany,
  setUserType,
  setUserCompanies,
} from '../redux/reducers/user.reducer';
import { getToken, removeToken, setInitialFilters } from '../helpers/storage';

import AccessLayout from '../layouts/access';
import AccountsPage from '../pages/accounts';
import AccountContent from '../pages/accountContent';
import CreateAccountPage from '../pages/createAccount';
import MainLayout from '../layouts/main';
import DashboardPage from '../pages/dashboard';
import Login from '../pages/login';
import RecoverPassword from '../pages/recoverPassword';
import ResetPassword from '../pages/resetPassword';
import DevicesPage from '../pages/devices';
import CreateDevicePage from '../pages/createDevice';
import DeviceContent from '../pages/deviceContent';
import DevicesProfilesPage from '../pages/devicesProfiles';
import DataManagementPage from '../pages/dataManagement';
import PoliciesPage from '../pages/policies/Policies';
import DeviceProfileContent from '../pages/deviceProfileContent';
import UserContentPage from '../pages/userContent/UserContent';
import PolicyContentPage from '../pages/policiesContent/PolicyContent';
import FilesPage from '../pages/files';
import UsersPage from '../pages/users/Users';
import CreateUserPage from '../pages/createUser/CreateUser';
import IntegrationsPage from '../pages/integrations/Integrations';
import CreateIntegrationPage from '../pages/createIntegration';
import SecretsPage from '../pages/secrets';
import CreateSecretPage from '../pages/createSecret';
import IntegrationContent from '../pages/integrationContent';
import DiscoveredDeviceContent from '../pages/discoveredDeviceContent';
import CommandsPage from '../pages/commands/Commands';
import CreateTranslatorPage from '../pages/dataManagement/createTranslator';
import CreateCommandPage from '../pages/createCommand';
import CommandContent from '../pages/commandContent';
import ConfigsPage from '../pages/configs';
import ConfigContentPage from '../pages/configContent';
import TranslatorContentPage from '../pages/dataManagement/translatorContent';
import CreateDeviceProfilePage from '../pages/createDeviceProfile';
import PollableContent from '../pages/dataManagement/pollableContent';
import SoftwarePackagesPage from '../pages/softwarePackages';
import CreatePollable from '../pages/dataManagement/createPollable';
import CreateIngestorPage from '../pages/dataManagement/createIngestor';
import IngestorContent from '../pages/dataManagement/ingestorContent';
import SoftwarePackageContent from '../pages/softwarePackageContent';
import CreateSoftwarePackage from '../pages/createSoftwarePackage';
import TranfersPage from '../pages/transfers';
import CreatePolicyPage from '../pages/createPolicy';
import CreateDeviceConfig from '../pages/createDeviceConfigConnection';
import CreateConfiguration from '../pages/createConfiguration';
import ConfigurationContent from '../pages/configurationContent';
import ConfigurationsPage from '../pages/configurations';
import CreateSettings from '../pages/createSettings';
import SettingsContent from '../pages/settingsContent/SettingsContent';
import SoftwarePackagesLog from '../pages/softwarePackagesLog';
import MessagesPage from '../pages/messages/Messages';
import ReportsPage from '../pages/Reports/Reports';
import CommandTranslatorsPage from '../pages/commandTranslators/CommandTranslators';
import CreateCommandTranslator from '../pages/createCommandTranslator';
import ScheduledJobsPage from '../pages/scheduledJobs/ScheduledJobs';
import CreateScheduledJobPage from '../pages/createScheduledJobs';
import ScheduledJobContent from '../pages/scheduledJobContent';
import WorkflowsPage from '../pages/workflows/Workflows';
import WorkflowContent from '../pages/workflowContent/WorkflowContent';
import ActionsPage from '../pages/actions/Actions';
import AnalyticsPage from './MomentaryPage';
import { AppTheme } from './Theme';
import { MAX_ACCOUNTS } from './constants';
import './App.css';
import RefreshLoader from '../components/Refresh';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    EdgeIQAPI.init({
      url: process.env.REACT_APP_API_URL,
    });
    setInitialFilters();
    const token = getToken();
    if (token) {
      EdgeIQAPI.setSessionToken(token);
      Authentication.me()
        .then((user: User) => {
          if (user) {
            // It's crucial for the branding to have the company of the user first
            dispatch(setUserData(user));
            Companies.getOneById(user.company_id)
              .then((result) => {
                dispatch(setUserCompany(result));
                // Then to have the user type to know the permissions and so to show or not create buttons for example
                return UserTypes.getOneById(user.user_type_id);
              })
              .then((userType: UserType) => {
                dispatch(setUserType(userType));
                setLoading(false);
                // TODO: There is no crucial need to get the user's extra companies in here, it just slows the setup
                return Companies.list(
                  {},
                  { itemsPerPage: MAX_ACCOUNTS, page: 1 },
                );
              })
              .then((result) => {
                dispatch(setUserCompanies(result.companies));
              });
          }
        })
        .catch(() => {
          removeToken();
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={<AccessLayout component={Login} backToLogin={false} />}
            />
            <Route
              path="/recover-password"
              element={
                <AccessLayout component={RecoverPassword} backToLogin={true} />
              }
            />
            <Route
              path="/reset-password"
              element={
                <AccessLayout component={ResetPassword} backToLogin={true} />
              }
            />
            <Route
              path="/dashboard"
              element={
                <MainLayout component={DashboardPage} loading={loading} />
              }
            />
            <Route
              path="/devices"
              element={<MainLayout component={DevicesPage} loading={loading} />}
            />
            <Route
              path="/new-device"
              element={
                <MainLayout component={CreateDevicePage} loading={loading} />
              }
            />
            <Route
              path="/device/:id"
              element={
                <MainLayout component={DeviceContent} loading={loading} />
              }
            />
            <Route
              path="/discovered-device/:id"
              element={
                <MainLayout
                  component={DiscoveredDeviceContent}
                  loading={loading}
                />
              }
            />
            <Route
              path="/device-profiles"
              element={
                <MainLayout component={DevicesProfilesPage} loading={loading} />
              }
            />
            <Route
              path="/device-profile/:id"
              element={
                <MainLayout
                  component={DeviceProfileContent}
                  loading={loading}
                />
              }
            />
            <Route
              path="/software-updates"
              element={
                <MainLayout
                  component={SoftwarePackagesPage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/software-update/:id"
              element={
                <MainLayout
                  component={SoftwarePackageContent}
                  loading={loading}
                />
              }
            />
            <Route
              path="/software-update/logs/:id"
              element={
                <MainLayout component={SoftwarePackagesLog} loading={loading} />
              }
            />
            <Route
              path="/software-update/logs/:id"
              element={
                <MainLayout component={SoftwarePackagesLog} loading={loading} />
              }
            />
            <Route
              path="/new-software-update"
              element={
                <MainLayout
                  component={CreateSoftwarePackage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/data-management"
              element={
                <MainLayout component={DataManagementPage} loading={loading} />
              }
            />
            <Route
              path="/policies"
              element={
                <MainLayout component={PoliciesPage} loading={loading} />
              }
            />
            <Route
              path="/users"
              element={<MainLayout component={UsersPage} loading={loading} />}
            />
            <Route
              path="/secrets"
              element={<MainLayout component={SecretsPage} loading={loading} />}
            />
            <Route
              path="/new-secret"
              element={
                <MainLayout component={CreateSecretPage} loading={loading} />
              }
            />
            <Route
              path="/new-user"
              element={
                <MainLayout component={CreateUserPage} loading={loading} />
              }
            />
            <Route
              path="/user/:id"
              element={
                <MainLayout component={UserContentPage} loading={loading} />
              }
            />
            <Route
              path="/profile/:id"
              element={
                <MainLayout component={UserContentPage} loading={loading} />
              }
            />
            <Route
              path="/accounts"
              element={
                <MainLayout component={AccountsPage} loading={loading} />
              }
            />
            <Route
              path="/new-account"
              element={
                <MainLayout component={CreateAccountPage} loading={loading} />
              }
            />
            <Route
              path="/account/:id"
              element={
                <MainLayout component={AccountContent} loading={loading} />
              }
            />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route
              path="/new-integration"
              element={
                <MainLayout
                  component={CreateIntegrationPage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/integrations"
              element={
                <MainLayout component={IntegrationsPage} loading={loading} />
              }
            />
            <Route
              path="/integration/:id"
              element={
                <MainLayout component={IntegrationContent} loading={loading} />
              }
            />
            <Route
              path="/new-command-translator"
              element={
                <MainLayout
                  component={CreateCommandTranslator}
                  loading={loading}
                />
              }
            />
            <Route
              path="/command-translators"
              element={
                <MainLayout
                  component={CommandTranslatorsPage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/commands"
              element={
                <MainLayout component={CommandsPage} loading={loading} />
              }
            />
            <Route
              path="/data-management/new-translator"
              element={
                <MainLayout
                  component={CreateTranslatorPage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/data-management/new-pollable"
              element={
                <MainLayout component={CreatePollable} loading={loading} />
              }
            />
            <Route
              path="/data-management/pollable-attribute/:id"
              element={
                <MainLayout component={PollableContent} loading={loading} />
              }
            />
            <Route
              path="/new-command"
              element={
                <MainLayout component={CreateCommandPage} loading={loading} />
              }
            />
            <Route
              path="/command/:id"
              element={
                <MainLayout component={CommandContent} loading={loading} />
              }
            />
            <Route
              path="/configurations"
              element={
                <MainLayout component={ConfigurationsPage} loading={loading} />
              }
            />
            <Route
              path="/new-configuration"
              element={
                <MainLayout component={CreateConfiguration} loading={loading} />
              }
            />
            <Route
              path="/configuration/:id"
              element={
                <MainLayout
                  component={ConfigurationContent}
                  loading={loading}
                />
              }
            />
            <Route
              path="/configuration/:id/new-settings"
              element={
                <MainLayout component={CreateSettings} loading={loading} />
              }
            />
            <Route
              path="/configuration/:configId/settings/:id"
              element={
                <MainLayout component={SettingsContent} loading={loading} />
              }
            />
            <Route
              path="/data-management/translator/:id"
              element={
                <MainLayout
                  component={TranslatorContentPage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/new-device-profile"
              element={
                <MainLayout
                  component={CreateDeviceProfilePage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/data-management/new-ingestor"
              element={
                <MainLayout component={CreateIngestorPage} loading={loading} />
              }
            />
            <Route
              path="/data-management/ingestor/:id"
              element={
                <MainLayout component={IngestorContent} loading={loading} />
              }
            />
            <Route
              path="/configs"
              element={<MainLayout component={ConfigsPage} loading={loading} />}
            />
            <Route
              path="/new-config-connection"
              element={
                <MainLayout component={CreateDeviceConfig} loading={loading} />
              }
            />
            <Route
              path="/config/:id"
              element={
                <MainLayout component={ConfigContentPage} loading={loading} />
              }
            />
            <Route
              path="/new-policy"
              element={
                <MainLayout component={CreatePolicyPage} loading={loading} />
              }
            />
            <Route
              path="/policy/:id"
              element={
                <MainLayout component={PolicyContentPage} loading={loading} />
              }
            />
            <Route
              path="/transfers"
              element={
                <MainLayout component={TranfersPage} loading={loading} />
              }
            />
            <Route
              path="/files"
              element={<MainLayout component={FilesPage} loading={loading} />}
            />

            <Route
              path="/messages"
              element={
                <MainLayout component={MessagesPage} loading={loading} />
              }
            />

            <Route
              path="/reports"
              element={<MainLayout component={ReportsPage} loading={loading} />}
            />

            <Route
              path="/analytics"
              element={
                <MainLayout component={AnalyticsPage} loading={loading} />
              }
            />

            <Route
              path="/scheduled-jobs"
              element={
                <MainLayout component={ScheduledJobsPage} loading={loading} />
              }
            />
            <Route
              path="/new-scheduled-job"
              element={
                <MainLayout
                  component={CreateScheduledJobPage}
                  loading={loading}
                />
              }
            />
            <Route
              path="/scheduled-job/:id"
              element={
                <MainLayout component={ScheduledJobContent} loading={loading} />
              }
            />

            <Route
              path="/workflows"
              element={
                <MainLayout component={WorkflowsPage} loading={loading} />
              }
            />
            <Route
              path="/workflow/:id"
              element={
                <MainLayout component={WorkflowContent} loading={loading} />
              }
            />
            <Route
              path="/actions"
              element={<MainLayout component={ActionsPage} loading={loading} />}
            />
            <Route
              path="/refresh"
              element={
                <MainLayout component={RefreshLoader} loading={loading} />
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
