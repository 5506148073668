import { SettingsApplicationRecord } from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';
import { TableColumn, TableItemType } from '../../../components/SharedTable';

export const SettingsApplicationRecordColumns: TableColumn[] = [
  {
    id: 'created_at',
    label: 'Date/Time',
    parser: (item: TableItemType): string | React.ReactElement => {
      const settingApplication = item as SettingsApplicationRecord;
      return `${DateTime.fromISO(settingApplication.created_at).toFormat(
        'TT',
      )} ${DateTime.fromISO(settingApplication.created_at).toLocaleString(
        DateTime.DATE_SHORT,
      )}`;
    },
    type: 'custom',
  },
  {
    cellValue: (item: TableItemType): string => {
      const integrationEvent = item as SettingsApplicationRecord;
      return integrationEvent.setting.name ?? '--';
    },
    id: 'setting',
    label: 'Setting',
    type: 'text',
  },
  {
    cellValue: (item: TableItemType): string => {
      const integrationEvent = item as SettingsApplicationRecord;
      return integrationEvent.command?.name ?? '--';
    },
    id: 'name',
    label: 'Command Name',
    type: 'text',
  },
];
