import React, { ChangeEvent } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

export interface CheckboxInputProps {
  label: string;
  value: string;
  prop: string;
  placeholder?: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onCheckboxClick?: (prop: string, value: string) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  prop,
  checked,
  indeterminate,
  disabled = false,
  value,
  onCheckboxClick,
}) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onCheckboxClick) {
      onCheckboxClick(prop, event.target.name);
    }
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid="checkbox-input"
          name={value}
          checked={checked}
          indeterminate={indeterminate}
          disabled={disabled}
          onChange={handleCheckboxChange}
        />
      }
      label={label}
    />
  );
};

export default CheckboxInput;
