import React from 'react';
import { Tooltip, Typography, Box } from '@mui/material';
import { Ingestor, Translator } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { getCompanySelector } from '../../../redux/reducers/user.reducer';

import {
  INGESTOR_HANDLER_TYPES_MAP,
  INGESTOR_LISTENER_TYPES_MAP,
} from '../../../constants/ingestors';

import useStyles from '../../../components/RightDrawer/styles';

interface AttachIngestorsCardProps {
  ingestor: Ingestor;
  translators: Translator[];
}

const AttachIngestorsCard: React.FC<AttachIngestorsCardProps> = ({
  ingestor,
  translators,
}) => {
  const companyData = useAppSelector((state: RootState) =>
    getCompanySelector(state.user, ingestor.company_id),
  );

  const classes = useStyles({ accountImage: companyData?.branding?.logo_url });

  return (
    <>
      <Tooltip placement="top" title={ingestor.name}>
        <Typography
          variant="h6"
          component="div"
          noWrap
          className={clsx('mb-2')}
        >
          {ingestor.name}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Listener type">
        <Typography
          variant="caption"
          component="div"
          className={clsx('mb-2', classes.yellowSubtitle)}
        >
          {INGESTOR_LISTENER_TYPES_MAP[ingestor.listener_type]}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Handler type">
        <Typography variant="overline" component="div" className="mb-2">
          {INGESTOR_HANDLER_TYPES_MAP[ingestor.handler_type]}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Translator">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-2', classes.tag)}
        >
          {
            translators.find(
              (translator) => translator._id === ingestor.translator_id,
            )?.name
          }
        </Typography>
      </Tooltip>
      <Box className={clsx('mb-1', classes.accountBox)}>
        <Typography variant="overline" component="div">
          {companyData?.name}
        </Typography>
      </Box>
    </>
  );
};

export default AttachIngestorsCard;
