import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Paper,
  MenuItem,
} from '@mui/material';
import {
  Configuration,
  Device,
  SettingsApplicationRecord,
  SettingApplicationRecords,
  PaginationFilter,
  Setting,
  SettingsApplicationRecordFilters,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import SettingApplicationListCard from './SettingApplicationListCard';
import {
  ApplySettingsDrawer,
  SettingApplicationRecordDrawer,
} from '../RightDrawer';
import SelectInput from '../../components/SelectInput';

interface SettingApplicationListProps {
  device?: Device;
  configuration?: Configuration;
  settingsToCompare?: Setting[]; // Can only be 2 settings at a time.
  showCompareButton?: boolean;
  onChoosingSetting?: (setting: Setting) => void;
}

const SettingApplicationList: React.FC<SettingApplicationListProps> = ({
  device,
  configuration,
  settingsToCompare,
  showCompareButton,
  onChoosingSetting,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [settingsApplicationRecords, setSettingsApplicationRecords] = useState<
    SettingsApplicationRecord[]
  >([]);
  const [type, setType] = useState('sent');
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openRecordDrawer, setOpenRecordDrawer] = useState(false);
  const [openApplySettingDrawer, setOpenApplySettingDrawer] = useState(false);
  const [selectedRecord, setSelectedRecord] =
    useState<SettingsApplicationRecord>();

  const getSettingsApplicationRecord = (
    pageNumber: number,
    typeToApply: string,
    addPage = false,
  ): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 10,
      page: pageNumber,
    };

    const filter: SettingsApplicationRecordFilters = {
      type: {
        operator: 'eq',
        value: typeToApply,
      },
    };
    if (device) {
      filter.device_id = {
        operator: 'eq',
        value: device._id,
      };
    }

    if (configuration) {
      filter.configuration_id = {
        operator: 'eq',
        value: configuration._id,
      };
    }

    SettingApplicationRecords.list(filter, pagination)
      .then((result) => {
        const newSettingApplication = addPage
          ? [
              ...settingsApplicationRecords,
              ...result.settingsApplicationRecords,
            ]
          : result.settingsApplicationRecords;
        setSettingsApplicationRecords(newSettingApplication);
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    setLoading(true);
    if (device?._id || configuration?._id) {
      getSettingsApplicationRecord(1, type);
    }
  }, [device, configuration]);

  const noLoading = (): void => {
    setLoading(false);
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const onChangeRecordsType = (_prop: string, value: string): void => {
    setType(value);
    getSettingsApplicationRecord(1, value);
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getSettingsApplicationRecord(page + 1, type, true);
  };

  const openData = (
    applicationRecord: SettingsApplicationRecord,
    dataType: string,
  ): void => {
    const configId = applicationRecord.configuration_id;
    let url = ``;
    switch (dataType) {
      case 'configuration':
        url = `${url}/configuration/${configId}`;
        break;
      case 'command':
        const commandId = applicationRecord.command._id;
        url = `${url}/command/${commandId}`;
        break;
      case 'device':
        const idDevice = applicationRecord.device_id;
        url = `${url}/device/${idDevice}`;
        break;
      case 'settings':
        const settingId = applicationRecord.setting_id;
        url = `${url}/configuration/${configId}/settings/${settingId}#${applicationRecord.version}`;
        break;
      default:
        break;
    }
    navigate(url, {
      state: {
        goBackLabel: device
          ? `Device: ${device?.name}`
          : `Configuration: ${configuration?.name}`,
        goBackUrl: device
          ? `device/${device?._id}#settings`
          : `configuration/${configuration?._id}#settings`,
      },
    });
  };

  const viewApplicationRecord = (
    applicationRecord: SettingsApplicationRecord,
  ): void => {
    setSelectedRecord(applicationRecord);
    setOpenRecordDrawer(true);
  };

  const reapplySettings = (
    applicationRecord: SettingsApplicationRecord,
  ): void => {
    setSelectedRecord(applicationRecord);
    setOpenApplySettingDrawer(true);
  };

  const handleCloseRecordDrawer = (): void => {
    setSelectedRecord(undefined);
    setOpenRecordDrawer(false);
  };

  const handleCloseApplySettings = (): void => {
    setSelectedRecord(undefined);
    setOpenApplySettingDrawer(false);
  };

  return (
    <Paper className="shadow py-8 px-6">
      <Typography
        data-cy="settings-application-records-title"
        className="mb-4"
        variant="h5"
      >
        Settings Application Records
      </Typography>
      <SelectInput
        prop="type"
        value={type}
        onSelectChange={onChangeRecordsType}
        classes="mb-7 w-50"
        fullWidth={false}
        options={[
          <MenuItem key="sent" className="m-4 p-2" dense value="sent">
            Applied
          </MenuItem>,
          <MenuItem key="reported" className="m-4 p-2" dense value="reported">
            Reported
          </MenuItem>,
        ]}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <Grid container direction={'column'}>
          {settingsApplicationRecords.map((settingApplication) => {
            return (
              <SettingApplicationListCard
                key={settingApplication._id}
                settingApplicationRecord={settingApplication}
                openSettings={openData}
                viewApplication={viewApplicationRecord}
                reapplySetting={reapplySettings}
                settingsToCompare={settingsToCompare}
                showCompareButton={showCompareButton}
                onChoosingSetting={onChoosingSetting}
              />
            );
          })}
          {settingsApplicationRecords.length !== total && (
            <Grid item xs={12} className="mb-9 loading-container">
              <Button
                data-cy="load-more-records"
                variant="outlined"
                size="large"
                onClick={handleLoadMore}
              >
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
          {settingsApplicationRecords.length === 0 && (
            <Grid item xs={12}>
              <Typography
                variant="button"
                data-cy="no-application-records-message"
              >
                {`This configuration ${
                  type === 'sent'
                    ? `has not been applied to ${
                        device ? 'this' : 'any'
                      } device, so there are no records yet.`
                    : 'no reported settings yet.'
                }`}
              </Typography>
            </Grid>
          )}
          {selectedRecord && (
            <SettingApplicationRecordDrawer
              open={openRecordDrawer}
              settingApplicationRecord={selectedRecord}
              deviceLink={!device?._id}
              configLink={!!device?._id}
              openOtherData={openData}
              handleCloseDrawer={handleCloseRecordDrawer}
            />
          )}
          {selectedRecord?.setting && selectedRecord?.configuration && (
            <ApplySettingsDrawer
              open={openApplySettingDrawer}
              isBulk={false}
              configuration={selectedRecord.configuration}
              commands={[]}
              devices={[selectedRecord.device]}
              setting={selectedRecord.setting}
              handleCloseDrawer={handleCloseApplySettings}
            />
          )}
        </Grid>
      )}
    </Paper>
  );
};

export default SettingApplicationList;
